import { FC, useCallback } from "react";
import { Header, TemplateContainer, IconContainer } from "./styled";
import Text from "../../../../../components/Text";
import { textTypes } from "../../../../../styles/typography";
import { Row, Col } from "../../../../../components/Grid";
import { useNavigate } from "react-router-dom";
import { ReactComponent as CloseIcon } from "../../../../../assets/icons/close.svg";
import { useStore } from "../../../../../store";
import Scrollbars from "react-custom-scrollbars";
import Button from "../../../../../components/Button";
import { ButtonSize, buttonTypes } from "../../../../../styles/buttons";
import { observer } from "mobx-react-lite";
import { Space } from "../../../../../types/constants";
import { getImageSrc } from "../../../../../utils/get-host";

const PreView: FC = () => {
  const { templates, campaigns } = useStore();
  const navigate = useNavigate();
  const selectTemplate = useCallback(() => {
    navigate("/marketing/campaigns/newCampaign/addSubject");
  }, []);
  const close = useCallback(() => {
    navigate("/marketing/campaigns");
  }, []);

  return (
    <>
      <Row>
        <Header>
          <Col>
            <Text type={textTypes.h6Bold!}>Preview</Text>
          </Col>
          <Col itemRight auto>
            <Row>
              <Button
                type="button"
                round
                size={ButtonSize.sm}
                variant={buttonTypes.default!}
                mr={Space.xs}
                onClick={selectTemplate}
              >
                SELECT TEMPLATE
              </Button>
              <IconContainer onClick={close} exit>
                <CloseIcon />
              </IconContainer>
            </Row>
          </Col>
        </Header>
      </Row>
      <Row>
        <TemplateContainer>
          <Scrollbars>
            {campaigns.templateId && <img src={getImageSrc(templates?.entries?.get(campaigns.templateId)?.image ?? "")} />}
          </Scrollbars>
        </TemplateContainer>
      </Row>
    </>
  );
};

export default observer(PreView);
