import { FC, useEffect, useState } from "react";
import Modal from "..";
import { textTypes } from "../../../styles/typography";
import { Row, Col } from "../../Grid";
import Text from "../../Text";
import {
	BottomContainer,
	Container,
	SpinnerContainer,
	TemplatesContainer,
	Card,
	CardImage
} from "./styled";
import { Space } from "../../../types/constants";
import { Transition } from "react-transition-group";
import { useStore } from "../../../store";
import { Form, Field } from "react-final-form";
import { useCallback } from "react";
import { useMemo } from "react";
import { FormTextField as TextField } from "../../TextField";
import { FormSelect as Select } from "../../Select";
import { TextFieldSize } from "../../../styles/text-field";
import Button from "../../Button";
import { ButtonSize, buttonTypes } from "../../../styles/buttons";
import { ReactComponent as CloseSVG } from "../../../assets/icons/close.svg";
import { SelectSize } from "../../../styles/select";
import { required, requiredMin } from "../../../validations";
import Spinner from "../../Spinners";
import { SpinnerSize, SpinnerType } from "../../../styles/spinners";
import { getGroupContactsCount, sendEmailsByGroup } from "../../../api/dynamic-groups";
import Scrollbars from "react-custom-scrollbars";
import { getImageSrc } from "../../../utils/get-host";

interface IGroupSend {
	id?: string;
	isOpen?: boolean;
	onSuccess: () => void;
	onClose: () => void;
}

interface IGroupSendForm {
	title?: string;
	email?: { label: string; value: string };
	templateId?: string;
}

interface ISendGroupEmailData {
	groupId: string;
	title: string;
	email: string;
	templateId: string;
}

const emailsOptions = [
	{
		label: "no-reply@pocketbook-int.com",
		value: "no-reply@pocketbook-int.com",
	},
	{
		label: "support@readrate.com",
		value: "support@readrate.com",
	},
	{
		label: "mails@bookland.com",
		value: "mails@bookland.com",
	},
];

const TemplateSelect: FC<{templates: Array<{
	id: string,
	name: string,
	image: string,
}>}> = ({templates}) => {
	return (
		<Field name="templateId">
			{({ input: { onChange, value } }) => (
				<>
					<Text mb={16} type={textTypes.bodySm!} customColor="rgba(24,24,25,0.9)">Template</Text>
					<TemplatesContainer>
						<Scrollbars>
							{ templates.map((template) => (
								<Card selected={template.id === value} onClick={() => {
									onChange(template.id);
								}}>
									<Text ml={16} type={textTypes.h4!}>
											{template.name}
										</Text>
										<CardImage>
											<img src={getImageSrc(template.image)} />
										</CardImage>
								</Card>
							))}
						</Scrollbars>
					</TemplatesContainer>
				</>
			)}
		</Field>
	);
};

const GroupSend: FC<IGroupSend> = ({ id, isOpen, onClose, onSuccess }) => {
	const { dynamicGroups, templates } = useStore();
	const [contactsCount, setContactsCount] = useState<number | undefined>(
		undefined
	);
	const currentGroup = useMemo(
		() => (id ? dynamicGroups.entries.get(id) : undefined),
		[id]
	);

	useEffect(() => {
		if (id) {
			(async () => {
				const count = await getGroupContactsCount(id);
				setContactsCount(count);
			})();
		} else {
			setContactsCount(undefined);
		}
	}, [id]);

	const payload = useCallback(
		({ title, email, templateId }: IGroupSendForm) => ({
			groupId: currentGroup?.id || "",
			title: title || "",
			email: email?.value || "",
			templateId: templateId || "",
		} as ISendGroupEmailData),
		[currentGroup]
	);

	const onSubmit = useCallback(
		async (values: IGroupSendForm) => {
			const data = payload(values);
			sendEmailsByGroup(data);
			onSuccess();
		},
		[currentGroup]
	);

	const validate = useCallback(({ title, email, templateId }: IGroupSendForm) => {
		const errors = {
			title: required(title) || requiredMin(title!, 2),
			email: email ? required(email.value) : "Required field",
			templateId: required(templateId)
		};

		return errors;
	}, []);

	const initialValues: IGroupSendForm = useMemo(() => {
		return {};
	}, [currentGroup]);

	return (
		<Modal onClose={onClose} isOpen={isOpen} backdrop={true}>
			<Transition in={isOpen} timeout={250} unmountOnExit>
				{(state) => (
					<Container state={state}>
						<Row mt={Space.lg} ml={Space.lg} mr={Space.lg}>
							<Row fitWidth>
								<Col auto>
									<Text type={textTypes.h3Bold!}>
										{currentGroup ? `${currentGroup?.name || ""}` : "Group"}
									</Text>
									<Text type={textTypes.bodyMd!}>
										{`Available contacts`}{" "}
										{contactsCount ? (
											contactsCount
										) : (
											<SpinnerContainer>
												<Spinner
													variant={SpinnerType.dots}
													size={SpinnerSize.sm}
												/>{" "}
											</SpinnerContainer>
										)}
									</Text>
								</Col>
								<Col itemRight>
									<Button
										size={ButtonSize.sm}
										variant={buttonTypes.softSecondary}
										onClick={onClose}
										icon
									>
										<CloseSVG />
									</Button>
								</Col>
							</Row>
							<Col pt={Space.lg}>
								<Form
									initialValues={initialValues}
									validate={validate}
									onSubmit={onSubmit}
									render={({ handleSubmit }) => (
										<form onSubmit={handleSubmit}>
											<Row mt={Space.sm}>
												<TextField
													name="title"
													label="Title"
													placeholder="Enter title"
													size={TextFieldSize.md}
													fitWidth
												/>
											</Row>
											<Row mt={Space.sm}>
												<Select
													name="email"
													label="Email"
													placeholder="Send email to send from"
													options={emailsOptions}
												/>
											</Row>
											<Row mt={Space.sm}>
												<TemplateSelect templates={templates.array()} />
											</Row>
											<BottomContainer>
												<Row pt={16} pl={24} pr={24}>
													<Col itemRight>
														<Button
															type="submit"
															size={ButtonSize.md}
															variant={buttonTypes.outlinePrimary}
															onClick={() => {}}
														>
															Send
														</Button>
													</Col>
												</Row>
											</BottomContainer>
										</form>
									)}
								/>
							</Col>
						</Row>
					</Container>
				)}
			</Transition>
		</Modal>
	);
};

export default GroupSend;
