import { FC, useEffect, useState } from "react";
import Modal from "..";
import { textTypes } from "../../../styles/typography";
import { Row, Col } from "../../Grid";
import Text from "../../Text";
import {
	BottomContainer,
	Container,
	TemplatesContainer,
	Card,
	CardImage,
} from "./styled";
import { Space } from "../../../types/constants";
import { Transition } from "react-transition-group";
import { useStore } from "../../../store";
import { Form, Field } from "react-final-form";
import { useCallback } from "react";
import { useMemo } from "react";
import { FormTextField as TextField } from "../../TextField";
import { FormSelect as Select } from "../../Select";
import { TextFieldSize } from "../../../styles/text-field";
import Button from "../../Button";
import { ButtonSize, buttonTypes } from "../../../styles/buttons";
import { ReactComponent as CloseSVG } from "../../../assets/icons/close.svg";
import { SelectSize } from "../../../styles/select";
import { required, requiredMin } from "../../../validations";
import Spinner from "../../Spinners";
import { SpinnerSize, SpinnerType } from "../../../styles/spinners";
import { sendEmailsByStatsId } from "../../../api/email-stats";
import Scrollbars from "react-custom-scrollbars";
import { getImageSrc } from "../../../utils/get-host";

interface IEmailStatsInfo {
	id?: string;
	isOpen?: boolean;
	onSuccess: () => void;
	onClose: () => void;
}

interface IEmailStatsInfoForm {
	title?: string;
	email?: { label: string; value: string };
	templateId?: string;
	contactsTypeState: { label: string; value: string };
}

interface ISendEmailStatsData {
	emailStatsId: string;
	title: string;
	email: string;
	templateId: string;
	contactsTypeState: string;
}

const emailsOptions = [
	{
		label: "no-reply@pocketbook-int.com",
		value: "no-reply@pocketbook-int.com",
	},
	{
		label: "support@readrate.com",
		value: "support@readrate.com",
	},
	{
		label: "mails@bookland.com",
		value: "mails@bookland.com",
	},
];

const contactsTypeStateOptions = [
	{
		label: "Opened",
		value: "OPENED",
	},
	{
		label: "Not Opened",
		value: "NOT_OPENED",
	},
];

const TemplateSelect: FC<{
	templates: Array<{
		id: string;
		name: string;
		image: string;
	}>;
}> = ({ templates }) => {
	return (
		<Field name="templateId">
			{({ input: { onChange, value } }) => (
				<>
					<Text
						mb={16}
						type={textTypes.bodySm!}
						customColor="rgba(24,24,25,0.9)"
					>
						Template
					</Text>
					<TemplatesContainer>
						<Scrollbars>
							{templates.map((template) => (
								<Card
									selected={template.id === value}
									onClick={() => {
										onChange(template.id);
									}}
								>
									<Text ml={16} type={textTypes.h4!}>
										{template.name}
									</Text>
									<CardImage>
										<img src={getImageSrc(template.image)} />
									</CardImage>
								</Card>
							))}
						</Scrollbars>
					</TemplatesContainer>
				</>
			)}
		</Field>
	);
};

const EmailStatsInfo: FC<IEmailStatsInfo> = ({
	id,
	isOpen,
	onClose,
	onSuccess,
}) => {
	const { emailStats, templates } = useStore();
	const currentStats = useMemo(
		() => (id ? emailStats.entries.get(id) : undefined),
		[id]
	);

	const payload = useCallback(
		({ title, email, templateId, contactsTypeState }: IEmailStatsInfoForm) =>
			({
				emailStatsId: currentStats?.id || "",
				title: title || "",
				email: email?.value || "",
				templateId: templateId || "",
				contactsTypeState: contactsTypeState.value,
			} as ISendEmailStatsData),
		[currentStats]
	);

	const onSubmit = useCallback(
		async (values: IEmailStatsInfoForm) => {
			const data = payload(values);
			sendEmailsByStatsId(data);
			onSuccess();
		},
		[currentStats]
	);

	const validate = useCallback(
		({ title, email, templateId }: IEmailStatsInfoForm) => {
			const errors = {
				title: required(title) || requiredMin(title!, 2),
				email: email ? required(email.value) : "Required field",
				templateId: required(templateId),
			};

			return errors;
		},
		[]
	);

	const initialValues: IEmailStatsInfoForm = useMemo(() => {
		return {
			contactsTypeState: contactsTypeStateOptions[0],
		};
	}, [currentStats]);

	return (
		<Modal onClose={onClose} isOpen={isOpen} backdrop={true}>
			<Transition in={isOpen} timeout={250} unmountOnExit>
				{(state) => (
					<Container state={state}>
						<Row mt={Space.lg} ml={Space.lg} mr={Space.lg}>
							<Row fitWidth>
								<Col auto>
									<Text type={textTypes.h3Bold!}>
										{currentStats
											? `${currentStats?.title! || ""}`
											: "Email stats"}
									</Text>
									{currentStats && (
										<Text type={textTypes.bodyMd!}>
											{`Opened/NOT:  \u00a0 ${currentStats.opened}/${
												currentStats.sent - currentStats.opened
											}`}
										</Text>
									)}
								</Col>
								<Col itemRight>
									<Button
										size={ButtonSize.sm}
										variant={buttonTypes.softSecondary}
										onClick={onClose}
										icon
									>
										<CloseSVG />
									</Button>
								</Col>
							</Row>
							<Col pt={Space.lg}>
								<Form
									initialValues={initialValues}
									validate={validate}
									onSubmit={onSubmit}
									render={({ handleSubmit }) => (
										<form onSubmit={handleSubmit}>
											<Row mt={Space.sm}>
												<TextField
													name="title"
													label="Title"
													placeholder="Enter title"
													size={TextFieldSize.md}
													fitWidth
												/>
											</Row>
											<Row mt={Space.sm}>
												<Select
													name="email"
													label="Email"
													placeholder="Send email to send from"
													options={emailsOptions}
												/>
											</Row>
											<Row mt={Space.sm}>
												<Select
													name="contactsTypeState"
													label="Send to"
													placeholder=""
													options={contactsTypeStateOptions}
												/>
											</Row>
											<Row mt={Space.sm}>
												<TemplateSelect templates={templates.array()} />
											</Row>
											<BottomContainer>
												<Row pt={16} pl={24} pr={24}>
													<Col itemRight>
														<Button
															type="submit"
															size={ButtonSize.md}
															variant={buttonTypes.outlinePrimary}
															onClick={() => {}}
														>
															Send
														</Button>
													</Col>
												</Row>
											</BottomContainer>
										</form>
									)}
								/>
							</Col>
						</Row>
					</Container>
				)}
			</Transition>
		</Modal>
	);
};

export default EmailStatsInfo;
