import PermissionType from "../../permissions";
import { ReactComponent as ContactsIcon } from "../../../assets/icons/contacts.svg";
import { ReactComponent as EmailIcon } from "../../../assets/icons/email.svg";
import { ReactComponent as GroupsIcon } from "../../../assets/icons/groups.svg";
import { ReactComponent as CompaniesIcon } from "../../../assets/icons/companies.svg";
import { ReactComponent as TemplatesIcon } from "../../../assets/icons/templates.svg";
import { ReactComponent as StatsIcon } from "../../../assets/icons/statistics.svg";
import { ReactComponent as MarketingIcon } from "../../../assets/icons/marketing.svg";

export const marketingRoutes = {
	label: "marketing",
	categoryIcon: <MarketingIcon />,
	items: [
		{
			label: "All Contacts",
			path: "/marketing/contacts",
			icon: (
				<>
					<ContactsIcon />
				</>
			),
			permission: PermissionType.VIEW_CONTACT,
		},
		{
			label: "Campaigns",
			path: "/marketing/campaigns",
			icon: (
				<>
					<EmailIcon />
				</>
			),
			permission: PermissionType.VIEW_CAMPAIGN,
		},
		{
			path: "/marketing/campaigns/newCampaign/addSubject",
			permission: PermissionType.VIEW_EMAIL_STATS,
		},
		{
			path: "/marketing/campaigns/newCampaign/chooseRecepients",
			permission: PermissionType.VIEW_EMAIL_STATS,
		},
		{
			path: "/marketing/campaigns/newCampaign/send",
			permission: PermissionType.VIEW_EMAIL_STATS,
		},
		{
			path: "/marketing/campaigns/newCampaign/preview",
			permission: PermissionType.VIEW_EMAIL_STATS,
		},
		{
			path: "/marketing/campaigns/newAutomationCampaign",
			permission: PermissionType.VIEW_EMAIL_STATS,
		},
		{
			label: "Groups",
			path: "/marketing/groups",
			icon: (
				<>
					<GroupsIcon />
				</>
			),
			permission: PermissionType.VIEW_GROUP,
		},
		{
			label: "Companies",
			path: "/marketing/companies",
			icon: (
				<>
					<CompaniesIcon />
				</>
			),
			permission: PermissionType.VIEW_COMPANY,
		},
		{
			label: "Templates",
			path: "/marketing/templates",
			icon: (
				<>
					<TemplatesIcon />
				</>
			),
			permission: PermissionType.VIEW_EMAIL_TEMPLATE,
		},
		{
			path: "/marketing/templates/editor",
			permission: PermissionType.ADD_EMAIL_TEMPLATE,
		},
		{
			path: "/marketing/templates/editor/:id",
			permission: PermissionType.EDIT_EMAIL_TEMPLATE,
		},
		// {
		// 	label: "Actions",
		// 	path: "/marketing",
		// 	icon: (
		// 		<>
		// 			<GroupsIcon />
		// 		</>
		// 	),
		// },
		{
			label: "Stats",
			path: "/marketing/stats",
			icon: (
				<>
					<StatsIcon />
				</>
			),
			permission: PermissionType.VIEW_EMAIL_STATS,
		},
	],
};
