import { ENDPOINTS } from "../types/Endpoints";
import authFetch from "../utils/auth-fetch";

export const changePassword = async (data: {
	currentPassword: string;
	newPassword: string;
}) => {
	const result = await (
		await authFetch(`${ENDPOINTS.USER.CHANGE_PASSWORD}`, {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(data),
		})
	).text;

	return result;
};
