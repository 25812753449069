import React, { FC, useState, createRef, useLayoutEffect } from "react";
import {
	Container,
	TabsContainer,
	TabsWrap,
	TabsUnderline,
	TabButton,
	TabUnderline,
	Content,
	IconContainer, // vlad code
} from "./styled";
import Text from "../Text";
import { textTypes } from "../../styles/typography";
import { colorTypes } from "../../styles/colors";
import { useMemo } from "react";
import { useCallback } from "react";

interface ITab {
	children: React.ReactNode;
	name: string;
	icon?: any; // vlad code
} 

interface ITabs {
	children: React.ReactElement<ITab>[];
	centerTabs?: boolean;
}

interface ITabItem { 
	name: string;
	ref?: any;
	icon?: any; // vlad code
}

export const Tab: FC<ITab> = ({ children }) => {
	return <>{children}</>;
};
 // My Code below
function useTabItems(tabsNames: string[], tabsIcons: any[]) { // add tabIcons
	const [tabs, setTabs] = useState<ITabItem[]>([]);

	React.useEffect(() => {
		setTabs((elTabs) =>
			Array(tabsNames.length)
				.fill({ name: "", ref: null, icon: null }) // add icon
				.map((_, i) =>
					elTabs[i] && elTabs[i].name && elTabs[i].ref
						? elTabs[i]
						: { name: tabsNames[i], ref: createRef(), icon: tabsIcons[i]} // add icon
				)
		); // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tabsNames.length]);
	return tabs;
}
// end of my code
export const Tabs: FC<ITabs> = ({ children, centerTabs = false }) => {
	const [tabLine, setTabLine] = useState({
		width: 0,
		x: 0,
	});
	const childElements = useMemo(
		() => children.filter((element) => element.type === Tab),
		[children]
	);
	const tabsNames = useMemo(
		() => childElements.map((element) => element.props.name),
		[childElements]
	);

	const tabsIcons = useMemo(() => childElements.map((element) => element.props.icon), [childElements]); // add 
	
	//const tabsItems = useTabItems(tabsNames);
	const tabsItems = useTabItems(tabsNames, tabsIcons); // vlad code
	const [selectedTabIndex, setSelectedIndexTab] = useState(0);

	const onTabClick = (index: number) => {
		setSelectedIndexTab(index);
	};

	const getTabWidth = useCallback(
		() =>
			(tabsItems[selectedTabIndex] &&
				tabsItems[selectedTabIndex].ref.current &&
				tabsItems[selectedTabIndex].ref.current.clientWidth) ||
			140,
		[tabsItems, selectedTabIndex]
	);
	const getTabPositionX = useCallback(
		() =>
			(tabsItems[selectedTabIndex] &&
				tabsItems[selectedTabIndex].ref.current &&
				tabsItems[selectedTabIndex].ref.current.offsetLeft) ||
			0,
		[tabsItems, selectedTabIndex]
	);

	useLayoutEffect(() => {
		const w = getTabWidth();
		const x = getTabPositionX();

		setTabLine({
			width: w,
			x: x,
		});
	}, [getTabPositionX]);

	const renderTabs = useCallback(
		() =>
			tabsItems.map((tab, index) => (
				<TabButton
					key={`button-${tab.name}`}
					ref={tab.ref}
					onClick={() => onTabClick(index)}
				>
					{/* my code below */}
					{tab.icon && <IconContainer show={selectedTabIndex === index} key={`icon-${tab.name}`}>
						{tab.icon}
					</IconContainer>}
					{/* end of my code */}
					<Text
						type={
							selectedTabIndex === index
								? textTypes.bodyMdBold!
								: textTypes.bodyMd!
						}
						mr={0}
						ml={0}
						mt={0}
						color={
							selectedTabIndex === index
								? colorTypes.primary._700!
								: colorTypes.gray._900!
						}
					>
						{tab.name}
					</Text>
				</TabButton>
			)),
		[tabsItems, selectedTabIndex]
	);

	return (
		<Container>
			<TabsContainer>
				{centerTabs ? (
					<TabsWrap>
						{renderTabs()}
						<TabUnderline tabPositionX={tabLine.x} tabWidth={tabLine.width} />
					</TabsWrap>
				) : (
					<>
						{renderTabs()}
						<TabUnderline tabPositionX={tabLine.x} tabWidth={tabLine.width} />
					</>
				)}
				<TabsUnderline />
			</TabsContainer>
			<Content key={`tab-content-${selectedTabIndex}`}>
				{childElements[selectedTabIndex]}
			</Content>
		</Container>
	);
};
