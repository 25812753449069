import { FC, useCallback, useMemo } from "react";
import { CampaignSettings } from "./styled";
import { Form } from "react-final-form";
import { FormTextField as TextField } from "../../../../../components/TextField";
import { FormSelect as Select } from "../../../../../components/Select";
import { TextFieldSize } from "../../../../../styles/text-field";
import { SelectSize } from "../../../../../styles/select";
import Button from "../../../../../components/Button";
import { ButtonSize, buttonTypes } from "../../../../../styles/buttons";
import Text from "../../../../../components/Text";
import { textTypes } from "../../../../../styles/typography";
import { Row, Col } from "../../../../../components/Grid";
import HeaderNewCampaign from "../../../../../components/HeaderNewCampaign";
import { useLocation, useNavigate } from "react-router";
import { Wrapper } from "../ChooseRecipients/styled";
import { required, requiredMin } from "../../../../../validations";
import { useStore } from "../../../../../store";
import { observer } from "mobx-react-lite";
import { Space } from "../../../../../types/constants";

const personalize: Array<any> = [
  { label: "Personalize1", value: "PersonalizeItem1" },
  { label: "Personalize2", value: "PersonalizeItem2" },
];
const from: Array<any> = [
  { label: "from1", value: "from1" },
  { label: "from2", value: "from2" },
];
const senderName: Array<any> = [
  { label: "senderName1", value: "senderName1" },
  { label: "senderName2", value: "senderName2" },
];

const payload = ({campaignName, subject, personalize, from, senderName}: any) => {
  return {
    campaignName: campaignName, // add
    subject: subject, // add 
    personalize: personalize.value,
    from: from.value,
    senderName: senderName.value,
  }
};

const AddSubject: FC = () => {
  let location = useLocation()!.state as any;
  let state = location === null ? false : location.edit; // add location to check previous page, if true ? previous page is send page : not a send page.
  const navigate = useNavigate();
  const { campaigns } = useStore();
  const onSubmit = useCallback(
    async (values: any) => {
      campaigns.subject?.init(payload(values));
      if(state){
        navigate("/marketing/campaigns/newCampaign/send");
      }else{
        navigate("/marketing/campaigns/newCampaign/chooseRecepients");
      }
    },
    []
  );

  const validate = useCallback(({campaignName, subject, personalize}) => {
    if(state === false){
      const errors = {
        campaignName: required(campaignName) || requiredMin(campaignName, 2),
        subject: required(subject) || requiredMin(subject, 2),
        personalize: required(personalize),
      }
      return errors;
    }
  }, []);

  const initialValues = useMemo(() => {
    if(state){
      return {
        campaignName: campaigns.subject?.campaignName, 
        subject: campaigns.subject?.subject, 
        personalize: personalize.find((item) => item.value === campaigns.subject?.personalize),
        from: from.find((item) => item.value === campaigns.subject?.from),
        senderName: senderName.find((item) => item.value === campaigns.subject?.senderName),
        }
    }
  }, [campaigns]);

  return (
    <>
      <HeaderNewCampaign />
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        validate={validate}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <CampaignSettings>
              <Row>
                <Text type={textTypes.h3Bold!}>Add a subject</Text>
              </Row>
              <Row id="campaign" mt={Space.lg} mr={726}>
                <TextField
                  name="campaignName"
                  label="Campaign name"
                  size={TextFieldSize.md}
                  fitWidth
                  labelHelperText="(only for internal usage)"
                />
              </Row>
              <Row mt={Space.lg}>
                <Col>
                  <TextField
                    name="subject"
                    label="Subject"
                    size={TextFieldSize.md}
                    fitWidth
                  />
                </Col>
                <Col auto ml={Space.xs}>
                  <Wrapper width={196}>
                    <Select
                      name="personalize"
                      label="&nbsp;"
                      size={SelectSize.md}
                      placeholder="Personalize"
                      options={personalize}
                    />
                  </Wrapper>
                </Col>
              </Row>
              <Row mt={Space.lg}>
                <Col mr={Space.xs}>
                  <Select
                    name="from"
                    label="From"
                    size={SelectSize.md}
                    placeholder="Select"
                    options={from}
                  />
                </Col>
                <Col>
                  <Select
                    name="senderName"
                    label="Sender name"
                    size={SelectSize.md}
                    placeholder="Select"
                    options={senderName}
                  />
                </Col>
              </Row>
              <Row mt={Space.lg}>
                <Col itemRight>
                  <Button
                    type="submit"
                    variant={buttonTypes.default}
                    size={ButtonSize.lg}
                    round
                  >
                    Continue
                  </Button>
                </Col>
              </Row>
            </CampaignSettings>
          </form>
        )}
      />
    </>
  );
};

export default observer(AddSubject);
