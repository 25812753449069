import { FC, useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { Row, Col } from "../../../../Grid";
import { ReactComponent as GroupsIcon } from "../../../../../assets/icons/groups.svg";
import { ReactComponent as CompaniesIcon } from "../../../../../assets/icons/companies.svg";
import { Tabs, Tab } from "../../../../Tabs";
import Table from "../../../../Table";
import TextField from "../../../../TextField";
import { TextFieldSize } from "../../../../../styles/text-field";
import { ReactComponent as SearchIcon } from "../../../../../assets/icons/search.svg";
import { useStore } from "../../../../../store";
import { Form } from "react-final-form";
import Button from "../../../../Button";
import { buttonTypes, ButtonSize } from "../../../../../styles/buttons";
import { Space } from "../../../../../types/constants";
import { InfoContainer, ImageContainer, Image } from "./styled";
import Text from "../../../../Text";
import { textTypes } from "../../../../../styles/typography";
import { RecepientsType } from "../../../../../store/campaigns-form";
import { Circle } from "../../../../Steps/styled";
import { Icon } from "../../../../Checkbox/styled";
import { observer } from "mobx-react-lite";

interface ICampaignRecepientsModal {
  changeEmail?: () => void;
  onClose: () => void;
  onSend: () => void;
  storeName: string;
}

const CampaignRecepientsModal: FC<ICampaignRecepientsModal> = ({
  changeEmail,
  onClose,
  onSend,
  storeName,
}) => {
  const navigate = useNavigate();
  const {
    dynamicGroups,
    companies,
    templates,
    campaigns: { recepients, templateId },
  } = useStore();
  const [searchValue, setSearchValue] = useState<string>("");

  const onSubmit = useCallback(async () => {
    navigate("/marketing/campaigns");
  }, []);

  const onSearchChange = useCallback((value: string | number) => {
    setSearchValue(value as string);
  }, []);

  const checkStoreName = useCallback(
    (x: any) => {
      if (
        storeName.toLowerCase() === x.contactType ||
        (storeName === "Bookland" && x.contactType === "obreystore") || 
        storeName.toLowerCase().replace(/\-/g, "") === x.contactType 
      ) {
        return true;
      } else return false;
    },
    [storeName]
  );

  const groupsData = useMemo(
    () =>
      dynamicGroups
        .array()
        .filter((x) => checkStoreName(x))
        .map((x) => ({
          id: x.id,
          name: x.name,
          contactType:
            x.contactType === "obreystore" ? "Bookland" : x.contactType,
        })),
    [dynamicGroups.array()]
  );

  const companiesData = useMemo(
    () =>
      companies.array().map((x) => ({
        id: x.id,
        name: x.name,
        contactType: "...",
      })),
    [companies.array()]
  );

  const columns = useMemo(
    () => [
      { Header: "GROUP NAME", accessor: "name" },
      {
        Header: "CONTACT TYPE",
        accessor: "contactType",
        pr: 135,
        alignRight: true,
      },
    ],
    []
  );

  const onGroupRowClick = useCallback(
    (data: any) => {
      const entityId =
        (recepients && recepients.entities && recepients.entities.id) || "";
      if (data.id !== entityId) {
        recepients?.setEntity({ id: data.id, type: RecepientsType.Group });
      } else {
        recepients?.setEntity(null);
      }
    },
    [recepients]
  );

  const onCompaniesRowClick = useCallback(
    (data: any) => {
      if (data.id !== recepients?.entities?.id) {
        recepients?.setEntity({ id: data.id, type: RecepientsType.Company });
      } else {
        recepients?.setEntity(null);
      }
    },
    [recepients]
  );
  const selectedColumns = useMemo(
    () => [
      (data: any) =>
        recepients?.entities?.id === data.id ? (
          <Circle confirm={true}>
            <Icon viewBox="0 0 24 24">
              <polyline points="6,13 10,17 18,8" />
            </Icon>
          </Circle>
        ) : (
          <></>
        ),
    ],
    [recepients?.entities?.id]
  );

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Row mt={Space.lg}>
            <Col ml={Space.lg} mr={Space.lg}>
              <Row>
                <InfoContainer>
                  <Col mr={Space.xs} ml={Space.xs} center auto>
                    <ImageContainer>
                      {templateId && <Image src={templates.entries.get(templateId)?.image} />}
                      {/* <Image src={image} /> */}
                    </ImageContainer>
                  </Col>
                  <Col>
                    <Text type={textTypes.bodyMdBold!}>{templates.entries.get(templateId!)?.name}</Text>
                  </Col>
                  <Col mr={Space.xs} itemRight>
                    <Button
                      size={ButtonSize.sm}
                      type="button"
                      variant={buttonTypes.outlinePrimary}
                      round
                      onClick={changeEmail}
                    >
                      CHANGE EMAIL
                    </Button>
                  </Col>
                </InfoContainer>
              </Row>
              <Row mt={Space.lg} mb={Space.xs}>
                <TextField
                  value={searchValue}
                  onChange={onSearchChange}
                  size={TextFieldSize.md}
                  placeholder="Search group or company"
                  fitWidth
                  customBackground="#EFEFEF"
                  prefixIcon={
                    <>
                      <SearchIcon />
                    </>
                  }
                />
              </Row>
              <Row>
                <Tabs>
                  <Tab name="Groups" icon={<GroupsIcon />}>
                    <Row mt={Space.lg}>
                      <Table
                        data-id="table_groups_modal"
                        columns={columns}
                        data={groupsData}
                        height={"250px"}
                        truncateContent={false}
                        onClick={onGroupRowClick}
                        prefixColumns={selectedColumns}
                        filter={searchValue}
                      />
                    </Row>
                  </Tab>
                  <Tab name="Companies" icon={<CompaniesIcon />}>
                    <Row mt={Space.lg}>
                      <Table
                        columns={columns}
                        data={companiesData}
                        height={"250px"}
                        truncateContent={false}
                        onClick={onCompaniesRowClick}
                        prefixColumns={selectedColumns}
                        filter={searchValue}
                      />
                    </Row>
                  </Tab>
                </Tabs>
              </Row>
              <Row mt={Space.xs} mb={Space.sm}>
                <Col itemRight>
                  <Button
                    size={ButtonSize.md}
                    variant={buttonTypes.outlineSecondary!}
                    type="button"
                    round
                    onClick={onClose}
                    mr={Space.xs}
                  >
                    Cancel
                  </Button>
                  <Button
                    size={ButtonSize.md}
                    variant={buttonTypes.default!}
                    type="button" // change after finish work
                    round
                    onClick={onSend}
                  >
                    Send
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </form>
      )}
    />
  );
};

export default observer(CampaignRecepientsModal);
