import useSWR from "swr";
import { ENDPOINTS } from "../types/Endpoints";
import authFetch from "../utils/auth-fetch";

interface ISendEmailStats {
	emailStatsId: string;
	title: string;
	email: string;
	templateId: string;
	contactsTypeState: string;
}

const emailStatsFetcher = (url: string) => {
	return authFetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
		},
	}).then((r) => r.json());
};

export const useEmailStats = (hasPermission: boolean) => {
	const { data } = useSWR(
		hasPermission ? ENDPOINTS.STATS.GET_MAIL_ALL_STATS : null,
		emailStatsFetcher
	);

	return data;
};

export const sendEmailsByStatsId = async (data: ISendEmailStats) => {
	const json = await (
		await authFetch(ENDPOINTS.STATS.SEND_EMAIL, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(data),
		})
	).json();

	return json;
};
