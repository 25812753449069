import useSWR from "swr";
import { ENDPOINTS } from "../types/Endpoints";
import authFetch from "../utils/auth-fetch";

const companiesFetcher = (url: string) => {
	return authFetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
		},
	}).then((r) => r.json());
};

export const useCompanies = (hasPermission: boolean) => {
	const { data } = useSWR(
		hasPermission ? ENDPOINTS.COMPANIES.GET_ALL : null,
		companiesFetcher
	);

	return data;
};

interface ICompany {
	id: string;
	name: string;
	webAddress?: string;
	responsible?: string;
	phoneNumber?: string;
	category?: string;
	industry?: string;
	stores?: Array<{
		name?: string;
		address?: string;
	}>;
	address?: {
		main?: string;
		another?: string;
		country?: string;
		region?: string;
		city?: string;
		index?: string;
	};
	comuntication?: {
		number?: string;
		fax?: string;
		email?: string;
		facebook?: string;
		twitter?: string;
	};
	createdOn?: string;
}

export const updateCompany = async (data: ICompany) => {
	delete data.createdOn;
	const json = await (
		await authFetch(ENDPOINTS.COMPANIES.ADD_COMPANY, {
			method: data.id ? "PUT" : "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(data),
		})
	).json();

	return json; // id from response
};

export const deleteCompany = async (id: string) => {
	const json = await (
		await authFetch(`${ENDPOINTS.COMPANIES.ADD_COMPANY}/${id}`, {
			method: "DELETE",
			headers: {
				"Content-Type": "application/json",
			},
		})
	).json();

	return json;
};
