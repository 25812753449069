import React, { RefObject, useEffect, useState } from "react";

export default function useOutsideClick(
	ref: Array<RefObject<HTMLElement>> | RefObject<HTMLElement>,
	state: boolean
): [boolean, React.Dispatch<React.SetStateAction<boolean>>] {
	const [newState, setNewState] = useState(state);
	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if(Array.isArray(ref)){
				if(ref.some((item) => (item.current && !item.current?.contains(event?.target as any)))){
						if (newState === true) {
							setNewState(false);
						}
					}
			} else {
				if (ref.current && !ref.current?.contains(event?.target as any)) {
					if (newState === true) {
						setNewState(false);
					}
				}
			}
		};
		document.addEventListener("click", handleClickOutside);
		return () => {
			document.removeEventListener("click", handleClickOutside);
		};
	}, [ref, newState]);

	return [newState, setNewState];
}
