import { DateTime } from "luxon";
import { FC, useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { Field, FieldRenderProps } from "react-final-form";
import { IMargin, IPadding } from "../../types/styles";
import Calendar from "../Calendar";
import TextField from "../TextField";
import { TextFieldSize } from "../../styles/text-field";
import { Container, CalendarContainer, FieldContainer, Popover } from "./styled";
import { Transition } from "react-transition-group";

export enum CalendarFieldSizes {
	xs = "xs",
	sm = "sm",
	md = "md",
	lg = "lg",
}

interface ICalendarField {
	date?: DateTime;
	size?: CalendarFieldSizes;
	placeholder?: string;
	calendarRight?: boolean; // TODO: Replace by enum for select direction
	locale?: string;
	label?: string;
	error?: string;
	customBackgroundColor?:string; // vlad changes
	onChange: (date: DateTime) => void;
}

interface IFormCalendarField extends IMargin, IPadding {
	name: string;
	date?: DateTime;
	size?: CalendarFieldSizes;
	locale?: string;
	placeholder?: string;
	label?: string;
	calendarRight?: boolean; // TODO: Replace by enum for select direction
	customBackgroundColor?:string; // vlad changes
}

interface IFormCalendarFieldWrapper
	extends FieldRenderProps<DateTime>,
		IMargin,
		IPadding {
	date?: DateTime;
	size?: CalendarFieldSizes;
	locale?: string;
	placeholder?: string;
	label?: string;
	calendarRight?: boolean; // TODO: Replace by enum for select direction
	customBackgroundColor?:string; // vlad changes
}

const CalendarField: FC<ICalendarField> = ({
	date = DateTime.local(),
	size,
	locale = "en",
	onChange,
	calendarRight = false,
	customBackgroundColor, // vlad changes
	...rest
}) => {
	const [show, setShow] = useState(false);

	const open = useCallback(() => {
		setShow(!show);
	}, [show]);

	const change = useCallback((date:DateTime) => {
		setShow(!show);
		onChange(date);
	}, [show, onChange]);

	useLayoutEffect(() => {
		const handle = () => {
			setShow(false);
		};

		document.addEventListener("click", handle);

		return () => {
			document.removeEventListener("click", handle);
		};
	}, []);
	const position = useRef<number>(0);
	const getPosition = useCallback(() => {
		const coords = document!.getElementById("container")!.getBoundingClientRect();
		const pageHeight = document.documentElement.clientHeight; // height of the all page
		const calendarHeight = 392;
		if(pageHeight - coords.bottom < calendarHeight){
			position.current = (pageHeight - coords.bottom - 10) + coords.height;
		};
	}, [show]);
	return (
		<Container
			id="container" // vlad add
			onClick={(e) => {
				getPosition(); // vlad add
				e.stopPropagation();
				e.preventDefault();
			}}
		>
			<FieldContainer onClick={open}>
				<TextField
					// size={size as unknown as TextFieldSize}
					size={size ? size : TextFieldSize.sm} // vlad changes
					fitWidth
					value={(date && date?.toISODate()) || ""}
					customBackground={customBackgroundColor} // vlad changes
					{...rest}
				/>
			</FieldContainer>
			<Transition in={show} timeout={200} unmountOnExit>
				{(state) => (
					<Popover state={state} position={position.current}>
					<CalendarContainer calendarRight={calendarRight}>
						<Calendar date={date} onChange={change} />
					</CalendarContainer>
				</Popover>
				)}
			</Transition>
		</Container>
	);
};

const CalendarFieldFormWrapper: FC<IFormCalendarFieldWrapper> = ({
	input: { onChange, value },
	meta,
	...rest
}) => {
	const change = useCallback(
		(date: DateTime) => {
			onChange(date);
		},
		[onChange]
	);

	return (
		<CalendarField
			date={value}
			onChange={change}
			error={
				(meta.touched && meta.error) ||
				(!meta.dirtySinceLastSubmit && meta.submitError)
			}
			{...rest}
		/>
	);
};

export const FormCalendarField: FC<IFormCalendarField> = ({ ...props }) => {
	return <Field {...props} component={CalendarFieldFormWrapper} />;
};

export default CalendarField;
