import { Row, Col } from "../../../components/Grid";
import Text from "../../../components/Text";
import Button from "../../../components/Button";
import { Tabs, Tab } from "../../../components/Tabs";
import { textTypes } from "../../../styles/typography";
import { Space } from "../../../types/constants";
import Calendar from "../../../components/Calendar";
import {
  CmpaignsMenu,
  Divider,
  CalendarContainer,
  CampaignFor,
  CampaignForItem,
  Wrapper,
  ArrowDownIcon,
} from "./styled";
import { FC, useMemo, useRef } from "react";
import { useState } from "react";
import { useCallback } from "react";
import CampaignsList, {
  ICampaignItem,
} from "../../../components/List/Campaign";
import { ButtonSize, buttonTypes } from "../../../styles/buttons";
import CreateCampaignModal from "../../../components/Modal/CreateCampaign";
import Scrollbars from "react-custom-scrollbars";
import { ReactComponent as Weather } from "../../../assets/icons/weather.svg"; // add svg for tab
import { ReactComponent as ListView } from "../../../assets/icons/list_view.svg"; // add svg for tab
import { DateTime } from "luxon";
import { Transition } from "react-transition-group";
import useOutsideClick from "../../../hooks/detectClickOutside";
import CalendarCampaignModal from "../../../components/Modal/CreateCampaign/CalendarCampaignModal";

enum MenuViewItem {
  all = "all",
  sent = "sent",
  draft = "draft",
  outbox = "outbox",
  email = "email",
  automations = "automation",
}

const listItems: Array<ICampaignItem> = [
  {
    title: "Valentine’s Day Discounts",
    description: "Sent Wed, May 17th, 7:78 AM to 3976 recipents by you",
    status: "sent",
    type: "email",
    visits: 23,
    opens: 56.34,
    clicks: 12,
  },
  {
    title: "Blog post campaign",
    description: "Edited on Wed, May 17th, 7:78 AM by you",
    type: "email",
    status: "draft",
  },
  {
    title: "Automation welcome",
    description: "Welcome new subscriber",
    type: "automation",
    status: "active",
    visits: 0,
    opens: 0.0,
    clicks: 0.0,
  },
];

interface IViewItem {
  type?: string;
  status?: string;
}

const ViewItem: FC<IViewItem> = ({ type, status }) => {
  const items = useMemo<Array<ICampaignItem>>(() => {
    if (status === "all") {
      return listItems;
    } else if (type) {
      return listItems.filter((x) => x.type === type);
    } else if (status) {
      return listItems.filter((x) => x.status === status);
    }

    return listItems;
  }, [type, status]);

  return (
    <>
      <CampaignsList items={items} />
    </>
  );
};

interface ICreateCampaign {
  open: boolean, 
  onClick?: (event: any) => void,
  top?: number;
  left?: number;
  right?: number;
  bottom?: number;
}

export const CreateCampaignFor: FC<ICreateCampaign> = ({open, onClick, ...rest}) => {
  return (
    <>
      <Transition in={open} timeout={200} unmountOnExit>
        {(state) => (
          <CampaignFor state={state} {...rest}>
            <CampaignForItem header>Create campaign for</CampaignForItem>
            <CampaignForItem id="E-commerce" onClick={onClick}>
              E-commerce
            </CampaignForItem>
            <CampaignForItem id="ReadRate" onClick={onClick}>
              ReadRate
            </CampaignForItem>
            <CampaignForItem id="Bookland" onClick={onClick}>
              Bookland
            </CampaignForItem>
          </CampaignFor>
        )}
      </Transition>
    </>
  );
};

const Campaigns = () => {
  const [calendarDate, setCalendarDate] = useState(DateTime.local());
  const [activeView, setActiveView] = useState(MenuViewItem.all);
  const [campaignModalOpen, setCampaignModalOpen] = useState(false); // create campaign page
  const storeListRef = useRef<any>(null);
  const [showStores, setShowStores] = useOutsideClick(storeListRef, false);
  const [openModal, setOpenModal] = useState<{open: boolean, name: string}>({open: false, name: ""}); // calendar campaign modal 
  const showCreateCampaignModal = useCallback((name?: string) => { // calendar create campaign modal
  setOpenModal({open: !openModal.open, name: name || ""});
}, [openModal]);

  const chooseStore = useCallback(() => {
    setShowStores(!showStores);
  }, [showStores]);

  const selectMenuView = useCallback((view: MenuViewItem) => {
    setActiveView(view);
  }, []);

  const toggleCampaignModal = useCallback(() => {
    setShowStores(false);
    setCampaignModalOpen(!campaignModalOpen);
  }, [campaignModalOpen]);

  const changeDate = useCallback((date: DateTime) => {
    setCalendarDate(date);
  }, []);
  
  return (
    <>
      <CreateCampaignModal
        isOpen={campaignModalOpen}
        onClose={() => {
          toggleCampaignModal();
        }}
        onSuccess={() => {
          toggleCampaignModal();
        }}
      />
      <Row pl={Space.xxxl} pr={Space.xxxl} pt={Space.xl} spaceBetween>
        <Col auto>
          <Text type={textTypes.h3Bold!}>Campaigns</Text>
        </Col>
        <Col auto>
          <Wrapper ref={storeListRef}>
            <Button
              variant={buttonTypes.default!}
              size={ButtonSize.md}
              round
              onClick={chooseStore}
            >
              <>
                CREATE CAMPAIGN
                <ArrowDownIcon open={showStores} />
              </>
            </Button>
            <CreateCampaignFor open={showStores} onClick={toggleCampaignModal} top={56} right={0}/>
          </Wrapper>
        </Col>
      </Row>
      <Row pl={Space.xxxl} pr={Space.xxxl} pt={Space.lg}>
        <Tabs>
          {/* add icon attribute below */}
          <Tab name="List view" icon={<ListView />}>
            <Row mt={Space.xl}>
              <Col auto>
                <CmpaignsMenu>
                  <Text
                    type={textTypes.captionMd!}
                    customColor="rgba(24, 24, 25, 0.4)"
                  >
                    VIEW BY STATUS
                  </Text>
                  <Text
                    type={
                      activeView === MenuViewItem.all
                        ? textTypes.bodyMdBold!
                        : textTypes.bodyMd!
                    }
                    onClick={() => selectMenuView(MenuViewItem.all)}
                    mt={Space.xs}
                  >
                    All
                  </Text>
                  <Text
                    type={
                      activeView === MenuViewItem.sent
                        ? textTypes.bodyMdBold!
                        : textTypes.bodyMd!
                    }
                    onClick={() => selectMenuView(MenuViewItem.sent)}
                    mt={Space.xs}
                  >
                    Sent
                  </Text>
                  <Text
                    type={
                      activeView === MenuViewItem.draft
                        ? textTypes.bodyMdBold!
                        : textTypes.bodyMd!
                    }
                    onClick={() => selectMenuView(MenuViewItem.draft)}
                    mt={Space.xs}
                  >
                    Draft
                  </Text>
                  <Text
                    type={
                      activeView === MenuViewItem.outbox
                        ? textTypes.bodyMdBold!
                        : textTypes.bodyMd!
                    }
                    onClick={() => selectMenuView(MenuViewItem.outbox)}
                    mt={Space.xs}
                  >
                    Outbox
                  </Text>
                  <Divider />
                  <Text
                    type={textTypes.captionMd!}
                    mt={Space.xs}
                    customColor="rgba(24, 24, 25, 0.4)"
                  >
                    VIEW BY TYPE
                  </Text>
                  <Text
                    type={
                      activeView === MenuViewItem.email
                        ? textTypes.bodyMdBold!
                        : textTypes.bodyMd!
                    }
                    onClick={() => selectMenuView(MenuViewItem.email)}
                    mt={Space.xs}
                  >
                    Email
                  </Text>
                  <Text
                    type={
                      activeView === MenuViewItem.automations
                        ? textTypes.bodyMdBold!
                        : textTypes.bodyMd!
                    }
                    onClick={() => selectMenuView(MenuViewItem.automations)}
                    mt={Space.xs}
                  >
                    Automations
                  </Text>
                  <Divider />
                  <Text
                    type={textTypes.captionMd!}
                    mt={Space.xs}
                    customColor="rgba(24, 24, 25, 0.4)"
                  >
                    FOLDERS
                  </Text>
                  <Text type={textTypes.bodyMdBold!} mt={Space.xs}>
                    + Create new folder
                  </Text>
                  <Text type={textTypes.bodyMd!} mt={Space.xs}>
                    Draft (0)
                  </Text>
                  <Text type={textTypes.bodyMd!} mt={Space.xs}>
                    Untitled (0)
                  </Text>
                </CmpaignsMenu>
              </Col>
              <Col pl={44}>
                <ViewItem
                  status={
                    activeView !== MenuViewItem.automations &&
                    activeView !== MenuViewItem.email
                      ? activeView
                      : ""
                  }
                  type={
                    activeView === MenuViewItem.automations ||
                    activeView === MenuViewItem.email
                      ? activeView
                      : ""
                  }
                />
              </Col>
            </Row>
          </Tab>
          {/* add icon attribute below */}
          <Tab name="Calendar view" icon={<Weather />}>
            <CalendarContainer>
              <Scrollbars>
                <Calendar date={calendarDate} onChange={changeDate} addNewCampaign openCampaignModal={showCreateCampaignModal}/>
              </Scrollbars>
            </CalendarContainer>
          </Tab>
        </Tabs>
      </Row>
      <CalendarCampaignModal isOpen={openModal.open} onClose={showCreateCampaignModal} storeName={openModal.name}/>
    </>
  );
};

export default Campaigns;
