import { FC } from "react";
import Modal from "..";
import { textTypes } from "../../../styles/typography";
import { Row, Col } from "../../Grid";
import Text from "../../Text";
import { BottomContainer, Container, DeleteIcon } from "./styled";
import { Space } from "../../../types/constants";
import { Transition } from "react-transition-group";
import { useStore } from "../../../store";
import { Form } from "react-final-form";
import { useCallback } from "react";
import { useMemo } from "react";
import { FormTextField as TextField } from "../../TextField";
import { FormSelect as Select } from "../../Select";
import { TextFieldSize } from "../../../styles/text-field";
import Button from "../../Button";
import { ButtonSize, buttonTypes } from "../../../styles/buttons";
import { ReactComponent as CloseSVG } from "../../../assets/icons/close.svg";
import { ReactComponent as DeleteSVG } from "../../../assets/icons/delete.svg";
import { SelectSize } from "../../../styles/select";
import { required, requiredMin } from "../../../validations";
import PermissionType from "../../../types/permissions";

interface IInternalContact {
	id?: string;
	isOpen?: boolean;
	onSuccess: () => void;
	onClose: () => void;
}

const InternalContact: FC<IInternalContact> = ({
	id,
	isOpen,
	onClose,
	onSuccess,
}) => {
	const { internalContacts, companies, user } = useStore();
	const currentContact = useMemo(
		() => (id ? internalContacts.entries.get(id) : undefined),
		[id]
	);

	const hasDeletePermission = useMemo(
		() => user.hasPolicy(PermissionType.DELETE_CONTACT),
		[user.permissions.policies]
	);

	const hasEditPermission = useMemo(
		() => user.hasPolicy(PermissionType.EDIT_CONTACT),
		[user.permissions.policies]
	);

	const hasCreatePermission = useMemo(
		() => user.hasPolicy(PermissionType.ADD_CONTACT),
		[user.permissions.policies]
	);

	const payload = useCallback(
		({ company, gender, prefferedLanguage, ...rest }) => {
			return {
				id: currentContact ? currentContact?.id : undefined,
				company:
					company !== undefined && company !== null ? company.value : null,
				gender: gender !== undefined && gender !== null ? gender.value : null,
				prefferedLanguage:
					prefferedLanguage !== undefined && prefferedLanguage !== null
						? prefferedLanguage.value
						: null,
				...rest,
			};
		},
		[currentContact]
	);

	const onSubmit = useCallback(
		async (values) => {
			const payloadData = payload(values);
			const contactId = await internalContacts.update(payloadData);
			if ((contactId || id) && payloadData.company) {
				companies.addEmployee(
					payloadData.company,
					internalContacts.entries.get(id || contactId)
				);
			}

			onSuccess();
		},
		[currentContact]
	);

	const validate = useCallback(({ fullName }) => {
		const errors = {
			fullName: required(fullName) || requiredMin(fullName, 2),
		};

		return errors;
	}, []);

	const deleteContact = useCallback(async () => {
		if (currentContact) {
			await internalContacts.delete(currentContact.id);
			companies.removeEmployee(currentContact.id);
		}
		onClose();
	}, [currentContact]);

	const initialValues = useMemo(() => {
		return currentContact
			? {
					fullName: currentContact.fullName,
					email: currentContact.email,
					company: currentContact.companyName && {
						label: currentContact.companyName,
						value: currentContact.company,
					},
					companyPosition: currentContact.companyPosition,
					gender: currentContact.gender && {
						label: currentContact.gender,
						value: currentContact.gender,
					},
					prefferedLanguage: currentContact.prefferedLanguage && {
						label: currentContact.prefferedLanguage,
						value: currentContact.prefferedLanguage,
					},
			  }
			: {};
	}, [currentContact]);

	return (
		<Modal onClose={onClose} isOpen={isOpen} backdrop={true}>
			<Transition in={isOpen} timeout={250} unmountOnExit>
				{(state) => (
					<Container state={state}>
						<Row mt={Space.lg} ml={Space.lg} mr={Space.lg}>
							<Row fitWidth>
								<Col>
									<Text type={textTypes.h3Bold!}>
										{id ? "Contact info" : "Create contact"}
									</Text>
								</Col>
								<Col itemRight>
									<Button
										size={ButtonSize.sm}
										variant={buttonTypes.softSecondary}
										onClick={onClose}
										icon
									>
										<CloseSVG />
									</Button>
								</Col>
							</Row>
							<Col pt={Space.lg}>
								<Form
									initialValues={initialValues}
									validate={validate}
									onSubmit={onSubmit}
									render={({ handleSubmit }) => (
										<form onSubmit={handleSubmit}>
											<Row>
												<TextField
													name="fullName"
													label="Full name"
													size={TextFieldSize.sm}
													fitWidth
												/>
											</Row>
											<Row mt={Space.md}>
												<Select
													name="gender"
													label="Gender"
													size={SelectSize.sm}
													placeholder="Select gender"
													options={[
														{ label: "Male", value: "Male" },
														{ label: "Female", value: "Female" },
													]}
												/>
											</Row>
											<Row mt={Space.md}>
												<TextField
													name="email"
													label="Email"
													size={TextFieldSize.sm}
													fitWidth
												/>
											</Row>
											<Row mt={Space.md}>
												<Select
													name="prefferedLanguage"
													label="Language"
													size={SelectSize.sm}
													placeholder="Select language"
													options={[
														"English",
														"Russian",
														"Ukrainian",
														"German",
													].map((x) => ({ label: x, value: x }))}
												/>
											</Row>
											<Row mt={Space.md}>
												<Select
													name="company"
													label="Company"
													size={SelectSize.sm}
													placeholder="Select company"
													options={companies
														.array()
														.map((x) => ({ label: x.name, value: x.id }))}
												/>
											</Row>
											<Row mt={Space.md}>
												<TextField
													name="companyPosition"
													label="Position"
													size={TextFieldSize.sm}
													fitWidth
												/>
											</Row>
											<BottomContainer>
												<Row pt={16} pl={24} pr={24}>
													<Col itemRight>
														{id ? (
															<>
																{hasDeletePermission && (
																	<Button
																		type="button"
																		size={ButtonSize.md}
																		variant={buttonTypes.softSecondary}
																		onClick={deleteContact}
																		mr={16}
																		icon
																	>
																		<DeleteIcon>
																			<DeleteSVG />
																		</DeleteIcon>
																	</Button>
																)}
																{hasEditPermission && (
																	<Button
																		type="submit"
																		size={ButtonSize.md}
																		variant={buttonTypes.outlinePrimary}
																		onClick={() => {}}
																	>
																		UPDATE
																	</Button>
																)}
															</>
														) : (
															<>
																{hasCreatePermission && (
																	<Button
																		type="submit"
																		size={ButtonSize.md}
																		variant={buttonTypes.outlinePrimary}
																		onClick={() => {}}
																	>
																		CREATE
																	</Button>
																)}
															</>
														)}
													</Col>
												</Row>
											</BottomContainer>
										</form>
									)}
								/>
							</Col>
						</Row>
					</Container>
				)}
			</Transition>
		</Modal>
	);
};

export default InternalContact;
