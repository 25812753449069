import { FC, MouseEvent, useCallback } from "react";
import { createPortal } from "react-dom";
import { css } from "styled-components";
import { baseProps } from "../../styles/helpers";
import styled from "styled-components/macro";
import { MODAL_ROOT_ID } from "../../types/constants";
import { IMargin } from "../../types/styles";
import { fadeIn, fadeOut } from "../../styles/animations"; // add fadeIn
import { Transition } from "react-transition-group";

export const ModalRoot = styled.div`
	position: relative;
	z-index: 999999;
`;

interface IBackdrop extends IMargin {
	backdrop?: boolean;
	state: string;
}

export const Backdrop = styled.div<IBackdrop>`
	${baseProps}
	position: fixed;
	width: 100%;
	${({ mt }) =>
		mt !== undefined
			? css`
					height: calc(100% - ${mt}px);
			  `
			: css`
					height: 100%;
			  `}
	${({ backdrop }) =>
		backdrop &&
		css`
			background: rgba(41, 42, 44, 0.5);
		`}
  ${({ state }) =>
		(state === "entering" &&
			css`
				animation: ${fadeIn} 300ms;
			`) ||
		(state === "exiting" &&
			css`
				animation: ${fadeOut} 300ms;
			`)}
`;

interface IModal extends IMargin {
	isOpen?: boolean;
	onClose?: () => void;
	backdrop?: boolean;
	children?: JSX.Element;
}

const Modal: FC<IModal> = ({ children, onClose, isOpen, ...rest }) => {
	const close = useCallback(
		(e: MouseEvent<HTMLDivElement, MouseEvent>) => {
			if (!e) return;
			if (e && e?.target === e?.currentTarget && onClose !== undefined) { // add onClose !== udnefined
				onClose!();
			}
		},
		[onClose]
	);
	const root = document.getElementById(MODAL_ROOT_ID);

	return (
		<Transition in={Boolean(root) && isOpen} timeout={200} unmountOnExit>
			{(state) =>
				createPortal(
					// @ts-ignore
					<Backdrop {...rest} onClick={close} state={state}>
						{children}
					</Backdrop>,
					root!
				)
			}
		</Transition>
	);
};

export default Modal;
