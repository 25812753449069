import Select from "react-select";
import { default as BaseAsyncSelect } from "react-select/async";
import { default as BaseCreatableSelect } from "react-select/creatable";
import { css } from "styled-components";
import { SelectSize } from "../../styles/select";
import { baseProps } from "../../styles/helpers";
import styled from "../../styles/theme";
import { IMargin, IPadding } from "../../types/styles";

export const Container = styled.div<IMargin & IPadding>`
	${baseProps}
	position: relative;
	width: 100%;
`;

interface ISelect {
	error?: boolean;
	size?: SelectSize;
	borderColor?: string;
	customBackground?: string; 
	textColor?: string;
	prefixIcon?: any;
	isDisabled?: boolean;
}

const getSize = (size?: SelectSize) => {
	switch (size) {
		case SelectSize.xs:
			return "24px";
		case SelectSize.sm:
			return "32px";
		case SelectSize.md:
			return "40px";
		case SelectSize.lg:
			return "58px";
		default:
			return "24";
	}
};

const selectBaseStyles = css<ISelect>`
	font-family: ${({ theme: { fonts } }) => fonts.default};
	font-style: normal;
	font-weight: normal;
	border: 1px solid;
	border-color: ${({borderColor}) => borderColor ? borderColor : "transparent"};
	border-radius: 4px;
	font-size: 16px;
	line-height: 24px;
	font-weight: 400;
	outline: none;
	transition: all 300ms ease;
	box-sizing: border-box;

	& .select__control {
		width: 100%;
		height: ${({ size }) => getSize(size)};
		border-radius: 4px;
		${({isDisabled, error, customBackground}) => isDisabled ? css`
			background-color: rgba(244,244,244, 1)};
			cursor: not-allowed;
		` : css`
			background-color: ${customBackground ? customBackground : "#fff"};
			border: 1px solid ${error ? "rgba(234, 104, 82, 1)" : "transparent"};
		`}
		box-sizing: border-box;
		//padding-left: 6px;

		&:hover {
			border-color: rgba(24, 24, 25, 0.42);
		}
	}

	& .select__control--is-focused {
		border: 1px solid black;
		outline: 0;
		box-shadow: none;
	}

	& .select__menu {
		width: 100%;
		border-radius: 4px;
		margin-top: 16px;
		box-sizing: border-box;
	}

	& .select__option {
		cursor: pointer;
		&:hover {
			background: #fafafa;
		}
	}

	& .select__option--is-selected {
		background: #fafafa;
		color: black;
		font-weight: 600;
	}

	& .select__option--is-focused {
		background: #fafafa;
	}

	& .select__placeholder {
		font-family: ${({ theme: { fonts } }) => fonts.default};
		font-style: normal;
		font-size: 16px;
		line-height: 24px;
		font-weight: 400;
		color: rgba(24, 24, 25, 0.42);
		${({ prefixIcon }) => Boolean(prefixIcon) && css`
			margin-left: 26px;
		`}
	}

	& .select__single-value{
		${({ prefixIcon }) => Boolean(prefixIcon) && css`
			margin-left: 26px;
		`}
	}

	& .select__multi-value{
		border-radius: 100px;
		background-color: rgba(241, 241, 241, 1);
		display: flex;
		align-items: center;
		cursor: pointer;
		padding: 4px 7.5px 4px 12px;
	};

	& .select__multi-value__label{
		color: rgba(24, 24, 25, 0.9);
		padding: 0;
		margin-right: 7.5px;
		font-weight: ${({theme: {typography}}) => typography.bodyMd.weight};
		font-size: ${({theme: {typography}}) => typography.bodyMd.size};
		line-height: ${({theme: {typography}}) => typography.bodyMd.lineHeight};
	};

	& .select__multi-value__remove{
		display: flex;
		align-items: center;
		justify-content: center;
		width: 15px;
		height: 15px;
		background-color: rgba(24, 24, 25, 0.42);
		border-radius: 100px;
		transition: all 300ms ease;
		padding: 3px;
		& > svg {
			width: 15px;
			height: 15px;
			& > path{
				fill: rgba(241, 241, 241, 1);
				fill-opacity: 1;
			};
		}
		:hover{
			background-color: rgba(24, 24, 25, 0.46);
		}
	};

	&:hover {
		border-color: none;
		box-shadow: none;
	}

	&:focus {
		outline: none;
		border-color: ${({ error }) =>
			error === true ? "rgba(234, 104, 82, 1)" : "rgba(10, 97, 92, 1)"};
		box-shadow: 0 0 0 1px rgba(56, 85, 179, 0.16);
	}

	&::placeholder {
		font-family: ${({ theme: { fonts } }) => fonts.default};
		font-style: normal;
		font-size: 16px;
		line-height: 24px;
		font-weight: 400;
		color: rgba(24, 24, 25, 0.42);
	}
`;

export const DefaultSelect = styled(Select)<ISelect>`
	${selectBaseStyles}
`;

export const CreatableSelect = styled(BaseCreatableSelect)<ISelect>`
	${selectBaseStyles}
`;

export const AsyncSelect = styled(BaseAsyncSelect)<ISelect>`
	${selectBaseStyles}
`;

// TODO: Find missing styles and move it to selectBaseStyles
// export const AsyncSelect = styled(BaseAsyncSelect)<ISelect>`
// 	font-family: ${({ theme: { fonts } }) => fonts.default};
// 	font-style: normal;
// 	font-weight: normal;
// 	border: 1px solid;
// 	border-color: ${({borderColor}) => borderColor ? borderColor : "transparent"};
// 	border-radius: 4px;
// 	font-size: 16px;
// 	line-height: 24px;
// 	font-weight: 400;
// 	outline: none;
// 	transition: all 300ms ease;
// 	box-sizing: border-box;

// 	& .select__control {
// 		width: 100%;
// 		height: ${({ size }) => getSize(size)};
// 		border: 1px solid
// 			${({ error }) => (error ? "rgba(234, 104, 82, 1)" : "transparent")};
// 		border-radius: 4px;
// 		background-color: ${({customBackground}) => customBackground ? customBackground : "#fff"};
// 		box-sizing: border-box;
// 		padding-left: 6px;

// 		&:hover {
// 			border-color: rgba(24, 24, 25, 0.42);
// 		}
// 	}

// 	& .select__control--is-focused {
// 		border: 1px solid black;
// 		outline: 0;
// 		box-shadow: none;
// 	}

// 	& .select__menu {
// 		width: 100%;
// 		border-radius: 4px;
// 		box-sizing: border-box;
// 	}

// 	& .select__option {
// 		cursor: pointer;

// 		&:hover {
// 			background: #fafafa;
// 		}
// 	}

// 	& .select__option--is-selected {
// 		background: #fafafa;
// 		color: black;
// 		font-weight: 600;
// 	}

// 	& .select__option--is-focused {
// 		background: #fafafa;
// 	}

// 	& .select__placeholder {
// 		font-family: ${({ theme: { fonts } }) => fonts.default};
// 		font-style: normal;
// 		font-size: 16px;
// 		line-height: 24px;
// 		font-weight: 400;
// 		color: rgba(24, 24, 25, 0.42);
// 	}

// 	&:hover {
// 		border-color: none;
// 		box-shadow: none;
// 	}

// 	&:focus {
// 		outline: none;
// 		border-color: ${({ error }) =>
// 			error === true ? "rgba(234, 104, 82, 1)" : "rgba(10, 97, 92, 1)"};
// 		box-shadow: 0 0 0 1px rgba(56, 85, 179, 0.16);
// 	}

// 	&::placeholder {
// 		font-family: ${({ theme: { fonts } }) => fonts.default};
// 		font-style: normal;
// 		font-size: 16px;
// 		line-height: 24px;
// 		font-weight: 400;
// 		color: rgba(24, 24, 25, 0.42);
// 	}
// `;

export const Label = styled.label<{ error?: boolean }>`
	font-family: ${({ theme: { fonts } }) => fonts.default};
	display: block;
	font-style: normal;
	font-weight: normal;
	margin-bottom: 8px;
	color: ${({ error }) =>
		error === true ? "rgba(234, 104, 82, 1)" : "rgba(24, 24, 25, 0.9)"};
	font-size: 12px;
	line-height: 16px;
	text-rendering: optimizeLegibility;
`;

export const WarningMessage = styled.label`
	font-family: ${({ theme: { fonts } }) => fonts.default};
	display: block;
	font-style: normal;
	font-weight: normal;
	margin-top: 4px;
	color: rgba(234, 104, 82, 1);
	font-size: 12px;
	line-height: 16px;
	text-rendering: optimizeLegibility;
	color: rgba(234, 104, 82, 1);
`;

export const ListContainer = styled.div`
	height: 378px;
`;


