import { FC, useCallback, useMemo, useState } from "react";
import Text from "../../../components/Text";
import Button from "../../../components/Button";
import { textTypes } from "../../../styles/typography";
import { Space } from "../../../types/constants";
import { Row, Col } from "../../../components/Grid";
import Table from "../../../components/Table";
import { persons } from "../../../types/random-persons";
import { ButtonSize, buttonTypes } from "../../../styles/buttons";
import { useStore } from "../../../store";
import { DateTime } from "luxon";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import {
	CardsView,
	Card,
	CardImage,
	CardControls,
	ModalContainer,
	CardImgCover,
} from "./styled";
import Scrollbars from "react-custom-scrollbars";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as DeleteSVG } from "../../../assets/icons/delete.svg";
import { ReactComponent as CopySVG } from "../../../assets/icons/copy.svg";
import { ReactComponent as EditSVG } from "../../../assets/icons/edit.svg";
import Modal from "../../../components/Modal";
import SendTestTemplateModal from "../../../components/Modal/SendTestTemplate";
import { Form } from "react-final-form";
import TextField from "../../../components/TextField";
import { TextFieldSize } from "../../../styles/text-field";
import { ReactComponent as SearchSVG } from "../../../assets/icons/search.svg";
import WithPermisions from "../../../components/WithPermisions";
import PermissionType from "../../../types/permissions";
import { getImageSrc } from "../../../utils/get-host";

const EDITOR_PATH = "/marketing/templates/editor";

interface ITemplateSaveModal {
	isOpen: boolean;
	onConfirm: () => void;
	onClose: () => void;
}

// Only for internal purpose
const TemplateDeleteModal: FC<ITemplateSaveModal> = ({
	isOpen,
	onConfirm,
	onClose,
}) => {
	const onSubmit = useCallback(async () => {
		await onConfirm();
	}, [onConfirm]);

	return (
		<Modal isOpen={isOpen} onClose={onClose} backdrop>
			<ModalContainer>
				<Row spaceBetween>
					<Col>
						<Text type={textTypes.h5Bold!}>Are you sure?</Text>
					</Col>
				</Row>
				<Row mt={Space.md}>
					<Col>
						<Form
							onSubmit={onSubmit}
							render={({ handleSubmit, submitting }) => (
								<form onSubmit={handleSubmit}>
									<Row mt={Space.md}>
										<Col itemRight>
											<Button
												variant={buttonTypes.outlineSecondary!}
												size={ButtonSize.md}
												round
												onClick={onClose}
											>
												CANCEL
											</Button>
											<Button
												type="submit"
												ml={Space.xs}
												size={ButtonSize.md}
												round
												disabled={submitting}
											>
												DELETE
											</Button>
										</Col>
									</Row>
								</form>
							)}
						/>
					</Col>
				</Row>
			</ModalContainer>
		</Modal>
	);
};

const Templates: FC = () => {
	const navigate = useNavigate();
	const { user, templates } = useStore();

	const [searchValue, setSearchValue] = useState<string>("");

	const [deleteModal, setDeleteModal] = useState<{
		isOpen: boolean;
		id?: string;
	}>({
		isOpen: false,
		id: undefined,
	});

	const [sendTestModal, setSendTestModal] = useState<{
		isOpen: boolean;
		id?: string;
	}>({
		isOpen: false,
		id: undefined,
	});

	const hasDeletePermission = useMemo(
		() => user.hasPolicy(PermissionType.DELETE_EMAIL_TEMPLATE),
		[user.permissions.policies]
	);

	const hasEditPermission = useMemo(
		() => user.hasPolicy(PermissionType.EDIT_EMAIL_TEMPLATE),
		[user.permissions.policies]
	);

	const templatesArray = useMemo(() => {
		const search = searchValue?.toLowerCase() ?? "";
		const data = templates ? templates.array() : [];

		return search
			? data.filter(
					(x) =>
						x.name?.toLowerCase().indexOf(search) >= 0 ||
						x.modifiedOn?.toLowerCase().indexOf(search) >= 0
			  )
			: data;
	}, [templates.array(), searchValue]);

	const onCreateClick = useCallback(() => {
		navigate(EDITOR_PATH);
	}, []);

	const onDeleteClick = useCallback(async (id: string) => {
		setDeleteModal({
			isOpen: true,
			id: id,
		});
	}, []);

	const onCopyClick = useCallback(async (id: string) => {
		await templates.copy(id);
	}, []);

	const confirmDelete = useCallback(async () => {
		if (deleteModal.id) await templates.delete(deleteModal.id);

		setDeleteModal({
			isOpen: false,
			id: undefined,
		});
	}, [deleteModal.id]);

	const toggleDeleteModal = useCallback(() => {
		setDeleteModal({
			isOpen: !deleteModal.isOpen,
			id: undefined,
		});
	}, [deleteModal]);

	const toggleSendTestModal = useCallback(() => {
		setSendTestModal({
			isOpen: !sendTestModal.isOpen,
			id: undefined,
		});
	}, [sendTestModal]);

	const onSearchChange = useCallback((value: string | number) => {
		setSearchValue(value as string);
	}, []);

	return (
		<>
			<TemplateDeleteModal
				isOpen={deleteModal.isOpen}
				onClose={toggleDeleteModal}
				onConfirm={confirmDelete}
			/>
			<SendTestTemplateModal
				id={sendTestModal.id}
				isOpen={sendTestModal.isOpen}
				onClose={toggleSendTestModal}
				onSuccess={toggleSendTestModal}
			/>
			<Row pl={Space.xxxl} pr={Space.xxxl} pt={Space.xl} spaceBetween>
				<Col auto>
					<Text type={textTypes.h3Bold!}>All templates</Text>
				</Col>
				<WithPermisions permissions={[PermissionType.ADD_EMAIL_TEMPLATE]}>
					<Col auto>
						<Button
							variant={buttonTypes.default!}
							size={ButtonSize.md}
							round
							onClick={onCreateClick}
						>
							ADD TEMPLATE
						</Button>
					</Col>
				</WithPermisions>
			</Row>
			<Row pl={Space.xxxl} pr={Space.xxxl} pt={Space.xl}>
				<Col md={5} lg={4} xl={3}>
					<TextField
						value={searchValue}
						onChange={onSearchChange}
						size={TextFieldSize.md!}
						prefixIcon={
							<>
								<SearchSVG />
							</>
						}
						placeholder="Search template"
						fitWidth
					/>
				</Col>
			</Row>
			<Row pl={34} pr={Space.xxxl} pt={Space.xl}>
				<CardsView>
					<Scrollbars>
						{templatesArray.length < 1 && (
							<Row mt={24} centerItems>
								<Text type={textTypes.captionMd!} alignCenter>
									No templates available
								</Text>
							</Row>
						)}
						<Row>
							{templatesArray.map((x) => (
								<Col xs={12} sm={12} md={12} lg={6} xl={3} mt={16} key={x.id}>
									<Card>
										<Text ml={16} type={textTypes.h4!}>
											{x.name}
										</Text>
										<CardImage>
											<img src={getImageSrc(x.image)} />
											<CardImgCover>
												<Button
													variant={buttonTypes.default!}
													size={ButtonSize.md}
													round
													onClick={() => {
														setSendTestModal({ id: x.id, isOpen: true });
													}}
												>
													SEND TEST
												</Button>
											</CardImgCover>
										</CardImage>
										<CardControls>
											<Row pl={Space.sm} pr={Space.sm} spaceBetween>
												<Col auto>
													<Text type={textTypes.bodyMd!}>
														{DateTime.fromISO(x.modifiedOn).toISODate()}
													</Text>
												</Col>
												<Col auto>
													<Row>
														{hasEditPermission && (
															<>
																<Col ml={Space.xs} auto>
																	<Link to={`${EDITOR_PATH}/${x.id}`}>
																		<EditSVG />
																	</Link>
																</Col>
																<Col ml={Space.xs} auto>
																	<CopySVG onClick={() => onCopyClick(x.id)} />
																</Col>
															</>
														)}
														{hasDeletePermission && (
															<Col
																ml={Space.xs}
																auto
																onClick={() => onDeleteClick(x.id)}
															>
																<DeleteSVG />
															</Col>
														)}
													</Row>
												</Col>
											</Row>
										</CardControls>
									</Card>
								</Col>
							))}
						</Row>
					</Scrollbars>
				</CardsView>
			</Row>
		</>
	);
};

export default observer(Templates);
