import styled from "../../../styles/theme";
import { css } from "styled-components";
import { fadeIn, fadeOut } from '../../../styles/animations';
import { ReactComponent as ArrowDown } from "../../../assets/icons/keyboard_arrow_down.svg";

export const CmpaignsMenu = styled.div`
  width: 135px;
  user-select: none;

  & > p {
    cursor: pointer;
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin-top: 14px;
  background-color: #e6e7e9;
`;

export const CalendarContainer = styled.div`
  width: 100%;
  height: calc(100vh - 175px);
  margin-right: 5px;
`;

export const Wrapper = styled.div`
  position: relative;
`;

interface ICampaignFor{
	state?: string;
  top?: number;
  right?: number;
  left?: number;
  bottom?: number;
}

export const CampaignFor = styled.div<ICampaignFor>`
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 239px;
  top: ${({top}) => top}px; 
  right:${({right}) => right}px; 
  left:${({left}) => left}px; 
  bottom:${({bottom}) => bottom}px; 
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 2px 4px 20px 4px rgba(34, 60, 80, 0.11);
  padding: 8px 8px 12px;
  z-index: 9999;
  @media (max-width: 1600px){
    width: 200px;
  };
  @media (max-width: 1400px){
    width: 180px;
  }
  ${({ state }) =>
    (state === "entering" &&
      css`
        animation: ${fadeIn} 300ms;
      `) ||
    (state === "exiting" &&
      css`
        animation: ${fadeOut} 300ms;
      `)};
`;

interface ICampaignForItem {
  header?: boolean;
}

export const CampaignForItem = styled.div<ICampaignForItem>`
  display: flex;
  align-items: center;
  height: 32px;
  font-size: ${({ theme: { typography } }) => typography.bodyMd.sizeMd};
  font-weight: ${({ theme: { typography } }) => typography.bodyMd.weight};
  line-height: ${({ theme: { typography } }) => typography.bodyMd.lineHeight};
  color: rgba(24, 24, 25, 0.9);
  padding-left: 12px;
  cursor: pointer;
  border-radius: 4px;
  :hover{
		background-color: #f6f6f6;
	};
	transition: all 300ms ease;
  ${({ header }) =>
    header &&
    css`
      color: rgba(24, 24, 25, 0.42);
      text-transform: uppercase;
      font-size: ${({ theme: { typography } }) => typography.bodySmBold.size};
      font-weight: ${({ theme: { typography } }) =>
        typography.bodySmBold.weight};
      line-height: ${({ theme: { typography } }) =>
        typography.bodySmBold.lineHeight};
      letter-spacing: 0.3px;
      margin-bottom: 5px;
	  cursor: default;
	  :hover{
		background-color: #fff;
	};
    `};
`;

interface IArrowDownIcon{
	open: boolean;
}

export const ArrowDownIcon = styled(ArrowDown)<IArrowDownIcon>`
	& > path {
		fill: #fff;
	};
	${({ open }) => open && css`transform: rotate(180deg);`};
	transition: all 300ms ease;
`;
