// Specs: https://mjml.io/documentation/#mjml-divider
import { isComponentType } from "./utils";

export default (editor: any, { dc, coreMjmlModel, coreMjmlView }: any) => {
	const type = "mj-divider";

	dc.addType(type, {
		isComponent: isComponentType(type),

		model: {
			...coreMjmlModel,
			defaults: {
				name: editor.I18n.t("grapesjs-mjml.components.names.divider"),
				draggable: "[data-gjs-type=mj-column],[data-gjs-type=mj-hero]",
				droppable: false,
				"style-default": {
					width: "100%",
					"border-width": "4px",
					"border-style": "solid",
					"border-color": "#000000",
					"padding-top": "10px",
					"padding-bottom": "10px",
					"padding-right": "25px",
					"padding-left": "25px",
				},
				stylable: [
					"padding",
					"padding-top",
					"padding-left",
					"padding-right",
					"padding-bottom",
					"width",
					"container-background-color",
					"border-detached",
					"border-width",
					"border-style",
					"border-color",
				],
				void: true,
			},
		},

		view: {
			...coreMjmlView,
			tagName: "tr",
			attributes: {
				style:
					"pointer-events: all; display: table; width: 100%; user-select: none;",
			},

			getMjmlTemplate() {
				return {
					start: `<mjml><mj-body><mj-column>`,
					end: `</mj-column></mj-body></mjml>`,
				};
			},

			getTemplateFromEl(sandboxEl: any) {
				return sandboxEl.querySelector("tr").innerHTML;
			},

			getChildrenSelector() {
				return "p";
			},
		},
	});
};
