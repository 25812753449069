import { FC, useMemo } from "react";
import Text from "../../../components/Text";
import TextField from "../../../components/TextField";
import Button from "../../../components/Button";
import { textTypes } from "../../../styles/typography";
import { Space } from "../../../types/constants";
import { Row, Col } from "../../../components/Grid";
import Table from "../../../components/Table";
import { ButtonSize, buttonTypes } from "../../../styles/buttons";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../store";
import UserInfoSidePage from "../../../components/Modal/Admin/UserInfo";
import { useState, useCallback } from "react";
import { TextFieldSize } from "../../../styles/text-field";
import { ReactComponent as SearchSVG } from "../../../assets/icons/search.svg";
import { DateTime } from "luxon";

const Users: FC = () => {
	const { admin } = useStore();

	const [user, setUser] = useState<{
		id?: string;
		isOpen: boolean;
	}>({
		id: undefined,
		isOpen: false,
	});

	const [searchValue, setSearchValue] = useState<string>("");

	const data = useMemo(
		() =>
			admin.usersArray.map((x) => ({
				id: x.id,
				username: x.username,
                role: x.role,
                createdOn: DateTime.fromISO(x.createdOn).toISODate()
			})),
		[admin.usersArray]
	);

	const columns = useMemo(
		() => [
			{
				Header: "Id",
				accessor: "id",
			},
			{
				Header: "Username",
				accessor: "username",
			},
			{
				Header: "Role",
				accessor: "role",
			},
			{
				Header: "Created On",
				accessor: "createdOn",
			},
		],
		[]
	);

	const createUser = useCallback(() => {
		setUser({
			id: undefined,
			isOpen: true,
		});
	}, []);

	const openUserInfo = useCallback(
		(id: string | undefined = undefined) => {
			setUser({
				id: id,
				isOpen: true,
			});
		},
		[user]
	);

	const closeUserInfo = useCallback(() => {
		setUser({
			id: undefined,
			isOpen: false,
		});
	}, [user]);

	const onSearchChange = useCallback((value:string | number) => {
		setSearchValue(value as string);
	}, []);

	return (
		<>
			<UserInfoSidePage
				id={user.id}
				isOpen={user.isOpen}
				onClose={closeUserInfo}
				onSuccess={closeUserInfo}
			/> 
			<Row pl={Space.xxxl} pr={Space.xxxl} pt={Space.xl} spaceBetween>
				<Col auto>
					<Text type={textTypes.h3Bold!}>All users</Text>
				</Col>
				<Col auto>
					<Button
						variant={buttonTypes.default!}
						size={ButtonSize.md}
						round
						onClick={createUser}
					>
						ADD USER
					</Button>
				</Col>
			</Row>
			<Row pl={Space.xxxl} pr={Space.xxxl} pt={Space.xl}>
				<Col md={5} lg={4} xl={3}>
					<TextField value={searchValue} onChange={onSearchChange} size={TextFieldSize.md!} prefixIcon={<><SearchSVG /></>} placeholder="Search user" fitWidth />
				</Col>
			</Row>
			<Row pl={Space.xxxl} pr={Space.xxxl} pt={Space.xl}>
				<Table
					onClick={(row) => openUserInfo(row.id)}
					columns={columns}
					data={data}
					height={"calc(100vh - 200px)"}
					filter={searchValue}
				/>
			</Row>
		</>
	);
};

export default observer(Users);
