import { FC, useEffect, useState } from "react";
import Modal from "..";
import { textTypes } from "../../../styles/typography";
import { Row, Col } from "../../Grid";
import Text from "../../Text";
import {
	Avatar,
	BottomContainer,
	Container,
	DeleteIcon,
	Divider,
	InfoFieldContainer,
	DeleteButton,
	DeleteWrapper,
	DeleteFor,
	ConfirmModalWrapper,
	ConfirmModal,
	SpinnerContainer,
} from "./styled";
import { Space } from "../../../types/constants";
import { Transition } from "react-transition-group";
import { useStore } from "../../../store";
import { Form } from "react-final-form";
import { useCallback } from "react";
import { useMemo } from "react";
import { FormTextField as TextField } from "../../TextField";
import { FormSelect as Select } from "../../Select";
import { TextFieldSize } from "../../../styles/text-field";
import Button from "../../Button";
import { ButtonSize, buttonTypes } from "../../../styles/buttons";
import { ReactComponent as AvatarIconSVG } from "../../../assets/icons/person_filled.svg";
import { ReactComponent as CloseSVG } from "../../../assets/icons/close.svg";
import { ReactComponent as DeleteSVG } from "../../../assets/icons/delete.svg";
import { SelectSize } from "../../../styles/select";
import { required, requiredMin } from "../../../validations";
import PermissionType from "../../../types/permissions";
import { colorTypes } from "../../../styles/colors";
import Spinner from "../../Spinners";
import { SpinnerType } from "../../../styles/spinners";
import {
	deleteContactCard,
	getContactCardInfo,
} from "../../../api/contact-card";
import { DateTime } from "luxon";

interface IContactCard {
	email?: string;
	isOpen?: boolean;
	onDeleted: () => void;
	onClose: () => void;
}

const contactTypesNames: Record<string, string> = {
	bookland: "Bookland",
	cloud: "Cloud",
	corporateSite: "Corporate site",
	legalApi: "Legal",
	ecommerce: "PocketBook store",
	readrate: "ReadRate",
	s2pb: "Send to PocketBook",
	psac: "SAC",
};

interface IContactServiceInfo {
	info?: IContactCard | null;
}

const getDateNormalized = (date: string) => {
	if (!date) return "...";

	const fromIso = DateTime.fromISO(date);
	const fromSeconds = DateTime.fromSeconds(Number(date));

	const dateObj =
		(fromIso.isValid && fromIso) || (fromSeconds.isValid && fromSeconds);

	return dateObj && dateObj.isValid ? dateObj.toISODate() : "...";
};

const unspecifiedTextColor = colorTypes.gray._400!;
const getFieldTextColor = (text: string) =>
	text === "Unspecified" ? unspecifiedTextColor : colorTypes.gray._800!;

const ContactServicesInfo: FC<IContactServiceInfo> = ({ info }) => {
	const [activeTab, setActiveTab] = useState("");
	const tabs = useMemo(() => {
		return info
			? Object.keys(info).filter(
					(x) =>
						(info as Record<string, any>)[x] !== null &&
						(info as Record<string, any>)[x] !== undefined
			  )
			: [];
	}, [info]);

	useEffect(() => {
		if (!activeTab && tabs.length > 0 && tabs.indexOf(activeTab) === -1) {
			setActiveTab(tabs[0]);
		}
	}, [tabs]);

	const currentInfo = useMemo(() => {
		const defaultInfo = {
			lastVisitDate: "...",
			registeredAt: "...",
			fullName: "Unspecified",
			phone: "Unspecified",
			email: "Unspecified",
			country: "Unspecified",
			street: "Unspecified",
			postcode: "Unspecified",
		};

		if (!activeTab) return defaultInfo;

		const infoRecord = (info as Record<string, any>)[activeTab];

		if (!infoRecord) return defaultInfo;

		return {
			lastVisitDate: getDateNormalized(
				infoRecord.lastVisitDate || infoRecord.lastLoginAt
			),
			registeredAt: getDateNormalized(
				infoRecord.registeredAt ||
					infoRecord.registrationDate ||
					infoRecord.createdAt
			),
			fullName:
				infoRecord.name ||
				infoRecord.fullName ||
				(infoRecord.firstName || infoRecord.lastName
					? `${infoRecord.firstName || ""} ${infoRecord.lastName || ""}`
					: "Unspecified"),
			phone: infoRecord.phone || "Unspecified",
			email: infoRecord.email || "Unspecified",
			country: infoRecord.country || "Unspecified",
			street: infoRecord.street || "Unspecified",
			postcode: infoRecord.postcode || "Unspecified",
		};
	}, [activeTab, info]);

	return (
		<>
			<Row fitWidth pb={12}>
				{tabs.map((x) => (
					<Col key={`tab-${x}`} pr={8} pb={12} auto>
						<Button
							variant={
								activeTab === x
									? buttonTypes.default!
									: buttonTypes.outlineSecondary!
							}
							size={ButtonSize.lg}
							round
							onClick={() => setActiveTab(x)}
						>
							{contactTypesNames[x]}
						</Button>
					</Col>
				))}
			</Row>
			<Divider />
			<Row pt={12} pb={12} fitWidth>
				<Col sm={4} center>
					<Text
						type={textTypes.captionSm!}
						color={colorTypes.gray._400!}
						alignCenter
					>
						Client segment
					</Text>
					<Text
						mt={4}
						type={textTypes.bodyMdBold!}
						color={colorTypes.gray._700!}
						alignCenter
					>
						...
					</Text>
				</Col>
				<Col sm={4} center>
					<Text
						type={textTypes.captionSm!}
						color={colorTypes.gray._400!}
						alignCenter
					>
						Last activity
					</Text>
					<Text
						mt={4}
						type={textTypes.bodyMdBold!}
						color={colorTypes.gray._700!}
						alignCenter
					>
						{currentInfo.lastVisitDate}
					</Text>
				</Col>
				<Col sm={4} center>
					<Text
						type={textTypes.captionSm!}
						color={colorTypes.gray._400!}
						alignCenter
					>
						Customer Since
					</Text>
					<Text
						mt={4}
						type={textTypes.bodyMdBold!}
						color={colorTypes.gray._700!}
						alignCenter
					>
						{currentInfo.registeredAt}
					</Text>
				</Col>
			</Row>
			<Divider />
			<Row mt={24} fitWidth centerItems>
				<InfoFieldContainer>
					<div>
						<Text
							type={textTypes.bodyMd!}
							color={colorTypes.gray._400!}
							alignCenter
						>
							Name
						</Text>
					</div>
					<div>
						<Text
							type={textTypes.bodyMd!}
							color={getFieldTextColor(currentInfo.fullName)}
						>
							{currentInfo.fullName}
						</Text>
					</div>
				</InfoFieldContainer>
			</Row>
			<Row mt={16} fitWidth centerItems>
				<InfoFieldContainer>
					<div>
						<Text
							type={textTypes.bodyMd!}
							color={colorTypes.gray._400!}
							alignCenter
						>
							Phone
						</Text>
					</div>
					<div>
						<Text
							type={textTypes.bodyMd!}
							color={getFieldTextColor(currentInfo.phone)}
						>
							{currentInfo.phone}
						</Text>
					</div>
				</InfoFieldContainer>
			</Row>
			<Row mt={16} fitWidth centerItems>
				<InfoFieldContainer>
					<div>
						<Text
							type={textTypes.bodyMd!}
							color={colorTypes.gray._400!}
							alignCenter
						>
							Email
						</Text>
					</div>
					<div>
						<Text
							type={textTypes.bodyMd!}
							color={getFieldTextColor(currentInfo.email)}
						>
							{currentInfo.email}
						</Text>
					</div>
				</InfoFieldContainer>
			</Row>
			<Row mt={16} fitWidth centerItems>
				<InfoFieldContainer>
					<div>
						<Text
							type={textTypes.bodyMd!}
							color={colorTypes.gray._400!}
							alignCenter
						>
							Country
						</Text>
					</div>
					<div>
						<Text
							type={textTypes.bodyMd!}
							color={getFieldTextColor(currentInfo.country)}
						>
							{currentInfo.country}
						</Text>
					</div>
				</InfoFieldContainer>
			</Row>
			<Row mt={16} fitWidth centerItems>
				<InfoFieldContainer>
					<div>
						<Text
							type={textTypes.bodyMd!}
							color={colorTypes.gray._400!}
							alignCenter
						>
							Address
						</Text>
					</div>
					<div>
						<Text
							type={textTypes.bodyMd!}
							color={getFieldTextColor(currentInfo.street)}
						>
							{currentInfo.street}
						</Text>
					</div>
				</InfoFieldContainer>
			</Row>
			<Row mt={16} fitWidth centerItems>
				<InfoFieldContainer>
					<div>
						<Text
							type={textTypes.bodyMd!}
							color={colorTypes.gray._400!}
							alignCenter
						>
							ZIP
						</Text>
					</div>
					<div>
						<Text
							type={textTypes.bodyMd!}
							color={getFieldTextColor(currentInfo.postcode)}
						>
							{currentInfo.postcode}
						</Text>
					</div>
				</InfoFieldContainer>
			</Row>
		</>
	);
};

const DeleteMenu: FC<{
	isOpen?: boolean;
	availableTypes?: Array<string>;
	onDelete: (type: string) => void;
	onClose: () => void;
}> = ({ isOpen = false, availableTypes = [], onDelete, onClose }) => {
	useEffect(() => {
		const onBodyClick = () => {
			onClose();
		};

		if (isOpen) {
			document.addEventListener("click", onBodyClick);
		}

		return () => {
			document.removeEventListener("click", onBodyClick);
		};
	}, [isOpen, onClose]);

	return (
		<Transition in={isOpen} timeout={200} unmountOnExit>
			{(state) => (
				<DeleteFor
					state={state}
					onClick={(e) => {
						e.preventDefault();
						e.stopPropagation();
					}}
				>
					{availableTypes?.map((x) => (
						<Row pb={12} key={`del-item-${x}`} onClick={() => onDelete(x)}>
							<Col auto pr={12} center>
								<DeleteSVG />
							</Col>
							<Col auto>
								<Text type={textTypes.bodyMd!} color={colorTypes.gray._800}>
									{contactTypesNames[x]}
								</Text>
							</Col>
						</Row>
					))}
					<Row pb={12} onClick={() => onDelete("all")}>
						<Col auto pr={12} center>
							<DeleteIcon>
								<DeleteSVG />
							</DeleteIcon>
						</Col>
						<Col auto>
							<Text type={textTypes.bodyMd!} color={colorTypes.red._500!}>
								All services
							</Text>
						</Col>
					</Row>
				</DeleteFor>
			)}
		</Transition>
	);
};

const ConfirmDeleteModal: FC<{
	email?: string;
	isOpen?: boolean;
	serviceType?: string;
	onClose: () => void;
	onConfirm: (serviceType: string) => void;
}> = ({ isOpen = false, email, serviceType, onClose, onConfirm }) => {
	const serviceName = useMemo(
		() =>
			serviceType && serviceType !== "all"
				? contactTypesNames[serviceType]
				: serviceType && "All services",
		[serviceType]
	);

	const onConfirmClick = useCallback(() => {
		onConfirm(serviceType!);
	}, [serviceType, onConfirm]);

	const onCloseClick = useCallback(
		(e: any) => {
			e.preventDefault();
			e.stopPropagation();
			onClose();
		},
		[onClose]
	);

	const preventBubling = useCallback((e: any) => {
		e.preventDefault();
		e.stopPropagation();
	}, []);

	return (
		<Transition in={isOpen} timeout={200} unmountOnExit>
			{(state) => (
				<ConfirmModalWrapper state={state} onClick={onCloseClick}>
					<ConfirmModal onClick={preventBubling}>
						<Row centerItems>
							<Col>
								<Text type={textTypes.h5Bold!} color={colorTypes.gray._800!}>
									Delete account info from {serviceName}
								</Text>
							</Col>
							<Col auto>
								<Button
									size={ButtonSize.sm}
									variant={buttonTypes.softSecondary}
									icon
									round
									onClick={onCloseClick}
								>
									<CloseSVG />
								</Button>
							</Col>
						</Row>
						<Row mt={24}>
							<Text
								type={textTypes.bodyMd!}
								color={colorTypes.gray._700!}
								isInline
								mr={8}
							>
								Are you sure you want to delete
							</Text>
							<Text
								type={textTypes.bodyMdBold!}
								color={colorTypes.gray._700!}
								isInline
								mr={8}
							>
								{email}
							</Text>
							<Text
								type={textTypes.bodyMd!}
								color={colorTypes.gray._700!}
								isInline
							>
								info from {serviceName}
							</Text>
						</Row>
						<Row mt={18}>
							<Col itemRight>
								<div>
									<Button
										size={ButtonSize.md}
										variant={buttonTypes.outlineSecondary}
										round
										onClick={onCloseClick}
									>
										CANCEL
									</Button>
									<Button
										size={ButtonSize.md}
										variant={buttonTypes.warningRed!}
										round
										ml={12}
										onClick={onConfirmClick}
									>
										DELETE
									</Button>
								</div>
							</Col>
						</Row>
					</ConfirmModal>
				</ConfirmModalWrapper>
			)}
		</Transition>
	);
};

const ContactCard: FC<IContactCard> = ({
	email = "",
	isOpen,
	onClose,
	onDeleted,
}) => {
	const [contactInfo, setContactInfo] = useState<{
		isLoading: boolean;
		contact?: any | null;
	}>({
		isLoading: false,
		contact: null,
	});
	const [deleteMenus, setDeleteMenusStates] = useState({
		menuIsOpen: false,
		serviceType: "",
		confirmDialogIsOpen: false,
	});

	const availableTypes = useMemo(
		() =>
			contactInfo.contact &&
			Object.keys(contactInfo.contact).filter(
				(x) =>
					(contactInfo.contact as Record<string, any>)[x] !== undefined &&
					(contactInfo.contact as Record<string, any>)[x] !== null
			),
		[contactInfo]
	);

	const onMenuItemDeleteClick = useCallback(
		(type: string) => {
			setDeleteMenusStates({
				...deleteMenus,
				confirmDialogIsOpen: true,
				serviceType: type,
			});
		},
		[deleteMenus]
	);

	const toggleDeleteMenu = useCallback(
		(e?: any) => {
			if (e) {
				e.preventDefault();
				e.stopPropagation();
			}
			setDeleteMenusStates({
				...deleteMenus,
				menuIsOpen: !deleteMenus.menuIsOpen,
			});
		},
		[deleteMenus]
	);

	const onConfirmDeleteClose = useCallback(() => {
		setDeleteMenusStates({
			...deleteMenus,
			confirmDialogIsOpen: false,
			serviceType: "",
		});
	}, [deleteMenus]);

	const onConfirmModalConfirm = useCallback(
		async (serviceType) => {
			if (serviceType === "all") {
				const isDeleted = await deleteContactCard({
					contact: contactInfo.contact,
					contactType: serviceType,
				});
				if (isDeleted) {
					setDeleteMenusStates({
						menuIsOpen: false,
						confirmDialogIsOpen: false,
						serviceType: "",
					});
				}
				onDeleted();
			} else {
				if (availableTypes && availableTypes.length === 1) {
					const isDeleted = await deleteContactCard({
						contact: contactInfo.contact,
						contactType: serviceType,
					});
					if (isDeleted) {
						setDeleteMenusStates({
							menuIsOpen: false,
							confirmDialogIsOpen: false,
							serviceType: "",
						});
					}
					onDeleted();
				} else {
					const isDeleted = await deleteContactCard({
						contact: contactInfo.contact,
						contactType: serviceType,
					});

					if (isDeleted) {
						const newContactInfo = { ...contactInfo.contact };
						(newContactInfo as Record<string, any>)[serviceType] = null;

						setDeleteMenusStates({
							...deleteMenus,
							confirmDialogIsOpen: false,
							serviceType: "",
						});

						setContactInfo({ isLoading: false, contact: newContactInfo });
					}
				}
			}
		},
		[contactInfo, availableTypes, deleteMenus]
	);

	useEffect(() => {
		if (isOpen && email) {
			setContactInfo({ isLoading: true, contact: null });

			(async () => {
				const contactCard = await getContactCardInfo(email);

				setContactInfo({ isLoading: false, contact: contactCard });
			})();
		}
	}, [isOpen, email]);

	return (
		<Modal onClose={onClose} isOpen={isOpen} backdrop={true}>
			<>
				<ConfirmDeleteModal
					isOpen={deleteMenus.confirmDialogIsOpen}
					serviceType={deleteMenus.serviceType}
					email={email}
					onClose={onConfirmDeleteClose}
					onConfirm={onConfirmModalConfirm}
				/>
				<Transition in={isOpen} timeout={250} unmountOnExit>
					{(state) => (
						<Container state={state}>
							<Row mt={Space.lg} ml={Space.lg} mr={Space.lg}>
								<Row fitWidth>
									<Col auto>
										<Avatar>
											<AvatarIconSVG />
										</Avatar>
									</Col>
									<Col center>
										<Text
											type={textTypes.h6Bold!}
											ml={18}
											color={colorTypes.gray._800!}
										>
											{email}
										</Text>
									</Col>
									<Col itemRight>
										<Button
											size={ButtonSize.sm}
											variant={buttonTypes.softSecondary}
											onClick={onClose}
											icon
										>
											<CloseSVG />
										</Button>
									</Col>
								</Row>
								{contactInfo.isLoading ? (
									<SpinnerContainer>
										<Spinner variant={SpinnerType.ring} />
									</SpinnerContainer>
								) : (
									<></>
								)}
								{contactInfo.isLoading === false && !contactInfo.contact ? (
									<SpinnerContainer>
										<Text
											type={textTypes.bodyMdBold!}
											ml={18}
											color={colorTypes.gray._600!}
										>
											Failed to loading contact
										</Text>
									</SpinnerContainer>
								) : (
									<></>
								)}
								{contactInfo.isLoading === false && contactInfo.contact ? (
									<Row fitWidth pt={32} pb={78}>
										<ContactServicesInfo info={contactInfo.contact} />
									</Row>
								) : (
									<></>
								)}
							</Row>
							<BottomContainer>
								{contactInfo.isLoading === false && contactInfo.contact ? (
									<Row fitWidth centerItems>
										<Col itemRight>
											<DeleteWrapper>
												<DeleteMenu
													isOpen={deleteMenus.menuIsOpen}
													availableTypes={availableTypes!}
													onDelete={onMenuItemDeleteClick}
													onClose={toggleDeleteMenu}
												/>
												<DeleteButton onClick={toggleDeleteMenu}>
													<DeleteSVG />
													<Text
														type={textTypes.captionMd!}
														ml={18}
														color={colorTypes.red._500!}
													>
														DELETE FROM...
													</Text>
												</DeleteButton>
											</DeleteWrapper>
										</Col>
									</Row>
								) : (
									<></>
								)}
							</BottomContainer>
						</Container>
					)}
				</Transition>
			</>
		</Modal>
	);
};

export default ContactCard;
