import React, { useCallback } from "react";
import { FC } from "react";
import { List, AutoSizer } from "react-virtualized";
import { Row, Col } from "../../Grid";
import {
  Container,
  Item,
  StatsItem,
  StatusBadge,
  Button,
  IconButton,
  ButtonsContainer,
} from "./styled";
import { textTypes } from "../../../styles/typography";
import Text from "../../Text";
import { ReactComponent as ArrowDownIcon } from "../../../assets/icons/keyboard_arrow_down.svg";
import { ReactComponent as EmailIcon } from "../../../assets/icons/email.svg";
import { ReactComponent as AutomationIcon } from "../../../assets/icons/automation.svg";
import Badge from "../../Badge";
import { BadgeSize, badgesTypes } from "../../../styles/badges";


export interface ICampaignItem {
  title?: string;
  description?: string;
  visits?: number;
  opens?: number;
  clicks?: number;
  type: "automation" | "email";
  status: "sent" | "draft" | "active";
}

interface ICampaignList {
  items: Array<ICampaignItem>;
}


const CampaignList: FC<ICampaignList> = ({ items }) => {

  const rowRenderer = useCallback(
    ({ key, index, style }) => {
      return (
        <Item key={key} style={style}>
          <Row>
            <Col auto pr={22}>
              {items[index].type === "email" ? (
                <EmailIcon />
              ) : (
                <AutomationIcon />
              )}
            </Col>
            <Col sm={3}>
              <Text type={textTypes.bodyLgBold!}>{items[index].title}</Text>
              <Text type={textTypes.bodySm!}>{items[index].description}</Text>
            </Col>
            <Col ml={52} mr={0}>
              <Badge // change badge
                status={items[index].status}
                type={badgesTypes.soft!}
                round
                size={BadgeSize.small}
              />
            </Col>
            {items[index].visits !== undefined && (
              <Col pl={16} pr={16}>
                <StatsItem>
                  <Text type={textTypes.bodyMdBold!}>
                    {items[index].visits}
                  </Text>
                  <Text type={textTypes.bodySm!}>Visits</Text>
                </StatsItem>
              </Col>
            )}
            {items[index].opens !== undefined && (
              <Col pl={16} pr={16}>
                <StatsItem>
                  <Text type={textTypes.bodyMdBold!}>
                    {items[index].opens}%
                  </Text>
                  <Text type={textTypes.bodySm!}>Opens</Text>
                </StatsItem>
              </Col>
            )}
            {items[index].clicks !== undefined && (
              <Col pl={16} pr={16}>
                <StatsItem>
                  <Text type={textTypes.bodyMdBold!}>
                    {items[index].clicks}%
                  </Text>
                  <Text type={textTypes.bodySm!}>Clicks</Text>
                </StatsItem>
              </Col>
            )}
            <Col>
              {items[index].status === "draft" ? (
                <ButtonsContainer>
                  <IconButton>
                    <ArrowDownIcon  />
                  </IconButton>
                  <Button>Edit</Button>
                </ButtonsContainer>
              ) : (
                <ButtonsContainer>
                  <IconButton>
                    <ArrowDownIcon />
                  </IconButton>
                  <Button>View Report</Button>
                </ButtonsContainer>
              )}
            </Col>
          </Row>
        </Item>
      );
    },
    [items]
  );

  return (
    <Container>
      <AutoSizer>
        {({ height, width }) => (
          <List
            height={height}
            width={width}
            rowCount={items.length}
            rowHeight={103}
            rowRenderer={rowRenderer}
          />
        )}
      </AutoSizer>
    </Container>
  );
};

export default CampaignList;
