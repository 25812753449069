import styled from "../../../../../styles/theme";
import { css } from 'styled-components';

export const InfoContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 552px;
    height: 80px;
    background-color: #fff;
    border-radius: 8px;
`;

interface IImageContainer{
    children?: any;
}

export const ImageContainer = styled.div<IImageContainer>`
    display: flex;
    justify-content: center;
    width: 56px;
    height: 56px;
    overflow: hidden;
    ${({children}) => children === undefined && css`
        background-color: #f6f6f6;
    `}
`;

export const Image = styled.img`
    height: 200px;
    width: fit-content;
    border-radius: 4px;
    background-color: #fff;
`;