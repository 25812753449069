import { flow, getSnapshot, Instance, types } from "mobx-state-tree";
import { createContext, useContext } from "react";
import localStore from "store";
import UserModel from "./user";
import DynamicGroupsModel from "./dynamic-groups";
import TemplatesModel from "./templates";
import InternalContactsModel from "./internal-contacts";
import CompaniesModel from "./companies";
import CampaignFormModel from "./campaigns-form";
import EmailStatsModel from "./email-stats";
import AdminModel from "./admin";

const initialState = {
	user: {
		permissions: {
			roleName: "",
			policies: [],
			departments: [],
			regions: [],
			contactTypes: [],
		},
	},
	dynamicGroups: {},
	internalContacts: {},
	templates: {},
	companies: {},
	campaigns: { recepients: {}, subject: {}, send: {} },
	emailStats: {},
	admin: { users: {}, roles: {} },
	initialized: false,
};

const StoreModel = types
	.model("StoreModel", {
		user: UserModel,
		dynamicGroups: DynamicGroupsModel,
		internalContacts: InternalContactsModel,
		companies: CompaniesModel,
		templates: TemplatesModel,
		campaigns: CampaignFormModel,
		emailStats: EmailStatsModel,
		admin: AdminModel,
	})
	.actions((self) => ({
		update() {},
		userLogOut() {
			self.user.setName("");
			self.user.setPermissions({
				roleName: "",
				policies: [],
				departments: [],
				regions: [],
				contactTypes: [],
			});
			self.admin.initRoles([]);
			self.admin.initUsers([]);
			localStore.clearAll();
		},
	}));

const getStoredData = () => {
	const storeData = localStore.get("1.0-store");

	if (storeData) {
		const data = {
			...storeData,
		};

		data.emailStats = storeData.emailStats || {};
		data.campaigns.recepients = storeData.recepients || {};
		data.campaigns.subject = storeData.subject || {};
		data.campaigns.send = storeData.send || {};
		data.user.permissions =
			(storeData.user && storeData.user.permissions) || {};
		data.admin = { users: {}, roles: {} };
		data.campaigns.automation = storeData.automation || {}; // add

		return data;
	}

	return initialState;
};

export const Store = StoreModel.create(getStoredData());

if (process.env.NODE_ENV !== "production") {
	(async () => {
		// localStorage.remove("1.0-store");
		// @ts-ignore
		await import("mobx-devtools-mst").then(
			({ default: makeInspectable }: any) => {
				makeInspectable(Store);
			}
		);

		window.addEventListener("beforeunload", () => {
			localStore.set("1.0-store", getSnapshot(Store));
		});
	})();
} else {
	window.addEventListener("beforeunload", () => {
		localStore.set("1.0-store", getSnapshot(Store));
	});
}

const StoreContext = createContext(Store);

export type StoreInstance = Instance<typeof StoreModel>;

export const useStore = () => useContext<StoreInstance>(StoreContext);
