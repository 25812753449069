import { FC } from "react";
import { Row, Col } from "../Grid";
import Text from "../Text";
import { textTypes } from "../../styles/typography";
import Badge from "../Badge";
import { badgesTypes, BadgeSize, BadgeStatus } from "../../styles/badges";
import Steps from "../Steps";
import { Container } from "./styled";

const steps: Array<Record<string, string>> = [
	{ label: "", value: "Create an email" },
	{ label: "addSubject", value: "Add Subject" },
	{ label: "chooseRecepients", value: "Choose recipients" },
	{ label: "send", value: "Send campaign" },
];

const HeaderNewCampaign: FC = () => {
	return (
		<>
			<Row centerItems fitWidth>
				<Container>
					<Col ml={0}>
						<Row>
							<Text type={textTypes.h6Bold!} mr={21}>
								New Campaign
							</Text>
						</Row>
						<Row>
							<Text type={textTypes.bodySm!} customColor="rgb(137,138,139)">
								Bookland
							</Text>
						</Row>
					</Col>
					<Col center>
						<Badge // change badge
							type={badgesTypes.soft!}
							status={BadgeStatus.draft}
							size={BadgeSize.small}
							round
						/>
					</Col>
					<Col mr={0} center>
						<Steps steps={steps} />
					</Col>
				</Container>
			</Row>
		</>
	);
};

export default HeaderNewCampaign;
