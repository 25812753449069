import { FC } from "react";
import { Row, Col } from "../../../../Grid";
import { Tabs, Tab } from "../../../../Tabs";
import { Space } from "../../../../../types/constants";
import TemplateCards from "../../../../CampaignCard/TemplateCard";

interface ICampaignTemplatesModal {
  onSelect?: () => void;
  onPreview?: () => void;
}

const CampaignTemplatesModal: FC<ICampaignTemplatesModal> = ({
  onSelect,
  onPreview,
}) => {
  return (
        <Row ml={Space.lg} mt={Space.lg}>
          <Tabs>
            <Tab name="All templates">
              <Row mt={Space.lg}>
                <Col>
                  <TemplateCards onSelect={onSelect} onPreview={onPreview}/>
                </Col>
              </Row>
            </Tab>
            <Tab name="Celebration">
              <Row mt={Space.lg}>
                <Col>
                  <TemplateCards />
                </Col>
              </Row>
            </Tab>
            <Tab name="Promotion">
              <Row mt={Space.lg}>
                <Col>
                  <TemplateCards />
                </Col>
              </Row>
            </Tab>
            <Tab name="Quiz">
              <Row mt={Space.lg}>
                <Col>
                  <TemplateCards />
                </Col>
              </Row>
            </Tab>
          </Tabs>
        </Row>
  );
};

export default CampaignTemplatesModal;
