import { FC, useCallback } from "react";
import { Row, Col } from "../../Grid";
import {
	Container,
	Devider,
	Backward,
	Forward,
	SaveContainer,
	CloseContainer,
	EditContainer,
	CampaignNameField,
} from "./styled";
import Text from "../../Text";
import { textTypes } from "../../../styles/typography";
import Badge from "../../Badge";
import { badgesTypes, BadgeStatus, BadgeSize } from "../../../styles/badges";
import { ReactComponent as Save } from "../../../assets/icons/save.svg";
import { ReactComponent as Close } from "../../../assets/icons/close.svg";
import Button from "../../Button";
import { ButtonSize, buttonTypes } from "../../../styles/buttons";
import { Space } from "../../../types/constants";
import { useNavigate } from "react-router-dom";
import Tooltip, { useTooltipData } from "../../Tooltip";
import { TooltipSide } from "../../../styles/tooltips";
import { ReactComponent as EditIcon } from "../../../assets/icons/edit_draft.svg";

interface IAutomationHeader {
	undo: () => void;
	redo: () => void;
	onSave: () => void;
}

const AutomationHeader: FC<IAutomationHeader> = ({ undo, redo, onSave }) => {
	const navigate = useNavigate();
	const [tooltipIn, tooltipOut, tooltipData] = useTooltipData();
	const activate = useCallback(() => {
		console.log("Activate"); // TODO
		onSave();
	}, []);
	const save = useCallback(() => {
		console.log("save"); // TODO
	}, []);
	const close = useCallback(() => {
		navigate("/marketing/campaigns");
	}, []);
	const editAutomation = useCallback(() => {
		document.getElementById("campaignName")?.focus();
	}, []); // TODO
	return (
		<>
			<Container>
				<Row fitWidth>
					<Col ml={0} center>
						<Row>
							<CampaignNameField // уточнить у Олега
								id="campaignName"
								placeholder="New Campaign"
								name="campaignName"
								onChange={editAutomation}
							/>
						</Row>
						<Row>
							<Text
								type={textTypes.bodySmBold!}
								customColor="rgba(24, 24, 25, 0.42)"
							>
								Bookland
							</Text>
						</Row>
					</Col>
					<Col ml={Space.tiny}>
						<EditContainer onClick={editAutomation}>
							<EditIcon />
						</EditContainer>
					</Col>
					<Col center itemRight>
						<Row>
							<Col mr={0}>
								<Row centerItems>
									<Badge
										type={badgesTypes.soft!}
										status={BadgeStatus.draft}
										size={BadgeSize.small}
										round
									/>
									<Devider />
									<Backward
										onClick={undo}
										data-tooltip={`Undo`}
										onMouseEnter={tooltipIn}
										onMouseLeave={tooltipOut}
									/>
									<Forward
										onClick={redo}
										data-tooltip={`Redo`}
										onMouseEnter={tooltipIn}
										onMouseLeave={tooltipOut}
									/>
									<Devider />
									<SaveContainer
										data-tooltip={`Save`}
										onMouseEnter={tooltipIn}
										onMouseLeave={tooltipOut}
									>
										<Save onClick={save} />
									</SaveContainer>
									<Devider />
									<Button
										variant={buttonTypes.default!}
										size={ButtonSize.md}
										round
										type="submit"
										onClick={activate}
										mr={Space.sm}
									>
										ACTIVATE
									</Button>
									{/* { location.pathname.match(/newAutomationCampaign/i)?.shift() ? ( // TODO condition for active campaign
										<>
											<Devider />
										<Backward
											onClick={backward}
											data-tooltip={`Undo`}

											onMouseEnter={tooltipIn}
											onMouseLeave={tooltipOut}
										/>
										<Forward
											onClick={forward}
											data-tooltip={`Redo`}
											onMouseEnter={tooltipIn}
											onMouseLeave={tooltipOut}
										/>
										<Devider />
										<SaveContainer
											data-tooltip={`Save`}
											onMouseEnter={tooltipIn}
											onMouseLeave={tooltipOut}
										>
											<Save onClick={save} />
										</SaveContainer>
										<Devider />
										<Button
											variant={buttonTypes.default!}
											size={ButtonSize.md}
											round
											type="button"
											onClick={activate}
											mr={Space.sm}
										>
											ACTIVATE
										</Button>
										</>
										) : (
										<>
											<Button
											variant={buttonTypes.outlinePrimary!}
											size={ButtonSize.md}
											round
											type="button"
											onClick={activate}
											ml={Space.sm}
											mr={Space.xs}
										>
											Pause automation
										</Button>
										<Button
											variant={buttonTypes.default!}
											size={ButtonSize.md}
											round
											type="button"
											onClick={editAutomation}
											mr={Space.sm}
										>
											Edit automation
										</Button>
										</> 
										)} */}
									<CloseContainer
										onClick={close}
										data-tooltip={`Save and close`}
										onMouseEnter={tooltipIn}
										onMouseLeave={tooltipOut}
									>
										<Close />
									</CloseContainer>
									<Tooltip {...tooltipData} side={TooltipSide.bottom} />
								</Row>
							</Col>
						</Row>
					</Col>
				</Row>
			</Container>
		</>
	);
};

export default AutomationHeader;
