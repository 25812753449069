import { DateTime } from "luxon";
import { flow, types } from "mobx-state-tree";
import { deleteTemplate, getTemplate, uploadTemplate } from "../api/templates";

const TemplateModel = types.model("TemplateModel", {
	id: types.identifier,
	name: types.string,
	image: types.string,
	modifiedOn: types.string,
});

const TemplatesModel = types
	.model("TemplatesModel", {
		entries: types.map(TemplateModel),
	})
	.actions((self) => ({
		init(templates?: any) {
			const entriesData = templates.reduce((acc: any, { id, ...rest }: any) => {
				return {
					...acc,
					[id]: {
						id: id,
						...rest,
					},
				};
			}, {});

			self.entries?.replace(entriesData);
		},
		update: flow(function* update(data: {
			id?: string;
			image: string;
			name: string;
			template: string;
		}) {
			const { id, name, image } = data;
			if (id) {
				const response = yield uploadTemplate(data);

				self.entries?.set(
					id,
					TemplateModel.create({
						id: id,
						modifiedOn: DateTime.local().toISO(),
						name: name,
						image: image,
					})
				);

				return id;
			}

			const response = yield uploadTemplate(data);
			const newId = response;
			self.entries?.set(
				newId,
				TemplateModel.create({
					id: newId,
					modifiedOn: DateTime.local().toISO(),
					name: name,
					image: image,
				})
			);

			return newId;
		}),
		copy: flow(function* copy(id: string) {
			const item = self.entries.get(id);
			const data = yield getTemplate(id);

			if (item?.id && data.id) {
				const response = yield uploadTemplate({
					image: data.image,
					name: `${item.name} Copy`,
					template: data.template,
				});

				const newId = response;

				self.entries?.set(
					newId,
					TemplateModel.create({
						id: newId,
						modifiedOn: DateTime.local().toISO(),
						name: `${item.name} Copy`,
						image: item.image,
					})
				);

				return newId;
			}

			return null;
		}),
		delete(id: string) {
			deleteTemplate(id);
			self.entries.delete(id);
		},
	}))
	.views((self) => ({
		array() {
			return self.entries
				? Array.from(self.entries?.values()).sort((first, second) => {
						const dt1 = first.modifiedOn && DateTime.fromISO(first.modifiedOn);
						const dt2 =
							second.modifiedOn && DateTime.fromISO(second.modifiedOn);
						if (dt1! > dt2!) {
							return -1;
						}
						if (dt1! < dt2!) {
							return 1;
						}

						return 0;
				  })
				: [];
		},
	}));

export default TemplatesModel;
