import { flow, getRoot, types } from "mobx-state-tree";
import {
	uploadInternalContact,
	deleteInternalContact,
} from "../api/internal-contacts";

interface IInternalContact {
	id: string;
	fullName: string;
	gender?: string;
	// "company": types.maybeNull,
	email?: string;
	companyPosition?: string;
	prefferedLanguage?: string;
}

export const InternalContactModel = types
	.model("InternalContactModel", {
		id: types.identifier,
		fullName: types.maybeNull(types.string),
		gender: types.maybeNull(types.string),
		company: types.maybeNull(types.string),
		email: types.maybeNull(types.string),
		companyPosition: types.maybeNull(types.string),
		prefferedLanguage: types.maybeNull(types.string),
	})
	.views((self) => ({
		get companyName() {
			const root: any = getRoot(self);
			const company = root.companies.entries.get(self.company);

			return (company && company.name) || "";
		},
	}));

const InternalContactsModel = types
	.model("InternalContactsModel", {
		entries: types.map(InternalContactModel),
	})
	.actions((self) => ({
		init(contacts?: any) {
			const entriesData = contacts.reduce(
				(acc: any, { id, company, ...rest }: any) => {
					return {
						...acc,
						[id]: {
							id: id,
							company: company && company.id,
							...rest,
						},
					};
				},
				{}
			);

			self.entries?.replace(entriesData);
		},
		update: flow(function* update(data: IInternalContact) {
			if (data.id) {
				const response = yield uploadInternalContact(data);

				self.entries?.set(data.id, InternalContactModel.create(data));

				return data.id;
			}

			const response = yield uploadInternalContact(data);
			const newId = response;

			self.entries?.set(
				newId,
				InternalContactModel.create({ ...data, id: newId })
			);

			return newId;
		}),
		delete(id: string) {
			deleteInternalContact(id);
			self.entries.delete(id);
		},
	}))
	.views((self) => ({
		array() {
			return self.entries
				? Array.from(self.entries?.values()).sort((first, second) => {
						if (first.fullName! > second.fullName!) {
							return -1;
						}
						if (first.fullName! < second.fullName!) {
							return 1;
						}

						return 0;
				  })
				: [];
		},
	}));

export default InternalContactsModel;
