import styled from "../../styles/theme";

export const Container = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	border-radius: 4px;
	transform: translate(-50%, -50%);
	width: 360px;
	background: #f5f5f5;
	box-shadow: 0px 6px 17px -1px rgba(60, 73, 87, 0.16);
	filter: drop-shadow(0px 4px 12px rgba(60, 73, 87, 0.04));
`;
