import { useCallback, useEffect, useMemo } from "react";
import { Form } from "react-final-form";
import Button from "../../components/Button";
import { Row, Col } from "../../components/Grid";
import Text from "../../components/Text";
import { FormTextField as TextField } from "../../components/TextField";
import { textTypes } from "../../styles/typography";
import { Container } from "./styled";
import { required, requiredMin } from "../../validations";
import { authorizeUser, validateToken } from "../../api/auth";
import { getPermissions } from "../../api/permissions";
import { FORM_ERROR } from "final-form";
import { colorTypes } from "../../styles/colors";
import { useNavigate } from "react-router-dom";
import { useStore } from "../../store";

interface ISignInForm {
	username: string;
	password: string;
}

const NEXT_PAGE = "/marketing";

const SignIn = () => {
	const { user } = useStore();
	const navigate = useNavigate();

	const onSubmit = useCallback(async (values: ISignInForm) => {
		const data = await authorizeUser(values);

		if (!data.authorized) {
			return { [FORM_ERROR]: "Login Failed" };
		}

		user.setName(data.username);

		const permissions = await getPermissions();
		user.setPermissions(permissions);

		navigate(NEXT_PAGE);
	}, []);

	useEffect(() => {
		(async () => {
			const isValid = await validateToken();

			if (isValid) navigate(NEXT_PAGE);
		})();
	}, []);

	const validate = useCallback(({ username, password }: ISignInForm) => {
		const errors = {
			username: required(username) || requiredMin(username, 4),
			password: required(password) || requiredMin(password, 4),
		};

		return errors;
	}, []);

	const initialValues = useMemo(
		() =>
			({
				username: "",
				password: "",
			} as ISignInForm),
		[]
	);

	return (
		<Container>
			<Text ml={16} mt={16} type={textTypes.h4!}>
				Sign in
			</Text>
			<Form
				initialValues={initialValues}
				onSubmit={onSubmit}
				validate={validate}
				validateOnBlur={false}
				render={({
					handleSubmit,
					submitting,
					submitError,
					modifiedSinceLastSubmit,
				}) => (
					<form onSubmit={handleSubmit}>
						<Row mt={24} ml={24} mr={24}>
							<TextField name="username" label="Username" fitWidth />
						</Row>
						<Row mt={16} ml={24} mr={24}>
							<TextField
								type="password"
								label="Password"
								name="password"
								fitWidth
							/>
						</Row>
						{Boolean(!modifiedSinceLastSubmit && submitError) && (
							<Row mt={16} ml={24}>
								<Text type={textTypes.captionMd!} color={colorTypes.red._700!}>
									{submitError}
								</Text>
							</Row>
						)}
						<Row mt={24} pb={16} centerItems>
							<Col auto>
								<Button
									disabled={
										(submitError && !modifiedSinceLastSubmit) || submitting
									}
									type="submit"
								>
									Login
								</Button>
							</Col>
						</Row>
					</form>
				)}
			/>
		</Container>
	);
};

export default SignIn;
