import styled, { css } from "styled-components";
import { CampaignSettings } from "../AddSubject/styled";

interface IWrapper {
  width?: number;
  height?: number;
}

export const Wrapper = styled.div<IWrapper>`
  height: ${({ height }) => (height ? `${height}px` : "100%")};
  width: ${({ width }) => (width ? `${width}px` : "100%")};
  padding: 0;
  border-radius: 8px;
`;

export const Container = styled(CampaignSettings)`
  @media (max-height: 1300px) {
    & > div[data-id="table_groups"]{
      height: 800px;
    }
  }
`;
