import {
	FC,
	useLayoutEffect,
	useRef,
	useCallback,
	useState,
	RefObject,
	useMemo,
	createRef,
} from "react";
import {
	Container,
	Status,
	OtherContainer,
	CalendarOtherEvents,
} from "./styled";
import Text from "../Text";
import { textTypes } from "../../styles/typography";
import { Row, Col } from "../Grid";
import CalendarEventModal from "./CalendarEventModal";
import useOutsideClick from "../../hooks/detectClickOutside";
import { Transition } from "react-transition-group";

interface IInfo {
	id: string; // clarify information with Oleg
	time?: string; // 2021-12-15T16:10:04.547+02:00
	name?: string; // name of email
}
interface ICalendarEvent {
	info: Array<IInfo>;
	id: string; // id of calendar element;
}
const CalendarEvent: FC<ICalendarEvent> = ({ info, id }) => {
	let dayContainer = document.getElementById(`${id}`)?.getBoundingClientRect();
	const containerHeight: number = 58;
	const otherContainer: number = 44;
	const [top, setTop] = useState<number>(0);
	const elements = useRef<number>(0); // count other container fit in the calendar day
	let height = dayContainer ? dayContainer?.height : 0; // height of the day container

	useLayoutEffect(() => {
		elements.current = Math.floor((height - 40) / 58);
		if (elements.current === 1 && info.length > 1) {
			if (height - 40 - containerHeight < 44) {
				// count other container fit in the calendar day
				elements.current = 0;
			}
		} else if (
			elements.current < info.length &&
			height - 40 - containerHeight * elements.current < otherContainer
		) {
			elements.current -= 1;
		}
	}, [height, info]);

	const otherModalRef = useRef<any>(null);
	const eventModalRef = useMemo<Array<RefObject<HTMLDivElement>>>(() => {
		const refs: Array<RefObject<HTMLDivElement>> = [];
		for (let i = 0; i < info.length; i++) {
			refs.push(createRef());
		}
		return refs;
	}, [info]);

	const [showOther, setShowOther] = useOutsideClick(otherModalRef, false);
	const [show, setShow] = useOutsideClick(eventModalRef, false); // for calendar event modal

	const openModalEvent = useCallback(
		(e: any) => {
			let element = e.target;
			let id = e.target.id;
			let index: number = parseInt(id.match(/\d+/gi)?.shift()!, 10);
			if (id.startsWith("OtherEventsItems_")) {
				setTop(
					containerHeight * elements.current +
						40 + // padding-top in a day component
						containerHeight * (index - elements.current) +
						16 +
						containerHeight
				);
			} else {
				setTop(element.offsetTop + containerHeight);
			}
			setShow(!show);
			e.stopPropagation();
		},
		[show, eventModalRef, top]
	);

	const openOtherContainer = useCallback(() => {
		setShowOther(!showOther);
	}, [showOther]);

	return (
		<>
			{elements.current !== 0 ? (
				<>
					{info.map((item, index) => {
						if (index < elements.current) {
							return (
								<Container
									top={containerHeight * index + 40}
									onClick={openModalEvent}
									ref={eventModalRef[index]}
								>
									<Col auto>
										<Status />
									</Col>
									<Col ml={0}>
										<Row>
											<Text
												type={textTypes.bodySmBold!}
												customColor="rgba(24, 24, 25, 0.9)"
											>
												{`${item.time?.match(/\d+\:\d+(?=:)/gi)} - Email`}
											</Text>
										</Row>
										<Row>{item.name}</Row>
									</Col>
								</Container>
							);
						} else if (index === elements.current) {
							return (
								<OtherContainer
									top={containerHeight * index}
									onClick={openOtherContainer}
									ref={otherModalRef}
								>
									{`+ ${info.length - index} more`}
								</OtherContainer>
							);
						}
					})}
				</>
			) : (
				<OtherContainer
					onClick={openOtherContainer}
				>{`+ ${info.length} more`}</OtherContainer>
			)}
			<CalendarEventModal isOpen={show} top={top} />
			<Transition in={showOther} timeout={200} unmountOnExit>
				{(state) => (
					<CalendarOtherEvents
						height={
							elements.current !== 0
								? containerHeight * (info.length - elements.current)
								: containerHeight * info.length
						}
						top={containerHeight * elements.current}
						state={state}
					>
						{info.map((item, index) => {
							if (index >= elements.current) {
								return (
									<Container
										id={`OtherEventsItems_${index}`}
										top={containerHeight * (index - elements.current) + 16}
										onClick={openModalEvent}
										ref={eventModalRef[index]}
									>
										<Col auto>
											<Status />
										</Col>
										<Col ml={0}>
											<Row>
												<Text
													type={textTypes.bodySmBold!}
													customColor="rgba(24, 24, 25, 0.9)"
												>
													{`${item.time?.match(/\d+\:\d+(?=:)/gi)} - Email`}
												</Text>
											</Row>
											<Row>{item.name}</Row>
										</Col>
									</Container>
								);
							}
						})}
					</CalendarOtherEvents>
				)}
			</Transition>
		</>
	);
};

export default CalendarEvent;
