import styled from "styled-components";

export const Container = styled.div`
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	right: 0px;
	width: 480px;
	//height: 212px;
	background: #F6F6F6; 
	//overflow: hidden;
	border-radius: 8px;
`;

export const Wrapper = styled.div`
    margin: 24px 24px 16px 24px;
`;

export const CloseContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border-radius: 32px;
    background: rgba(65, 65, 65, 0.04);
    cursor: pointer;
    transition: all 300ms ease;
    :hover{
        background: rgba(65, 65, 65, 0.1);
    };
`;