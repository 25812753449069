import { reflectObjectProps } from "../utils/reflection";

export enum ChipVariant{
    filled = "filled",
    outline = "outline",
}

export enum ChipsSize {
    sm = "sm",
    md = "md",
    lg = "lg",
};

export enum ChipType {
    textOnly = "textOnly",
    multipleIcons = "multipleIcons",
    leadingIcon = "leadingIcon",
    trailingIcon = "trailingIcon",
    avatar = "avatar",
};

export const chips = {
    filled: {
        background: {
            default: "rgba(240, 241, 242, 1)",
            hover: "rgb(230, 231, 233)",
            focused: "rgb(214,214,216)",
            active: "rgb(240, 241, 242)",
            disabled: "rgb(246,246,246)",
        },
        color: {
            default: "rgba(24, 24, 25, 0.9)",
            disabled: "rgb(175,175,175)",
        },
        font: {
            sm:{
                fontSize: 12, 
                weight: 400,
                lineHeight: 16,
            },
            md:{
                fontSize: 14, 
                weight: 400,
                lineHeight: 20,
            },
            lg:{
                fontSize: 16, 
                weight: 400,
                lineHeight: 24,
            }
        },
        border: 0,
        borderRadius: 100,
        borderColor: {
            default: "transparent",
            disabled: "transparent",
            active: "rgba(10, 97, 92, 1)",
            hover: "transparent",
            focused: "transparent",
        },
        padding: {
            sm: {
                textOnly: "2px 8px",
                multipleIcons: "1.5px 2px 1.5px 4px",
                leadingIcon: "1.5px 8px 1.5px 4px",
                trailingIcon: "1.5px 4px 1.5px 8px",
                avatar: "1.5px 2px 1.5px 2px",
            },
            md: {
                textOnly: "4px 12px",
                multipleIcons: "4px 6px",
                leadingIcon: "4px 12px 4px 6px",
                trailingIcon: "4px 6px 4px 12px",
                avatar: "2px 6px 2px 2px",
            },
            lg: {
                textOnly: "6px 16px",
                multipleIcons: "6px 8px 6px 6px",
                leadingIcon: "6px 16px 6px 6px",
                trailingIcon: "6px 8px 6px 16px",
                avatar: "4px 8px 4px 4px",
            },
        },
        marginText: {
            sm: {
                textOnly: {
                    ml: 0,
                    mr: 0,
                },
                multipleIcons: {
                    ml: 4,
                    mr: 4,
                },
                leadingIcon: {
                    ml: 4,
                    mr: 0,
                },
                trailingIcon: {
                    ml: 0,
                    mr: 4,
                },
                avatar: {
                    ml: 4,
                    mr: 4,
                }
            },
            md: {
                textOnly: {
                    ml: 0,
                    mr: 0,
                },
                multipleIcons: {
                    ml: 6,
                    mr: 6,
                },
                leadingIcon: {
                    ml: 6,
                    mr: 0,
                },
                trailingIcon: {
                    ml: 0,
                    mr: 6,
                },
                avatar: {
                    ml: 6,
                    mr: 6,
                }
            },
            lg: {
                textOnly: {
                    ml: 0,
                    mr: 0,
                },
                multipleIcons: {
                    ml: 6,
                    mr: 6,
                },
                leadingIcon: {
                    ml: 6,
                    mr: 0,
                },
                trailingIcon: {
                    ml: 0,
                    mr: 6,
                },
                avatar: {
                    ml: 6,
                    mr: 6,
                }
            },
        },
        avatarColor: {
            default: "rgba(255,255,255,1)",
            disabled: "rgba(255,255,255,1)",
            hover: "rgba(255,255,255,1)",
            focused: "rgba(255,255,255,1)",
            active: "rgba(255,255,255,1)",
        }
    },
    outline: {
        background: {
            default: "rgba(255, 255, 255, 1)",
            hover: "rgb(240, 241, 242)",
            focused: "rgb(230,231,233)",
            active: "rgb(255, 255, 255, 1)",
            disabled: "rgb(255, 255, 255, 1)",
        },
        color: {
            default: "rgba(24, 24, 25, 0.9)",
            disabled: "rgb(181,181,181)",
        },
        font: {
            sm:{
                fontSize: 12, 
                weight: 400,
                lineHeight: 16,
            },
            md:{
                fontSize: 14, 
                weight: 400,
                lineHeight: 20,
            },
            lg:{
                fontSize: 16, 
                weight: 400,
                lineHeight: 24,
            }
        },
        border: 1,
        borderRadius: 100,
        borderColor: {
            default: "rgba(230, 231, 233, 1)",
            disabled: "rgb(240, 241, 242)",
            active: "rgba(10, 97, 92, 1)",
            hover: "rgba(230, 231, 233, 1)",
            focused: "rgba(230, 231, 233, 1)",
        },
        padding: {
            sm: {
                textOnly: "2px 8px",
                multipleIcons: "1.5px 2px 1.5px 4px",
                leadingIcon: "1.5px 8px 1.5px 4px",
                trailingIcon: "1.5px 4px 1.5px 8px",
                avatar: "1.5px 2px 1.5px 2px",
            },
            md: {
                textOnly: "4px 12px",
                multipleIcons: "4px 6px",
                leadingIcon: "4px 12px 4px 6px",
                trailingIcon: "4px 6px 4px 12px",
                avatar: "2px 6px 2px 2px",
            },
            lg: {
                textOnly: "6px 16px",
                multipleIcons: "6px 8px 6px 6px",
                leadingIcon: "6px 16px 6px 6px",
                trailingIcon: "6px 8px 6px 16px",
                avatar: "4px 8px 4px 4px",
            },
        },
        marginText: {
            sm: {
                textOnly: {
                    ml: 0,
                    mr: 0,
                },
                multipleIcons: {
                    ml: 4,
                    mr: 4,
                },
                leadingIcon: {
                    ml: 4,
                    mr: 0,
                },
                trailingIcon: {
                    ml: 0,
                    mr: 4,
                },
                avatar: {
                    ml: 4,
                    mr: 4,
                }
            },
            md: {
                textOnly: {
                    ml: 0,
                    mr: 0,
                },
                multipleIcons: {
                    ml: 6,
                    mr: 6,
                },
                leadingIcon: {
                    ml: 6,
                    mr: 0,
                },
                trailingIcon: {
                    ml: 0,
                    mr: 6,
                },
                avatar: {
                    ml: 6,
                    mr: 6,
                }
            },
            lg: {
                textOnly: {
                    ml: 0,
                    mr: 0,
                },
                multipleIcons: {
                    ml: 6,
                    mr: 6,
                },
                leadingIcon: {
                    ml: 6,
                    mr: 0,
                },
                trailingIcon: {
                    ml: 0,
                    mr: 6,
                },
                avatar: {
                    ml: 6,
                    mr: 6,
                }
            },
        },
        avatarColor: {
            default: "rgb(240,241,242)",
            disabled: "rgb(246,246,246)",
            hover: "rgb(230,231,233)",
            focused: "rgb(230,231,233)",
            active: "rgb(240,241,242)",
        }
    },
};

export const chipsTypes = reflectObjectProps(chips);