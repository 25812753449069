import styled, { css } from "styled-components";
import { TextFieldSize } from "../../styles/text-field";

interface ITextField {
	fieldSize?: string;
	fitWidth?: boolean;
	error?: boolean;
	postfixIcon?: boolean;
	prefixIcon?: boolean;
	customBackground?: string; // add 
}

interface IContainer{
	fitWidth?: boolean;
}

export const Container = styled.div<IContainer>`
	position: relative;
	width: ${({ fitWidth }) => (fitWidth ? "100%" : "180px")};
`;

const iconBase = css`
	position:absolute;
	top: 50%;
	transform: translateY(-50%);
	height: 18px;

	& svg path {
		fill:rgba(24, 24, 25, 0.42);
	}
`;

export const PrefixIcon = styled.div`
	${iconBase}
	left: 12px;
`;

export const PostfixIcon = styled.div`
	${iconBase}
	right: 12px;
`;

export const TextField = styled.input<ITextField>`
	width: ${({ fitWidth }) => (fitWidth ? "100%" : "180px")};
	border: 1px solid transparent;
	border-radius: 4px;
	font-size: 16px;
	background-color: ${({customBackground}) => customBackground ? customBackground : "#fff"}; // vlad code 
	line-height: 24px;
	font-weight: 400;
	outline: none;
	transition: all 300ms ease;
	padding-left: ${({prefixIcon}) => prefixIcon ? 38 : 12}px;
	padding-right: ${({postfixIcon}) => postfixIcon ? 38 : 12}px;
	box-sizing: border-box;
	${({ fieldSize }) =>
		fieldSize === TextFieldSize.xs
			? css`
					height: 24px;
			  `
			: fieldSize === TextFieldSize.sm
			? css`
					height: 32px;
			  `
			: fieldSize === TextFieldSize.md
			? css`
					height: 40px;
			  `
			: fieldSize === TextFieldSize.lg
			? css`
					height: 48px;
			  `
			: null};
	::placeholder {
		font-family: ${({ theme: { fonts } }) => fonts.default};
		font-style: normal;
		font-size: 16px;
		line-height: 24px;
		font-weight: 400;
		color: rgba(24, 24, 25, 0.42);
	}
	:hover {
		border-color: rgba(24, 24, 25, 0.42);
	}
	:focus {
		background-color: #fff; // add 
		border-color: ${({ error }) =>
			error === true ? "rgba(234, 104, 82, 1)" : "rgba(10, 97, 92, 1)"};
		box-shadow: 0 0 0 1px rgba(56, 85, 179, 0.16);
	}
	cursor: pointer;
`;

export const Label = styled.div<ITextField>`
	display: flex;
	margin-bottom: 8px;
	color: ${({ error }) =>
		error === true ? "rgba(234, 104, 82, 1)" : "rgba(24, 24, 25, 0.9)"};
	font-size: 12px;
	line-height: 16px;
`;

//create LabelHelperText below
export const LabelHelperText = styled(Label)`
	color: rgba(24, 24, 25, 0.42);
`;

export const Error = styled.div`
	display: block;
	margin-top: 4px;
	color: rgba(234, 104, 82, 1);
	font-size: 12px;
	line-height: 16px;
`;
