export const ecommerceStores = [
	{
		label: "Germany-DE",
		value: 1,
	},
	{
		label: "Germany-EN",
		value: 7,
	},
	{
		label: "Ukraine-UK",
		value: 33,
	},
	{
		label: "Ukraine-RU",
		value: 34,
	},
	{
		label: "Russian-RU",
		value: 35,
	},
];
