import { FC } from "react";
import Modal from "..";
import { textTypes } from "../../../styles/typography";
import { Row, Col } from "../../Grid";
import Text from "../../Text";
import { BottomContainer, Container, DeleteIcon } from "./styled";
import { Space } from "../../../types/constants";
import { Transition } from "react-transition-group";
import { useStore } from "../../../store";
import { Form } from "react-final-form";
import { useCallback } from "react";
import { useMemo } from "react";
import { FormTextField as TextField, TextFieldType } from "../../TextField";
import { FormSelect as Select } from "../../Select";
import { TextFieldSize } from "../../../styles/text-field";
import Button from "../../Button";
import { ButtonSize, buttonTypes } from "../../../styles/buttons";
import { ReactComponent as CloseSVG } from "../../../assets/icons/close.svg";
import { ReactComponent as DeleteSVG } from "../../../assets/icons/delete.svg";
import { SelectSize } from "../../../styles/select";
import { required, requiredMin } from "../../../validations";
import { Rules } from "./Rules";
import arrayMutators from "final-form-arrays";
import {
	FieldType,
	contactTypeFields,
	fieldsLabels,
	fieldsOptions,
} from "./Rules/fields";
import { DateTime } from "luxon";
import { keys, reduce } from "lodash";
import { IDynamicGroup } from "../../../store/dynamic-groups";

interface IGroupEditSidePage {
	id?: string;
	isOpen?: boolean;
	onSuccess: () => void;
	onClose: () => void;
}

interface IGroupForm {
	name: string;
	contactType?: { value: string; label: string };
	rules: [];
}

const contactTypeOptions = [
	{ value: "obreystore", label: "Bookland" },
	{ value: "readrate", label: "ReadRate" },
	{ value: "ecommerce", label: "PocketBook ECommerce" },
];

type FormRules = Array<{
	name: string;
	option:
		| { value: any; label: string }
		| Array<{ value: any; label: string }>
		| DateTime
		| { from: DateTime; to: DateTime };
}>;

const initialGroupData = {
	balance: null,
	contactType: null,
	country: null,
	createdDate: null,
	gender: null,
	id: undefined,
	isActive: null,
	isHasPocketBookDevice: null,
	isSubscribedToInterviewNewsletter: null,
	isSubscribedToMarketingNewsletter: null,
	isSubscribedToNewsletter: null,
	isSubscribedToPromotionalNewsletter: null,
	isSubscribedToTestNewsletter: null,
	isSubscribedToUnsubscribeNewsletter: null,
	isSubscriberNotActive: null,
	language: null,
	lastOwnedBookDate: null,
	name: null,
	ownedBooksCategory: null,
	plannedToReadBookCategory: null,
	readedBookCategory: null,
	readingBookCategory: null,
	storeId: null,
	subscriptionStatus: null,
	visitDate: null,
};

const groupToForm = ({ id, name, contactType, ...rest }: IDynamicGroup) => {
	return {
		id: id,
		name: name,
		contactType: contactTypeOptions.find((x) => x.value === contactType),
		rules: keys(rest).map((key) => {
			const fieldType = contactTypeFields[contactType][key];
			const option = (rest as Record<string, any>)[key];
			const options =
				typeof fieldsOptions[contactType][key] !== "function"
					? fieldsOptions[contactType][key] || []
					: [];

			if (!option) {
				return undefined;
			}

			switch (fieldType) {
				case FieldType.Select:
					return {
						name: key,
						option: {
							label: (options as any[]).find((x) => x.value === option)?.label!,
							value: option,
						},
					};
				case FieldType.MultiSelect:
					return {
						name: key,
						option: option ? option.map((x:any) => ({
							label: (options as any[]).find((y) => y.value === x)?.label!,
							value: x,
						})) : [],
					};
				case FieldType.Date:
					return {
						name: key,
						option: DateTime.fromISO(option)
					};
				case FieldType.DateRange:
					return {
						name: key,
						option: {
							from: option.from && DateTime.fromISO(option.from),
							to:	option.from &&  DateTime.fromISO(option.to)
						}
					};
				case FieldType.CurrencyRange:
					return {
						name: key,
						option: {
							currency: {
								label: (options as any[]).find((x) => x.value === option)?.label!,
								value: option,
							},
							min: option.min,
							max: option.max
						}
					};
			}
		}).filter(x => x !== undefined),
	};
};

const rulesPayload = (values: FormRules, contactType: string) => {
	const rules = values.filter((x) => x.name && x.option);
	let rulesDictionary: Record<string, any> = {};

	// Used instead reduce for stability
	for (let i = 0; i < rules.length; i++) {
		const acc = rulesDictionary;
		const { name, option } = rules[i];

		switch (contactTypeFields[contactType][name]) {
			case FieldType.Select:
				rulesDictionary = {
					...acc,
					[name]: (option as any).value,
				};
				break;
			case FieldType.MultiSelect:
				rulesDictionary = {
					...acc,
					[name]: (option as any[]).map((x) => x.value),
				};
				break;
			case FieldType.Date:
				rulesDictionary = {
					...acc,
					[name]: (option as DateTime).toISO(),
				};
				break;
			case FieldType.DateRange:
				const { from, to } = option as { from?: DateTime; to?: DateTime };
				rulesDictionary = {
					...acc,
					[name]: {
						isNot: false,
						from: from && from.toISO(),
						to: to && to.toISO(),
					},
				};
				break;
			case FieldType.CurrencyRange:
				const { currency, min, max } = option as unknown as {
					currency?: { value: any; label: string };
					min?: number;
					max: number;
				};
				rulesDictionary = {
					...acc,
					[name]:
						currency && currency.value && (min || max)
							? {
									currency: currency.value,
									min: min,
									max: max,
							  }
							: null,
				};
				break;
		}
	}

	return rulesDictionary;
};

const payload = ({ name, contactType, rules }: IGroupForm) => {
	const rulesDictionary = rulesPayload(rules, contactType?.value!);
	const groupInfo = {
		...initialGroupData,
		...{
			name: name,
			contactType: contactType?.value! || null,
			...rulesDictionary,
		},
	} as Record<string, any>;

	return groupInfo;
};

const GroupEdit: FC<IGroupEditSidePage> = ({
	id,
	isOpen,
	onClose,
	onSuccess,
}) => {
	const { dynamicGroups } = useStore();
	const currentGroup = useMemo(
		() => (id ? dynamicGroups.entries.get(id) : undefined),
		[id]
	);

	const onSubmit = useCallback(
		async (values: IGroupForm) => {
			const data = payload(values);

			dynamicGroups.update((currentGroup ? {...data, id: currentGroup.id} : data) as any);
			onSuccess();
		},
		[currentGroup]
	);

	const validate = useCallback(({ name, contactType }: IGroupForm) => {
		const errors = {
			name: required(name) || requiredMin(name, 2),
			contactType: required(contactType && contactType.value)
		};

		return errors;
	}, []);

	const deleteGroup = useCallback(async () => {
		if (currentGroup) {
			dynamicGroups.delete(currentGroup.id);
		}
		onClose();
	}, [currentGroup]);

	const initialValues = useMemo(() => {
		return currentGroup ? groupToForm(currentGroup as any) : {
			rules: [],
		};
	}, [currentGroup]);

	return (
		<Modal onClose={onClose} isOpen={isOpen} backdrop={true}>
			<Transition in={isOpen} timeout={250} unmountOnExit>
				{(state) => (
					<Container state={state}>
						<Row mt={Space.lg} ml={Space.lg} mr={Space.lg}>
							<Row fitWidth>
								<Col>
									<Text type={textTypes.h3Bold!}>
										{id ? "Group info" : "Create group"}
									</Text>
								</Col>
								<Col itemRight>
									<Button
										size={ButtonSize.sm}
										variant={buttonTypes.softSecondary}
										onClick={onClose}
										icon
									>
										<CloseSVG />
									</Button>
								</Col>
							</Row>
							<Col pt={Space.lg}>
								<Form
									initialValues={initialValues}
									mutators={{ ...arrayMutators }}
									validate={validate}
									onSubmit={onSubmit}
									render={({ handleSubmit, values, form: { change } }) => (
										<form onSubmit={handleSubmit}>
											<Row>
												<TextField
													name="name"
													label="Name"
													size={TextFieldSize.sm}
													fitWidth
												/>
											</Row>
											<Row mt={Space.sm}>
												<Select
													name="contactType"
													label="Contact type"
													placeholder="Select contact type"
													parse={(value) => {
														change("rules", []);

														return value;
													}}
													options={contactTypeOptions}
												/>
											</Row>
											<Row>
												<Rules
													name="rules"
													contactType={
														values.contactType && values.contactType.value
													}
												/>
											</Row>
											<BottomContainer>
												<Row pt={16} pl={24} pr={24}>
													<Col itemRight>
														{id ? (
															<>
																<Button
																	type="button"
																	size={ButtonSize.md}
																	variant={buttonTypes.softSecondary}
																	onClick={deleteGroup}
																	mr={16}
																	icon
																>
																	<DeleteIcon>
																		<DeleteSVG />
																	</DeleteIcon>
																</Button>
																<Button
																	type="submit"
																	size={ButtonSize.md}
																	variant={buttonTypes.outlinePrimary}
																>
																	UPDATE
																</Button>
															</>
														) : (
															<Button
																type="submit"
																size={ButtonSize.md}
																variant={buttonTypes.outlinePrimary}
															>
																CREATE
															</Button>
														)}
													</Col>
												</Row>
											</BottomContainer>
										</form>
									)}
								/>
							</Col>
						</Row>
					</Container>
				)}
			</Transition>
		</Modal>
	);
};

export default GroupEdit;
