import PermissionType from "../../permissions";
import { ReactComponent as ContactsIcon } from "../../../assets/icons/contacts.svg";
import { ReactComponent as AdminIcon } from "../../../assets/icons/admin-lock.svg";
import { ReactComponent as RolesIcon } from "../../../assets/icons/groups_triple.svg";
import { ReactComponent as HistoryIcon } from "../../../assets/icons/schedule.svg";

export const adminRoutes = {
	label: "admin",
	categoryIcon: <AdminIcon />,
	items: [
		{
			label: "Users",
			path: "/admin/users",
			icon: (
				<>
					<ContactsIcon />
				</>
			),
			permission: PermissionType.VIEW_SYSTEM_USER,
		},
		{
			label: "Roles",
			path: "/admin/roles",
			icon: (
				<>
					<RolesIcon />
				</>
			),
			permission: PermissionType.VIEW_SYSTEM_ROLE,
		},
		{
			label: "History",
			path: "/admin/history",
			icon: (
				<>
					<HistoryIcon />
				</>
			),
			permission: PermissionType.VIEW_SYSTEM_USER_LOGS,
		},
	],
};
