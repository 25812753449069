import { css } from "styled-components";
import { ButtonSize } from "../../styles/buttons";
import { marginProps } from "../../styles/helpers";
import styled from "../../styles/theme";
import { IMargin } from "../../types/styles";

interface IBaseButton extends IMargin {
	round?: boolean;
	icon?: boolean;
	variant: string;
	size: string;
	fitWidth?: boolean;
}

interface IButtonType {
	radius: number;
	padding: Record<string, Record<string, string>>;
	font: Record<string, Record<string, Record<string, number>>>;
	background: {
		base: string;
		hover: string;
		focus: string;
		active: string;
		disabled: string;
	};
	border?: {
		base: string;
		hover: string;
		focus: string;
		active: string;
		disabled: string;
	};
	textColor: {
		base: string;
		hover: string;
		focus: string;
		active: string;
		disabled: string;
	};
}

type ButtonVariant = Record<string, IButtonType>;

export const BaseButton = styled.button<IBaseButton>`
	${marginProps}
	//
  font-size: ${({ round, size, variant, theme: { buttons } }) =>
		round
			? (buttons as ButtonVariant)[variant].font.round[size].size
			: (buttons as ButtonVariant)[variant].font.default[size].size}px;
	font-weight: ${({ round, size, variant, theme: { buttons } }) =>
		round
			? (buttons as ButtonVariant)[variant].font.round[size].weight
			: (buttons as ButtonVariant)[variant].font.default[size].weight};
	line-height: ${({ round, size, variant, theme: { buttons } }) =>
		round
			? (buttons as ButtonVariant)[variant].font.round[size].lineHeight
			: (buttons as ButtonVariant)[variant].font.default[size].lineHeight}px;
	letter-spacing: ${({ round, size, variant, theme: { buttons } }) =>
		round
			? (buttons as ButtonVariant)[variant].font.round[size].letterSpacing
			: (buttons as ButtonVariant)[variant].font.default[size].letterSpacing}px;
	//
	outline: none;
	width: ${({ fitWidth }) => (fitWidth ? "100%" : "auto")};
	border: ${({ variant, theme: { buttons } }) =>
		(buttons as ButtonVariant)[variant].border?.base ?? "none"};
	border-radius: ${({ round, variant, theme: { buttons } }) =>
		round ? 40 : (buttons as ButtonVariant)[variant].radius}px;
	padding: ${({ variant, round, size, icon, theme: { buttons } }) =>
		icon
			? (buttons as ButtonVariant)[variant].padding["icon"][size]
			: round
			? (buttons as ButtonVariant)[variant].padding["round"][size]
			: (buttons as ButtonVariant)[variant].padding["default"][size]};
	background: ${({ variant, theme: { buttons } }) =>
		(buttons as ButtonVariant)[variant].background.base};
	color: ${({ variant, theme: { buttons } }) =>
		(buttons as ButtonVariant)[variant].textColor.base};
	cursor: pointer;
	font-family: ${({ theme: { fonts } }) => fonts.default};
	box-sizing: border-box;
	transition: all 300ms ease;
	& svg {
		${({ size }) =>
			size === ButtonSize.lg
				? css`
						width: 24px;
						height: 24px;
				  `
				: css`
						width: 18px;
						height: 18px;
				  `}
		margin-bottom: -4px;
	}

	&:hover {
		border: ${({ variant, theme: { buttons } }) =>
			(buttons as ButtonVariant)[variant].border?.hover ?? "none"};
		color: ${({ variant, theme: { buttons } }) =>
			(buttons as ButtonVariant)[variant].textColor.hover};
		background: ${({ variant, theme: { buttons } }) =>
			(buttons as ButtonVariant)[variant].background.hover};
	}

	&:focus {
		border: ${({ variant, theme: { buttons } }) =>
			(buttons as ButtonVariant)[variant].border?.focus ?? "none"};
		background: ${({ variant, theme: { buttons } }) =>
			(buttons as ButtonVariant)[variant].background.focus};
		color: ${({ variant, theme: { buttons } }) =>
			(buttons as ButtonVariant)[variant].textColor.focus};
		outline: none;
	}

	&:active {
		border: ${({ variant, theme: { buttons } }) =>
			(buttons as ButtonVariant)[variant].border?.active ?? "none"};
		background: ${({ variant, theme: { buttons } }) =>
			(buttons as ButtonVariant)[variant].background.active};
		color: ${({ variant, theme: { buttons } }) =>
			(buttons as ButtonVariant)[variant].textColor.active};
		outline: none;
	}

	&:disabled {
		border: ${({ variant, theme: { buttons } }) =>
			(buttons as ButtonVariant)[variant].border?.disabled ?? "none"};
		background: ${({ variant, theme: { buttons } }) =>
			(buttons as ButtonVariant)[variant].background.disabled};
		color: ${({ variant, theme: { buttons } }) =>
			(buttons as ButtonVariant)[variant].textColor.disabled};
	}
`;

interface IIconContainer {
	size?: string;
}

export const IconContainer = styled.div<IIconContainer>`
	display: flex;
	align-items: center;
	justify-content: center;
	${({ size }) =>
		size === ButtonSize.lg || size === ButtonSize.md
			? css`
					width: 24px;
					height: 24px;
			  `
			: size === ButtonSize.sm || size === ButtonSize.xs
			? css`
					width: 18px;
					height: 18px;
					& > svg {
						width: 11px;
						height: 11px;
						margin: 0;
					}
			  `
			: css`
					width: auto;
					height: auto;
			  `};
`;
