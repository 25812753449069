const booksCategories = [
  "Fiction",
  "Fantasy",
  "Dark Fantasy",
  "Sword and Sorcery",
  "Epic Fantasy",
  "Mythic",
  "Low Fantasy",
  "Historical and Prehistoric Fantasy",
  "Science Fantasy",
  "Urban Fantasy",
  "Role-Playing Game Fiction",
  "Comedy Fantasy and Parodies",
  "Paranormal",
  "Science Fiction",
  "Space Opera",
  "Space Exploration & Discovery",
  "High-Tech & Hard Sci-Fi",
  "Soft and Social Sci-Fi",
  "Contemporary",
  "Adventure Sci-Fi and Spy-Fi",
  "Military Sci-Fi",
  "Apocalyptic and Post-Apocalyptic Sci-Fi",
  "Utopia and Dystopia",
  "Detective",
  "Cyberpunk",
  "Biopunk",
  "Steampunk",
  "Time Travel",
  "Superhero",
  "Alternative History",
  "Horror & Occult",
  "Gothic Fiction",
  "Splatterpunk",
  "Occult",
  "Ghosts",
  "Vampire Novels",
  "Demonic",
  "Mystery, Detective",
  "Police Procedurals",
  "Crime Fiction",
  "Women Sleuths",
  "Hard-Boiled",
  "Historical Detective",
  "Psychological Detective",
  "Political Detective",
  "Spy Detective",
  "Pulp Fiction",
  "Ironic Detective",
  "Thrillers",
  "Legal Thriller",
  "Medical Thriller",
  "Political Thriller",
  "Spies",
  "Psychological Thriller",
  "Techno-Thriller",
  "Action-Adventure Fiction",
  "Epic",
  "Imaginary Voyage",
  "Lost Worlds",
  "Men’s Adventure",
  "Robinsonade",
  "Sea Story",
  "Westerns",
  "Comic Fiction",
  "Romantic Comedy",
  "Black Comedy",
  "Parody",
  "Satire",
  "Anecdotes, Quotations",
  "Limericks, Verse",
  "Historical Fiction",
  "Prehistoric Fiction",
  "Regency Novel",
  "Holocaust Novel",
  "Memoir, Biography",
  "Military",
  "Modern",
  "Countries, Nations",
  "Romance",
  "Contemporary Romance ",
  "Paranormal Romance",
  "Romantic Suspense ",
  "Historical Romance",
  "Science Fiction Romance",
  "Short Stories",
  "Children’s Fiction",
  "Animals",
  "Historical Fiction",
  "Entertainment",
  "Science Fiction",
  "Short Stories",
  "Action-Adventure Fiction",
  "People, Places",
  "Fairy Tales, Folk Tales, Legends, Mythology",
  "Juvenile Fiction",
  "Action-Adventure Fiction",
  "Animals",
  "Fairy Tales, Folklore",
  "Health, Daily Living",
  "Historical Fiction",
  "Prehistoric Fiction",
  "Horror, Ghost Stories",
  "Legends, Myths",
  "Fables",
  "People, Places",
  "Religious",
  "Social Issues",
  "Erotic Fiction",
  "Erotic Romance",
  "Picaresque Novel",
  "Women’s Erotica",
  "Lifestyle",
  "Hollywood Novel",
  "Legal Novel",
  "Medical Novel",
  "Musical Novel",
  "Lab Lit",
  "Sports",
  "Political Novel",
  "Human Rights",
  "Urban Fiction",
  "Religious Fiction",
  "Classic Literature",
  "Ancient Literature",
  "Medieval",
  "Renaissance",
  "Industrial Age",
  "Contemporary Classic",
  "Anthologies",
  "Education Fiction",
  "Family Life",
  "Poetry",
  "Epic",
  "Sonnet",
  "Lyrical",
  "Ballads",
  "Free Verse",
  "Love",
  "Magic",
  "Dramaturgy",
  "Comedy",
  "Tragedy",
  "Melodrama",
  "Tragicomedy",
  "Drama",
  "Philosophical Fiction",
  "Existentialist Fiction",
  "Ancient Philosophy",
  "Novel of Ideas",
  "Platonic Dialogues",
  "Experimental Fiction",
  "Antinovel",
  "Ergodic Literature",
  "Concrete Poetry",
  "Flarf Poetry",
  "Metafiction",
  "Net-Poetry",
  "Oulipo",
  "Provocative Literature ",
  "Kitsch ",
  "World Literature",
  "Australia and Oceania",
  "Asia",
  "North America",
  "South America",
  "Africa",
  "Europe",
  "Non-fiction",
  "Nature & Science",
  "Wild Life",
  "Planet Earth",
  "Universe",
  "Technology",
  "Environment",
  "Ecology",
  "Warfare",
  "Intelligence, Espionage, Sabotage",
  "Military Policy",
  "Moral, Ethical and Social Aspects",
  "Special Forces",
  "Combined Operations",
  "Military Life, Civil-Military Relations",
  "Women in Military",
  "Crime & Law",
  "Assassinations & Conspiracies",
  "Computer Crime",
  "Kidnapping & Missing Persons",
  "Murder",
  "Organized Crime",
  "Outlaws & Lawmen of The Old West",
  "Personal & Private Investigation",
  "Pirates & Piracy",
  "Prisons & Prison Life",
  "Robbery & Burglary",
  "Family Violence",
  "Serial Killers",
  "Narcotics",
  "White Collar & Nonviolent Crime",
  "Military Crimes",
  "Law",
  "People & Society",
  "Biography & Autobiography",
  "Ethnics, Nationalities, Minorities",
  "Body, Mind & Spirit",
  "Health",
  "Family",
  "Crafts",
  "Boys & Men",
  "Girls & Women",
  "Holidays & Celebrations",
  "Philosophy, Essays",
  "Social Issues",
  "Gays & Lesbians",
  "Criticism",
  "Religion",
  "Recreation",
  "Politics",
  "Business & Economics",
  "Diseases",
  "Transport & Machinery",
  "Automotive",
  "Railroads",
  "Aircrafts and Flights",
  "Spacecrafts",
  "Robots",
  "Ships",
  "Places & Travel",
  "Cities Life",
  "Curiosities & Wonders",
  "Travel",
  "Explorers and Adventurers ",
  "Art",
  "Painting and Drawing",
  "Architecture",
  "Sculpture",
  "Design",
  "Fashion",
  "Culinary Art",
  "Photography",
  "Jewelcrafting",
  "Video Games",
  "Motion Pictures and TV",
  "Theatre",
  "Music",
  "Dance",
  "Literature",
  "Museums",
  "Conceptual Art",
  "World",
  "Australia and Oceania",
  "Oriental Countries",
  "Asia",
  "USA, Canada",
  "Latin America and Caribs",
  "Africa",
  "Europe",
  "Sports",
  "Baseball",
  "Basketball",
  "Biographies",
  "Coaching and Training",
  "Football (American)",
  "Golf",
  "Hockey",
  "Mountaineering",
  "Football (Soccer)",
  "Softball",
  "Water Sports",
  "Winter Sports",
  "Air Sports",
  "Racket Sports",
  "Team Sports",
  "Individual Sports",
  "Extreme Sports",
  "Comics, Graphic Novels",
  "Mainstream",
  "Superhero Comics",
  "Action and Adventure Comics",
  "Horror, Thriller Comics",
  "Mystery, Crime Comics",
  "Sci-Fi Comics",
  "Fantasy Comics",
  "Romance Comics",
  "Children’s Comics",
  "Anthologies, Compilations",
  "Epic Comics",
  "Alternative, Underground comix",
  "Adult, Erotica Comics",
  "Custom-Made Comics",
  "Fiction & Literature Classics Adaptations",
  "Gay & Lesbian Comics",
  "Nonfiction Comics",
  "Manga",
  "Western Manga",
  "General (Classic style) Manga",
  "Ecchi",
  "Mecha",
  "Shojo ",
  "Shonen ",
  "Hentai ",
  "Yaoi, Bara, Shonen-ai ",
  "Yuri, Shojo-ai",
  "Cyberpunk",
  "Fantasy Manga",
  "Sci-fi Manga",
  "Mystery Manga",
  "Comedy and Drama Manga",
  "Action and Adventure Manga",
  "Horror",
  "Nonfiction manga",
  "Science and Education Literature",
  "Social Science",
  "Antiques & Collectibles",
  "Crime Sciences",
  "Criminology",
  "Forensic Science",
  "Penology",
  "Minorities",
  "Environment",
  "Media",
  "Public Welfare",
  "Art & Culture Studies",
  "Music",
  "Pest Control",
  "Design",
  "Fashion",
  "Cinema & Theater",
  "Media",
  "Radio",
  "Television",
  "Reference",
  "Dictionaries & Thesauri",
  "Encyclopedias",
  "Guides and Manuals",
  "Almanacs",
  "Atlases & Maps",
  "Etiquette",
  "Genealogy & Family History",
  "Quotations",
  "History",
  "People",
  "Biographies",
  "Autobiographies",
  "Memoirs",
  "Religious Literature",
  "Apologetics",
  "Proverbs",
  "Scripture",
  "Buddhism",
  "Сonfucianism",
  "Hinduism",
  "Christianity",
  "Islam",
  "Judaism",
  "Unconventional Religions",
  "Military",
  "Ancient Wars",
  "Medieval wars",
  "Early Modern Wars",
  "Contemporary Wars",
  "World War I",
  "World War II",
  "Colonial Wars",
  "Nuclear Warfare",
  "Biological & Chemical Warfare",
  "Revolutions and Rebellions",
  "Military Science",
  "Weapons",
  "Technics",
  "Strategy",
  "Future Warfare",
  "World",
  "Australia and Oceania",
  "Asia",
  "North America",
  "South America",
  "Africa",
  "Europe",
  "Historical Periods",
  "Prehistoric",
  "Classical Antiquity",
  "Middle Ages",
  "Renaissance",
  "Industrial Era",
  "Modern Era",
  "IT & Computer Science",
  "Computer Program Languages",
  "C/C++",
  "Java",
  "PHP",
  "Javascript",
  "Python",
  "C#",
  "Perl",
  "SQL",
  "Ruby",
  "Shell",
  "Visual Basic",
  "Actionscript",
  "Assembly Language",
  "Objective C",
  "Lisp",
  "Delphi",
  "Pascal",
  "Scheme",
  "Haskell",
  "Software",
  "Business Software",
  "CAD / CAM Software",
  "Database Software",
  "Graphics Applications & Software",
  "Math & Science Applications",
  "Video ",
  "Audio",
  "Computer Architecture",
  "Artificial Intelligence ",
  "Computer Hardware",
  "Computer Certification & Training",
  "Graphics & Design",
  "Operating Systems",
  "Games, Game Development",
  "Networking & Telecommunications",
  "Web",
  "Blogging",
  "E-Commerce",
  "Online Education ",
  "Electronic Publishing",
  "Email",
  "Internet Law",
  "Life Online",
  "Protocols & Standards",
  "Searching the Web",
  "Web Browsers",
  "Web Directories & Guides",
  "Web Portals / Service Providers",
  "Web Programming / Development",
  "Web Servers",
  "Web Services",
  "Web Site Design",
  "Business Literature",
  "Business Enterprises",
  "Finance",
  "Bookkeeping, Accounting",
  "Banks & Banking",
  "Currency Exchange",
  "Inflation",
  "Money & Monetary Policy",
  "Taxation",
  "Personal Finance",
  "Auditing",
  "Commerce",
  "E-Commerce ",
  "Advertising & Promotion",
  "Sales & Selling",
  "Human Resources & Rersonnel Managemen",
  "Business Communication",
  "Business Ethics",
  "Careers",
  "Motivation",
  "Training",
  "Time Management",
  "Business Law",
  "Consumer Behavior",
  "Development",
  "Economic History",
  "Exports & Imports",
  "Forecasting",
  "Green Business",
  "Home-Based Businesses",
  "Industries",
  "Agribusiness",
  "Computer Industry",
  "Energy",
  "Fashion &Textile Industy",
  "Travel ",
  "Manufacturing",
  "Media & Communications",
  "Park & Recreation Manage",
  "Retailing",
  "Service",
  "Transportation",
  "Chemical Industry",
  "Medicine",
  "Services Industry",
  "Information Management",
  "Insurance",
  "Casualty",
  "General",
  "Health",
  "Liability",
  "Life",
  "Property",
  "Marketing",
  "Risk Assessment & Managem",
  "Direct",
  "General",
  "Industrial",
  "Multilevel",
  "Research",
  "Outsourcing",
  "Telemarketing",
  "Project Management",
  "Public Relations",
  "Quality Control",
  "Real Estate",
  "Small Business",
  "Strategic Planning",
  "Reference",
  "Parenting",
  "Children",
  "Toddler",
  "1-3 age",
  "4-8 age",
  "9-12 age",
  "Juvenile",
  "Education",
  "Preschool Education",
  "Primary Education",
  "1 form",
  "2 form",
  "3 form",
  "4 form",
  "Secondary Education",
  "5 form",
  "6 form",
  "7 form",
  "8 form",
  "9 form",
  "10 form",
  "11 form",
  "Higher Education",
  "Articles",
  "Monograph",
  "Essay",
  "Research Papers",
  "Alternative Education",
  "Indigenous Education",
  "Adult & Postgraduate",
  "Occupational Literature",
  "Natural Sciences",
  "Astronomy",
  "Biology",
  "Chemistry",
  "Ecology",
  "Physics",
  "Oceanography",
  "Medicine",
  "Geology",
  "Geography",
  "Agriculture",
  "Maths",
  "Social Sciences",
  "Pedagogy",
  "Anthropology",
  "Economics",
  "Management",
  "Law",
  "Language Disciplines",
  "Political Science",
  "Psychology",
  "Sociology",
  "Cultural Studies",
  "Religion",
  "Labour Training",
  "Physical Training",
  "Journalism",
  "Folklore & Mythology",
  "Literature Science ",
  "Philosophy",
  "Ethnography",
  "Sports",
  "Engineering Sciences",
  "Civil and Environmental Engineering",
  "Biological, Chemical & Biochemical Engineering",
  "Computer Science",
  "Ocean Engineering",
  "Aerospace Engineering",
  "Materials Science and Engineering",
  "Mechanical Engineering",
  "Machine Engineering",
  "Engineering Systems",
  "Energy & Power Plants Engineering",
  "Nuclear Science and Engineering",
  "Foreign Language Study",
  "African Languages",
  "Ancient languages",
  "Arabic",
  "Celtic Languages",
  "Chinese",
  "Danish",
  "Dutch",
  "English",
  "French",
  "German",
  "Greek (modern)",
  "Hebrew, Yiddish",
  "Hungarian",
  "Indic Languages",
  "Italian",
  "Japanese",
  "Korean",
  "Latin",
  "Multi-Language Dictionaries",
  "Oceanic & Australian Languages",
  "Persian",
  "Polish",
  "Portuguese",
  "Russian",
  "Scandinavian Languages",
  "Serbian & Croatian",
  "Slavic Languages",
  "Southeast Asian Languages",
  "Spanish",
  "Turkish & Turkic Languages",
  "Applied Literature",
  "Home, Family and Society",
  "Marriage, Weddings",
  "Love & Romance",
  "Communication and Relationships",
  "Parenting",
  "Death, Grief, Bereavement",
  "Holidays",
  "Gardening & Horticulture",
  "Antiques & Collectibles",
  "Entertaining",
  "How-to & Home Improvements",
  "Interior Design",
  "Small Homes & Cottages",
  "Pets",
  "Birds",
  "Cats",
  "Dogs",
  "Fish & Aquariums",
  "Horses",
  "Mice, Hamsters & Guinea Pigs",
  "Reptiles & Amphibians",
  "Rabbits",
  "Computers and Internet",
  "Computer Program Languages",
  "Software",
  "Computer Architecture",
  "Computer Certification & Training",
  "Graphics & Design",
  "Operating Systems",
  "Video Games",
  "Networking & Telecommunications",
  "Web",
  "Health, Body, Mind",
  "Diets & Weight Loss",
  "Exercise & Fitness",
  "Beauty & Fashion",
  "Dreams",
  "Religious Inspiration",
  "Aging",
  "Spiritual",
  "Hypnosis and Self-Hypnosis",
  "Inner Child",
  "Meditation",
  "Healthy Cooking and Nutrition",
  "Men’s Health",
  "Psychology & Mental Health",
  "Healing & Recovery",
  "Safety & First Aid",
  "Sexuality",
  "Women’s Health",
  "Herbal Medications and Naturopathy",
  "Healthy Living",
  "Homeopathy",
  "Massage & Reflexotherapy",
  "Pain Management",
  "Physical Impairments",
  "Pregnancy & Childbirth",
  "Sleep",
  "Vision",
  "Vitamins",
  "Work-Related Health",
  "Yoga",
  "Acupressure & Acupuncture",
  "Aerobics",
  "Alternative Therapies",
  "Aromatherapy",
  "Breastfeeding",
  "Children’s Health",
  "Maturing",
  "Disorders and Diseases",
  "Eating Disorders",
  "Sleep Disorders",
  "Anxieties & Phobias",
  "Depression",
  "Stress",
  "Mood Disorders",
  "Oncology",
  "Respiratory",
  "Diabetes",
  "Allergies",
  "Abdominal",
  "AIDS & HIV",
  "Alzheimer’s & Dementia",
  "Contagious",
  "Chronic Fatigue Syndrome",
  "Skin",
  "Genetic",
  "Genitourinary & STDS",
  "Cardiovascular",
  "Immune System",
  "Musculoskeletal",
  "Nervous System",
  "Infertility",
  "Abuse & Addictions",
  "Tobacco",
  "Drug Dependence",
  "Alcoholism",
  "Gambling",
  "Personal Growth, Self-Help",
  "Happiness",
  "Motivational & Inspirational",
  "Memory Improvement",
  "Neuro-Linguistic Programming (NLP)",
  "Self-Esteem",
  "Success",
  "Creativity",
  "Time Management",
  "Twelve-Step Programs",
  "Anger Management",
  "Hobbies, Crafts and Arts",
  "Textiles and Fabrics",
  "Embroidery",
  "Knitting",
  "Crocheting",
  "Cross-stitch",
  "Needlepoint",
  "Weaving",
  "Quilts & Quilting",
  "Spinning",
  "Rugs",
  "Sewing",
  "Handcrafting",
  "Toymaking",
  "DIY",
  "Glass & Glassware",
  "Jewelry",
  "Wood Working",
  "Leatherwork",
  "Metal Work",
  "Carving",
  "Polymer Clay",
  "Pottery & Ceramics",
  "Rubber Stamping",
  "Scrapbooking",
  "Active Hobbies",
  "Hunting and Fishing",
  "Scuba & Snorkeling",
  "Hiking & Camping",
  "Tourism & Travel",
  "Arts and Creative Hobbies",
  "Painting and Drawing",
  "Design",
  "Photography",
  "Theatre, Acting",
  "Music",
  "Dance",
  "Origami, Papercrafts",
  "Decorating",
  "Dye",
  "Fashion",
  "Flower Arranging",
  "Framing",
  "Beadwork",
  "Stenciling",
  "Potpourri",
  "Baskets",
  "Applique",
  "Candle & Soap Making",
  "Printmaking",
  "Folkcrafts",
  "Models and Miniatures",
  "Puzzles & Games",
  "Handwriting Analysis",
  "Journal Writing, Сollecting",
  "Crafts for Children",
  "Stuffed Animals",
  "Puppets & Puppetry",
  "Sports",
  "Cooking",
  "Baking",
  "Canning & Preserving",
  "Drinks & Beverages",
  "Gastronomy",
  "Organic Cooking",
  "Outdoor Cooking",
  "Professional Cooking",
  "Quick & Easy",
  "Special Appliances",
  "Special Diet",
  "Vegetables & Vegetarian",
  "World Cuisine",
  "African",
  "Asian",
  "Caribbean & West Indian",
  "European",
  "Latin American",
  "Oriental",
  "Business",
  "Real Estate",
  "Finance",
  "Outsourcing",
  "Project Management",
  "Public Relations",
  "Commerce",
  "Small Business",
  "Development",
  "Forecasting",
  "Home-Based Businesses",
  "Marketing",
  "Careers",
  "Training",
  "Short Stories",
  "Drug Guides",
  "Cabaret",
  "Contemporary Fantasy",
  "Humor and Comic Literature",
  "Interactive & Multimedia",
  "Archaeology",
  "Religion",
  "Children and Adolescents",
  "Surgery",
  "Genetics, Evolution",
  "Urban Society",
  "Emigration & Immigration",
  "Human Geography",
  "Developmental Biology",
  "Martial Arts & Self-Defense",
  "Labor & Industrial Relations",
  "Feminism",
  "Democracy",
  "Endocrinology",
  "Government",
  "Criminal Law",
  "Constitutional Law",
  "Administrative Law",
  "Sailing & Boating",
  "Freedom & Security",
  "Gynecology",
  "Public Policy",
  "Nursing",
  "Pharmacy",
  "Dermatology",
  "Globalization",
  "Zoology",
  "Neuropsychology",
  "Protection and Security",
  "Mac OS, iOS",
  "Library & Information Science",
  "Meteorology & Climatology",
  "Discrimination & Racism",
  "Jurisprudence",
  "Marine Biology",
  "Love & Romance",
  "Toxicology",
  "Psychotherapy",
  "Industrial & Organizational Psychology",
  "Peace",
  "Microelectronics",
  "Electronics",
  "Economic Conditions",
  "Political Ideologies",
  "Microbiology",
  "Ethics",
  "Economy",
  "Ball Games",
  "Football",
  "Physiology",
  "Stress Management",
  "Interpersonal Relations",
  "Biochemistry",
  "Professional Development",
  "Nutrition",
  "Media",
  "Algebra",
  "Psychopathology",
  "Labor",
  "Industrial Health & Safety",
  "Mechanics",
  "Acoustics",
  "Mechanics",
  "Acoustics",
  "Natural Resources",
  "Mathematical & Computational",
  "Geometry",
  "Condensed Matter",
  "Optics & Light",
  "Hematology",
  "Epistemology",
  "Logic",
  "Self-Perfection",
  "Logistics",
  "Epic Poetry",
  "Merchandise Knowledge",
  "Statistics",
  "Civil Defense",
  "Health and Safety",
  "Comic Fiction",
  "Violence",
  "Clinical Chemistry",
  "Number Theory",
  "Thermodynamics",
  "Emotions",
  "Genetics",
  "Methods & Materials",
  "Holidays & Celebrations",
  "Movements",
  "Forensic Science",
  "Parasitology",
  "Applied Psychology",
  "Research & Methodology",
  "Movements",
  "Microeconomics",
  "Econometrics",
  "Dentistry",
  "Aesthetics",
  "Criticism",
  "Military",
  "Gerontology",
  "Electromagnetism",
  "Anatomy",
  "Circuits",
  "Electricity",
  "Radiology",
  "Emergency Medicine",
  "Internal Medicine",
  "Discrete Mathematics",
  "Investments & Securities",
  "Allied Health Services",
  "Semiconductors",
  "Russian Literature",
  "Theology",
  "Athletics",
  "Gymnastics",
  "Cultural History",
  "Renaissance",
  "Antique Philosophy",
  "Middle Ages",
  "Modern Philosophy",
  "Eastern Philosophy",
  "Development Theory",
  "Literary Criticism, Diaries, Letters",
  "Water Sports",
  "Special Needs Education",
  "Winter Sports",
  "Paleontology",
  "Kids & Teens",
  "Arts and Creativity",
  "History",
  "Politics & Economics",
  "Human & Society",
  "Nature, Animals & Plants",
  "Air Sports",
  "Automotive & Motorcycle",
  "Children's Studies",
  "Gay Studies",
  "Careers",
  "Mobile & Wireless Communications",
  "Rock",
  "Electronic",
  "Pop",
  "Classical",
  "School Literature",
  "English Literature",
  "Ukrainian Literature",
  "European Literature",
  "Asian Literature",
  "Spanish and Latin American Literature",
  "Francophone Literature",
  "German Literature",
  "Bilingual Education",
  "Spectroscopy",
  "Waves",
  "Naval",
  "Interactive books (programs)",
  "International",
  "Lean Cuisine",
  "Veterinary",
  "School Classic Literature",
  "Contemporary Ukrainian Literature",
  "History of Ukraine",
  "Travel Guides",
  "Music",
  "Relaxation, Meditation, Music Therapy",
  "Children's Music",
  "Classical",
  "Nature Sounds",
  "Jazz",
  "Biophysics",
  "Nanotechnology",
  "Urology",
  "Test Preparation & Review",
  "Pulmonary & Thoracic Medicine",
  "Anesthesiology",
  "Rheumatology",
  "HTML",
  "XML",
  "Health Policy",
  "Family & General Practice",
  "Quality Control",
  "Pathology",
  "Epidemiology",
  "Biotechnology",
  "Forensic Medicine",
  "Legal History",
  "Civil Procedure",
  "UNIX & Linux ",
  "Rhetoric",
  "Grammar & Punctuation",
  "Speech",
  "Ophthalmology",
  "Golden Ukrainian Writers",
  "Christmas Books",
  "St. Valentine's Day",
  "Latest updates for less than $2 (offer ends April 19th)",
  "10 books for less than $3.5 (offer ends April 15th)",
  "Contemporary Russian Literature",
  "Top 10 Books in September 2012 – August 2013",
  "Top 10 Ukrainian Books in September 2012 – August 2013",
  "Sci-Fi Horror",
  "Promotion",
  "Teaching, Teacher's Editions",
  "Elementary School",
  "Middle School",
  "High School",
  "This Week Offers",
  "Best Releases (last 30 days)",
  "Popular eBooks of the Season",
  "Best eBooks 2014",
  "Best eBooks 2013",
  "Film Adaptations",
  "Business eBooks: «Must Read»",
  "Popular Non-Fiction of the Year",
  "eBooks for Parents. Must Have",
  "100 Popular Self Development eBooks",
  "Top-40 new Novels from Around the World",
  "Magazines recommend these eBooks",
  "Coming Soon",
  "Special Offers",
  "The Winners of Literary Awards",
  "Best eBooks 2015",
  "Holiday Book List",
  'best of "Karavan Istorii"',
  "Best Audiobooks",
  "Best eBooks",
  "2016",
  "2017",
  "Special offer",
  "Распродажа издательства МИФ ",
  "Специально для девочек",
  "Бесплатные книги",
  "Русская классика",
  "Зарубежные новинки",
];

export default booksCategories;
