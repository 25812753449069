import { FC, useCallback, useState, useMemo, useEffect, useRef } from "react";
import AutomationHeader from "../../../../components/HeaderNewCampaign/AutomationHeader";
import { Form, Field } from "react-final-form";
import arrayMutators, { push } from "final-form-arrays";
import { FieldArray, FieldArrayRenderProps } from "react-final-form-arrays";
import {
	Container,
	TriggerContainer,
	AddBlockContainer,
	VerticalDevider,
	NextStepContainer,
	NextStepItem,
	ConditionContainer,
	ConditionIconContainer,
	CloseContainer,
	ConditionDevider,
	DelayContainer,
} from "./styled";
import TriggerModal from "../../../../components/Modal/Automation/Trigger";
import { ReactComponent as AddIcon } from "../../../../assets/icons/add.svg";
import { ReactComponent as PlayIcon } from "../../../../assets/icons/play.svg";
import { ReactComponent as ConditionIcon } from "../../../../assets/icons/condition.svg";
import { ReactComponent as DelayIcon } from "../../../../assets/icons/sand_glass.svg";
import { ReactComponent as EmailIcon } from "../../../../assets/icons/email.svg";
import { ReactComponent as CloseIcon } from "../../../../assets/icons/close.svg";
import { observer } from "mobx-react-lite";
import EmailsModal from "../../../../components/Modal/Automation/Email";
import DelayModal from "../../../../components/Modal/Automation/Delay";
import ActionModal from "../../../../components/Modal/Automation/Action";
import ConditionModal from "../../../../components/Modal/Automation/Condition";
import Text from "../../../../components/Text";
import { Row, Col } from "../../../../components/Grid";
import { textTypes } from "../../../../styles/typography";
import { Space } from "../../../../types/constants";
import { useStore } from "../../../../store";

interface INextStep {
	left: number;
	isOpen: boolean;
	onClose: () => void;
	onSelect: (modal: string) => void;
}

const NextStep: FC<INextStep> = ({ left, isOpen, onClose, onSelect }) => {
	const selectStep = useCallback(
		(e: any) => {
			onSelect(e.target.id);
			onClose();
		},
		[onClose, onSelect]
	);

	return (
		<>
			{isOpen && (
				<NextStepContainer id="nextStep" left={left}>
					<Text type={textTypes.h5Bold!} mb={Space.md}>
						Add the next step
					</Text>
					<Row>
						<NextStepItem mr={Space.sm} onClick={selectStep} id="email">
							<EmailIcon />
							Email
						</NextStepItem>
						<NextStepItem mr={Space.sm} onClick={selectStep} id="delay">
							<DelayIcon />
							Delay
						</NextStepItem>
						<NextStepItem mr={Space.sm} onClick={selectStep} id="condition">
							<ConditionIcon />
							Condition
						</NextStepItem>
						<NextStepItem onClick={selectStep} id="action">
							<PlayIcon />
							Action
						</NextStepItem>
					</Row>
				</NextStepContainer>
			)}
		</>
	);
};

enum Modals {
	email = "email",
	delay = "delay",
	condition = "condition",
	action = "action",
}

const AutomationCampaign: FC = () => {
	const { campaigns, templates } = useStore();
	const [nextStep, setNextStep] = useState<boolean>(false);
	const [openTriggerModal, setOpenTriggerModal] = useState<boolean>(false);
	const [trigger, setTrigger] = useState<boolean>(true);
	const [openModal, setOpenModal] = useState<Record<string, boolean>>({
		email: false,
		delay: false,
		condition: false,
		action: false,
	});
	const positionNextStep = useRef<number>(0);
	const onSubmit = useCallback(async (values: any) => {
		//campaigns.automation?.init(payload(values));
		console.log("onSubmit"); // TODO
	}, []);

	let steps: Array<{ name: string; value: string }> = useMemo(() => {
		// FIX THIS
		return [];
	}, []);

	const showTriggerModal = useCallback(() => {
		// open trigger modal
		setOpenTriggerModal(!openTriggerModal);
	}, [openTriggerModal]);

	const selectNextStep = useCallback(
		(id: string) => {
			// open next step component
			setOpenModal((prevState) => ({ ...prevState, [id]: !prevState[id] }));
			setNextStep(false);
		},
		[nextStep]
	);

	const showNextStep = useCallback(
		(event: any) => {
			// open next step component
			const addContainer = event.currentTarget.getBoundingClientRect();
			const pageWidth = document.documentElement.clientWidth;
			let pos = addContainer.left - pageWidth / 2 + addContainer.width / 2;
			if (
				event &&
				(event.currentTarget.id === "yes" || event.currentTarget.id === "no")
			) {
				setNextStep(!nextStep);
				positionNextStep.current = pos;
			} else {
				setNextStep(!nextStep);
				positionNextStep.current = 0;
			}
			document.getElementById("nextStep")?.scrollIntoView({ block: "end" }); // FIX THIS LATER
		},
		[nextStep]
	);

	const backward = useCallback(
		(field: { name: string; value: string } | undefined) => {
			if (field) {
				steps.push({ name: field.name, value: field.value });
				if (field.name === "trigger") {
					setTrigger(true);
					setNextStep(false);
				}
			}
		},
		[]
	);

	const forward = useCallback((push: any) => {
		let field = steps.pop();
		if (field) {
			if (field.name === "trigger") {
				setTrigger(false);
			}
			push("automation_campaigns", {
				name: field?.name,
				value: field?.value,
			});
		}
	}, []);

	return (
		<>
			<Form
				onSubmit={onSubmit}
				mutators={{ ...arrayMutators }}
				render={({
					handleSubmit,
					form: {
						mutators: { push, pop },
					},
				}) => {
					return (
						<form onSubmit={handleSubmit}>
							<AutomationHeader
								onSave={handleSubmit}
								undo={() => backward(pop("automation_campaigns"))}
								redo={() => forward(push)}
							/>
							<Container pt={Space.xxl}>
								<FieldArray name="automation_campaigns">
									{({
										fields,
									}: FieldArrayRenderProps<
										{ name: string; value: string },
										HTMLElement
									>) =>
										fields.map((field, index) => {
											console.log("fields: " + fields.value[index].name);
											return (
												<>
													{fields.value[index].name === "condition" ? (
														<>
															<VerticalDevider />
															<Field
																name={`${fields.value[index].name}_${index}`}
																component={() => (
																	<Row>
																		<Col>
																			<ConditionDevider no />
																			<AddBlockContainer
																				id="no"
																				left
																				onClick={showNextStep}
																			>
																				<AddIcon />
																			</AddBlockContainer>
																		</Col>
																		<Col>
																			<ConditionContainer>
																				<ConditionIconContainer>
																					<ConditionIcon />
																				</ConditionIconContainer>
																				<CloseContainer
																					onClick={() => fields.remove(index)}
																				>
																					<CloseIcon />
																				</CloseContainer>
																				{fields.value[index].value}
																			</ConditionContainer>
																		</Col>
																		<Col>
																			<ConditionDevider yes />
																			<AddBlockContainer
																				id="yes"
																				right
																				onClick={showNextStep}
																			>
																				<AddIcon />
																			</AddBlockContainer>
																		</Col>
																	</Row>
																)}
															/>
														</>
													) : fields.value[index].name === "delay" ? (
														<>
															<VerticalDevider />
															<Field
																name={`${fields.value[index].name}_${index}`}
																component={() => (
																	<DelayContainer>
																		Wait for &nbsp;
																		<Text type={textTypes.bodyMdBold!}>
																			{fields.value[index].value}
																		</Text>
																	</DelayContainer>
																)}
															/>
															<VerticalDevider />
															<AddBlockContainer onClick={showNextStep}>
																<AddIcon />
															</AddBlockContainer>
														</>
													) : (
														<>
															{index > 0 && <VerticalDevider />}
															<Field
																name={`${fields.value[index].name}_${index}`}
																component={() => (
																	<TriggerContainer>
																		{(index === 0 && (
																			<Text type={textTypes.bodyMdBold!}>
																				Start
																			</Text>
																		)) ||
																			(fields.value[index].name === "email" && (
																				<Text type={textTypes.bodyMdBold!}>
																					Email
																				</Text>
																			))}
																		{fields.value[index].value}
																	</TriggerContainer>
																)}
															/>
															<VerticalDevider />
															<AddBlockContainer onClick={showNextStep}>
																<AddIcon />
															</AddBlockContainer>
														</>
													)}
												</>
											);
										})
									}
								</FieldArray>
								<TriggerContainer
									id="start-trigger"
									onClick={showTriggerModal}
									show={trigger}
									start
								>
									Select your trigger
								</TriggerContainer>
								<NextStep
									isOpen={nextStep}
									onClose={() => setNextStep(false)}
									onSelect={selectNextStep}
									left={positionNextStep.current}
								/>
								<TriggerModal
									isOpen={openTriggerModal}
									onClose={showTriggerModal}
									onSuccess={(chosen) => {
										push("automation_campaigns", {
											name: "trigger",
											value: chosen,
										});
										setTrigger(!trigger);
									}}
								/>
								<EmailsModal
									isOpen={openModal.email}
									onClose={() => selectNextStep(Modals.email)}
									onSelect={() => {
										push("automation_campaigns", {
											name: "email",
											value: templates
												.array()
												.map((item) =>
													item.id === campaigns.templateId ? item.name : null
												),
										});
									}}
								/>
								<DelayModal
									isOpen={openModal.delay}
									onClose={() => selectNextStep(Modals.delay)}
									onSave={(delay) => {
										push("automation_campaigns", {
											name: "delay",
											value: delay,
										});
										selectNextStep(Modals.delay);
									}}
								/>
								<ConditionModal
									isOpen={openModal.condition}
									onClose={() => selectNextStep(Modals.condition)}
									onSelect={(chosen) => {
										push("automation_campaigns", {
											name: "condition",
											value: chosen,
										});
									}}
								/>
								<ActionModal
									isOpen={openModal.action}
									onClose={() => selectNextStep(Modals.action)}
									onSelect={(chosen) => {
										push("automation_campaigns", {
											name: "action",
											value: chosen,
										});
									}}
								/>
							</Container>
						</form>
					);
				}}
			/>
		</>
	);
};

export default observer(AutomationCampaign);
