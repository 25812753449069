import { css } from "styled-components";
import styled from "styled-components/macro";

interface IContainer {
	height?: string;
}

interface IColumn {
	minWidth?: number;
	maxWidth?: number;
	alignRight?: boolean;
	pr?: number;
	pl?: number;
	truncateContent?: boolean;
}

export const Container = styled.div`
	position: relative;
	width: 100%;
	overflow: hidden;
`;

export const ActionIconsHR = styled.div`
	width: 30px;
`;

export const ActionIconsTD = styled.div`
	width: 30px;
	align-items: center;
	display: flex;
	visibility: hidden;
	cursor: pointer;
`;

export const TableContainer = styled.div<IContainer>`
	font-feature-settings: "tnum";
	border-radius: 8px;
	background: #fff;
	${({ height }) =>
		height &&
		css`
			height: ${height};
		`}
`;
export const Thead = styled.div`
	box-sizing: border-box;
	border-bottom: 1px solid #e6e7e9;
`;
export const Tr = styled.div`
	height: 52px;
	transition: ease-in-out 0.2s;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	color: rgba(24, 24, 25, 0.9);
	box-sizing: border-box;
	border-bottom: 1px solid #e6e7e9;

	&:hover {
		background: ${({ theme: { colors } }) => colors.gray._50} !important;
	}

	&:hover ${ActionIconsTD} {
		visibility: visible;
	}
`;

export const TheadRow = styled.div`
	vertical-align: middle !important;
	height: 52px;
	min-width: 108px;
`;


export const Th = styled.div<IColumn>`
	height: 52px;
	min-width: ${({ minWidth = 108 }) => minWidth}px;
	${({ maxWidth }) =>
		maxWidth &&
		css`
			max-width: ${maxWidth}px;
		`}
	padding-left: ${({ pl }) => pl || 20}px !important;
	padding-right: ${({ pr }) => pr || 20}px !important;
	${({ alignRight }) => alignRight && "flex-direction: row-reverse;"}
	display: flex;
	align-items: center;
`;

export const ThOption = styled.div<IColumn>`
	height: 52px;
	min-width: ${({ minWidth = 108 }) => minWidth}px;
	${({ maxWidth }) =>
		maxWidth &&
		css`
			max-width: ${maxWidth}px;
		`}
	padding-left: ${({ pl }) => pl || 20}px !important;
	padding-right: ${({ pr }) => pr || 20}px !important;
	${({ alignRight }) => alignRight && "flex-direction: row-reverse;"}
	display: flex;
	align-items: center;
	box-sizing: border-box;
    flex: 30 0 auto;
    min-width: 0px;
    width: 30px;
`;

export const TdOption = styled.div<IColumn>`
	padding-left: ${({ pl }) => pl || 20}px !important;
	padding-right: ${({ pr }) => pr || 20}px !important;
	${({ alignRight }) => alignRight && "flex-direction: row-reverse;"}
	${({ maxWidth }) =>
		maxWidth &&
		css`
			max-width: ${maxWidth}px;
		`}
	line-height: 1.15;
	cursor: pointer;
	display: flex;
	align-items: center;
	font-family: ${({ theme: { fonts } }) => fonts.default};
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	color: rgba(24, 24, 25, 0.9);
	box-sizing: border-box;
    flex: 30 0 auto;
    min-width: 0px;
    width: 30px;
`;

export const Tbody = styled.div`
	height: calc(100% - 54px); // change 41 on 54
`;
export const Td = styled.div<IColumn>`
	padding-left: ${({ pl }) => pl || 20}px !important;
	padding-right: ${({ pr }) => pr || 20}px !important;
	${({ alignRight }) => alignRight && "flex-direction: row-reverse;"}
	${({ maxWidth }) =>
		maxWidth &&
		css`
			max-width: ${maxWidth}px;
		`}
	line-height: 1.15;
	cursor: pointer;
	display: flex;
	align-items: center;
	font-family: ${({ theme: { fonts } }) => fonts.default};
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	color: rgba(24, 24, 25, 0.9);

	${({ truncateContent }) =>
		truncateContent &&
		css`
			position: relative;
			overflow: hidden;
			white-space: nowrap;

			&::after {
				transition: background-color 0.2s ease-in-out;
				content: "";
				position: absolute;
				bottom: 0px;
				right: 0px;
				width: 30px;
				height: 95%;
				background: -moz-linear-gradient(
					270deg,
					rgba(255, 255, 255, 1) 0%,
					rgba(255, 255, 255, 0.26934523809523814) 100%
				);
				background: -webkit-linear-gradient(
					270deg,
					rgba(255, 255, 255, 1) 0%,
					rgba(255, 255, 255, 0.26934523809523814) 100%
				);
				background: linear-gradient(
					270deg,
					rgba(255, 255, 255, 1) 0%,
					rgba(255, 255, 255, 0.26934523809523814) 100%
				);
			}

			${Tr}:hover &::after {
				background: -moz-linear-gradient(
					270deg,
					rgba(249, 250, 251, 1) 0%,
					rgba(249, 250, 251, 0.26934523809523814) 100%
				);
				background: -webkit-linear-gradient(
					270deg,
					rgba(249, 250, 251, 1) 0%,
					rgba(249, 250, 251, 0.26934523809523814) 100%
				);
				background: linear-gradient(
					270deg,
					rgba(249, 250, 251, 1) 0%,
					rgba(249, 250, 251, 0.26934523809523814) 100%
				);
			}
		`}
`;
