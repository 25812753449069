import { FC, useEffect, useState } from "react";
import {
	BrowserRouter,
	Navigate,
	Route,
	RouteProps,
	Routes,
	useLocation,
	useNavigate,
} from "react-router-dom";
import { validateToken } from "./api/auth";
import DataLoader from "./components/DataLoader";
import ECommerce from "./pages/e-commerce";
import ContactsMenu from "./pages/e-commerce/contactsMenu";
// import Departments from "./pages/departments";
import Marketing from "./pages/marketing";
import Campaigns from "./pages/marketing/campaigns";
import Contacts from "./pages/marketing/contacts";
import Companies from "./pages/marketing/companies";
import Groups from "./pages/marketing/groups";
import Templates from "./pages/marketing/templates";
import TemplatesEditor from "./pages/marketing/templates/editor";
import SignIn from "./pages/sign-in";
import styled from "./styles/theme";
import { MODAL_ROOT_ID, TOOLTIP_ROOT_ID } from "./types/constants";
import Stats from "./pages/marketing/stats";
import AddSubject from "./pages/marketing/campaigns/new-campaign/AddSubject";
import ChooseRecepients from "./pages/marketing/campaigns/new-campaign/ChooseRecipients";
import SendCampaign from "./pages/marketing/campaigns/new-campaign/SendCampaign";
import PreView from "./pages/marketing/campaigns/new-campaign/PreView";
import AutomationCampaign from "./pages/marketing/campaigns/automationCampaign";
import Admin from "./pages/admin";
import AdminUsers from "./pages/admin/users";
import AdminRoles from "./pages/admin/roles";
import AdminUsersHistory from "./pages/admin/history";
import { useStore } from "./store";
import ContactCenter from "./pages/contact-center";
import ContactCenterContacts from "./pages/contact-center/contacts";

const PortalContainer = styled.div`
	position: absolute;
	top: 0;
	left: 0;
`;

const ProtectedPage: FC<{ page?: JSX.Element | null }> = ({ page }) => {
	const { user, userLogOut } = useStore();
	const [status, setStatus] = useState({ loading: true, valid: false });
	const navigate = useNavigate();
	const { pathname } = useLocation();

	useEffect(() => {
		const pathArr = pathname.split("/");
		const hasId = pathArr[pathArr.length - 1].length === 24;
		if (hasId) {
			pathArr[pathArr.length - 1] = ":id";
		}

		if (user.availableRoutes.indexOf(pathArr.join("/")) < 0) {
			if (!user.availableRoutes[0]) {
				userLogOut();
				navigate("/sign-in");
			} else {
				navigate(user.availableRoutes[0]);
			}
		}

		const intervalId = setInterval(() => {
			(async () => {
				const isValid = await validateToken();

				if (!isValid) {
					navigate("/sign-in");
				}
			})();
		}, 10000);

		(async () => {
			const isValid = await validateToken();

			setStatus({
				loading: false,
				valid: isValid,
			});
		})();

		return () => {
			clearInterval(intervalId);
		};
	}, []);

	useEffect(() => {
		if (!status.loading && !status.valid) {
			navigate("/sign-in");
		}
	}, [status]);

	return !page || status.loading ? <></> : page;
};

const ProtectedRoute: FC<RouteProps> = ({ element, ...rest }) => {
	return <Route element={<ProtectedPage page={element} />} {...rest} />;
};

function App() {
	return (
		<>
			<DataLoader />
			<PortalContainer id={TOOLTIP_ROOT_ID}></PortalContainer>
			<PortalContainer id={MODAL_ROOT_ID}></PortalContainer>

			<BrowserRouter>
				<Routes>
					{/* <ProtectedRoute path="/" element={<Departments />} /> */}
					<Route path="/sign-in" element={<SignIn />} />
					<Route path="/marketing" element={<Marketing />}>
						<Route element={<Navigate to="/marketing/contacts" />} />
						<ProtectedRoute path="/contacts" element={<Contacts />} />
						<ProtectedRoute path="/campaigns" element={<Campaigns />} />
						<ProtectedRoute
							path="/campaigns/newCampaign/addSubject"
							element={<AddSubject />}
						/>
						<ProtectedRoute
							path="/campaigns/newCampaign/chooseRecepients"
							element={<ChooseRecepients />}
						/>
						<ProtectedRoute
							path="/campaigns/newCampaign/send"
							element={<SendCampaign />}
						/>
						<ProtectedRoute
							path="/campaigns/newCampaign/preview"
							element={<PreView />}
						/>
						<ProtectedRoute
							path="/campaigns/newAutomationCampaign"
							element={<AutomationCampaign />}
						/>
						<ProtectedRoute path="/companies" element={<Companies />} />
						{/* Add Groups to test transition */}
						<ProtectedRoute path="/groups" element={<Groups />} />
						<ProtectedRoute path="/templates" element={<Templates />} />
						<ProtectedRoute
							path="/templates/editor"
							element={<TemplatesEditor />}
						/>
						<ProtectedRoute
							path="/templates/editor/:id"
							element={<TemplatesEditor />}
						/>
						<ProtectedRoute path="/stats" element={<Stats />} />
					</Route>
					{/* Add ECommerce Route */}
					<Route path="/e-commerce" element={<ECommerce />}>
						<ProtectedRoute path="/" element={<ContactsMenu />} />
					</Route>
					<ProtectedRoute
						path="*"
						element={<Navigate to="/marketing/contacts" />}
					/>
					<Route path="/contact-center" element={<ContactCenter />}>
						<ProtectedRoute path="/" element={<ContactCenterContacts />} />
					</Route>
					<Route path="/admin" element={<Admin />}>
						<Route element={<Navigate to="/admin/users" />} />
						<ProtectedRoute path="/users" element={<AdminUsers />} />
						<ProtectedRoute path="/roles" element={<AdminRoles />} />
						<ProtectedRoute path="/history" element={<AdminUsersHistory />} />
					</Route>
				</Routes>
			</BrowserRouter>
		</>
	);
}

export default App;
