import { ENDPOINTS } from "../types/Endpoints";
import authFetch from "../utils/auth-fetch";

interface IAutocompleteItem {
	id: string;
	value: string;
}

export const searchReadRateContacts = async (search: string) => {
	const json = await (
		await authFetch(
			`${ENDPOINTS.AUTOCOMPLETE.READRATE_CONTACTS}?value=${search}`
		)
	).json();

	return json && json.length > 0
		? json.map((x: IAutocompleteItem) => ({
				label: x.value,
				value: x.id,
		  }))
		: [];
};

export const searchEcommerceContacts = async (search: string) => {
	const json = await (
		await authFetch(
			`${ENDPOINTS.AUTOCOMPLETE.ECOMMERCE_CONTACTS}?value=${search}`
		)
	).json();

	return json && json.length > 0
		? json.map((x: IAutocompleteItem) => ({
				label: x.value,
				value: x.id,
		  }))
		: [];
};

export const searchBooklandContacts = async (search: string) => {
	const json = await (
		await authFetch(
			`${ENDPOINTS.AUTOCOMPLETE.BOOKLAND_CONTACTS}?value=${search}`
		)
	).json();

	return json && json.length > 0
		? json.map((x: IAutocompleteItem) => ({
				label: x.value,
				value: x.id,
		  }))
		: [];
};
