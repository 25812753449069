import getHost from "../utils/get-host";

/** Host location */
const HOST = getHost();

/** Api location */
const API = `${HOST}api/`;
/** API path */
const AUTH = `${API}auth`;

const USER = `${API}user`;

const CONTACTS = `${API}contacts`;
const COMPANIES = `${API}company`;
const GROUPS = `${API}groups`;

const AUTOCOMPLETE = `${API}autocomplete`;
const EMAIL_TEMPLATE = `${API}emailTemplate`;
const EMAIL = `${API}email`;
const STATS = `${API}emailstats`;
const FILES = `${API}files`;
const ADMIN = `${API}admin/`;

// CALL CENTER
const CONTACT_CARD = `${API}contactCard`;

/** ENDPOINTS contains path's described in swagger */
export const ENDPOINTS = {
	AUTH: {
		TOKEN: {
			/** POST, json { Login: 'string', Password: 'string' }, returns user auth credentials */
			GENERATE: `${AUTH}/token/generate`,
			/** POST, textbody '', returns boolean if user authentificated */
			VALIDATE: `${AUTH}/token/validate`,
		},
	},
	USER: {
		GET_PERMISSIONS: `${USER}/permissions`,
		CHANGE_PASSWORD: `${USER}/change/password`,
	},
	CONTACTS: {
		GET_ALL: CONTACTS,
		GET_CONTACT: `${CONTACTS}/getContact`,
		ADD_CONTACT: CONTACTS,
		/** POST, json { ContactId: 'string', TemplateId: 'string' }, sends mail to contact */
		SEND_EMAIL: `${CONTACTS}/sendEmail`,
		QUERY_CONTACTS: `${CONTACTS}/query`,
		GET_BY_GROUP: `${CONTACTS}/getByGroup`,
	},
	CONTACT_CARD: {
		/** GET, json { email: 'string', contactType: 'string' }, get contact */
		GET: CONTACT_CARD,
		GET_CONTACT_TYPES: `${CONTACT_CARD}/contactTypes`,
		/** DELETE, json { contact: 'ContactModel', contactType: 'string' }, delete contact */
		DELETE: `${CONTACT_CARD}/delete`,
	},
	COMPANIES: {
		GET_ALL: COMPANIES,
		GET_COMPANY: `${COMPANIES}/getCompany`,
		ADD_COMPANY: COMPANIES,
		/** POST, json { CompanyId: 'string', TemplateId: 'string' }, sends mail to company */
		SEND_EMAIL: `${COMPANIES}/sendEmail`,
		QUERY_COMPANIES: `${COMPANIES}/query`,
	},
	GROUPS: {
		GET_GROUP: `${GROUPS}`,
		GET_ECOMMERCE_STORES: `${GROUPS}/ecommerce/stores`,
		ADD_GROUP: GROUPS,
		SEND_EMAIL: `${GROUPS}/sendEmail`,
		COUNT: `${GROUPS}/count`,
	},
	AUTOCOMPLETE: {
		COMPANIES: `${AUTOCOMPLETE}/companies`,
		CONTACTS: `${AUTOCOMPLETE}/contacts`,
		READRATE_CONTACTS: `${AUTOCOMPLETE}/readrate/contacts`,
		ECOMMERCE_CONTACTS: `${AUTOCOMPLETE}/ecommerce/contacts`,
		BOOKLAND_CONTACTS: `${AUTOCOMPLETE}/obrey/contacts`,
	},
	EMAIL_TEMPLATE: {
		ADD_TEMPLATE: EMAIL_TEMPLATE,
		GET_TEMPLATE: `${EMAIL_TEMPLATE}/template`,
		/** POST, json { Name: 'string' }, returns templates by sprecified query */
		QUERY_TEMPLATE: `${EMAIL_TEMPLATE}/query`,
		/** POST, json { Template: 'string' }, returns compiled MJML template */
		RENDER_TEMPLATE: `${EMAIL_TEMPLATE}/render`,
		UPLOAD_IMAGE: `${EMAIL_TEMPLATE}/uploadImage`,
		COPY_TEMPLATE: `${EMAIL_TEMPLATE}/copy`,
	},
	EMAIL: {
		SEND_MAIL_READRATE: `${EMAIL}/readrate/send`,
		SEND_MAIL_ECOMMERCE: `${EMAIL}/ecommerce/send`,
		SEND_MAIL_TO_ALL_READRATE: `${EMAIL}/readrate/sendtoall`,
		SEND_TEST_TEMPLATE: `${EMAIL}/test/template`,
	},
	STATS: {
		/** GET, params { emailId: 'string', type: 'string', page: 'number', size: 'number' }, gets mail stats */
		GET_MAIL_CONTACTS: `${STATS}/contacts`,
		/** GET, params { page: 'number', size: 'number' }, gets mail stats */
		GET_MAIL_STATS: `${STATS}/stats`,
		GET_MAIL_ALL_STATS: `${STATS}/stats/all`,
		GET_MAIL_DETAILS: `${STATS}/details`,
		SEND_EMAIL: `${STATS}/sendEmail`,
	},
	FILES: {
		UPLOAD: `${FILES}/upload`,
		GET: `${FILES}`,
	},
	ADMIN: {
		USERS: {
			GET: `${ADMIN}users`,
			GET_ALL: `${ADMIN}users/all`,
			ADD: `${ADMIN}users`,
			UPDATE: `${ADMIN}users`,
			USER_LOGS: `${ADMIN}users/logs`,
		},
		ROLES: {
			GET: `${ADMIN}roles`,
			GET_ALL: `${ADMIN}roles/all`,
			GET_POLICIES: `${ADMIN}roles/policies`,
			ADD: `${ADMIN}roles`,
			UPDATE: `${ADMIN}roles`,
		},
	},
};
