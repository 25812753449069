import styled from "../../styles/theme";
import { css } from "styled-components";
import { fadeIn, fadeOut } from "../../styles/animations";

interface IBaseAlert {
  size: string; // detailed, compact, comfort (primary/default)
  variant: string; // info, warning, error, success
  state?: string;
}

interface IAlertType {
  radius: number;
  border?: number;
  borderColor: Record<string, string>;
  background: Record<string, string>;
  color: string;
  buttonColor: Record<string, string>;
  padding: string;
  font: {
    weight: number;
    size: number;
    height: number;
  };
}

type AlertVariant = Record<string, IAlertType>;

export const BaseAlert = styled.div<IBaseAlert>`
  position: fixed;
  z-index: 999999;
  display: flex;
  flex-direction: row;
  align-items: ${({size}) => size === "compact_primary" || size === "compact_default" ? 'center' : 'flex-start'};
  width: 1056px;
  border-radius: ${({ size, theme: { alerts } }) =>
    (alerts as AlertVariant)[size].radius}px;
  border: ${({ size, theme: { alerts } }) =>
      (alerts as AlertVariant)[size].border}px
    solid;
  border-color: ${({ size, variant, theme: { alerts } }) =>
    (alerts as AlertVariant)[size].borderColor[variant]};
  padding: ${({ size, theme: { alerts } }) =>
    (alerts as AlertVariant)[size].padding};
  background-color: ${({ size, variant, theme: { alerts } }) =>
    (alerts as AlertVariant)[size].background[variant]};
  font-weight: ${({ size, theme: { alerts } }) =>
    (alerts as AlertVariant)[size].font.weight};
  font-size: ${({size, theme: { alerts } }) =>
    (alerts as AlertVariant)[size].font.size}px;
  line-height: ${({ size, theme: { alerts } }) =>
    (alerts as AlertVariant)[size].font.height}px;
  left: 50%;
  transform: translateX(-50%);
  top: auto;
  bottom: 20px;
  ${({state}) => state === "entering" && css`
    animation: ${fadeIn} 300ms;
  ` || state === "exiting" && css`
    animation: ${fadeOut} 300ms;
  `};
`;

interface IIcon {
  size?: string;
}
export const Icon = styled.div<IIcon>`
  ${({ size }) =>
    size === "compact_primary" || size === "compact_default"
      ? css`
          display: flex;
          align-items: center;
          width: 18px;
          height: 18px;
          margin-right: 8px;
          & > svg {
            width: 15px;
            height: 15px;
          }
        `
      : size === "detailed_primary" || size === "detailed_default" ? css`
          width: 24px;
          height: 24px;
          margin-right: 12px;
          & > svg {
            width: 20px;
            height: 20px;
          }
        ` : css`
          display: flex;
          align-items: center;
          width: 24px;
          height: 24px;
          margin-right: 12px;
          & > svg {
            width: 20px;
            height: 20px;
          }
        `};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  justify-content: center;
`;

export const Title = styled.div`
  width: fit-content;
  color: rgba(24, 24, 25, 0.9);
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  margin-bottom: 8px;
`;

export const ControlContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 0;
  margin-top: 16px;
`;

export const ControlButton = styled.button<IBaseAlert>`
  padding: 6px 12px;
  color: #fff;
  font-weight: 600;
  font-size: ${({ size, theme: { alerts } }) =>
    (alerts as AlertVariant)[size].font.size}px;
  line-height: ${({ size, theme: { alerts } }) =>
    (alerts as AlertVariant)[size].font.height}px;
  background-color: ${({ size, variant, theme: { alerts } }) =>
    (alerts as AlertVariant)[size].buttonColor[variant]};
  margin-right: 8px;
  cursor: pointer;
  border: none;
  border-radius: 40px;
  transition: all 300ms ease;
  :hover {
    background: rgba(0,0,0,0.4);
  }
`;

interface IClose {
  size: string;
}

export const Close = styled.div<IClose>`
  position: absolute;
  display: flex;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  ${({ size }) =>
   size === "detailed_primary" || size === "detailed_default"
      ? css`
        position: absolute;
        right: 8px;
        top: 8px;
      `
      : css`
        position: static;
        width: 24px;
        height: 24px;
        margin-right: 0;
        margin-left: auto;
      `}
  cursor: pointer;
`;
