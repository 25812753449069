import styled from '../../styles/theme';

export const Container = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    background-color: #fff;
    padding: 17px 192px;
    @media (max-width: 1800px){
        padding-left: 100px;
        padding-right: 100px;
    }
    @media (max-width: 1700px){
        padding-left: 50px;
        padding-right: 50px
    };
    @media (max-width: 1500px){
        padding-left: 40px;
        padding-right: 40px
    }
    @media (max-width: 1450px){
        padding-left: 20px;
        padding-right: 20px;
    };
    @media (max-width: 1300px){
        padding-left: 20px;
        padding-right: 20px;
    }
`;
