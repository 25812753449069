import { FC, useMemo } from "react";
import Text from "../../../components/Text";
import TextField from "../../../components/TextField";
import Button from "../../../components/Button";
import { textTypes } from "../../../styles/typography";
import { Space } from "../../../types/constants";
import { Row, Col } from "../../../components/Grid";
import Table from "../../../components/Table";
import { ButtonSize, buttonTypes } from "../../../styles/buttons";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../store";
import { useState, useCallback } from "react";
import { TextFieldSize } from "../../../styles/text-field";
import { ReactComponent as SearchSVG } from "../../../assets/icons/search.svg";
import WithPermisions from "../../../components/WithPermisions";
import PermissionType from "../../../types/permissions";
import { Select } from "../../../components/Select";
import { ReactComponent as FilterListSVG } from "../../../assets/icons/filter_list.svg";
import ContactCard from "../../../components/Modal/ContactCard";
import { IContactCard } from "../../../types/contact-center";
import {
	getContactCardInfo,
	searchContactCard,
} from "../../../api/contact-card";
import Spinner from "../../../components/Spinners";
import { SpinnerSize, SpinnerType } from "../../../styles/spinners";

const contactTypes = [
	// { label: "Bookland", value: "bookland" }, TODO: uncomment when provider be available
	{ label: "Cloud", value: "cloud" },
	// { label: "Corporate site", value: "corporateSite" }, TODO: uncomment when provider be available
	{ label: "Legal", value: "legalApi" },
	{ label: "PocketBook store", value: "ecommerce" },
	{ label: "ReadRate", value: "readrate" },
	{ label: "Send to PocketBook", value: "s2pb" },
	{ label: "SAC", value: "psac" },
];

const Contacts: FC = () => {
	const [contact, setContact] = useState<{
		contactInfo?: IContactCard | null;
		email?: string;
		isOpen: boolean;
		isLoading: boolean;
	}>({
		contactInfo: undefined,
		email: undefined,
		isOpen: false,
		isLoading: false,
	});

	const [searchValue, setSearchValue] = useState<string>("");
	const [contactType, setContactType] = useState<
		{ label: string; value: string } | undefined
	>();

	const data = useMemo(() => {
		const contactRecord =
			contactType &&
			contact &&
			contact.contactInfo &&
			(contact.contactInfo as Record<string, any>)[contactType.value];

		return contactRecord
			? [
					{
						email: searchValue,
						fullName:
							contactRecord.firstName || contactRecord.lastName
								? `${contactRecord.firstName || ""} ${
										contactRecord.lastName || ""
								  }`
								: contactRecord.name || "",
						phone: contactRecord.phone || contactRecord.phoneNumber || "",
						country: contactRecord.country || "",
					},
			  ]
			: [];
	}, [contact, searchValue, contactType]);

	const columns = useMemo(
		() => [
			{
				Header: "Email",
				accessor: "email",
			},
			{
				Header: "name",
				accessor: "fullName",
			},
			{
				Header: "Phone number",
				accessor: "phone",
			},
			{
				Header: "Country",
				accessor: "country",
			},
		],
		[]
	);

	const openContact = useCallback(() => {
		setContact({
			...contact,
			isOpen: true,
		});
	}, [contact]);

	const onSearchContact = useCallback(async () => {
		if (contact && contact.email === searchValue) return;
		if (!contactType || !searchValue || searchValue.length < 1) return;

		setContact({ ...contact, isLoading: true });

		const value = await searchContactCard({
			email: searchValue,
			contactType: contactType.value,
		});

		if (value && (value as Record<string, any>)[contactType.value]) {
			setContact({
				contactInfo: value,
				email: searchValue,
				isOpen: false,
				isLoading: false,
			});
		} else if (!value || !(value as Record<string, any>)[contactType.value]) {
			setContact({
				contactInfo: null,
				email: "",
				isOpen: false,
				isLoading: false,
			});
		}
	}, [searchValue, contact, contactType]);

	const closeContact = useCallback(() => {
		setContact({
			...contact,
			email: "",
			isOpen: false,
		});
	}, [contact]);

	const onSearchChange = useCallback((value: string | number) => {
		setSearchValue(value as string);
		setContact({
			isOpen: false,
			email: "",
			contactInfo: null,
			isLoading: false,
		});
	}, []);

	return (
		<>
			<ContactCard
				isOpen={contact.isOpen}
				email={contact.email}
				onClose={() => {
					closeContact();
				}}
				onDeleted={() => {
					setContact({
						contactInfo: null,
						email: "",
						isOpen: false,
						isLoading: false,
					});
				}}
			/>
			<Row pl={Space.xxxl} pr={Space.xxxl} pt={Space.xl} spaceBetween>
				<Col auto>
					<Text type={textTypes.h3Bold!}>Contacts</Text>
				</Col>
			</Row>
			<Row pl={Space.xxxl} pr={Space.xxxl} pt={Space.xl} spaceBetween>
				<Col md={8} lg={7} xl={5}>
					<Row>
						<Col md={6} lg={5} xl={4} pr={12}>
							<Select
								isSearchable={false}
								prefixIcon={
									<>
										<FilterListSVG />
									</>
								}
								customBackground="rgba(74, 74, 74, 0.04)"
								placeholder="Contact type"
								options={contactTypes}
								value={contactType as any}
								onChange={(value) => {
									setContactType(value as any);
								}}
							/>
						</Col>
						<Col>
							<TextField
								value={searchValue}
								onChange={onSearchChange}
								size={TextFieldSize.md!}
								prefixIcon={
									<>
										<SearchSVG />
									</>
								}
								placeholder="Type email"
								fitWidth
							/>
						</Col>
					</Row>
				</Col>
				<Col auto>
					<WithPermisions permissions={[PermissionType.VIEW_CONTACT_CARD]}>
						<Col auto>
							<Button
								variant={buttonTypes.default!}
								size={ButtonSize.md}
								round
								onClick={onSearchContact}
							>
								SEARCH
							</Button>
						</Col>
					</WithPermisions>
				</Col>
			</Row>
			<Row pl={Space.xxxl} pr={Space.xxxl} pt={Space.xl}>
				<Table
					onClick={() => openContact()}
					columns={columns}
					data={data}
					height={"calc(100vh - 200px)"}
					filter={searchValue}
					placeholder={
						<Row mt={24} centerItems>
							<Text type={textTypes.captionMd!} alignCenter>
								{!searchValue &&
									"Please select contact type, enter email and click search to find a contact"}
								{Boolean(
									searchValue && !contact.isLoading && !contact.contactInfo
								) && "No contacts available"}
								{contact.isLoading && (
									<Row mt={16} centerItems>
										<Spinner variant={SpinnerType.ring} size={SpinnerSize.md} />
									</Row>
								)}
							</Text>
						</Row>
					}
				/>
			</Row>
		</>
	);
};

export default observer(Contacts);
