import { FC, useCallback } from "react";
import Modal from "../../index";
import {
	Container,
	TriggerListItem,
	Devider,
	NextContainer,
	TriggerList,
} from "./styled";
import Button from "../../../Button";
import Text from "../../../Text";
import { Row, Col } from "../../../Grid";
import { textTypes } from "../../../../styles/typography";
import { ReactComponent as CloseIcon } from "../../../../assets/icons/close.svg";
import { ReactComponent as NextIcon } from "../../../../assets/icons/keyboard_arrow_right.svg";
import { ButtonSize, buttonTypes } from "../../../../styles/buttons";
import { Space } from "../../../../types/constants";
import Scrollbars from "react-custom-scrollbars";

interface ITrigger {
	isOpen: boolean;
	onClose: () => void;
	onSuccess: (chosenTrigger: string) => void;
}

const triggerItems: Array<string> = [
	"When someone joins a group",
	"When someone views specific product N times",
	"When someone browses a specific page",
	"When someone opens any campaign",
	"When someone opens a specific campaign",
	"When someone clicks on any link in any campaign",
	"When someone leaves their cart",
	"When someone purchases specific products",
	"The anniversary of date",
	"The exact match of date",
	"The exact match of date",
	"The exact match of date",
	"The exact match of date",
	"The exact match of date",
	"The exact match of date",
];

const TriggerModal: FC<ITrigger> = ({ isOpen, onClose, onSuccess }) => {
	const chooseTrigger = useCallback(
		(event: any) => {
			if (event.target && event.target.id) {
				onSuccess(event.target.id);
			}
			onClose();
		},
		[onSuccess, onClose]
	);

	return (
		<Modal isOpen={isOpen} onClose={onClose} backdrop={true}>
			<Container>
				<Row fitWidth mb={Space.md}>
					<Col auto>
						<Text type={textTypes.h5Bold!}>Select a trigger</Text>
					</Col>
					<Col itemRight>
						<Button
							type="button"
							size={ButtonSize.sm}
							variant={buttonTypes.softSecondary!}
							round
							icon
							onClick={onClose}
						>
							<CloseIcon />
						</Button>
					</Col>
				</Row>
				<TriggerList elements={triggerItems.length}>
					<Scrollbars hideTracksWhenNotNeeded>
						{triggerItems.map((item, index) => {
							return (
								<>
									<TriggerListItem
										id={`${item}`}
										onClick={chooseTrigger}
									>
										{item}
										<NextContainer>
											<NextIcon />
										</NextContainer>
									</TriggerListItem>
									{index === triggerItems.length - 1 ? null : <Devider />}
								</>
							);
						})}
					</Scrollbars>
				</TriggerList>
			</Container>
		</Modal>
	);
};

export default TriggerModal;
