import { continueStatement } from "@babel/types";
import { AsYouType } from "libphonenumber-js/min";
import React, { ChangeEvent, FC } from "react";
import { useCallback } from "react";
import { Field, FieldRenderProps } from "react-final-form";
import { TextFieldSize } from "../../styles/text-field";
import {
  Container,
  PrefixIcon,
  PostfixIcon,
  TextField as BaseTextField,
  Label,
  Error,
  LabelHelperText,
} from "./styled";

export enum TextFieldType {
  text = "text",
  number = "number",
  textarea = "textarea",
  phone = "phone",
  password = "password",
  time = "time", // add time type
}

interface ITextField {
  name?: string;
  type?:
    | TextFieldType
    | string
    | "text"
    | "number"
    | "textarea"
    | "phone"
    | "password"
    | "time"; // add time type
  placeholder?: string;
  label?: string;
  size?: string;
  fitWidth?: boolean;
  onChange?: (value: string | number) => void;
  value?: string;
  error?: string;
  prefixIcon?: JSX.Element;
  postfixIcon?: JSX.Element;
  labelHelperText?: string; 
  customBackground?: string; 
}
export const TextField: FC<ITextField> = ({
  label,
  error,
  onChange,
  type = TextFieldType.text,
  size = TextFieldSize.xs,
  prefixIcon,
  postfixIcon,
  fitWidth,
  labelHelperText, 
  customBackground, 
  ...rest
}) => {
  const change = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChange && onChange(event.target.value);
    },
    [onChange]
  );
  const changeNumber = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const number = parseInt(event.target.value);
      onChange && !isNaN(number) && onChange(number);
    },
    [onChange]
  );

  const changePhone = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      const number = event.target.value.startsWith("+") ? value : `+${value}`;
      const asYouType = new AsYouType();
      const parsedNumber = asYouType.input(number!);

      onChange && onChange(parsedNumber);
    },
    [onChange]
  );

  const onFocus = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      const number = value.startsWith("+") ? value : `+${value}`;
      onChange && onChange(number!);
    },
    [onChange]
  );

  const onBlur = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      const number = value.length <= 1 && value.startsWith("+") ? "" : value;
      onChange && onChange(number!);
    },
    [onChange]
  );

  const compare = useCallback((values: Array<string>, compareItem: string) => {
    return values.some((arrItem) => {
      return arrItem === compareItem;
    });
  }, []);

  const changeTime = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      let value = event.target.value;
      const values: Array<string> = ["0","1","2","3","4","5","6","7","8","9"];
      if (value.length === 1 && compare(values.slice(0, 3), value)) {
        onChange && onChange(value);
      } else if (value.length === 2) {
        if (
          (compare(values.slice(0, 2), value.substring(0, 1)) && // first symbol
            compare(values, value.slice(-1))) ||  // second symbol
          (value.substring(0, 1) === "2" && // first symbol
            compare(values.slice(0, 4), value.substring(value.length, 1))) // second symbol
        ) {
            value += ":";
            onChange && onChange(value);
        }
      } else if ((value.length === 4 && compare(values.slice(0, 6), value.slice(-1))) || (value.length === 5 && compare(values, value.slice(-1)))) { // 0-5 | 0-9
          onChange && onChange(value);
      }
      else if(value.slice(-1) === ":"){
        value = value.slice(0,-1);
        onChange && onChange(value);
      }
      if(value.length === 0){
        onChange && onChange(value);
      }
    },
    [onChange]
  );

  return prefixIcon || postfixIcon ? (
    <>
      {label && (
        <Label>
          {label}
          &nbsp;
          {labelHelperText && (
            <LabelHelperText>{labelHelperText}</LabelHelperText>
          )}
        </Label>
      )}
      {(type === TextFieldType.text ||
        type === TextFieldType.number ||
        type === TextFieldType.password) && (
        <Container fitWidth={fitWidth}>
          {prefixIcon && <PrefixIcon>{prefixIcon}</PrefixIcon>}
          <BaseTextField
            type={type}
            error={Boolean(error)}
            onChange={type === TextFieldType.number ? changeNumber : change}
            fieldSize={size}
            fitWidth={fitWidth}
            prefixIcon={Boolean(prefixIcon)}
            postfixIcon={Boolean(postfixIcon)}
            customBackground={customBackground}
            {...rest}
          />
          {postfixIcon && <PostfixIcon>{postfixIcon}</PostfixIcon>}
        </Container>
      )}
      {type === TextFieldType.phone && (
        <Container fitWidth={fitWidth}>
          {prefixIcon && <PrefixIcon>{prefixIcon}</PrefixIcon>}
          <BaseTextField
            error={Boolean(error)}
            onChange={changePhone}
            onFocus={onFocus}
            onBlur={onBlur}
            fieldSize={size}
            fitWidth={fitWidth}
            prefixIcon={Boolean(prefixIcon)}
            postfixIcon={Boolean(postfixIcon)}
            customBackground={customBackground}
            {...rest}
          />
          {postfixIcon && <PostfixIcon>{postfixIcon}</PostfixIcon>}
        </Container>
      )}
      {type === TextFieldType.time && ( 
        <Container fitWidth={fitWidth}>
          {prefixIcon && <PrefixIcon>{prefixIcon}</PrefixIcon>}
          <BaseTextField
            error={Boolean(error)}
            onChange={changeTime}
            fieldSize={size}
            fitWidth={fitWidth}
            prefixIcon={Boolean(prefixIcon)}
            postfixIcon={Boolean(postfixIcon)}
            customBackground={customBackground}
            {...rest}
          />
        </Container> 
      )}
      {error && <Error>{error}</Error>}
    </>
  ) : (
    <>
      {label && (
        <Label>
          {label}
          &nbsp;
          {labelHelperText && (
            <LabelHelperText>{labelHelperText}</LabelHelperText>
          )}
        </Label>
      )}
      {(type === TextFieldType.text ||
        type === TextFieldType.number ||
        type === TextFieldType.password) && (
        <BaseTextField
          type={type}
          error={Boolean(error)}
          onChange={type === TextFieldType.number ? changeNumber : change}
          fieldSize={size}
          fitWidth={fitWidth}
          customBackground={customBackground}
          {...rest}
        />
      )}
      {type === TextFieldType.phone && (
        <BaseTextField
          error={Boolean(error)}
          onChange={changePhone}
          onFocus={onFocus}
          onBlur={onBlur}
          fieldSize={size}
          fitWidth={fitWidth}
          customBackground={customBackground}
          {...rest}
        />
      )}
      {type === TextFieldType.time && ( 
        <Container fitWidth={fitWidth}>
          {prefixIcon && <PrefixIcon>{prefixIcon}</PrefixIcon>}
          <BaseTextField
            error={Boolean(error)}
            onChange={changeTime}
            fieldSize={size}
            fitWidth={fitWidth}
            prefixIcon={Boolean(prefixIcon)}
            postfixIcon={Boolean(postfixIcon)}
            customBackground={customBackground} 
            {...rest}
          />
        </Container> 
      )}
      {error && <Error>{error}</Error>}
    </>
  );
};

interface ITextFieldWrapper extends FieldRenderProps<string, HTMLElement> {
  label?: string;
  type?: TextFieldType | "text" | "number" | "textarea" | "phone" | "password" | "time";
  size?: string;
  fitWidth?: boolean;
  placeholder?: string;
  prefixIcon?: JSX.Element;
  postfixIcon?: JSX.Element;
  labelHelperText?: string; 
  customBackground?: string; 
  defaultValue?: string; // 
}
const TextFieldWrapper: FC<ITextFieldWrapper> = ({
  label,
  fitWidth,
  meta,
  size,
  input,
  placeholder,
  labelHelperText,
  customBackground, 
}) => {
  return (
    <TextField
      label={label}
      fitWidth={fitWidth}
      error={meta.touched ? meta.error : ""}
      size={size}
      placeholder={placeholder}
      labelHelperText={labelHelperText}
      customBackground={customBackground} 
      {...input}
    />
  );
};

interface IFormTextField {
  name: string;
  type?: TextFieldType | "text" | "number" | "textarea" | "phone" | "password" | "time" | "time";
  label?: string;
  fitWidth?: boolean;
  size?: string;
  placeholder?: string;
  prefixIcon?: JSX.Element;
  postfixIcon?: JSX.Element;
  labelHelperText?: string; 
  customBackground?: string; 
}
export const FormTextField: FC<IFormTextField> = ({
  name,
  label,
  type = TextFieldType.text,
  fitWidth,
  size,
  placeholder,
  labelHelperText, 
  customBackground,
}) => {
  return (
    <Field
      type={type}
      name={name}
      label={label}
      fitWidth={fitWidth}
      size={size}
      placeholder={placeholder}
      component={TextFieldWrapper}
      labelHelperText={labelHelperText} 
      customBackground={customBackground} 
    />
  );
};

export default TextField;
