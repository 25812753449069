import { FC } from "react";
import { Grid, Row, Col } from "../../components/Grid";
import { Outlet, useLocation } from "react-router-dom";
import Navigation from "../../components/Navigation";
import { marketingRoutes } from "../../types/menu-routes/marketing";
import { Transition, TransitionGroup } from "react-transition-group";
import { fadeIn } from "../../styles/animations";
import styled, { css } from "styled-components";

interface IWrapper {
  state?: string;
}

export const Wrapper = styled.div<IWrapper>`
  width: 100%;
  height: 100vh; // add 
  overflow-y: auto; // add 
  ${({ state }) =>
    (state === "entering" &&
      css`
        animation: 300ms ${fadeIn};
      `) ||
    (state === "exiting" &&
      css`
        display: none;
      `)};
`;

const Marketing: FC = () => {
  const { pathname } = useLocation();

  return (
    <Grid>
      <Row>
        <Col auto>
          <Navigation routes={marketingRoutes} />
        </Col>
        <Col>
          <TransitionGroup exit={false}>
            <Transition key={pathname} timeout={300}>
              {(state) => (
                <Wrapper state={state}>
                  <Outlet />
                </Wrapper>
              )}
            </Transition>
          </TransitionGroup>
        </Col>
      </Row>
    </Grid>
  );
};

export default Marketing;
