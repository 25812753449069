import React, { FC, useCallback, useMemo } from "react";
import Modal from "../";
import { Container, CloseContainer, Wrapper } from "./styled";
import { Row, Col } from "../../Grid";
import Text from "../../Text";
import { textTypes } from "../../../styles/typography";
import { ReactComponent as CloseIcon } from "../../../assets/icons/close.svg";
import { FormTextField as TextField, TextFieldType } from "../../TextField";
import Button from "../../Button";
import { ButtonSize, buttonTypes } from "../../../styles/buttons";
import { TextFieldSize } from "../../../styles/text-field";
import { Form } from "react-final-form";
import { changePassword } from "../../../api/user";
import { required, requiredMin } from "../../../validations";

interface IChangePasswordModal {
	isOpen?: boolean;
	onSuccess: () => void;
	onClose: () => void;
}

interface IChangePasswordForm {
	currentPassword: string;
	newPassword: string;
}

const ChangePasswordModal: FC<IChangePasswordModal> = ({
	isOpen,
	onClose,
	onSuccess,
}) => {
	const onSubmit = useCallback(
		(values: IChangePasswordForm) => {
			changePassword(values);
			onSuccess();
		},
		[]
	);

	const validate = useCallback(
		({ currentPassword, newPassword }: IChangePasswordForm) => {
			const errors = {
				currentPassword: currentPassword
					? required(currentPassword) || requiredMin(currentPassword!, 3)
					: undefined,
				newPassword: newPassword
					? required(newPassword) || requiredMin(newPassword!, 4)
					: undefined,
			};

			return errors;
		},
		[]
	);

	const initialValues = useMemo(
		() =>
			({
				currentPassword: "",
				newPassword: "",
			} as IChangePasswordForm),
		[]
	);

	return (
		<Modal onClose={onClose} isOpen={isOpen} backdrop={true}>
			<Container>
				<Wrapper>
					<Row mb={10}>
						<Col>
							<Text type={textTypes.h5Bold!}>Change password</Text>
						</Col>
						<Col auto itemRight>
							<CloseContainer onClick={onClose}>
								<CloseIcon />
							</CloseContainer>
						</Col>
					</Row>
					<Form
						onSubmit={onSubmit}
						initialValues={initialValues}
						validate={validate}
						render={({ handleSubmit }) => (
							<form onSubmit={handleSubmit}>
								<Row>
									<TextField
										name="currentPassword"
										type={TextFieldType.password}
										label="Current password"
										size={TextFieldSize.md}
										fitWidth
										placeholder="Type your current password"
									/>
								</Row>
								<Row mt={16}>
									<TextField
										name="newPassword"
										type={TextFieldType.password}
										label="New password"
										size={TextFieldSize.md}
										fitWidth
										placeholder="Type your new password"
									/>
								</Row>
								<Row mt={16}>
									<Col mr={12} itemRight>
										<Button
											type="button"
											size={ButtonSize.lg}
											variant={buttonTypes.outlineSecondary}
											round
											onClick={onClose}
										>
											Cancel
										</Button>
									</Col>
									<Col auto>
										<Button
											type="submit"
											size={ButtonSize.lg}
											variant={buttonTypes.default}
											round
										>
											Change
										</Button>
									</Col>
								</Row>
							</form>
						)}
					/>
				</Wrapper>
			</Container>
		</Modal>
	);
};

export default ChangePasswordModal;
