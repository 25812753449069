import styled from "../../styles/theme";
import { fadeIn } from "../../styles/animations";
import { css } from "styled-components";
interface ITabUnderline {
  tabPositionX: number; // x - Position
  tabWidth: number;
}

export const Container = styled.div`
  width: 100%;
`;

export const TabsContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const TabUnderline = styled.div<ITabUnderline>`
  position: absolute;
  left: ${({ tabPositionX }) => tabPositionX || 0}px;
  width: ${({ tabWidth }) => tabWidth || 0}px;
  height: 4px;
  background: ${({ theme: { colors } }) => colors.primary._700};
  transition: 0.2s;

  @media (max-width: 450px) {
    margin-left: -8px;
  }
`;

export const TabsUnderline = styled.div`
  position: absolute;
  width: 100%;
  height: 1px;
  background: #e4e8ee;
  bottom: -4px;
`;

export const TabButton = styled.div`
  display: inline-flex; // change inline-block to inline-flex
  align-items: center; // add
  padding: 16px 16px;
  margin-top: 4px;
  cursor: pointer;
`;

interface IContent {
  state?: string;
}

export const Content = styled.div<IContent>`
  width: 100%;
  animation: 300ms ${fadeIn};
`;

export const TabsWrap = styled.div`
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  width: fit-content;
`;
//My code below
interface IIconContainer {
  show?: boolean;
}

export const IconContainer = styled.div<IIconContainer>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  margin-right: 8px;
  ${({ show }) =>
    show &&
    css`
      & > svg {
        & > path {
          stroke: ${({ theme: { colors } }) => colors.primary._700};
		  stroke-opacity: 1;
        }
      }
    `};
`;
