import { reflectObjectProps } from "../utils/reflection";

export enum BadgeSize {
  xxLarge = "xxLarge",
  xLarge = "xLarge",
  large = "large",
  medium = "medium",
  small = "small",
  xSmall = "xSmall",
};

export enum BadgeVariant {
  primary = "primary",
  secondary = "secondary",
  warning = "warning",
  success = "success",
  danger = "danger",
  info = "info",
};

export enum BadgeStatus{
  sent = 'sent',
  active = 'active',
  draft = 'draft',
  default = 'default'
};

export const badges = {
  filled: {
    background: {
      primary: "rgba(10, 97, 92, 1)",
      secondary: "rgba(79, 79, 79, 1)",
      warning: "rgba(255, 167, 43, 1)",
      success: "rgba(0, 192, 138, 1)",
      danger: "rgba(234, 104, 82, 1)",
      info: "rgba(88, 135, 218, 1)",
    },
    color: {
      primary: "rgba(255,255,255,1)",
      secondary: "rgba(255,255,255,1)",
      warning: "rgba(255,255,255,1)",
      success: "rgba(255,255,255,1)",
      danger: "rgba(255,255,255,1)",
      info: "rgba(255,255,255,1)",
    },
    borderRadius: {
      default: 4,
      round: 100,
    },
    border: 0,
    borderColor: {
      primary: "transparent",
      secondary: "transparent",
      warning: "transparent",
      success: "transparent",
      danger: "transparent",
      info: "transparent",
    },
    font: {
      xxLarge: {
        size: 16,
        lineHeight: 24,
        weight: 600,
      },
      xLarge: {
        size: 16,
        lineHeight: 24,
        weight: 600,
      },
      large: {
        size: 14,
        lineHeight: 20,
        weight: 600,
      },
      medium: {
        size: 12,
        lineHeight: 16,
        weight: 600,
      },
      small: {
        size: 12,
        lineHeight: 16,
        weight: 600,
      },
      xSmall: {
        size: 12,
        lineHeight: 16,
        weight: 600,
      },
    },
    padding: {
      xxLarge: {
        default: "8px",
        round: "8px 12px",
      },
      xLarge: {
        default: "6px 8px",
        round: "6px 12px",
      },
      large: {
        default: "6px 8px",
        round: "6px 12px",
      },
      medium: {
        default: "6px 8px",
        round: "6px 12px",
      },
      small: {
        default: "4px 8px",
        round: "4px 12px",
      },
      xSmall: {
        default: "2px 8px",
        round: "2px 12px",
      },
    },
  },
  soft: {
    background: {
      primary: "rgba(10, 97, 92, 0.12)",
      secondary: "rgba(74, 74, 74, 0.12)",
      warning: "rgba(255, 167, 43, 0.15)",
      success: "rgba(0, 192, 138, 0.15)",
      danger: "rgba(234, 104, 82, 0.15)",
      info: "rgba(88, 135, 218, 0.15)",
    },
    color: {
      primary: "rgba(10, 97, 92, 1)",
      secondary: "rgba(24, 24, 25, 0.9)",
      warning: "rgba(255, 167, 43, 1)",
      success: "rgba(0, 192, 138, 1)",
      danger: "rgba(234, 104, 82, 1)",
      info: "rgba(88, 135, 218, 1)",
    },
    borderRadius: {
      default: 4,
      round: 100,
    },
    border: 0,
    borderColor: {
      primary: "transparent",
      secondary: "transparent",
      warning: "transparent",
      success: "transparent",
      danger: "transparent",
      info: "transparent",
    },
    font: {
      xxLarge: {
        size: 16,
        lineHeight: 24,
        weight: 600,
      },
      xLarge: {
        size: 16,
        lineHeight: 24,
        weight: 600,
      },
      large: {
        size: 14,
        lineHeight: 20,
        weight: 600,
      },
      medium: {
        size: 12,
        lineHeight: 16,
        weight: 600,
      },
      small: {
        size: 12,
        lineHeight: 16,
        weight: 600,
      },
      xSmall: {
        size: 12,
        lineHeight: 16,
        weight: 600,
      },
    },
    padding: {
      xxLarge: {
        default: "8px",
        round: "8px 12px",
      },
      xLarge: {
        default: "6px 8px",
        round: "6px 12px",
      },
      large: {
        default: "6px 8px",
        round: "6px 12px",
      },
      medium: {
        default: "6px 8px",
        round: "6px 12px",
      },
      small: {
        default: "4px 8px",
        round: "4px 12px",
      },
      xSmall: {
        default: "2px 8px",
        round: "2px 12px",
      },
    },
  },
  outlined: {
    background: {
      primary: "rgba(255,255,255,1)",
      secondary: "rgba(255,255,255,1)",
      warning: "rgba(255,255,255,1)",
      success: "rgba(255,255,255,1)",
      danger: "rgba(255,255,255,1)",
      info: "rgba(255,255,255,1)",
    },
    color: {
      primary: "rgba(10, 97, 92, 1)",
      secondary: "rgba(24, 24, 25, 0.9)",
      warning: "rgba(255, 167, 43, 1)",
      success: "rgba(0, 192, 138, 1)",
      danger: "rgba(234, 104, 82, 1)",
      info: "rgba(88, 135, 218, 1)",
    },
    borderRadius: {
      default: 4,
      round: 100,
    },
    border: 1,
    borderColor: {
      primary: "rgba(10, 97, 92, 1)",
      secondary: "rgba(79, 79, 79, 1)",
      warning: "rgba(255, 167, 43, 1)",
      success: "rgba(0, 192, 138, 1)",
      danger: "rgba(234, 104, 82, 1)",
      info: "rgba(88, 135, 218, 1)",
    },
    font: {
      xxLarge: {
        size: 16,
        lineHeight: 24,
        weight: 600,
      },
      xLarge: {
        size: 16,
        lineHeight: 24,
        weight: 600,
      },
      large: {
        size: 14,
        lineHeight: 20,
        weight: 600,
      },
      medium: {
        size: 12,
        lineHeight: 16,
        weight: 600,
      },
      small: {
        size: 12,
        lineHeight: 16,
        weight: 600,
      },
      xSmall: {
        size: 12,
        lineHeight: 16,
        weight: 600,
      },
    },
    padding: {
      xxLarge: {
        default: "8px",
        round: "8px 12px",
      },
      xLarge: {
        default: "6px 8px",
        round: "6px 12px",
      },
      large: {
        default: "6px 8px",
        round: "6px 12px",
      },
      medium: {
        default: "6px 8px",
        round: "6px 12px",
      },
      small: {
        default: "4px 8px",
        round: "4px 12px",
      },
      xSmall: {
        default: "2px 8px",
        round: "2px 12px",
      },
    },
  },
};

export const badgesTypes = reflectObjectProps(badges);
