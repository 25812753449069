import React, { FC, useMemo } from "react";
import { textTypes } from "../../styles/typography";
import { IMargin } from "../../types/styles";
import { Body, Caption, H1, H2, H3, H4, H5, H6, TextWrapper } from "./styled";

interface IText extends IMargin {
	type: string;
	color?: string;
	customColor?: string;
	allowNewLines?: boolean;
	float?: "left" | "right";
	isBlock?: boolean;
	isInline?: boolean;
	isItalic?: boolean;
	underline?: boolean;
	alignCenter?: boolean;
	onClick?: () => void;
	children?: React.ReactNode;
	clickable?: boolean;
}

const Text: FC<IText> = ({ children, float, type, ...rest }: IText) => {
	const SelectedTextType = useMemo<JSX.Element>(() => {
		switch (type) {
			case textTypes.h1:
				return (
					<H1 textFloat={float} type={type} {...rest}>
						{children}
					</H1>
				);
			case textTypes.h1Bold:
				return (
					<H1 textFloat={float} type={type} {...rest}>
						{children}
					</H1>
				);
			case textTypes.h2:
				return (
					<H2 textFloat={float} type={type} {...rest}>
						{children}
					</H2>
				);
			case textTypes.h2Bold:
				return (
					<H2 textFloat={float} type={type} {...rest}>
						{children}
					</H2>
				);
			case textTypes.h3:
				return (
					<H3 textFloat={float} type={type} {...rest}>
						{children}
					</H3>
				);
			case textTypes.h3Bold:
				return (
					<H3 textFloat={float} type={type} {...rest}>
						{children}
					</H3>
				);
			case textTypes.h4:
				return (
					<H4 textFloat={float} type={type} {...rest}>
						{children}
					</H4>
				);
			case textTypes.h4Bold:
				return (
					<H4 textFloat={float} type={type} {...rest}>
						{children}
					</H4>
				);
			case textTypes.h5:
				return (
					<H5 textFloat={float} type={type} {...rest}>
						{children}
					</H5>
				);
			case textTypes.h5Bold:
				return (
					<H5 textFloat={float} type={type} {...rest}>
						{children}
					</H5>
				);
			case textTypes.h6:
				return (
					<H6 textFloat={float} type={type} {...rest}>
						{children}
					</H6>
				);
			case textTypes.h6Bold:
				return (
					<H6 textFloat={float} type={type} {...rest}>
						{children}
					</H6>
				);
			case textTypes.bodySm:
				return (
					<Body textFloat={float} type={type} {...rest}>
						{children}
					</Body>
				);
			case textTypes.bodySmBold:
				return (
					<Body textFloat={float} type={type} {...rest}>
						{children}
					</Body>
				);
			case textTypes.bodyMd:
				return (
					<Body textFloat={float} type={type} {...rest}>
						{children}
					</Body>
				);
			case textTypes.bodyMdBold:
				return (
					<Body textFloat={float} type={type} {...rest}>
						{children}
					</Body>
				);
			case textTypes.bodyLg:
				return (
					<Body textFloat={float} type={type} {...rest}>
						{children}
					</Body>
				);
			case textTypes.bodyLgBold:
				return (
					<Body textFloat={float} type={type} {...rest}>
						{children}
					</Body>
				);
			case textTypes.captionSm:
				return (
					<Caption textFloat={float} type={type} {...rest}>
						{children}
					</Caption>
				);
			case textTypes.captionMd:
				return (
					<Caption textFloat={float} type={type} {...rest}>
						{children}
					</Caption>
				);
			case textTypes.captionLg:
				return (
					<Caption textFloat={float} type={type} {...rest}>
						{children}
					</Caption>
				);
			default:
				return (
					<TextWrapper textFloat={float} type={type} {...rest}>
						{children}
					</TextWrapper>
				);
		}
	}, [type, rest]);

	return SelectedTextType;
};

export default Text;
