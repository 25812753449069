import { DateTime } from "luxon";
import { flow, types } from "mobx-state-tree";
import { updateCompany, deleteCompany } from "../api/companies";
import { InternalContactModel } from "./internal-contacts";

interface ICompany {
	id?: string;
	name: string;
	webAddress?: string;
	responsible?: string;
	phoneNumber?: string;
	category?: string;
	industry?: string;
	stores?: Array<{
		name?: string;
		address?: string;
	}>;
	address?: {
		main?: string;
		another?: string;
		country?: string;
		region?: string;
		city?: string;
		index?: string;
	};
	comuntication?: {
		number?: string;
		fax?: string;
		email?: string;
		facebook?: string;
		twitter?: string;
	};
}

const CompanyStore = types.model({
	name: types.maybeNull(types.string),
	address: types.maybeNull(types.string),
});

const CompanyAddress = types.model({
	main: types.maybeNull(types.string),
	another: types.maybeNull(types.string),
	country: types.maybeNull(types.string),
	region: types.maybeNull(types.string),
	city: types.maybeNull(types.string),
	index: types.maybeNull(types.string),
});

const CompanyComuntication = types.model({
	number: types.maybeNull(types.string),
	fax: types.maybeNull(types.string),
	email: types.maybeNull(types.string),
	facebook: types.maybeNull(types.string),
	twitter: types.maybeNull(types.string),
});

const CompanyModel = types
	.model("CompanyModel", {
		id: types.identifier,
		name: types.string,
		webAddress: types.maybeNull(types.string),
		responsible: types.maybeNull(types.string),
		phoneNumber: types.maybeNull(types.string),
		category: types.maybeNull(types.string),
		industry: types.maybeNull(types.string),
		employees: types.array(types.safeReference(InternalContactModel)),
		stores: types.maybeNull(types.array(CompanyStore)),
		address: types.maybeNull(CompanyAddress),
		comuntication: types.maybeNull(CompanyComuntication),
		modifiedOn: types.string,
		createdOn: types.maybeNull(types.string),
	})
	.actions((self) => ({
		updateInfo(data: any) {
			self.name = data.name;
			self.webAddress = data.webAddress;
			self.responsible = data.responsible;
			self.phoneNumber = data.phoneNumber;
			self.category = data.category;
			self.industry = data.industry;
			self.employees = data.employees;
			self.stores = data.stores;
			self.address = data.address;
			self.comuntication = data.comuntication;
			self.modifiedOn = DateTime.local().toISO();
			self.createdOn = data.createdOn;
		},
	}))
	.views((self) => ({
		employeesCount() {
			return self.employees.length;
		},
	}));

const CompaniesModel = types
	.model("CompaniesModel", {
		entries: types.map(CompanyModel),
	})
	.actions((self) => ({
		init(contacts?: any) {
			const entriesData = contacts.reduce(
				(acc: any, { id, employees, ...rest }: any) => {
					return {
						...acc,
						[id]: {
							id: id,
							employees: (employees && employees.map((x: any) => x.id)) || [],
							...rest,
						},
					};
				},
				{}
			);

			self.entries?.replace(entriesData);
		},
		update: flow(function* update(data: ICompany) {
			if (data.id) {
				// @ts-ignore
				const response = yield updateCompany(data);
				let selected = self.entries.get(data.id);

				if (selected) {
					selected.updateInfo(data);
				}

				return data.id;
			}

			// @ts-ignore
			const response = yield updateCompany(data);
			const newId = response;
			self.entries?.set(
				newId,
				CompanyModel.create({
					...data,
					id: newId,
					modifiedOn: DateTime.local().toISO(),
				} as any)
			);

			return newId;
		}),
		addEmployee(id: string, employee: any) {
			const company = self.entries.get(id);

			if (company) {
				self.entries.forEach((x) => {
					x.employees.replace(x.employees.filter((x) => x?.id !== employee.id));
				});
				company.employees && company.employees.push(employee);
			}
		},
		removeEmployee(id: string) {
			self.entries.forEach((x) => {
				x.employees &&
					x.employees.replace(x.employees.filter((x) => x?.id !== id));
			});
		},
		delete(id: string) {
			deleteCompany(id);
			self.entries.delete(id);
		},
	}))
	.views((self) => ({
		array() {
			return self.entries
				? Array.from(self.entries?.values()).sort((first, second) => {
						const dt1 = first.modifiedOn && DateTime.fromISO(first.modifiedOn);
						const dt2 =
							second.modifiedOn && DateTime.fromISO(second.modifiedOn);
						if (dt1! > dt2!) {
							return -1;
						}
						if (dt1! < dt2!) {
							return 1;
						}

						return 0;
				  })
				: [];
		},
	}));

export default CompaniesModel;
