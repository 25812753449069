import styled from "../../../../../styles/theme";

export const Container = styled.div`
	width: 100%;
	height: calc(100% - 88px);
	padding: 26px 100px 24px 100px;
	box-sizing: border-box;
`;

export const Header = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	background-color: #fff;
	padding: 24px 100px;
	user-select: none;
`;

export const TemplateContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 8px;
	background-color: #fff;
	color: rgba(24, 24, 25, 0.9);
	height: 100%;
	user-select: none;
	text-align: center;
`;
