import styled, { css } from "styled-components";

interface IToolTip {
  show?: boolean;
  top?: number;
  left?: number;
  side?: string;
}

export const ToolTipContainer = styled.div<IToolTip>`
  position: absolute;
  display: flex;
  z-index: 9999;
  align-items: center;
  justify-content: center;
  white-space: nowrap; 
  padding: 4px 8px;
  background-color: #4f4f4f;
  color: rgba(255, 255, 255, 1);
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  border-radius: 4px;
  left: ${({ left }) => left}px;
  top: ${({ top }) => (top && top - 12) || 0}px;
  opacity: ${({ show }) => (show ? 1 : 0)};
  :before {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    ${({ side }) =>
      (side === "left" &&
        css`
          top: 50%;
          transform: translateY(-50%);
          left: 100%;
          border-top: 5px solid transparent;
          border-left: 5px solid #4f4f4f;
          border-bottom: 5px solid transparent;
        `) ||
      (side === "right" &&
        css`
          top: 50%;
          transform: translateY(-50%);
          right: 100%;
          border-top: 5px solid transparent;
          border-right: 5px solid #4f4f4f;
          border-bottom: 5px solid transparent;
        `) ||
      (side === "top" && css`
	  	  top: 100%;
          transform: translateY(0);
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 5px solid #4f4f4f;
	  `) ||
      (side === "bottom" &&
        css`
          top: 0;
          transform: translateY(-100%);
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-bottom: 5px solid #4f4f4f;
        `)};
  }
`;
