import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { theme } from "./styles/theme";
import "./font-faces.css";
import { ThemeProvider, createGlobalStyle } from "styled-components";
import { ModalRoot } from "./components/Modal";
import { MODAL_ROOT_ID } from "./types/constants";

const GlobalStyle = createGlobalStyle`
body {
	background: #F6F6F6;
  margin: 0;
  font-family: 'Noto-Sans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
`;

ReactDOM.render(
	<React.StrictMode>
		<ThemeProvider theme={theme}>
			<ModalRoot id={MODAL_ROOT_ID} />
			<GlobalStyle />
			<App />
		</ThemeProvider>
	</React.StrictMode>,
	document.getElementById("root")
);

reportWebVitals();
