import { DateTime } from "luxon";
import { flow, IMSTArray, ISimpleType, types } from "mobx-state-tree";
import { deleteRole, updateRole } from "../api/admin/roles";
import { deleteSystemUser, updateSystemUser } from "../api/admin/users";

const UserModel = types.model("UserModel", {
	id: types.identifier,
	username: types.string,
	role: types.string,
	createdOn: types.string,
});

const RoleModel = types.model("RoleModel", {
	id: types.identifier,
	name: types.string,
	regions: types.array(types.string),
	contactTypes: types.array(types.string),
	departments: types.array(types.string),
	policies: types.array(types.string),
	modifiedOn: types.string,
});

const AdminModel = types
	.model("UserModel", {
		users: types.map(UserModel),
		roles: types.map(RoleModel),
	})
	.actions((self) => ({
		initUsers(users?: any) {
			const entriesData = users.reduce((acc: any, { id, ...rest }: any) => {
				return {
					...acc,
					[id]: {
						id: id,
						...rest,
					},
				};
			}, {});

			self.users?.replace(entriesData);
		},
		initRoles(roles?: any) {
			const entriesData = roles.reduce(
				(
					acc: any,
					{ id, departments, regions, contactTypes, ...rest }: any
				) => {
					return {
						...acc,
						[id]: {
							id: id,
							departments: departments ?? [],
							regions: regions ?? [],
							contactTypes: contactTypes ?? [],
							...rest,
						},
					};
				},
				{}
			);

			self.roles?.replace(entriesData);
		},

		updateUser: flow(function* update({
			password,
			...rest
		}: {
			id?: string;
			username: string;
			password?: string;
			role: string;
		}) {
			if (rest.id) {
				const current = self.users.get(rest.id);
				const response = yield updateSystemUser({
					password: password,
					...rest,
				});

				self.users?.set(
					rest.id!,
					UserModel.create({
						id: current?.id ?? "",
						createdOn: current?.createdOn!,
						username: rest.username || current?.username!,
						role: rest.role || current?.role!,
					})
				);

				return rest.id;
			}

			const response = yield updateSystemUser({ password: password, ...rest });
			const newId = response;

			self.users?.set(
				newId,
				UserModel.create({
					id: newId,
					createdOn: DateTime.local().toISO(),
					username: rest.username,
					role: rest.role,
				})
			);

			return newId;
		}),
		updateRole: flow(function* update(data: {
			id?: string;
			name: string;
			regions: Array<string>;
			policies: Array<string>;
		}) {
			if (data.id) {
				const current = self.roles.get(data.id);
				const response = yield updateRole(data);

				if (current) {
					current.modifiedOn = DateTime.local().toISO();
					current.name = data.name ?? current.name;
					current.policies =
						(data.policies as IMSTArray<ISimpleType<string>>) ??
						current.policies;
					current.regions =
						(data.regions as IMSTArray<ISimpleType<string>>) ?? current.regions;
				}

				return data.id;
			}

			const response = yield updateRole(data);
			const newId = response;

			self.roles?.set(
				newId,
				RoleModel.create({
					id: newId,
					name: data.name,
					regions: data.regions,
					contactTypes: [],
					departments: [],
					policies: data.policies,
					modifiedOn: DateTime.local().toISO(),
				})
			);

			return newId;
		}),
		deleteUser(id: string) {
			deleteSystemUser(id);
			self.users.delete(id);
		},
		deleteRole(id: string) {
			deleteRole(id);
			self.roles.delete(id);
		},
	}))
	.views((self) => ({
		get usersArray() {
			return self.users
				? Array.from(self.users?.values()).sort((first, second) => {
						const dt1 = first.createdOn && DateTime.fromISO(first.createdOn);
						const dt2 = second.createdOn && DateTime.fromISO(second.createdOn);
						if (dt1! > dt2!) {
							return -1;
						}
						if (dt1! < dt2!) {
							return 1;
						}

						return 0;
				  })
				: [];
		},
		get rolesArray() {
			return self.roles
				? Array.from(self.roles?.values()).sort((first, second) => {
						const dt1 = first.modifiedOn && DateTime.fromISO(first.modifiedOn);
						const dt2 =
							second.modifiedOn && DateTime.fromISO(second.modifiedOn);
						if (dt1! > dt2!) {
							return -1;
						}
						if (dt1! < dt2!) {
							return 1;
						}

						return 0;
				  })
				: [];
		},
	}));

export default AdminModel;
