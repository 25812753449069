import { FC, useMemo } from "react";
import Text from "../../../../Text";
import { textTypes } from "../../../../../styles/typography";
import Button from "../../../../Button";
import { buttonTypes, ButtonSize } from "../../../../../styles/buttons";
import { Row, Col } from "../../../../Grid";
import { Header, TemplateContainer, Container } from "./styled"; // add Container
import { IconContainer } from "../../../../../pages/marketing/campaigns/new-campaign/PreView/styled";
import { ReactComponent as CloseIcon } from "../../../../../assets/icons/close.svg";
import Scrollbars from "react-custom-scrollbars";
import { useStore } from "../../../../../store";
import { observer } from "mobx-react-lite";
import { Space } from "../../../../../types/constants";
import { getImageSrc } from "../../../../../utils/get-host";

interface IPreview {
	onSelect?: () => void;
	onClose?: () => void;
}

const Preview: FC<IPreview> = ({ onSelect, onClose }) => {
	const { templates, campaigns } = useStore();
	const template = useMemo(() => campaigns && templates && templates.entries && templates.entries.get(campaigns.templateId!),[campaigns.templateId, templates.array]);
	return (
		<>
			<Row>
				<Header>
					<Col>
						<Text type={textTypes.h6Bold!}>Preview</Text>
					</Col>
					<Col itemRight auto>
						<Row>
							<Button
								type="button"
								round
								size={ButtonSize.sm}
								variant={buttonTypes.default!}
								mr={Space.xs}
								onClick={onSelect}
							>
								SELECT TEMPLATE
							</Button>
							<IconContainer onClick={onClose} exit>
								<CloseIcon />
							</IconContainer>
						</Row>
					</Col>
				</Header>
			</Row>
			<Container>
				<TemplateContainer>
					<Scrollbars>
						{Boolean(campaigns.templateId && template) && (
							<img src={getImageSrc(template && template.image || "")} />
						)}
					</Scrollbars>
				</TemplateContainer>
			</Container>
		</>
	);
};

export default observer(Preview);
