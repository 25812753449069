import { flow, types } from "mobx-state-tree";
//// new model
const AutomationModel = types
	.model({
		delay: types.maybeNull(types.string),
	})
	.actions((self) => ({
		init({ delay }: { delay: string }) {
			self.delay = delay;
		},
	}));
////// add new model
const AddSubjectModel = types
	.model({
		campaignName: types.maybeNull(types.string),
		subject: types.maybeNull(types.string),
		personalize: types.maybeNull(types.string),
		from: types.maybeNull(types.string),
		senderName: types.maybeNull(types.string),
	})
	.actions((self) => ({
		init({
			campaignName,
			subject,
			personalize,
			from,
			senderName,
		}: {
			campaignName: string;
			subject: string;
			personalize: string;
			from: string;
			senderName: string;
		}) {
			self.campaignName = campaignName;
			self.subject = subject;
			self.personalize = personalize;
			self.from = from;
			self.senderName = senderName;
		},
	}));

export enum RecepientsType {
	Group = "group",
	Company = "company",
}

const ChooseRecepientsModel = types
	.model({
		entities: types.maybeNull(
			types.model({
				id: types.string,
				type: types.enumeration(Object.values(RecepientsType)),
			})
		),
	})
	.actions((self) => ({
		setEntity(values: { id: string; type: RecepientsType } | null) {
			self.entities = values && { id: values.id, type: values.type };
		},
	}));

const SendCampaignModel = types
	.model({
		send: types.maybeNull(types.string),
		date: types.maybeNull(types.string),
		time: types.maybeNull(types.string),
		timeZone: types.maybeNull(types.string),
	})
	.actions((self) => ({
		init(send: string, date: string, time: string, timeZone: string) {
			self.send = send;
			self.date = date;
			self.time = time;
			self.timeZone = timeZone;
		},
	}));

const CampaignFormModel = types
	.model({
		templateId: types.maybeNull(types.string),
		subject: types.maybeNull(AddSubjectModel),
		recepients: types.maybeNull(ChooseRecepientsModel),
		send: types.maybeNull(SendCampaignModel),
		automation: types.maybeNull(AutomationModel), // add
	})
	.actions((self) => ({
		init(data: any) {
			self.templateId = data.id;
			self.subject = data.subject;
			self.recepients = data.recepients;
			self.send = data.send;
		},
		setTemplate(id: string | null) {
			self.templateId = id;
		},
		setRecepientEntities(values: { id: string; type: RecepientsType } | null) {
			self.recepients?.setEntity(values);
		},
	}));

export default CampaignFormModel;
