import { DateTime } from "luxon";
import { flow, types } from "mobx-state-tree";
import { uploadDynamicGroup, deleteDynamicGroup } from "../api/dynamic-groups";

export interface IDynamicGroup {
	id?: string;
	name: string;
	balance?: {
		currency: string;
		min: number;
		max: number;
	};
	contactType: string;
	country?: Array<string>;
	createdById?: string;
	createdDate?: string;
	createdOn?: string;
	gender?: string;
	isActive?: boolean;
	isHasPocketBookDevice?: boolean;
	isSubscribedToInterviewNewsletter?: boolean;
	isSubscribedToMarketingNewsletter?: boolean;
	isSubscribedToNewsletter?: boolean;
	isSubscribedToPromotionalNewsletter?: boolean;
	isSubscribedToTestNewsletter?: boolean;
	isSubscribedToUnsubscribeNewsletter?: boolean;
	isSubscriberNotActive?: boolean;
	language?: Array<string>;
	lastOwnedBookDate?: string;
	modifiedById?: string;
	modifiedOn?: string;
	ownedBooksCategory?: string;
	plannedToReadBookCategory?: string;
	readedBookCategory?: string;
	readingBookCategory?: string;
	storeId?: Array<number>;
	subscriptionStatus?: Array<number>;
}

const GroupBalanceModel = types.model({
	currency: types.string,
	min: types.maybeNull(types.number),
	max: types.maybeNull(types.number),
});

const GroupDateRange = types.model({
	isNot: types.boolean,
	from: types.maybeNull(types.string),
	to: types.maybeNull(types.string),
});

const DynamicGroupModel = types.model("DynamicGroupModel", {
	id: types.identifier,
	balance: types.maybeNull(GroupBalanceModel),
	contactType: types.string,
	country: types.maybeNull(types.array(types.string)),
	createdById: types.maybeNull(types.string),
	createdDate: types.maybeNull(GroupDateRange),
	createdOn: types.string,
	gender: types.maybeNull(types.string),
	isActive: types.maybeNull(types.boolean),
	isHasPocketBookDevice: types.maybeNull(types.boolean),
	isSubscribedToInterviewNewsletter: types.maybeNull(types.boolean),
	isSubscribedToMarketingNewsletter: types.maybeNull(types.boolean),
	isSubscribedToNewsletter: types.maybeNull(types.boolean),
	isSubscribedToPromotionalNewsletter: types.maybeNull(types.boolean),
	isSubscribedToTestNewsletter: types.maybeNull(types.boolean),
	isSubscribedToUnsubscribeNewsletter: types.maybeNull(types.boolean),
	isSubscriberNotActive: types.maybeNull(types.boolean),
	language: types.maybeNull(types.array(types.string)),
	lastOwnedBookDate: types.maybeNull(types.string),
	modifiedById: types.maybeNull(types.string),
	modifiedOn: types.string,
	name: types.string,
	ownedBooksCategory: types.maybeNull(types.string),
	plannedToReadBookCategory: types.maybeNull(types.string),
	readedBookCategory: types.maybeNull(types.string),
	readingBookCategory: types.maybeNull(types.string),
	storeId: types.maybeNull(types.array(types.number)),
	subscriptionStatus: types.maybeNull(types.array(types.number)),
	visitDate: types.maybeNull(GroupDateRange),
});

const DynamicGroupsModel = types
	.model("DynamicGroupsModel", {
		entries: types.map(DynamicGroupModel),
	})
	.actions((self) => ({
		init(groups?: any) {
			const entriesData = groups.reduce(
				(acc: any, { id, visitDate, createdDate, ...rest }: any) => {
					return {
						...acc,
						[id]: {
							id: id,
							visitDate: visitDate &&
								visitDate.length > 0 && {
									isNot: false,
									from: visitDate[0] && visitDate[0].value.from,
									to: visitDate[0] && visitDate[0].value.to,
								},
							createdDate: createdDate &&
								createdDate.length > 0 && {
									isNot: false,
									from: createdDate[0] && createdDate[0].value.from,
									to: createdDate[0] && createdDate[0].value.to,
								},
							...rest,
						},
					};
				},
				{}
			);

			self.entries?.replace(entriesData);
		},
		update: flow(function* update({ id, ...rest }: IDynamicGroup) {
			const now = DateTime.local().toISODate();
			if (id) {
				const response = yield uploadDynamicGroup({ ...rest, id: id });
				const current = self.entries.get(id);

				self.entries?.set(
					id,
					DynamicGroupModel.create({
						...(rest as any),
						id: id,
						createdOn: current?.createdOn,
						modifiedOn: now,
					})
				);

				return id;
			}

			const response = yield uploadDynamicGroup(rest);
			const newId = response;
			self.entries?.set(
				newId,
				DynamicGroupModel.create({
					...(rest as any),
					id: newId,
					createdOn: now,
					modifiedOn: now,
				})
			);

			return newId; // newId;
		}),
		delete(id: string) {
			deleteDynamicGroup(id);
			self.entries.delete(id);
		},
	}))
	.views((self) => ({
		array() {
			return self.entries
				? Array.from(self.entries?.values()).sort((first, second) => {
						const dt1 = first.modifiedOn && DateTime.fromISO(first.modifiedOn);
						const dt2 =
							second.modifiedOn && DateTime.fromISO(second.modifiedOn);
						if (dt1! > dt2!) {
							return -1;
						}
						if (dt1! < dt2!) {
							return 1;
						}

						return 0;
				  })
				: [];
		},
	}));

export default DynamicGroupsModel;
