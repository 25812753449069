import React, { FC, useCallback, useEffect, useMemo } from "react";
import { useTable, useGlobalFilter, useFlexLayout, useRowSelect, Column } from "react-table";
import Text from "../Text";
import { textTypes } from "../../styles/typography";
import VirtualList from "../List";
import {
	ActionIconsHR,
	ActionIconsTD,
	Container,
	TableContainer,
	Tbody,
	Td,
	TdOption,
	Th,
	ThOption,
	Thead,
	TheadRow,
	Tr,
} from "./styled";

interface ITable {
	columns: Array<Column<object>>;
	data: object[];
	height?: string;
	placeholder?: JSX.Element;
	rowIcon?: JSX.Element;
	prefixColumns?: Array<(data:any) => JSX.Element>;
	postfixColumns?: Array<(data:any) => JSX.Element>;
	onClick?: (data: any) => void;
	onRemove?: (data: any) => void;
	truncateContent?: boolean; // vlad code
	filter?: string;
}

const Table: FC<ITable> = ({
	columns,
	data,
	height,
	placeholder,
	rowIcon,
	onClick,
	onRemove,
	prefixColumns = [],
	postfixColumns = [],
	truncateContent = true, // vlad code
	filter
}) => {
	const defaultColumn = useMemo(
		() => ({
			// // When using the useFlexLayout:
			// minWidth: 40, // minWidth is only used as a limit for resizing
			// width: 250, // width is used for both the flex-basis and flex-grow
			// maxWidth: 400, // maxWidth is only used as a limit for resizing
		}),
		[]
	);

	const copyToClipboard = useCallback((text: string) => {
		const textField = document.createElement("textarea");
		textField.style.width = "0px";
		textField.style.height = "0px";
		textField.innerText = text;
		document.body.appendChild(textField);
		textField.focus();
		textField.select();
		document.execCommand("copy");
		textField.remove();
	}, []);

	const { getTableProps, getTableBodyProps, setGlobalFilter, headerGroups, rows, prepareRow } =
		useTable(
			{
				columns,
				data,
				defaultColumn,
			},
			useFlexLayout,
			useRowSelect,
			useGlobalFilter
		) as any; // TODO: Add type

	useEffect(() => setGlobalFilter!(filter || ""), [filter]);

	const stopPropagationFunc = useCallback((e:any) => {
		e.stopPropagation();
	}, []);

	const renderRow = React.useCallback(
		({ index, key, style }) => {
			const row = rows[index];
			prepareRow(row);
			return (
				<div key={key} style={style}>
					<Tr
						{...row.getRowProps()}
						onClick={(_e) => {
							onClick && onClick(row.original);
						}}
					>
						{/* {rowIcon && <Td>{rowIcon}</Td>} */}
						{prefixColumns.map((col, i) => (<TdOption key={i} onClick={stopPropagationFunc}>{col(row.original)}</TdOption>))}
						{row.cells.map((cell: any) => (
							<Td
								maxWidth={cell && cell.column && cell.column.maxWidth}
								alignRight={cell && cell.column && cell.column.alignRight}
								pl={cell && cell.column && cell.column.pl}
								pr={cell && cell.column && cell.column.pr}
								// truncateContent={
								// 	cell && cell.column && cell.column.truncateContent
								// }
								truncateContent={truncateContent} // vlad code
								{...cell.getCellProps()}
							>
								{cell.render("Cell")}
							</Td>
						))}
						{postfixColumns.map((col, i) => (<TdOption key={i} alignRight onClick={stopPropagationFunc}>{col(row.original)}</TdOption>))}
						{/* {onRemove && (
							<ActionIconsTD
								onClick={(e) => {
									e.stopPropagation();
									onRemove(row.original);
								}}
							></ActionIconsTD>
						)} */}
					</Tr>
				</div>
			);
		},
		[rows, prepareRow, onClick, stopPropagationFunc, prefixColumns, postfixColumns]
	);

	return (
		<Container>
			<TableContainer {...getTableProps()} height={height}>
				<Thead>
					{headerGroups.map((headerGroup: any) => (
						<TheadRow {...headerGroup.getHeaderGroupProps()}>
							{/* {rowIcon && <Th>&nbsp</Th>} */}
							{prefixColumns.map((_, i) => (<ThOption key={i}>{'\u00A0'}</ThOption>))}
							{headerGroup.headers.map((column: any) => (
								<Th
									maxWidth={column.maxWidth}
									alignRight={column.alignRight}
									pl={column.pl}
									pr={column.pr}
									{...column.getHeaderProps()}
								>
									<Text
										type={textTypes.captionMd!}
										customColor="rgba(24, 24, 25, 0.42)"
									>
										{column.render("Header")}
									</Text>
								</Th>
							))}
							{/* {onRemove && <ActionIconsHR></ActionIconsHR>} */}
							{postfixColumns.map((_, i) => (<ThOption key={i} alignRight>{'\u00A0'}</ThOption>))}
						</TheadRow>
					))}
				</Thead>
				<Tbody {...getTableBodyProps()}>
					{rows.length > 0 ? (
						<VirtualList
							rowCount={rows.length}
							rowHeight={49}
							rowRenderer={renderRow}
							shadowOnScroll
						/>
					) : (
						placeholder
					)}
				</Tbody>
			</TableContainer>
		</Container>
	);
};

export default Table;
