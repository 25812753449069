import styled from "../../styles/theme";
import { css } from "styled-components";
import { BadgeVariant, BadgeStatus } from "../../styles/badges";

interface IBadge {
  variant?: string; // primary, secondary, warning, success, danger, info
  status?: string; // sent, active, draft
  size: string; // xxLarge, xLarge, Large, Medium, Small, xSmall
  type: string; // filled, soft, outlined
  round?: boolean;
}

interface IBadgeType {
  background: Record<string, string>;
  color: Record<string, string>;
  borderRadius: {
    default: number;
    round: number;
  };
  border: number;
  borderColor: Record<string, string>;
  font: Record<
    string,
    {
      size: number;
      lineHeight: number;
      weight: number;
    }
  >;
  padding: Record<
    string,
    {
      default: string;
      round: string;
    }
  >;
}

type BadgesVariant = Record<string, IBadgeType>;

const badgeStatus = (variant?: string, status?: string) => {
  switch (status) {
    case BadgeStatus.sent:
      return BadgeVariant.success;
    case BadgeStatus.active:
      return BadgeVariant.primary;
    case BadgeStatus.draft:
      return BadgeVariant.warning;
    case BadgeStatus.default:
      return BadgeVariant.info;
    default:
      return variant === undefined ? BadgeVariant.info : variant;
  }
};

export const Container = styled.div<IBadge>`
  display: flex;
  width: fit-content;
  background-color: ${({ variant, status, type, theme: { badges } }) =>
    (badges as BadgesVariant)[type].background[badgeStatus(variant, status)]};
  color: ${({ variant, status, type, theme: { badges } }) =>
    (badges as BadgesVariant)[type].color[badgeStatus(variant, status)]};
  ${({ round, size, type, theme: { badges } }) =>
    (round &&
      css`
        border-radius: ${(badges as BadgesVariant)[type].borderRadius.round}px;
        padding: ${(badges as BadgesVariant)[type].padding[size].round};
      `) ||
    css`
      border-radius: ${(badges as BadgesVariant)[type].borderRadius.default}px;
      padding: ${(badges as BadgesVariant)[type].padding[size].default};
    `};
    border: ${({type, theme: {badges}}) => (badges as BadgesVariant)[type].border}px solid;
    border-color: ${({type, variant = BadgeVariant.info, status, theme: {badges}}) => (badges as BadgesVariant)[type].borderColor[badgeStatus(variant, status)]};
    font-size: ${({type, size, theme: {badges}}) => (badges as BadgesVariant)[type].font[size].size}px;
    font-weight: ${({type, size, theme: {badges}}) => (badges as BadgesVariant)[type].font[size].weight};
    line-height: ${({type, size, theme: {badges}}) => (badges as BadgesVariant)[type].font[size].lineHeight}px;
`;
