import { FC, useEffect, useMemo } from "react";
import Text from "../../../components/Text";
import TextField from "../../../components/TextField";
import { textTypes } from "../../../styles/typography";
import { Space } from "../../../types/constants";
import { Row, Col } from "../../../components/Grid";
import Table from "../../../components/Table";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../store";
import { useState, useCallback } from "react";
import { TextFieldSize } from "../../../styles/text-field";
import { ReactComponent as SearchSVG } from "../../../assets/icons/search.svg";
import { DateTime } from "luxon";
import { useUsersHistory } from "../../../api/admin/users";
import PermissionType from "../../../types/permissions";

const UsersHistory: FC = () => {
	const { user } = useStore();
	const data = useUsersHistory(
		user.hasPolicy(PermissionType.VIEW_SYSTEM_USER_LOGS),
		300
	);

	const [searchValue, setSearchValue] = useState<string>("");

	const columns = useMemo(
		() => [
			{
				Header: "Id",
				accessor: "id",
				width: 82
			},
			{
				Header: "Username",
				accessor: "username",
				width: 60
			},
			{
				Header: "Action",
				accessor: "message",
			},
			{
				Header: "Date",
				accessor: "createdOn",
				width: 80
			},
		],
		[]
	);

	const onSearchChange = useCallback((value: string | number) => {
		setSearchValue(value as string);
	}, []);

	return (
		<>
			<Row pl={Space.xxxl} pr={Space.xxxl} pt={Space.xl} spaceBetween>
				<Col auto>
					<Text type={textTypes.h3Bold!}>History</Text>
				</Col>
			</Row>
			<Row pl={Space.xxxl} pr={Space.xxxl} pt={Space.xl}>
				<Col md={5} lg={4} xl={3}>
					<TextField
						value={searchValue}
						onChange={onSearchChange}
						size={TextFieldSize.md!}
						prefixIcon={
							<>
								<SearchSVG />
							</>
						}
						placeholder="Search"
						fitWidth
					/>
				</Col>
			</Row>
			<Row pl={Space.xxxl} pr={Space.xxxl} pt={Space.xl}>
				<Table
					columns={columns}
					data={data ?? []}
					height={"calc(100vh - 200px)"}
					filter={searchValue}
				/>
			</Row>
		</>
	);
};

export default observer(UsersHistory);
