import booksCategories from "../../../../types/books-categories";
import countries from "../../../../types/countries";
import currencies from "../../../../types/currencies";
import languages from "../../../../types/languages";
import { ecommerceStores } from "../../../../types/ecommerce-stores";
//import { getEcommerceStores } from "../../../../api/ecommerce-stores";

export enum FieldType {
	Select,
	MultiSelect,
	Date,
	DateRange,
	CurrencyRange,
}

export type Options = { value: unknown; label: string };
export type OptionsFetch = () => unknown;
export type RuleOptions = Array<Options> | OptionsFetch | undefined;
export type RuleData = Array<Options> | Options | string;

export const contactTypeFields: Record<string, Record<string, FieldType>> = {
	obreystore: {
		// Bookland contact type
		isSubscribedToNewsletter: FieldType.Select,
		visitDate: FieldType.DateRange,
		isHasPocketBookDevice: FieldType.Select,
		balance: FieldType.CurrencyRange,
		lastOwnedBookDate: FieldType.Date,
		createdDate: FieldType.DateRange,
		ownedBooksCategory: FieldType.Select,
		language: FieldType.MultiSelect,
		country: FieldType.MultiSelect,
		gender: FieldType.MultiSelect,
	},
	readrate: {
		isSubscribedToNewsletter: FieldType.Select,
		isSubscribedToTestNewsletter: FieldType.Select,
		isSubscribedToMarketingNewsletter: FieldType.Select,
		isSubscribedToPromotionalNewsletter: FieldType.Select,
		isSubscribedToInterviewNewsletter: FieldType.Select,
		isSubscribedToUnsubscribeNewsletter: FieldType.Select,
		readedBookCategory: FieldType.MultiSelect,
		readingBookCategory: FieldType.MultiSelect,
		plannedToReadBookCategory: FieldType.MultiSelect,
		visitDate: FieldType.DateRange,
		language: FieldType.MultiSelect,
		gender: FieldType.MultiSelect,
	},
	ecommerce: {
		visitDate: FieldType.DateRange,
		isActive: FieldType.Select,
		createdDate: FieldType.DateRange,
		subscriptionStatus: FieldType.MultiSelect,
		storeId: FieldType.MultiSelect,
		language: FieldType.MultiSelect,
		country: FieldType.MultiSelect,
	},
};

export const fieldsLabels: Record<string, string> = {
	balance: "Balance",
	language: "Language",
	country: "Country",
	gender: "Gender",
	visitDate: "Visit date",
	createdDate: "Created date",
	lastOwnedBookDate: "Last owned book date",
	ownedBooksCategory: "Owned books category",
	readedBookCategory: "Readed book category",
	readingBookCategory: "Reading book category",
	plannedToReadBookCategory: "Planned to read book category",
	isActive: "Is active",
	subscriptionStatus: "Subcription",
	isHasPocketBookDevice: "Has pocketbook device",
	storeId: "Store",
	isSubscribedToNewsletter: "Subscribed to newsletter",
	isSubscribedToTestNewsletter: "Subscribed to test newsletter",
	isSubscribedToMarketingNewsletter:
		"Subscribed to marketing and communication newsletter",
	isSubscribedToPromotionalNewsletter: "Subscribed to promotional newsletter",
	isSubscribedToInterviewNewsletter: "Subscribed to interview newsletter",
	isSubscribedToUnsubscribeNewsletter: "Unsubscribed from the newsletter",
};

const booleanOptions = [
	{ label: "True", value: true },
	{ label: "False", value: false },
];

const subscriptionOptions = [
	{
		value: 1,
		label: "SUBSCRIBED",
	},
	{
		value: 2,
		label: "NOT_ACTIVE",
	},
	{
		value: 3,
		label: "UNSUBSCRIBED",
	},
	{
		value: 4,
		label: "UNCONFIRMED",
	},
];

const genderOptions = [
	{
		label: "Male",
		value: "male",
	},
	{
		label: "Female",
		value: "female",
	},
];

const booksCategoriesOptions = booksCategories.map((x) => ({
	value: x,
	label: x,
}));

export const fieldsOptions: Record<string, Record<string, RuleOptions>> = {
	obreystore: {
		// Bookland contact type
		isSubscribedToNewsletter: booleanOptions,
		isHasPocketBookDevice: booleanOptions,
		balance: currencies,
		ownedBooksCategory: booksCategoriesOptions,
		language: languages,
		country: countries,
		gender: genderOptions,
	},
	readrate: {
		isSubscribedToNewsletter: booleanOptions,
		isSubscribedToTestNewsletter: booleanOptions,
		isSubscribedToMarketingNewsletter: booleanOptions,
		isSubscribedToPromotionalNewsletter: booleanOptions,
		isSubscribedToInterviewNewsletter: booleanOptions,
		isSubscribedToUnsubscribeNewsletter: booleanOptions,
		readedBookCategory: booksCategoriesOptions,
		readingBookCategory: booksCategoriesOptions,
		plannedToReadBookCategory: booksCategoriesOptions,
		language: languages,
		gender: genderOptions,
	},
	ecommerce: {
		isActive: booleanOptions,
		subscriptionStatus: subscriptionOptions,
		storeId: ecommerceStores, // TODO: Change to this getEcommerceStores,
		language: languages,
		country: countries,
	},
};
