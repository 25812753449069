import { reflectObjectProps } from "../utils/reflection";

export const banners = {
    default: {
        background: "rgba(10, 97, 92, 0.12)",
        font: {
            size: 16,
            weight: 400,
            lineHeight: 24,
        },
        linkColor: 'rgb(35,112,106)',
        padding: "16px",
    },
    info: {
        background: "rgba(88, 135, 218, 0.15)",
        font: {
            size: 16,
            weight: 400,
            lineHeight: 24,
        },
        linkColor: 'rgb(35,112,106)',
        padding: "16px",
    },
    error: {
        background: "rgba(234, 104, 82, 0.15)",
        font: {
            size: 16,
            weight: 400,
            lineHeight: 24,
        },
        linkColor: 'rgb(35,112,106)',
        padding: "16px",
    },
    warning: {
        background: "rgba(255, 167, 43, 0.15)",
        font: {
            size: 16,
            weight: 400,
            lineHeight: 24,
        },
        linkColor: 'rgb(35,112,106)',
        padding: "16px",
    },
    success: {
        background: "rgba(0, 192, 138, 0.15)",
        font: {
            size: 16,
            weight: 400,
            lineHeight: 24,
        },
        linkColor: 'rgb(35,112,106)',
        padding: "16px",
    },
}

export const bannerTypes = reflectObjectProps(banners);