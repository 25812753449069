import { FC, useCallback, useState } from "react";
import { useNavigate } from "react-router";
import HeaderNewCampaign from "../../../../../components/HeaderNewCampaign";
import { Row, Col } from "../../../../../components/Grid";
import { FormSelect as Select } from "../../../../../components/Select";
import { SelectSize } from "../../../../../styles/select";
import {
	FormTextField as TextField,
	TextFieldType,
} from "../../../../../components/TextField";
import { TextFieldSize } from "../../../../../styles/text-field";
import Text from "../../../../../components/Text";
import { textTypes } from "../../../../../styles/typography";
import {
	EditButton,
	InfoContainer,
	Wrapper,
	TemplateContainer,
	TemplateWrapper,
	Card,
	CardImage,
  Container,
} from "./styled";
import { Field, Form } from "react-final-form";
import Button from "../../../../../components/Button";
import { ButtonSize, buttonTypes } from "../../../../../styles/buttons";
import { ReactComponent as EditIcon } from "../../../../../assets/icons/edit.svg";
import Badge from "../../../../../components/Badge";
import {
	BadgeSize,
	badgesTypes,
	BadgeVariant,
} from "../../../../../styles/badges";
import { useStore } from "../../../../../store";
import Scrollbars from "react-custom-scrollbars";
import SendTest from "../../../../../components/Modal/CreateCampaign/SendTest"; // Modal
import Alert from "../../../../../components/Alert";
import { alertTypes, VariantTypes } from "../../../../../styles/alerts";
import {
	CalendarFieldSizes,
	FormCalendarField as CalendarField,
} from "../../../../../components/CalendarField";
import { requiredMin } from "../../../../../validations";
import { observer } from "mobx-react-lite";
import { RecepientsType } from "../../../../../store/campaigns-form";
import { DateTime } from "luxon";
import { Space } from "../../../../../types/constants";
import { getImageSrc } from "../../../../../utils/get-host";

const TemplateView: FC<{
	templates: Array<{
		id?: string;
		name: string;
		image: string;
	}>;
	id?: string | undefined;
}> = ({ templates, id }) => {
	return (
		<>
			<Field name="templateImage">
				{() => (
					<>
						<TemplateWrapper>
							<Scrollbars>
								{templates.map(
									(
										template // изменить
									) =>
										template.id === id ? (
											<Card key={template.id}>
												<Text ml={Space.sm} type={textTypes.h4!}>
													{template.name}
												</Text>
												<CardImage>
													<img src={getImageSrc(template.image)} />
												</CardImage>
											</Card>
										) : (
											<></>
										)
								)}
							</Scrollbars>
						</TemplateWrapper>
					</>
				)}
			</Field>
		</>
	);
};

const SendCampaign: FC = () => {
	const navigate = useNavigate();
	const { campaigns, companies, dynamicGroups, templates } = useStore();
	const [show, setShow] = useState<boolean>(false); // for modal "Send a test"
	const [sent, setSent] = useState<boolean>(false);
	const showTestModal = useCallback(() => {
		// for modal "Send a test"
		setShow(!show);
	}, [show]);

	const sentMessage = useCallback(() => {
		// for alert when click "Send" in "Send a test"
		setShow(false);
		setSent(true);
	}, [sent, show]);

	const closeSentMessage = useCallback(() => {
		// for alert when click "Send" in "Send a test"
		setSent(false);
	}, [sent]);

	const send: Array<any> = [
		{ label: "Send now", value: "SendNow" },
		{ label: "Send later", value: "SendLater" },
	];
	
	const now = DateTime.local();
	const timeZone: Array<any> = [
		{ label: "-09:00 Juneau", value: now.setZone("America/Juneau").toISO()},
		{ label: "-08:00 Los Angeles", value: now.setZone("America/Los_Angele").toISO()},
		{ label: "-07:00 Phoenix", value: now.setZone("America/Phoenix").toISO()},
		{ label: "-06:00 Mexico City", value: now.setZone("America/Mexico_City").toISO()},
		{ label: "-05:00 New York", value:now.setZone("America/New_York").toISO()},
		{ label: "-04:00 Caracas", value: now.setZone("America/Caracas").toISO()},
		{ label: "-03:00 Sao Paulo", value: now.setZone("America/Sao_Paulo").toISO()},
		{ label: "-02:00 Noroha", value: now.setZone("Brazil/Noronha").toISO()},
		{ label: "-01:00 Azores", value: now.setZone("Atlantic/Azores").toISO()},
		{ label: "+00:00 London", value: now.setZone("Europe/London").toISO()},
		{ label: "+01:00 Berlin", value: now.setZone("Europe/Berlin").toISO()},
		{ label: "+02:00 Kyiv", value: now.toISO()}, // 2021-12-15T16:10:04.547+02:00
		{ label: "+03:00 Moscow", value: now.setZone("Europe/Moscow").toISO()},
		{ label: "+04:00 Dubai", value: now.setZone("Asia/Dubai").toISO()},
		{ label: "+05:00 Karachi", value: now.setZone("Asia/Karachi").toISO()},
		{ label: "+06:00 Almaty", value: now.setZone("Asia/Almaty").toISO()},
		{ label: "+07:00 Bangkok", value: now.setZone("Asia/Bangkok").toISO()},
		{ label: "+08:00 Shanghai", value: now.setZone("Asia/Shanghai").toISO()},
		{ label: "+09:00 Tokyo", value: now.setZone("Asia/Tokyo").toISO()},
		{ label: "+10:00 Brisbane", value: now.setZone("Australia/Brisbane").toISO()},
		{ label: "+11:00 Sydney", value: now.setZone("Australia/Sydney").toISO()},
		{ label: "+12:00 Fiji", value: now.setZone("Pacific/Fiji").toISO()},
	];

	const edit = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		switch (event.currentTarget.id) {
			case "subject":
				navigate("/marketing/campaigns/newCampaign/addSubject", {state: {edit: true}});
				break;
			case "recepients":
				navigate("/marketing/campaigns/newCampaign/chooseRecepients");
				break;
			default:
				navigate("/marketing/campaigns/newCampaign/send");
				break;
		}
	};

	const onSubmit = useCallback(async () => {}, []); // change dependencies
	const validate = useCallback(({ time }) => {
		const errors = {
			time: requiredMin(time, 4),
		};
		return errors;
	}, []);
	return (
		<>
			
			<HeaderNewCampaign />
			<Form
				onSubmit={onSubmit}
				validate={validate}
				render={({ handleSubmit, values }) => (
					<form onSubmit={handleSubmit}>
						<Container>
							<Row>
								<Col>
									<Text type={textTypes.h3Bold!}>Send campaign</Text>
								</Col>
							</Row>
							<Row mt={Space.lg}>
								<Col auto>
									<Wrapper id="wrapper" width={500}>
										<Row id="subjectSenderInfo" mb={Space.md} mr={Space.xxl}>
											<InfoContainer>
												<Row>
													<Col>
														<Text type={textTypes.h6Bold!}>
															Subject and sender
														</Text>
													</Col>
														<EditButton id="subject" onClick={edit}>
															<EditIcon />
														</EditButton>
												</Row>
												<Row id="SubjectInfo" mt={Space.sm}>
													<Col>
														<Text
															type={textTypes.bodyMd!}
															customColor="rgba(24, 24, 25, 0.42)"
														>
															Subject
														</Text>
														<Row>
															<Text type={textTypes.bodyMd!}>
																{campaigns.subject?.subject ?? ""} &nbsp;
															</Text>
															<Badge
																variant={BadgeVariant.primary!}
																type={badgesTypes.soft!}
																size={BadgeSize.small}
															>
																Name
															</Badge>
														</Row>
													</Col>
												</Row>
												<Row id="SenderNameInfo" mt={Space.sm}>
													<Col>
														<Text
															type={textTypes.bodyMd!}
															customColor="rgba(24, 24, 25, 0.42)"
														>
															Sender name
														</Text>
														<Text type={textTypes.bodyMd!}>
															{campaigns.subject?.senderName ?? ""}
														</Text>
													</Col>
												</Row>
											</InfoContainer>
										</Row>
										<Row id="recepientsInfo" mb={Space.md} mr={Space.xxl}>
											<InfoContainer>
												<Row>
													<Col>
														<Text type={textTypes.h6Bold!}>Recepients</Text>
													</Col>
													<EditButton id="recepients" onClick={edit}>
														<EditIcon />
													</EditButton>
												</Row>
												<Row mt={Space.sm}>
													<Col>
														<Text type={textTypes.bodyMd!}>
															{campaigns.recepients &&
															campaigns.recepients?.entities
																? campaigns.recepients?.entities?.type! ===
																  RecepientsType.Group
																	? dynamicGroups.entries.get(campaigns.recepients.entities.id)?.name ?? "Group"
																	: companies.entries.get(campaigns.recepients.entities.id)?.name ?? "Company"
																: "..."}
														</Text>
													</Col>
													<Col>
														<Text type={textTypes.bodyMd!} float="right">
															42234
														</Text>
													</Col>
												</Row>
											</InfoContainer>
										</Row>
										<Row id="schedule" mr={Space.xxl}>
											<InfoContainer>
												<Row>
													<Text type={textTypes.bodyLgBold!}>Schedule</Text>
												</Row>
												<Row mt={Space.sm}>
													<Select
														name="send"
														size={SelectSize.md}
														options={send}
														customBackground="#F6F6F6"
													/>
												</Row>
												{values["send"] !== undefined &&
													values["send"].value === send[1].value && (
														<>
															<Row fitWidth mt={Space.sm}>
																<Col mr={Space.tiny}>
																	<Row>
																		<CalendarField
																			name={"date"}
																			placeholder={"Date"}
																			customBackgroundColor="#F6F6F6"
																			size={CalendarFieldSizes.md}
																		/>
																	</Row>
																	<Row mt={Space.xs}>
																		<Select
																			name="time-zone"
																			size={SelectSize.md}
																			options={timeZone}
																			customBackground="#F6F6F6"
																		/>
																	</Row>
																</Col>
																<Col>
																	<TextField
																		name="time"
																		size={TextFieldSize.md}
																		placeholder="12:00"
																		fitWidth
																		type={TextFieldType.time}
																		customBackground="#F6F6F6"
																	/>
																</Col>
															</Row>
														</>
													)}
											</InfoContainer>
										</Row>
									</Wrapper>
								</Col>
								<Col>
									<Row>
										<TemplateContainer>
											<Row>
												<TemplateView templates={templates.array()} id={campaigns.templateId!} />
											</Row>
										</TemplateContainer>
									</Row>
									<Row mt={Space.sm}>
										<Col mr={Space.xs} itemRight>
											<Button
												type="button"
												variant={buttonTypes.softPrimary}
												size={ButtonSize.lg}
												round
												onClick={showTestModal}
											>
												Send a test
											</Button>
										</Col>
										<Col itemRight auto>
											<Button
												type="submit"
												variant={buttonTypes.default}
												size={ButtonSize.lg}
												round
												onClick={() => navigate("/marketing")}
											>
												Send
											</Button>
										</Col>
									</Row>
								</Col>
							</Row>
						</Container>
						<SendTest isOpen={show} onSuccess={sentMessage} onClose={showTestModal} />
						{values["email"] !== undefined && (
										<Alert
										isOpen={sent}
										onClose={closeSentMessage}
										size={alertTypes.comfort_default!}
										variant={VariantTypes.success}
									>
										{`Test email has been sent to ${values["email"][0].value}`}
									</Alert>
						)}
					</form>
				)}
			/>
		</>
	);
};

export default observer(SendCampaign);
