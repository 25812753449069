import { FC, useState, useCallback, useLayoutEffect } from "react";
import Modal from "../..";
import { Container, Close } from "./styled";
import { Row, Col } from "../../../Grid";
import Text from "../../../Text";
import { textTypes } from "../../../../styles/typography";
import { Space } from "../../../../types/constants";
import { ReactComponent as CloseIcon } from "../../../../assets/icons/close.svg";
import CampaignTemplatesModal from "./TemplatesModal";
import CampaignRecepientsModal from "./RecepientsModal";
import Preview from "./PreviewModal";
import SnackBar from "../../../Snackbar";
import { SnackBarPositions } from "../../../../styles/snackbar";
import { useStore } from "../../../../store";
import { RecepientsType } from "../../../../store/campaigns-form";

interface ICalendarCampaignModal {
	isOpen: boolean;
	onClose: () => void;
	storeName?: string;
}

export interface IInfo {
	image?: any;
	templateName?: string | undefined;
	groupName?: string | undefined;
	store: string;
}

const CalendarCampaignModal: FC<ICalendarCampaignModal> = ({
	isOpen,
	onClose,
	storeName = "...",
}) => {
	const { templates, campaigns, dynamicGroups, companies } = useStore();
	const [openSnackBar, setOpenSnackbar] = useState<boolean>(false); // when click send button => open snackbar
	const [chooseRecepients, setChooseRecepients] = useState<boolean>(false); // open chooseRecepients modal
	const [openPreview, setOpenPreview] = useState<boolean>(false); // open preview modal
	const [store, setStore] = useState<string>(storeName);
	const openRecepients = useCallback(() => {
		setStore((prevState) => (storeName ? storeName : prevState));
		setChooseRecepients(!chooseRecepients);
		setOpenPreview(false);
	}, [chooseRecepients, storeName]);

	const showPreview = useCallback(() => {
		setOpenPreview(!openPreview);
	}, [openPreview]);

	const changeEmail = useCallback(() => {
		setChooseRecepients(!chooseRecepients);
	}, [chooseRecepients]);

	const showSnackBar = useCallback(() => {
		setOpenSnackbar(!openSnackBar);
		setChooseRecepients(!chooseRecepients);
		onClose(); // close modal
	}, [openSnackBar, chooseRecepients]);

	const closeSnackBar = useCallback(() => {
		setOpenSnackbar(false);
	}, [openSnackBar]);

	const close = () => {
		onClose(); //close modal
		setTimeout(() => {
			setChooseRecepients(false); // Чтобы не было заметно перехода к темплейтМодал, когда закрываешь окно
			setOpenPreview(false);
		}, 1000);
	};

	return (
		<>
			<Modal onClose={close} isOpen={isOpen} backdrop={true}>
				<Container>
					{!openPreview && (
						<Row ml={Space.lg} mt={Space.lg} mr={Space.lg}>
						<Col mr={Space.basic}>
							<Text type={textTypes.h5Bold!}>
								{chooseRecepients
									? `Choose recepients`
									: `Create an Email for ${storeName || store}`}
							</Text>
						</Col>
						<Col mr={0}>
							<Close onClick={close}>
								<CloseIcon />
							</Close>
						</Col>
					</Row>
					)}
					{(!chooseRecepients && !openPreview && (
						<CampaignTemplatesModal onSelect={openRecepients} onPreview={showPreview}/>
					)) ||
						(chooseRecepients && (
							<CampaignRecepientsModal
								onClose={close}
								changeEmail={changeEmail}
								onSend={showSnackBar}
								storeName={store}
							/>
						)) || (openPreview && <Preview onSelect={openRecepients} onClose={showPreview}/>)}
				</Container>
			</Modal>
			<SnackBar
				isOpen={openSnackBar}
				position={SnackBarPositions.botCenter}
				buttonText="Undo"
				onClick={showSnackBar}
				onClose={closeSnackBar}
			>
				{`${
					templates.entries.get(campaigns.templateId!)?.name
				} campaign have been sent to ${
					campaigns.recepients && campaigns.recepients.entities
						? campaigns.recepients.entities.type === RecepientsType.Group
							? dynamicGroups.entries.get(campaigns.recepients.entities.id)
									?.name ?? "Group"
							: companies.entries.get(campaigns.recepients.entities.id)?.name ??
							  "Companies"
						: "..."
				}`}
			</SnackBar>
		</>
	);
};

export default CalendarCampaignModal;
