import { FC, useCallback, useMemo, useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { getTemplate } from "../../../../api/templates";
import TemplateEditor from "../../../../components/TemplateEditor";

interface ITemplate {
	id?: string;
	name?: string;
	template?: string;
	compiledTemplate?: string;
}

const TemplatesEditor: FC = () => {
	const params = useParams();
	const [template, setTemplate] = useState<ITemplate | undefined>({
		id: params["id"],
	});

	useEffect(() => {
		const id = params["id"];

		if (id) {
			(async () => {
				const data: ITemplate = await getTemplate(id);

				if (data.id) {
					setTemplate(data);
				}
			})();
		}
	}, []);

	return !template?.id || (template?.id && template.template) ? (
		<TemplateEditor
			id={template?.id}
			template={template?.template}
			name={template?.name}
		/>
	) : (
		<></>
	);
};

export default TemplatesEditor;
