import { ReactComponent as ContactCenterIcon } from "../../../assets/icons/contact-center.svg";
import { ReactComponent as ContactsIcon } from "../../../assets/icons/contacts.svg";
import PermissionType from "../../permissions";

export const contactCenterRoutes = {
  label: "contact center",
  categoryIcon: <ContactCenterIcon />,
  items: [
    {
      label: "Contacts",
      path: "/contact-center",
      icon: (
        <>
          <ContactsIcon />
        </>
      ),
      permission: PermissionType.VIEW_CONTACT_CARD,
    },
  ],
};
