import { eCommerceRoutes } from "./e-commerce";
import { marketingRoutes } from "./marketing";
import { legalRoutes } from "./legal";
import { loyalityRoutes } from "./loyality";
import { contactCenterRoutes } from "./contact-center";
import { adminRoutes } from "./admin";
import { ReactComponent as MarketingIcon } from "../../assets/icons/marketing.svg";
import { ReactComponent as ContactCenterIcon } from "../../assets/icons/contact-center.svg";
import { ReactComponent as LegalIcon } from "../../assets/icons/legal.svg";
import { ReactComponent as AdminIcon } from "../../assets/icons/admin-lock.svg";
import { DepartmentType } from "../permissions";

interface IRoute {
	key: string;
	name: string;
	label: string;
	defaultPath: string;
	children: any;
	icon: JSX.Element;
	departmentType: DepartmentType;
}

export const routes: Record<string, IRoute> = {
	marketing: {
		key: "marketing",
		name: "marketing",
		label: "marketing",
		defaultPath: "contacts",
		departmentType: DepartmentType.MARKETING,
		children: marketingRoutes,
		icon: (
			<>
				<MarketingIcon />
			</>
		),
	},
	// eCommerce: {
	// 	key: "eCommerce",
	// 	name: "e-commerce",
	// 	label: "e-commerce",
	// 	defaultPath: "",
	// 	children: eCommerceRoutes,
	// 	icon: (<></>),
	// },
	contactCenter: {
		key: "contactCenter",
		name: "contact-center",
		label: "contact center",
		defaultPath: "",
		departmentType: DepartmentType.CONTACT_CENTER,
		children: contactCenterRoutes,
		icon: (
			<>
				<ContactCenterIcon />
			</>
		),
	},
	// loyality: {
	// 	key: "loyality",
	// 	name: "loyality",
	// 	label: "loyality",
	// 	defaultPath: "",
	// 	children: loyalityRoutes,
	// 	icon: (<></>),
	// },
	// legal: {
	// 	key: "legal",
	// 	name: "legal",
	// 	label: "legal",
	// 	defaultPath: "",
	// 	children: legalRoutes,
	// 	icon: (
	// 		<>
	// 			<LegalIcon />
	// 		</>
	// 	),
	// },
	admin: {
		key: "admin",
		name: "admin",
		label: "admin",
		defaultPath: "",
		departmentType: DepartmentType.ADMIN,
		children: adminRoutes,
		icon: (
			<>
				<AdminIcon />
			</>
		),
	},
};

const getDefaultPath = (route: string | IRoute) => {
	const isRoute = typeof route !== "string";
	const currentRoute: IRoute = isRoute
		? (route as IRoute)
		: routes[route as string];

	return currentRoute.defaultPath
		? `/${currentRoute.name}/${currentRoute.defaultPath}`
		: `/${currentRoute.name}`;
};

const routesNames = Object.keys(routes);
const routesLabels = routesNames.map((x) => routes[x].label);
const routesArray = routesNames.map((x) => ({
	...routes[x],
}));

export default {
	routes: routes,
	routesNames: routesNames,
	routesLabels: routesLabels,
	getDefaultPath: getDefaultPath,
	get routesArray() {
		return routesArray;
	},
};
