export default () => {
	const toggleImages = (components: any, on: any) => {
		const srcPlh = "##";

		components.each((component: any) => {
			if (component.get("type") === "image") {
				const source = component.get("src");

				if (on) {
					if (source === srcPlh) {
						component.set("src", component.get("src_bkp"));
					}
				} else if (source !== srcPlh) {
					component.set("src_bkp", component.get("src"));
					component.set("src", srcPlh);
				}
			}

			toggleImages(component.get("components"), on);
		});
	};

	return {
		run(editor: any) {
			const components = editor.getComponents();
			// @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
			toggleImages(components);
		},
		stop(editor: any) {
			const components = editor.getComponents();
			toggleImages(components, 1);
		},
	};
};
