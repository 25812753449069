import { FC, useLayoutEffect } from "react";
import { useCallback, useState } from "react";
import { createPortal } from "react-dom";
import { TOOLTIP_ROOT_ID } from "../../types/constants";
import { ToolTipContainer } from "./styled";
import { TooltipSide } from "../../styles/tooltips";
import { useMemo } from "react";

interface IUseTooltipData {
	label: string;
	top: number;
	left: number;
	show: boolean;
	width: number; 
	height: number; 
}

type TooltipHoverIn = (event: any) => void;
type TooltipHoverOut = () => void;

export const useTooltipData = (): [
	TooltipHoverIn,
	TooltipHoverOut,
	IUseTooltipData
] => {
	const [data, setData] = useState<IUseTooltipData>({
		label: "",
		top: 0,
		left: 0,
		show: false,
		width: 0, 
		height: 0, 
	});

	const hoverIn = useCallback((event: any) => {
		const { top, left, width, height } = event.target.getBoundingClientRect();
		setData({
			label: event.currentTarget.dataset.tooltip,
			top: top,
			left: left,
			show: true,
			width: width, 
			height: height, 
		});
	}, []);

	const hoverOut = useCallback(() => {
		const { label, top, left, width, height } = data;
		setData({ label, top, left, show: false, width, height });
	}, [data]);

	return [hoverIn, hoverOut, data];
};

const Tooltip: FC<{
	show: boolean;
	label: string;
	top: number;
	left: number;
	width: number; 
	height: number; 
	side: string;
}> = ({ show, label, top, left, width, height, side }) => {
	const tooltipRoot = document.getElementById(TOOLTIP_ROOT_ID);
	const [tooltipSize, setTooltipSize] = useState<{width: number, height: number}>({width: 0, height: 0});
	useLayoutEffect(() => {
		let element = document?.getElementById(`tooltip_${label}`)?.getBoundingClientRect();
		setTooltipSize({width: element?.width! | 0, height: element?.height! | 0});
	}, [label]);
	let tooltipWidth: number = tooltipSize.width;
	let tooltipHeight: number = tooltipSize.height; 
	const pageWidth = document.documentElement.clientWidth; 
	const pageHeight = document.documentElement.clientHeight; 
	const selectedSide = useMemo(() => {
		if (side === TooltipSide.auto) {
			if (pageWidth - (left + width) > tooltipWidth + 8) {
				return TooltipSide.right;
			} else if (pageWidth - (pageWidth - left) > tooltipWidth + 8) {
				return TooltipSide.left;
			} else if (pageHeight - (top + height) > tooltipHeight + 8) {
				return TooltipSide.bottom;
			} else if (pageHeight - (pageHeight - top) > tooltipHeight + 8) {
				return TooltipSide.top;
			}
		}
		else return side;
	}, [side]);

	const position: {posLeft: number, posTop: number} | undefined = useMemo(() => {
		switch (selectedSide) {
			case TooltipSide.bottom:
				return {
					posLeft: left + width / 2 - tooltipWidth / 2,
					posTop: top + height + tooltipHeight / 2 + 8,
				}
			case TooltipSide.top:
				return {
					posLeft: left + width / 2 - tooltipWidth / 2,
					posTop: top - tooltipHeight / 2 - 8,
				}
			case TooltipSide.left:
				return {
					posLeft: left - 8 - tooltipWidth,
					posTop: top + height / 2,
				}
			case TooltipSide.right:
				return {
					posLeft: left + width + 8,
					posTop: top + height / 2,
				}
			default:
				console.log("Error! Side not found. Try to change the (side) value ");
				return {
					posLeft: 0,
					posTop: 0,
				}
		}
	}, [left, top, height, width, tooltipSize]);
	return show ? (
		createPortal(
			<ToolTipContainer id={`tooltip_${label}`} show={show} top={position.posTop} left={position.posLeft} side={selectedSide}>
				{label}
			</ToolTipContainer>,
			tooltipRoot as Element
		)
	) : (
		<></>
	);
};

export default Tooltip;
