import { css } from "styled-components";
import styled from "../../styles/theme";
import { CheckboxSize } from "../../styles/checkbox";

interface ICheckBoxContainer {
  checked: boolean;
  sizeBox: string;
  disabled?: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: fit-content;
  align-items: center;
  justify-content: center;
  margin: 5px;
`;

export const HiddenBox = styled.input.attrs({ type: "checkbox" })`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const WrapperBox = styled.div<ICheckBoxContainer>`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid;
  border-radius: 4px;
  ${({ checked, disabled }) =>
    checked && !disabled
      ? css`
          background-color: ${({ theme: { colors } }) => colors.primary._700};
          border-color: transparent;
        `
      : css`
          background-color: transparent;
          border-color: rgba(145, 146, 148, 1);
        `};
  ${({ sizeBox }) =>
    (sizeBox === CheckboxSize.sm &&
      css`
        width: 14px;
        height: 14px;
      `) ||
    (sizeBox === CheckboxSize.lg &&
      css`
        width: 18px;
        height: 18px;
      `)};
  &:hover {
    cursor: pointer;
    border-color: ${({ theme: { colors } }) => colors.primary._700};
  }
  ${({ disabled }) =>
    disabled &&
    css`
      cursor: default;
      border-color: rgba(222, 222, 223, 1);
      &:hover {
        border-color: rgba(222, 222, 223, 1);
        cursor: default;
      }
    `};
  transition: all 150ms ease;
  margin-right: 15px;
`;

export const Icon = styled.svg`
  fill: none;
  stroke: #fff;
  stroke-width: 2px;
  & > polyline {
    stroke-linecap: round;
  }
`;
