import { FC } from "react";
import Modal from "../../";
import { Container, CloseContainer, Wrapper } from "./styled";
import { Row, Col } from "../../../Grid";
import Text from "../../../Text";
import { textTypes } from "../../../../styles/typography";
import { ReactComponent as CloseIcon } from "../../../../assets/icons/close.svg";
import Button from "../../../Button";
import { ButtonSize, buttonTypes } from "../../../../styles/buttons";
import { FormSelect as Select } from "../../../Select";
import { useStore } from "../../../../store";
import { Space } from "../../../../types/constants";

interface ISendTestModal {
  isOpen?: boolean;
  onSuccess: () => void;
  onClose: () => void;
}

const SendTestModal: FC<ISendTestModal> = ({ isOpen, onClose, onSuccess }) => {

  const { internalContacts } = useStore();
  let emails: ({label: string, value: string})[] = internalContacts.array().map((x) => (
    x && x.email ? {label: x.email, value: x.email} : {label: "", value: ""}
  ));

  return (
    <Modal onClose={onClose} isOpen={isOpen} backdrop={true}>
      <Container>
        <Wrapper>
          <Row mb={Space.xs}>
            <Col>
              <Text type={textTypes.h5Bold!}>Send a test email</Text>
            </Col>
            <Col auto itemRight>
              <CloseContainer onClick={onClose}>
                <CloseIcon />
              </CloseContainer>
            </Col>
          </Row>
          <Row>
          <Col>
            <Select
              name="email"
              label="Recepient"
              isMulti
              placeholder="Email"
              isCreatable
              options={emails}
            />
          </Col>
          </Row>
          <Row mt={Space.sm}>
            <Col mr={Space.xs} itemRight>
              <Button
                type="button"
                size={ButtonSize.lg}
                variant={buttonTypes.outlineSecondary}
                round
                onClick={onClose}
              >
                Cancel
              </Button>
            </Col>
            <Col auto>
              <Button
                type="button"
                size={ButtonSize.lg}
                variant={buttonTypes.default}
                round
                onClick={onSuccess}
              >
                Send
              </Button>
            </Col>
          </Row>
        </Wrapper>
      </Container>
    </Modal>
  );
};

export default SendTestModal;
