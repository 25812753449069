import { FC, useMemo } from "react";
import Text from "../../../components/Text";
import TextField from "../../../components/TextField";
import Button from "../../../components/Button";
import { textTypes } from "../../../styles/typography";
import { Space } from "../../../types/constants";
import { Row, Col } from "../../../components/Grid";
import Table from "../../../components/Table";
import { ButtonSize, buttonTypes } from "../../../styles/buttons";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../store";
import InternalContactSidePage from "../../../components/Modal/InternalContact";
import { useState, useCallback } from "react";
import { TextFieldSize } from "../../../styles/text-field";
import { ReactComponent as SearchSVG } from "../../../assets/icons/search.svg";
import WithPermisions from "../../../components/WithPermisions";
import PermissionType from "../../../types/permissions";

const Contacts: FC = () => {
	const { internalContacts } = useStore();

	const [internalContact, setInternalContact] = useState<{
		id?: string;
		isOpen: boolean;
	}>({
		id: undefined,
		isOpen: false,
	});

	const [searchValue, setSearchValue] = useState<string>("");

	const data = useMemo(
		() =>
			internalContacts.array().map((x) => ({
				id: x.id,
				email: x.email,
				fullName: x.fullName,
				companyPosition: x.companyPosition,
				prefferedLanguage: x.prefferedLanguage,
			})),
		[internalContacts.array()]
	);

	const columns = useMemo(
		() => [
			{
				Header: "Email",
				accessor: "email",
			},
			{
				Header: "Full name",
				accessor: "fullName",
			},
			{
				Header: "Position",
				accessor: "companyPosition",
			},
			{
				Header: "Language",
				accessor: "prefferedLanguage",
			},
		],
		[]
	);

	const createInternalContact = useCallback(() => {
		setInternalContact({
			id: undefined,
			isOpen: true,
		});
	}, []);

	const openInternalContact = useCallback(
		(id: string | undefined = undefined) => {
			setInternalContact({
				id: id,
				isOpen: true,
			});
		},
		[internalContact]
	);

	const closeInternalContact = useCallback(() => {
		setInternalContact({
			id: undefined,
			isOpen: false,
		});
	}, [internalContact]);

	const onSearchChange = useCallback((value: string | number) => {
		setSearchValue(value as string);
	}, []);

	return (
		<>
			<InternalContactSidePage
				id={internalContact.id}
				isOpen={internalContact.isOpen}
				onClose={closeInternalContact}
				onSuccess={closeInternalContact}
			/>
			<Row pl={Space.xxxl} pr={Space.xxxl} pt={Space.xl} spaceBetween>
				<Col auto>
					<Text type={textTypes.h3Bold!}>All Contacts</Text>
				</Col>
				<WithPermisions permissions={[PermissionType.ADD_CONTACT]}>
					<Col auto>
						<Button
							variant={buttonTypes.default!}
							size={ButtonSize.md}
							round
							onClick={createInternalContact}
						>
							ADD CONTACT
						</Button>
					</Col>
				</WithPermisions>
			</Row>
			<Row pl={Space.xxxl} pr={Space.xxxl} pt={Space.xl}>
				<Col md={5} lg={4} xl={3}>
					<TextField
						value={searchValue}
						onChange={onSearchChange}
						size={TextFieldSize.md!}
						prefixIcon={
							<>
								<SearchSVG />
							</>
						}
						placeholder="Search contact"
						fitWidth
					/>
				</Col>
			</Row>
			<Row pl={Space.xxxl} pr={Space.xxxl} pt={Space.xl}>
				<Table
					onClick={(row) => openInternalContact(row.id)}
					columns={columns}
					data={data}
					height={"calc(100vh - 200px)"}
					filter={searchValue}
					placeholder={<Row mt={24} centerItems><Text type={textTypes.captionMd!} alignCenter>No contacts available</Text></Row>}
				/>
			</Row>
		</>
	);
};

export default observer(Contacts);
