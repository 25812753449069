import styled from "../../styles/theme";
import { css } from 'styled-components';
import { fadeIn, fadeOut } from "../../styles/animations";

interface IContainer {
    top?: number;
}

export const Container = styled.div<IContainer>` // TODO :focus 
    position: absolute;
    display: flex;
    height: 32px;
    top: ${({top}) => top !== undefined ? top : 0}px; // +40 отступ, чтобы видно было дату на календаре
    right: 10px;
    left: 10px;
    border: 1px solid #E7E7E8;
    border-radius: 4px;
    background-color: #fff;
    font-size: ${({theme: { typography }}) => typography.bodySm.size};
    font-weight: ${({theme: { typography }}) => typography.bodySm.weight};
    line-height: ${({theme: { typography }}) => typography.bodySm.lineHeight};
    color: rgba(24, 24, 25, 0.9);
    padding: 8px 14px 10px 8px;
    white-space: nowrap;
    overflow: hidden;
    transition: all 300ms ease;
    cursor: pointer;
    & > div {
        pointer-events: none;
    }
    :hover{
        background-color: #F6F6F6;
    }
    &::after {
				transition: background-color 0.2s ease-in-out;
				content: "";
				position: absolute;
				bottom: 0px;
				right: 0px;
				width: 40px;
				height: 95%;
				background: -moz-linear-gradient(
					270deg,
					rgba(255, 255, 255, 1) 0%,
					rgba(246, 246, 246, 0.1) 100%
				);
				background: -webkit-linear-gradient(
					270deg,
					rgba(255, 255, 255, 1) 0%,
					rgba(246, 246, 246, 0.1) 100%
				);
				background: linear-gradient(
					270deg,
					rgba(255, 255, 255, 1) 0%,
					rgba(255, 255, 255, 0.1) 100%
				);
			}
`;

export const Status = styled.div`
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background-color:rgba(0, 192, 138, 1);
    margin-right: 4px;
    margin-top: 4px;
`;

export const OtherContainer = styled.div<IContainer>`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    padding: 0;
    top: ${({top}) => top ? top + 40 : 40}px;
    right: 10px;
    left: 10px;
    border: 1px solid #E7E7E8;
    border-radius: 4px;
    background-color: #fff;
    font-size: ${({theme: { typography }}) => typography.bodySmBold.size};
    font-weight: ${({theme: { typography }}) => typography.bodySmBold.weight};
    line-height: ${({theme: { typography }}) => typography.bodySmBold.lineHeight};
    color: rgba(24, 24, 25, 0.9);
    white-space: nowrap;
    overflow: hidden;
    cursor: pointer;
`;

interface ICalendarOtherEvents{
    top?: number;
    height?: number;
    state?: string;
}

export const CalendarOtherEvents = styled.div<ICalendarOtherEvents>`
    position: absolute;
    display: flex;
    width: 200px;
    height: ${({height}) => height ? height : 200}px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 16px 21px;
    border-radius: 8px;
    box-shadow: 2px 4px 20px 4px rgba(34, 60, 80, 0.11);
    z-index: 999;
    background-color: #fff;
    top: ${({top}) => top !== undefined ? top + 40 : 0}px; // +40 отступ, чтобы видно было дату на календаре
    ${({ state }) =>
    (state === "entering" &&
      css`
        animation: ${fadeIn} 300ms;
      `) ||
    (state === "exiting" &&
      css`
        animation: ${fadeOut} 300ms;
      `)};
`;
