export type DictionaryObject<K, T> = {
	[key in keyof K]?: T;
};

export const reflectObjectProps = <T>(target: T, prefix: string = "") => {
	const reflected: DictionaryObject<typeof target, string> = {};

	Object.keys(target).forEach((x) => {
		(reflected as Record<string, string>)[x] = prefix ? `${prefix}_${x}` : x;
	});

	return reflected;
};
