import {FC} from 'react';
import { useStore } from "../../../store/index";
import { CardsView } from './styled';
import { Row, Col } from '../../Grid';
import CampaignCard from '../index';
import Scrollbars from "react-custom-scrollbars";

interface ITemplateCards {
  onSelect?: () => void; // for card button Select
  onPreview?: () => void; // for card button Preview
}

const TemplateCards: FC<ITemplateCards> = ({onSelect, onPreview}) => {
    const { templates } = useStore();
    return (
      <>
        <CardsView>
          <Scrollbars>
            <Row>
              <Col auto mr={24} mt={24}>
                <CampaignCard newCard id={`newTemplate`} />
              </Col>
              {templates.array().map((x) => (
                <Col key={x.id} auto mr={24} mt={24}>
                  <CampaignCard image={x.image} id={`template_${x.id}`} onSelect={onSelect} onPreview={onPreview}>
                    {x.name}
                  </CampaignCard>
                </Col>
              ))}
            </Row>
          </Scrollbars>
        </CardsView>
      </>
    );
  };

export default TemplateCards;