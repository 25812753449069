import styled, { css } from "styled-components";
import SlideLeft from "../../assets/icons/SlideLeft.svg";
import SlideRight from "../../assets/icons/SlideRight.svg";
import { List } from "react-virtualized";

export const MonthList = styled(List)`
  margin-right: 5px;
  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-track {
    background-color: #fff;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: rgba(0, 0, 0, 0.2);
  }
`;

export const YearList = styled(MonthList)``;

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  background-color: #fdfdfd;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.22);
  border-radius: 8px;
  margin-top: 40px; //MARGIN OF CALENDAR
  margin-bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 61px;
  padding: 20px;
`;

interface ISlider {
  slide: string;
}

export const Slider = styled.div<ISlider>`
  display: flex;
  width: 40px;
  height: 40px;
  background-color: inherit;
  background-repeat: no-repeat;
  background-position: center;
  transition: all 0.5s ease;
  cursor: pointer;
  ${({ slide }) =>
    slide === "left"
      ? css`
          background-image: url(${SlideLeft});
          margin-right: 0px;
          margin-left: auto;
        `
      : css`
          background-image: url(${SlideRight});
          margin-right: 12px;
          margin-left: 0;
        `};
  :hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

export const HeaderText = styled.div`
  display: flex;
  flex-direction: row;
  width: auto;
  height: 20px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: rgba(24, 24, 25, 0.9);
  cursor: pointer;
`;

export const HeaderMonthContainer = styled.div`
  position: absolute;
  z-index: 1;
  width: 140px;
  height: 150px;
  background-color: #fff;
  color: rgba(24, 24, 25, 0.9);
  top: 80px;
  left: 20px;
  border-radius: 4px;
  box-shadow: 2px 4px 20px 4px rgba(34, 60, 80, 0.11);
  padding: 8px 2px 8px 8px;
`;

interface IHeaderMonthItem {
  choose: boolean;
}

export const HeaderMonthItem = styled.div<IHeaderMonthItem>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ choose }) => (choose ? "#f1f1f1" : "inherit")};
  width: 100%;
  height: 30px;
  font-size: ${({ theme: { typography } }) => typography.bodyMdBold.size};
  line-height: ${({ theme: { typography } }) =>
    typography.bodyMdBold.lineHeight};
  transition: all 300ms ease;
  border-radius: 4px;
  :hover {
    background-color: ${({ choose }) => (choose ? "#f1f1f1" : "#f6f6f6")};
  }
`;

 interface IMainContent {
  addNewCampaign?: boolean;
 };

export const MainContent = styled.div<IMainContent>`
  display: grid;
  grid-template-columns: repeat(7, calc(100% / 7));
  grid-template-rows: repeat(6, calc(100% / 6));
  column-gap: 0px;
  row-gap: 0px;
  align-items: flex-start;
  user-select: none;
`;

interface IDay {
  targetDay?: boolean;
}

export const Day = styled.div<IDay>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border: 1px solid;
  border-color: #e6e7e9 #e6e7e9 transparent transparent;
  ${({ targetDay }) =>
    targetDay === true
      ? css`
          background-color: ${({ theme: { colors } }) => colors.primary._120};
        `
      : css`
          background-color: #fff;
        `};
  transition: all 300ms ease;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: rgba(24, 24, 25, 0.9);
  &::after {
    content: "";
    padding-bottom: calc(100%);
  }
  :hover {
    background-color: ${({ theme: { colors } }) => colors.primary._80};
    #currentDay {
      color: rgba(24, 24, 25, 0.42); // My Code
    }
  }
`;

export const CurrentDay = styled(Day)`
  background-color: ${({ theme: { colors } }) => colors.primary._700};
  color: #fff;
  :hover{
	  color: rgba(24, 24, 25, 0.9);
  }
`;

export const DayCellContent = styled.div`
  position: absolute;
  padding-top: 8px;
  text-align: center;
  #currentDay {
    color: #fff; // My Code
  }
  & p {
    font-size: 10px;
    margin: 0;
    color: rgba(24, 24, 25, 0.42);
  }
`;

export const PrevNextDay = styled(Day)<IDay>`
  ${({ targetDay }) =>
    targetDay === true
      ? css`
          background-color: #e6e7e9;
        `
      : css`
          background-color: #f6f6f6;
        `};
  :hover{
    background-color: #e6e7e9;
  }
`;

export const CreateCampaign = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 6px;
  right: 7.5px;
  width: 24px;
  height: 24px;
  border-radius: 24px;
  background-color: ${({ theme: { colors } }) => colors.primary._80};
  cursor: pointer;
`;
