import { FC, useEffect } from "react";
import { BaseSnackBar } from "./styled";
import { SnackBarPositions } from "../../styles/snackbar";
import Button from "../Button";
import { ButtonSize } from "../../styles/buttons";
import { Transition } from "react-transition-group";

interface ISnackBar {
	isOpen: boolean;
	position?: string;
	buttonText: string; // Text of button
	children: string; // Text of snackbar
	onClick?: () => void; // function for button
	onClose: () => void; // close snackbar
}

const SnackBar: FC<ISnackBar> = ({
	isOpen,
	position = SnackBarPositions.botCenter!,
	buttonText,
	children,
	onClick,
	onClose,
}) => {

  useEffect(() => {
	if(isOpen){
		setTimeout(() => {
			onClose();
		}, 5000);
	  }
  }, [isOpen]);

	return (
		<Transition in={isOpen} timeout={200} unmountOnExit>
			{(state) => (
				<BaseSnackBar position={position} state={state}>
					{children}
					<Button onClick={onClick} type="button" round size={ButtonSize.sm}>
						{buttonText}
					</Button>
				</BaseSnackBar>
			)}
		</Transition>
	);
};

export default SnackBar;
