import { FC } from "react";
import Modal from "../..";
import { textTypes } from "../../../../styles/typography";
import { Row, Col } from "../../../Grid";
import Text from "../../../Text";
import { BottomContainer, Container, DeleteIcon } from "./styled";
import { Space } from "../../../../types/constants";
import { Transition } from "react-transition-group";
import { useStore } from "../../../../store";
import { Form } from "react-final-form";
import { useCallback } from "react";
import { useMemo } from "react";
import { FormTextField as TextField, TextFieldType } from "../../../TextField";
import { FormSelect as Select } from "../../../Select";
import { TextFieldSize } from "../../../../styles/text-field";
import Button from "../../../Button";
import { ButtonSize, buttonTypes } from "../../../../styles/buttons";
import { ReactComponent as CloseSVG } from "../../../../assets/icons/close.svg";
import { ReactComponent as DeleteSVG } from "../../../../assets/icons/delete.svg";
import { SelectSize } from "../../../../styles/select";
import { required, requiredArray, requiredMin } from "../../../../validations";
import PermissionType from "../../../../types/permissions";
import countries from "../../../../types/countries";

interface IRoleInfoSidePage {
	id?: string;
	isOpen?: boolean;
	onSuccess: () => void;
	onClose: () => void;
}

interface IRoleInfoForm {
	id?: string;
	name: string;
	regions: Array<{ label: string, value: string }>;
    policies: Array<{ label: string, value: string }>;
}

const permissionsOptions = Object.keys(PermissionType).map((x) => ({ label: x, value: (PermissionType as Record<string, string>)[x]}));
const permissionsLabels = Object.keys(PermissionType).reduce((acc:Record<string, string>, current:string) => {
        return {
            ...acc,
            [(PermissionType as Record<string, string>)[current]]: current
        };
}, {});

const payload = ({ id, name, regions, policies }:IRoleInfoForm) => ({
    id: id,
    name: name,
    regions: regions.map((x) => x.value),
    policies: policies.map((x) => x.value)
});

const RoleInfo: FC<IRoleInfoSidePage> = ({ id, isOpen, onClose, onSuccess }) => {
	const { user, admin } = useStore();
	const currentRole = useMemo(
		() => (id ? admin.roles.get(id) : undefined),
		[id]
	);

	const hasDeletePermission = useMemo(
		() => user.hasPolicy(PermissionType.DELETE_SYSTEM_ROLE),
		[user.permissions.policies]
	);

	const hasEditPermission = useMemo(
		() => user.hasPolicy(PermissionType.EDIT_SYSTEM_ROLE),
		[user.permissions.policies]
	);

	const hasCreatePermission = useMemo(
		() => user.hasPolicy(PermissionType.ADD_SYSTEM_ROLE),
		[user.permissions.policies]
	);

	const onSubmit = useCallback(
		async (values: IRoleInfoForm) => {
			admin.updateRole(payload(values));
			onSuccess();
		},
		[currentRole]
	);

	const validate = useCallback(({ name, policies, regions }: IRoleInfoForm) => {
		const errors = {
			name: required(name) || requiredMin(name, 4),
			policies: requiredArray(policies),
			regions: requiredArray(regions)
		};

		return errors;
	}, []);

	const deleteRole = useCallback(async () => {
		if (currentRole) {
			admin.deleteRole(currentRole.id);
		}
		onClose();
	}, [currentRole]);

	const initialValues = useMemo(() => {
		return currentRole ? {
            id: currentRole.id,
            name: currentRole.name,
            regions: currentRole.regions.map((x) => ({ label: countries.find((country) => country.value === x)?.label, value: x})),
            policies: currentRole.policies.map((x) => ({ label: permissionsLabels[x], value: x}))
        } as unknown as IRoleInfoForm : { name: "", regions: [], policies: []};
	}, [currentRole]);

	return (
		<Modal onClose={onClose} isOpen={isOpen} backdrop={true}>
			<Transition in={isOpen} timeout={250} unmountOnExit>
				{(state) => (
					<Container state={state}>
						<Row mt={Space.lg} ml={Space.lg} mr={Space.lg}>
							<Row fitWidth>
								<Col>
									<Text type={textTypes.h3Bold!}>
										{id ? "Role info" : "Create role"}
									</Text>
								</Col>
								<Col itemRight>
									<Button
										size={ButtonSize.sm}
										variant={buttonTypes.softSecondary}
										onClick={onClose}
										icon
									>
										<CloseSVG />
									</Button>
								</Col>
							</Row>
							<Col pt={Space.lg}>
								<Form
									initialValues={initialValues}
									validate={validate}
									onSubmit={onSubmit}
									render={({ handleSubmit }) => (
										<form onSubmit={handleSubmit}>
											<Row>
												<TextField
													name="name"
													label="Name"
													size={TextFieldSize.sm}
													fitWidth
												/>
											</Row>
                                            <Row mt={16}>
												<Select
													name="policies"
													label="Permissions"
                                                    options={permissionsOptions}
                                                    isMulti
												/>
											</Row>
                                            <Row mt={16}>
												<Select
													name="regions"
													label="Regions"
                                                    options={countries}
													isMulti
												/>
											</Row>
											<BottomContainer>
												<Row pt={16} pl={24} pr={24}>
													<Col itemRight>
														{id ? (
															<>
																{hasDeletePermission && (
																	<Button
																		type="button"
																		size={ButtonSize.md}
																		variant={buttonTypes.softSecondary}
																		onClick={deleteRole}
																		mr={16}
																		icon
																	>
																		<DeleteIcon>
																			<DeleteSVG />
																		</DeleteIcon>
																	</Button>
																)}
																{hasEditPermission && (
																	<Button
																		type="submit"
																		size={ButtonSize.md}
																		variant={buttonTypes.outlinePrimary}
																		onClick={() => {}}
																	>
																		UPDATE
																	</Button>
																)}
															</>
														) : (
															<>
																{hasCreatePermission && (
																	<Button
																		type="submit"
																		size={ButtonSize.md}
																		variant={buttonTypes.outlinePrimary}
																		onClick={() => {}}
																	>
																		CREATE
																	</Button>
																)}
															</>
														)}
													</Col>
												</Row>
											</BottomContainer>
										</form>
									)}
								/>
							</Col>
						</Row>
					</Container>
				)}
			</Transition>
		</Modal>
	);
};

export default RoleInfo;
