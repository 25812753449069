import styled from "../../styles/theme";
import { css, keyframes } from "styled-components";
import { fadeIn, fadeOut } from "../../styles/animations";
import { paddingProps } from "../../styles/helpers";
import { IPadding } from "../../types/styles";

interface IContainer {
  newCard?: boolean;
}

export const Container = styled.div<IContainer>`
  display: flex;
  flex-direction: column;
  width: 246px;
  ${({ newCard }) =>
    (newCard &&
      css`
        align-items: center;
        justify-content: center;
        height: 376px;
        background-color: ${({ theme: { colors } }) => colors.primary._40};
        border: 1px dashed;
        border-color: ${({ theme: { colors } }) => colors.primary._160};
        &:hover {
          background-color: ${({ theme: { colors } }) => colors.primary._80};
        }
        transition: all 300ms ease;
      `) ||
    css`
      width: 246px;
      border: 1px solid rgba(74, 74, 74, 0.16);
    `};
  border-radius: 8px;
  padding: 0;
  cursor: pointer;
  overflow: hidden;
`;

interface IHover extends IPadding {
  leave: boolean;
}

export const Hover = styled.div<IHover>`
  ${paddingProps}
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(100% - 80px);
  height: 100%;
  background-color: ${({ leave }) =>
    leave ? "rgba(24, 24, 25, 0.42)" : "transparent"};
  ${({ leave }) =>
    leave
      ? css`
          opacity: 1;
          animation: ${fadeIn} 300ms;
        `
      : css`
          opacity: 0;
          animation: ${fadeOut} 300ms;
        `};
  transition: all 300ms ease;
`;

interface IImageContainer {
  children?: any;
}

export const ImageContainer = styled.div<IImageContainer>`
  position: relative;
  height: 304px;
  ${({ children }) =>
    children === undefined &&
    css`
      background-color: rgba(225, 236, 235, 1);
    `};
  padding: 0;
`;

export const InfoContainer = styled.div`
  position: relative;
  height: 72px;
  background-color: ${({ theme: { colors } }) => colors.white};
  background-color: #f1f1f1; // delete after confirm
`;
