import { reflectObjectProps } from "../utils/reflection";

export enum VariantTypes {
    info = "info",
    error = "error",
    warning = "warning",
    success = "success",
};

export const alerts = {
    detailed_primary: {
      radius: 8,
      border: 1,
      borderColor: {
        info: "rgba(88, 135, 218, 0.5)",
        error: "rgba(234, 104, 82, 0.5)",
        warning: "rgba(255, 167, 43, 0.5)",
        success: "rgba(0, 192, 138, 0.5)",
      },
      background: {
        info: "rgba(88, 135, 218, 0.15)",
        error: "rgba(234, 104, 82, 0.15)",
        warning: "rgba(255, 167, 43, 0.15)  ",
        success: "rgba(0, 192, 138, 0.15)",
      },
      color: "rgba(24, 24, 25, 0.9)",
      buttonColor: {
        info: "rgba(88, 135, 218, 1)",
        error: "rgba(234, 104, 82, 1)",
        warning: "rgba(255, 167, 43, 1)",
        success: "rgba(0, 192, 138, 1)",
      },
      padding: "20px",
      font:{
        weight: 400,
        size: 14,
        height: 20,
      },
    },
    detailed_default: {
      radius: 8,
      border: 1,
      borderColor: {
        info: "rgba(88, 135, 218, 0.5)",
        error: "rgba(234, 104, 82, 0.5)",
        warning: "rgba(255, 167, 43, 0.5)",
        success: "rgba(0, 192, 138, 0.5)",
      },
      background: {
        info: "#fff",
        error: "#fff",
        warning: "#fff  ",
        success: "#fff",
      },
      color: "rgba(24, 24, 25, 0.9)",
      buttonColor: {
        info: "rgba(88, 135, 218, 1)",
        error: "rgba(234, 104, 82, 1)",
        warning: "rgba(255, 167, 43, 1)",
        success: "rgba(0, 192, 138, 1)",
      },
      padding: "20px",
      font:{
        weight: 400,
        size: 14,
        height: 20,
      },
    },
    comfort_primary: {
      radius: 8,
      border: 1,
      borderColor: {
        info: "rgba(88, 135, 218, 0.5)",
        error: "rgba(234, 104, 82, 0.5)",
        warning: "rgba(255, 167, 43, 0.5)",
        success: "rgba(0, 192, 138, 0.5)",
      },
      background: {
        info: "rgba(88, 135, 218, 0.15)",
        error: "rgba(234, 104, 82, 0.15)",
        warning: "rgba(255, 167, 43, 0.15)",
        success: "rgba(0, 192, 138, 0.15)",
      },
      color: "rgba(24, 24, 25, 0.9)",
      buttonColor: {
        info: "rgba(88, 135, 218, 1)",
        error: "rgba(234, 104, 82, 1)",
        warning: "rgba(255, 167, 43, 1)",
        success: "rgba(0, 192, 138, 1)",
      },
      padding: "16px",
      font:{
        weight: 400,
        size: 16,
        height: 24,
      },
    },
    comfort_default: {
      radius: 8,
      border: 1,
      borderColor: {
        info: "rgba(88, 135, 218, 0.5)",
        error: "rgba(234, 104, 82, 0.5)",
        warning: "rgba(255, 167, 43, 0.5)",
        success: "rgba(0, 192, 138, 0.5)",
      },
      background: {
        info: "#fff",
        error: "#fff",
        warning: "#fff",
        success: "#fff",
      },
      color: "rgba(24, 24, 25, 0.9)",
      buttonColor: {
        info: "rgba(88, 135, 218, 1)",
        error: "rgba(234, 104, 82, 1)",
        warning: "rgba(255, 167, 43, 1)",
        success: "rgba(0, 192, 138, 1)",
      },
      padding: "16px",
      font:{
        weight: 400,
        size: 16,
        height: 24,
      },
    },
    compact_primary: {
      radius: 4,
      border: 0,
      borderColor: {
        info: "none",
        error: "none",
        warning: "none",
        success: "none",
      },
      background: {
        info: "rgba(88, 135, 218, 0.15)",
        error: "rgba(234, 104, 82, 0.15)",
        warning: "rgba(255, 167, 43, 0.15)",
        success: "rgba(0, 192, 138, 0.15)",
      },
      color: "rgba(24, 24, 25, 0.9)",
      buttonColor: {
        info: "rgba(88, 135, 218, 1)",
        error: "rgba(234, 104, 82, 1)",
        warning: "rgba(255, 167, 43, 1)",
        success: "rgba(0, 192, 138, 1)",
      },
      padding: "12px",
      font:{
        weight: 400,
        size: 14,
        height: 20,
      },
    },
    compact_default: {
      radius: 4,
      border: 0,
      borderColor: {
        info: "none",
        error: "none",
        warning: "none",
        success: "none",
      },
      background: {
          info: "rgba(246, 246, 246, 1)",
          error: "rgba(246, 246, 246, 1)",
          warning: "rgba(246, 246, 246, 1)",
          success: "rgba(246, 246, 246, 1)",
      },
      color: "rgba(24, 24, 25, 0.9)",
      buttonColor: {
        info: "rgba(88, 135, 218, 1)",
        error: "rgba(234, 104, 82, 1)",
        warning: "rgba(255, 167, 43, 1)",
        success: "rgba(0, 192, 138, 1)",
      },
      padding: "12px",
      font:{
        weight: 400,
        size: 14,
        height: 20,
      },
    },
};

export const alertTypes = reflectObjectProps(alerts);
