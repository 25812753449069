export enum SnackBarPositions {
    topLeft = "top-left",
    topCenter = "top-center",
    topRight = "top-right",
    midLeft = "middle-left",
    midCenter = "middle-center",
    midRight = "middle-right",
    botLeft = "bottom-left",
    botCenter = "bottom-center",
    botRight = "bottom-right",
};