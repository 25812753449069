import styled from 'styled-components';

export const Container = styled.div`
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	right: 0px;
	width: 1152px;
	height: 642px;
	background: #F6F6F6;
	overflow: hidden;
	border-radius: 8px;
`;

export const Close = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    background-color: rgba(74, 74, 74, 0.04);
    transition: all 300ms ease;
    :hover{
        background-color: rgba(74, 74, 74, 0.1);
    };
    border-radius: 24px;
    cursor: pointer;
`;