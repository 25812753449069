import { FC, useEffect, useState } from "react";
import Modal from "../..";
import { textTypes } from "../../../../styles/typography";
import { Row, Col } from "../../../Grid";
import Text from "../../../Text";
import {
	BottomContainer,
	CloseIconContainer,
	Container,
	DeleteIcon,
} from "./styled";
import { Space } from "../../../../types/constants";
import { Transition } from "react-transition-group";
import { useStore } from "../../../../store";
import { Form } from "react-final-form";
import { useCallback } from "react";
import { useMemo } from "react";
import { FormTextField as TextField, TextFieldType } from "../../../TextField";
import { FormSelect as Select } from "../../../Select";
import { FormCheckbox as Checkbox } from "../../../Checkbox";
import { TextFieldSize } from "../../../../styles/text-field";
import Button from "../../../Button";
import { ButtonSize, buttonTypes } from "../../../../styles/buttons";
import { ReactComponent as CloseSVG } from "../../../../assets/icons/close.svg";
import { ReactComponent as DeleteSVG } from "../../../../assets/icons/delete.svg";
import { SelectSize } from "../../../../styles/select";
import { required, requiredMin } from "../../../../validations";
import PermissionType from "../../../../types/permissions";
import { Tabs, Tab } from "../../../Tabs";
import { getUserHistory } from "../../../../api/admin/users";
import Table from "../../../Table";
import { DateTime } from "luxon";

interface IUserInfoSidePage {
	id?: string;
	isOpen?: boolean;
	onSuccess: () => void;
	onClose: () => void;
}

interface IUserInfoForm {
	id?: string;
	username: string;
	password?: string;
	role: { label: string; value: string };
	changePassword?: boolean;
}

const payload = ({ role, ...rest }: IUserInfoForm) => ({
	...rest,
	role: role.value,
});

const UserInfo: FC<IUserInfoSidePage> = ({
	id,
	isOpen,
	onClose,
	onSuccess,
}) => {
	const { user, admin } = useStore();
	const currentUser = useMemo(
		() => (id ? admin.users.get(id) : undefined),
		[id]
	);

	const isNew = useMemo(
		() => !Boolean(currentUser && currentUser.id),
		[currentUser]
	);

	const [userLogs, setUserLogs] = useState<
		Array<{ message: string; modifiedOn: string }>
	>([]);

	useEffect(() => {
		if (currentUser && currentUser.id) {
			(async () => {
				const logs = await getUserHistory(currentUser.id);

				setUserLogs(logs);
			})();
		}
	}, [currentUser]);

	const data = useMemo(
		() =>
			userLogs.map((x) => ({
				message: x.message,
				date: DateTime.fromISO(x.modifiedOn).toISODate(),
			})),
		[userLogs]
	);

	const columns = useMemo(
		() => [
			{
				Header: "Action",
				accessor: "message",
			},
			{
				Header: "Date",
				accessor: "date",
				width: 60,
			},
		],
		[]
	);

	const roles = useMemo(() => {
		let result =
			admin.rolesArray.map((x) => ({ label: x.name, value: x.name })) ?? [];
		result.unshift({ label: "Admin", value: "Admin" });

		return result;
	}, [admin.rolesArray]);

	const hasDeletePermission = useMemo(
		() => user.hasPolicy(PermissionType.DELETE_SYSTEM_USER),
		[user.permissions.policies]
	);

	const hasEditPermission = useMemo(
		() => user.hasPolicy(PermissionType.EDIT_SYSTEM_USER),
		[user.permissions.policies]
	);

	const hasCreatePermission = useMemo(
		() => user.hasPolicy(PermissionType.ADD_SYSTEM_USER),
		[user.permissions.policies]
	);

	const onSubmit = useCallback(
		async (values: IUserInfoForm) => {
			admin.updateUser(payload(values));
			onSuccess();
		},
		[currentUser]
	);

	const validate = useCallback(
		({ username, password, role, changePassword }: IUserInfoForm) => {
			const errors = {
				username: required(username) || requiredMin(username, 2),
				password: currentUser
					? changePassword
						? required(password!) || requiredMin(password!, 4)
						: undefined
					: required(password!) || requiredMin(password!, 4),
				role: required(role)
			};

			return errors;
		},
		[currentUser]
	);

	const deleteUser = useCallback(async () => {
		if (currentUser) {
			admin.deleteUser(currentUser.id);
		}
		onClose();
	}, [currentUser]);

	const initialValues = useMemo(() => {
		return currentUser
			? {
					id: currentUser.id,
					username: currentUser.username,
					role: { label: currentUser.role, value: currentUser.role },
			  }
			: {};
	}, [currentUser]);

	return (
		<Modal onClose={onClose} isOpen={isOpen} backdrop={true}>
			<Transition in={isOpen} timeout={250} unmountOnExit>
				{(state) => (
					<Container state={state}>
						<Row mt={Space.lg} ml={Space.lg} mr={Space.lg}>
							{id ? (
								<>
									<CloseIconContainer>
										<Button
											size={ButtonSize.sm}
											variant={buttonTypes.softSecondary}
											onClick={onClose}
											icon
										>
											<CloseSVG />
										</Button>
									</CloseIconContainer>
									<Tabs centerTabs>
										<Tab name="Info">
											<Col pt={Space.lg}>
												<Form
													initialValues={initialValues}
													validate={validate}
													onSubmit={onSubmit}
													render={({
														handleSubmit,
														values: { changePassword },
													}) => (
														<form onSubmit={handleSubmit}>
															<Row>
																<TextField
																	name="username"
																	label="Username"
																	size={TextFieldSize.sm}
																	fitWidth
																/>
															</Row>
															{(isNew || (!isNew && changePassword)) && (
																<Row mt={16}>
																	<TextField
																		type={TextFieldType.password}
																		name="password"
																		label="Password"
																		size={TextFieldSize.sm}
																		fitWidth
																	/>
																</Row>
															)}
															{!isNew && (
																<Row mt={16}>
																	<Checkbox
																		name="changePassword"
																		label="Change password"
																	/>
																</Row>
															)}
															<Row mt={16}>
																<Select
																	name="role"
																	label="Role"
																	options={roles}
																/>
															</Row>
															<BottomContainer>
																<Row pt={16} pl={24} pr={24}>
																	<Col itemRight>
																		{id ? (
																			<>
																				{hasDeletePermission && (
																					<Button
																						type="button"
																						size={ButtonSize.md}
																						variant={buttonTypes.softSecondary}
																						onClick={deleteUser}
																						mr={16}
																						icon
																					>
																						<DeleteIcon>
																							<DeleteSVG />
																						</DeleteIcon>
																					</Button>
																				)}
																				{hasEditPermission && (
																					<Button
																						type="submit"
																						size={ButtonSize.md}
																						variant={buttonTypes.outlinePrimary}
																						onClick={() => {}}
																					>
																						UPDATE
																					</Button>
																				)}
																			</>
																		) : (
																			<>
																				{hasCreatePermission && (
																					<Button
																						type="submit"
																						size={ButtonSize.md}
																						variant={buttonTypes.outlinePrimary}
																						onClick={() => {}}
																					>
																						CREATE
																					</Button>
																				)}
																			</>
																		)}
																	</Col>
																</Row>
															</BottomContainer>
														</form>
													)}
												/>
											</Col>
										</Tab>
										<Tab name="History">
											<Row mt={24}>
												{data.length > 0 ? (
													<Table
														columns={columns}
														data={data}
														height={"calc(100vh - 120px)"}
													/>
												) : (
													<Col center>
														<Text alignCenter type={textTypes.captionMd!}>
															No logs available
														</Text>
													</Col>
												)}
											</Row>
										</Tab>
									</Tabs>
								</>
							) : (
								<>
									<Row fitWidth>
										<Col>
											<Text type={textTypes.h3Bold!}>Create user</Text>
										</Col>
										<Col itemRight>
											<Button
												size={ButtonSize.sm}
												variant={buttonTypes.softSecondary}
												onClick={onClose}
												icon
											>
												<CloseSVG />
											</Button>
										</Col>
									</Row>
									<Col pt={Space.lg}>
										<Form
											initialValues={initialValues}
											validate={validate}
											onSubmit={onSubmit}
											render={({
												handleSubmit,
												values: { changePassword },
											}) => (
												<form onSubmit={handleSubmit}>
													<Row>
														<TextField
															name="username"
															label="Username"
															size={TextFieldSize.sm}
															fitWidth
														/>
													</Row>
													{(isNew || (!isNew && changePassword)) && (
														<Row mt={16}>
															<TextField
																type={TextFieldType.password}
																name="password"
																label="Password"
																size={TextFieldSize.sm}
																fitWidth
															/>
														</Row>
													)}
													{!isNew && (
														<Row mt={16}>
															<Checkbox
																name="changePassword"
																label="Change password"
															/>
														</Row>
													)}
													<Row mt={16}>
														<Select name="role" label="Role" options={roles} />
													</Row>
													<BottomContainer>
														<Row pt={16} pl={24} pr={24}>
															<Col itemRight>
																{id ? (
																	<>
																		{hasDeletePermission && (
																			<Button
																				type="button"
																				size={ButtonSize.md}
																				variant={buttonTypes.softSecondary}
																				onClick={deleteUser}
																				mr={16}
																				icon
																			>
																				<DeleteIcon>
																					<DeleteSVG />
																				</DeleteIcon>
																			</Button>
																		)}
																		{hasEditPermission && (
																			<Button
																				type="submit"
																				size={ButtonSize.md}
																				variant={buttonTypes.outlinePrimary}
																				onClick={() => {}}
																			>
																				UPDATE
																			</Button>
																		)}
																	</>
																) : (
																	<>
																		{hasCreatePermission && (
																			<Button
																				type="submit"
																				size={ButtonSize.md}
																				variant={buttonTypes.outlinePrimary}
																				onClick={() => {}}
																			>
																				CREATE
																			</Button>
																		)}
																	</>
																)}
															</Col>
														</Row>
													</BottomContainer>
												</form>
											)}
										/>
									</Col>
								</>
							)}
						</Row>
					</Container>
				)}
			</Transition>
		</Modal>
	);
};

export default UserInfo;
