import styled, { css } from "styled-components";
import { fadeIn, fadeOut } from "../../styles/animations";
import { default as BaseText } from "../Text";

export const Container = styled.div`
	width: 100%;
`;

interface ICalendarContainer{
	calendarRight?: boolean;
}

export const CalendarContainer = styled.div<ICalendarContainer>`
	width: 340px;
	border-radius: 4px;
	${({ calendarRight }) => calendarRight && "margin-left: -150px;"}
	background-color: white;
	box-shadow: 0px 0px 3px 0px rgb(0, 0, 0, 0.56);
	
	& > div {
		margin-top: 16px;
	}
`;

export const FieldContainer = styled.div`
	width: 100%;
`;

interface IPopover{
	state?: string;
	position?: number;
}

export const Popover = styled.div<IPopover>`
	position: absolute;
	z-index: 1;
	float: right;
	${({position}) => Boolean(position) && css` // vlad change
		bottom: ${position}px;
	`};
	${({ state }) =>
		(state === "entering" &&
			css`
				animation: ${fadeIn} 300ms;
			`) ||
		(state === "exiting" &&
			css`
				animation: ${fadeOut} 300ms;
			`)}
`;

export const Text = styled(BaseText)`
	position: relative;
	top: -6px;
	left: 16px;
	font-weight: 400;
`;
