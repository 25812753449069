import styled from "../../../../../styles/theme";
import { css } from "styled-components";

export const TemplateContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: #fff;
  color: rgba(24, 24, 25, 0.9);
  height: calc(100vh - 150px);
  width: 100%;
  margin: 26px 208px auto 208px;
  user-select: none;
  text-align: center;
  @media (max-width: 1600px) {
    margin-left: 150px;
    margin-right: 150px;
  }
  @media (max-width: 1300px) {
    margin-left: 100px;
    margin-right: 100px;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 72px;
  background-color: #fff;
  padding: 16px 192px;
  user-select: none;
  @media (max-width: 1600px) {
    padding-left: 100px;
    padding-right: 100px;
  }
  @media (max-width: 1400px) {
    padding-left: 70px;
    padding-right: 70px;
  }
`;

interface IIconContainer {
  selected?: boolean;
  mr?: number;
  exit?: boolean;
}

export const IconContainer = styled.div<IIconContainer>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: rgba(65, 65, 65, 0.04);
  border-radius: 32px;
  cursor: pointer;
  transition: all 300ms ease;
  margin-right: ${({ mr }) => (mr ? mr : 0)}px;
  ${({ selected }) =>
    selected &&
    css`
      background-color: ${({ theme: { colors } }) => colors.primary._700};
      & > svg path {
        fill: #fff;
      }
    `};
  ${({ exit }) =>
    exit &&
    css`
      :hover {
        background-color: rgba(65, 65, 65, 0.1);
      }
    `}
`;
