const getHost = () => {
	const env = Boolean(process.env.REACT_APP_LOCAL_DEV)
		? "local-development"
		: process.env.NODE_ENV;

	switch (env) {
		case "production":
			return "https://api.crm.pocketbook.ch/";
		case "development":
			return "http://localhost:5000/";
		case "local-development":
			return "http://localhost:5000/";
		default:
			return "https://api.crm.pocketbook.ch/";
	}
};

const host = getHost();

export const getImageSrc = (imageId: string) =>
	imageId ? `${host}api/Files/template/img/${imageId}` : "";

export default getHost;
