import useSWR from "swr";
import { ENDPOINTS } from "../../types/Endpoints";
import authFetch from "../../utils/auth-fetch";

const rolesFetcher = (url: string) => {
	return authFetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
		},
	}).then((r) => r.json());
};

export const useAdminRoles = (hasPermission: boolean) => {
	const { data } = useSWR(
		hasPermission ? ENDPOINTS.ADMIN.ROLES.GET_ALL : null,
		rolesFetcher
	);

	return data;
};

export const updateRole = async (data: {
	id?: string;
	name: string;
	regions: Array<string>;
	policies: Array<string>;
}) => {
	const json = await (
		await authFetch(
			data.id
				? `${ENDPOINTS.ADMIN.ROLES.ADD}/${data.id}`
				: ENDPOINTS.ADMIN.ROLES.ADD,
			{
				method: data.id ? "PUT" : "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(data),
			}
		)
	).json();

	if (data.id) {
		return json;
	}

	const newId = json && json.split(" ");

	return newId[1] ? newId[1].slice(0, -1) : newId[0]; // id from response
};

export const deleteRole = async (id: string) => {
	const json = await (
		await authFetch(`${ENDPOINTS.ADMIN.ROLES.GET}/${id}`, {
			method: "DELETE",
			headers: {
				"Content-Type": "application/json",
			},
		})
	).json();

	return json;
};
