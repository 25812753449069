import styled, { css } from "styled-components";
import { slideInFromRight, slideOutToRight } from "../../../../styles/animations";

interface IContainer {
	state?: string;
}

export const Container = styled.div<IContainer>`
	position: fixed;
	top: 0px;
	right: 0px;
	right: 0px;
	width: 460px;
	box-sizing: border-box;
	height: 100%;
	background: #f6f6f6;
	overflow: hidden;

	${({ state }) =>
		(state === "entered" &&
			css`
				animation: ${slideInFromRight} 250ms;
			`) ||
		(state === "exiting" &&
			css`
				animation: ${slideOutToRight} 250ms;
			`)}
`;

export const BottomContainer = styled.div`
	position: absolute;
	height: 72px;
	width: 100%;
	margin-left: -24px;
	background: #fff;
	bottom: 0;
`;

export const DeleteIcon = styled.div`
	& svg path {
		fill: #ea5c52;
	}

	&:hover {
		svg path {
			fill: #ea6852;
		}
	}
`;

export const CloseIconContainer = styled.div`
	position: absolute;
	right: 16px;
	z-index: 1;
`;
