import {
  FC,
  useCallback,
  useEffect,
  useState,
} from "react";
import VirtualList from "../List";
import { Field, FieldRenderProps } from "react-final-form";
import { components, IndicatorProps, MultiValueProps, ValueContainerProps} from "react-select";
import { IMargin, IPadding } from "../../types/styles";
import { SelectSize } from "../../styles/select";
import { ReactComponent as DownIconSVG } from "../../assets/icons/arrow_down.svg";
import {
  Container,
  Label,
  DefaultSelect,
  CreatableSelect,
  AsyncSelect,
  WarningMessage,
  ListContainer,
} from "./styled";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";

interface ISelect extends IMargin, IPadding {
  name?: string;
  placeholder?: string;
  error?: string;
  label?: string;
  value?: string;
  size?: SelectSize;
  xsSize?: SelectSize;
  smSize?: SelectSize;
  isMulti?: boolean;
  isSearchable?: boolean;
  isDisabled?: boolean;
  isCreatable?: boolean;
  borderColor?: string;
  customBackground?: string;
  textColor?: string;
  prefixIcon?: JSX.Element,
  options?: Array<{ value: string; label: string }>;
  asyncOptions?: () => Promise<Array<{ value: string; label: string }>>;
  loadOptions?: (
    input: string
  ) => Promise<Array<{ value: string; label: string }>>;
  onChange: (event: string) => void;
}

interface IFormSelect extends IMargin, IPadding {
  name: string;
  placeholder?: string;
  label?: string;
  isMulti?: boolean;
  isCreatable?: boolean;
  isSearchable?: boolean;
  isDisabled?: boolean;
  options?: Array<{ value: string; label: string }>;
  borderColor?: string;
  customBackground?: string;
  textColor?: string;
  prefixIcon?: JSX.Element,
  asyncOptions?: () => Promise<Array<{ value: string; label: string }>>;
  loadOptions?: (
    input: string
  ) => Promise<Array<{ value: string; label: string }>>;
  parse?: (value: any) => any;
  size?: SelectSize;
  xsSize?: SelectSize;
  smSize?: SelectSize;
}

interface IFormSelectWrapper
  extends FieldRenderProps<string>,
    IMargin,
    IPadding {
  name?: string;
  placeholder?: string;
  isCreatable?: boolean;
  isSearchable?: boolean;
  isMulti?: boolean;
  isDisabled?: boolean;
  borderColor?: string;
  customBackground?: string;
  textColor?: string;
  prefixIcon?: JSX.Element,
  options?: Array<{ value: string; label: string }>;
  asyncOptions?: () => Promise<Array<{ value: string; label: string }>>;
  loadOptions?: (
    input: string
  ) => Promise<Array<{ value: string; label: string }>>;
  size?: SelectSize;
  xsSize?: SelectSize;
  smSize?: SelectSize;
  label?: string;
}

export const DropdownIndicator: FC<IndicatorProps<any, any>> = ({
  ...props
}) => {
  return (
    <components.DropdownIndicator {...props}>
      <DownIconSVG />
    </components.DropdownIndicator>
  );
};

const ValueContainer:FC<ValueContainerProps<any, any, any>> = ({ children, selectProps: { prefixIcon, ...selectProps }, ...props }) => {
  return (
    components.ValueContainer && (
      <components.ValueContainer {...props} selectProps={selectProps}>
        { Boolean(prefixIcon) ? (
          <>{prefixIcon}</>
        ) : <></>}
        {children}
      </components.ValueContainer>
    )
  );
};

export const MultiValueRemove: FC<MultiValueProps<any>> = ({ ...props }) => {
  return (
    <components.MultiValueRemove {...props}>
      <CloseIcon />
    </components.MultiValueRemove>
  );
};

const MenuList = (props: any) => {
  const items = props.children || [];

  return items.length > 9 ? (
    <ListContainer>
      <VirtualList
        rowCount={items.length}
        rowHeight={40}
        rowRenderer={({ index, key, style }) => {
          return (
            <div key={key} style={style}>
              {items[index]}
            </div>
          );
        }}
      />
    </ListContainer>
  ) : (
    props.children
  );
};

export const Select: FC<ISelect> = ({
  name,
  placeholder,
  label,
  error,
  onChange,
  size,
  xsSize,
  smSize,
  value,
  options,
  isMulti,
  isCreatable,
  isDisabled,
  isSearchable = true,
  asyncOptions,
  loadOptions,
  borderColor,
  customBackground,
  textColor,
  prefixIcon,
  ...rest
}) => {
  const [loadedOptions, setLoadedOptions] =
    useState<Array<{ value: string; label: string }>>();
  const change = useCallback(
    (value) => {
      onChange(value);
    },
    [onChange]
  );

  useEffect(() => {
    if (asyncOptions) {
      (async () => {
        const list = await asyncOptions();

        setLoadedOptions(list);
      })();
    }
  }, [asyncOptions]);

  return (
    <Container {...rest}>
      {label && <Label error={Boolean(error)}>{label}</Label>}
      {loadOptions ? (
        <AsyncSelect
          name={name}
          components={{
            DropdownIndicator,
            IndicatorSeparator: () => null,
            MenuList,
            MultiValueRemove,
          }}
          cacheOptions
          classNamePrefix="select"
          placeholder={placeholder}
          error={Boolean(error)}
          onChange={change}
          size={size!}
          customBackground={customBackground}
          borderColor={borderColor}
          xsSize={xsSize}
          smSize={smSize}
          value={value}
          isMulti={isMulti}
          isDisabled={isDisabled}
          loadOptions={loadOptions}
        />
      ) : isCreatable ? (
        <CreatableSelect
          name={name}
          components={{
            DropdownIndicator,
            IndicatorSeparator: () => null,
            MenuList,
            MultiValueRemove,
          }}
          classNamePrefix="select"
          placeholder={placeholder}
          error={Boolean(error)}
          onChange={change}
          size={size!}
          customBackground={customBackground}
          borderColor={borderColor}
          xsSize={xsSize}
          smSize={smSize}
          value={value}
          isMulti={isMulti}
          isDisabled={isDisabled}
          menuPlacement="auto"
          options={Boolean(asyncOptions) ? loadedOptions || [] : options}
        />
      ) : (
        <DefaultSelect
          name={name}
          components={{
            DropdownIndicator,
            IndicatorSeparator: () => null,
            MenuList,
            ValueContainer,
            MultiValueRemove,
          }}
          classNamePrefix="select"
          placeholder={placeholder}
          error={Boolean(error)}
          onChange={change}
          size={size!}
          customBackground={customBackground}
          borderColor={borderColor}
          xsSize={xsSize}
          smSize={smSize}
          value={value}
          isSearchable={isSearchable}
          isMulti={isMulti}
          prefixIcon={prefixIcon}
          isDisabled={isDisabled}
          menuPlacement="auto"
          options={Boolean(asyncOptions) ? loadedOptions || [] : options}
          tex
        />
      )}
      {error && <WarningMessage>{error}</WarningMessage>}
    </Container>
  );
};

const SelectFormWrapper: FC<IFormSelectWrapper> = ({
  input: { onChange, value },
  onChange: afterChange,
  meta,
  borderColor,
  customBackground,
  ...rest
}) => {
  return (
    <Select
      value={value!}
      onChange={onChange}
      borderColor={borderColor}
      customBackground={customBackground}
      error={
        (meta.touched && meta.error) ||
        (!meta.dirtySinceLastSubmit && meta.submitError)
      }
      {...rest}
    />
  );
};

export const FormSelect: FC<IFormSelect> = ({ ...props }) => {
  return <Field {...props} component={SelectFormWrapper as any} />;
};

export default FormSelect;
