import styled from "styled-components";

export const Container = styled.div`
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	right: 0px;
	width: 1152px; // change 978px
	height: 642px;
	//background: #ffffff;
	background: #F6F6F6; // vlad changes
	overflow: hidden;
	border-radius: 8px; // vlad changes
`;

export const CenterItems = styled.div`
	position: relative;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: fit-content;
`;

export const CustomTemplateContainer = styled.div`
	width: 206px;
	height: 200px;
	border: 1px solid rgba(0, 0, 0, 0.3);
	box-sizing: border-box;
	border-radius: 8px;
	cursor: pointer;
`;

export const AutomationTemplateContainer = styled.div`
	width: 292px;
	height: 135px;
	border: 1px solid rgba(0, 0, 0, 0.3);
	box-sizing: border-box;
	border-radius: 8px;
	cursor: pointer;
	padding: 0 12px;
	position: relative;
`;

export const EventDescription = styled.div`
	position: absolute;
	bottom: 10px;
`;

export const ArrowIconWrap = styled.div`
	width: 18px;
	height: 18px;
`;

export const AutomationsContainer = styled.div`
	width: 100%;
	position: relative;
	overflow: hidden;
	height: 476px;
`;

