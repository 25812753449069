import { FC, useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import HeaderNewCampaign from "../../../../../components/HeaderNewCampaign";
import { Row, Col } from "../../../../../components/Grid";
import Text from "../../../../../components/Text";
import { textTypes } from "../../../../../styles/typography";
import Button from "../../../../../components/Button";
import { ButtonSize, buttonTypes } from "../../../../../styles/buttons";
import { Form } from "react-final-form";
import { Tabs, Tab } from "../../../../../components/Tabs";
import { ReactComponent as GroupsIcon } from "../../../../../assets/icons/groups.svg";
import { ReactComponent as CompaniesIcon } from "../../../../../assets/icons/companies.svg";
import Table from "../../../../../components/Table";
import { useStore } from "../../../../../store";
import { Container } from "./styled";
import { observer } from "mobx-react-lite";
import { Icon } from "../../../../../components/Checkbox/styled";
import { Circle } from "../../../../../components/Steps/styled";
import { ReactComponent as SearchIcon } from "../../../../../assets/icons/search.svg";
import TextField from "../../../../../components/TextField";
import { TextFieldSize } from "../../../../../styles/text-field";
import { RecepientsType } from "../../../../../store/campaigns-form";
import { Space } from "../../../../../types/constants";

const ChooseRecepients: FC = () => {
  const navigate = useNavigate();
  const { dynamicGroups, companies, campaigns: { recepients } } = useStore();
  const [searchValue, setSearchValue] = useState<string>("");

  const groupsData = useMemo(
    () =>
      dynamicGroups.array().map((x) => ({
        id: x.id,
        name: x.name,
        contactType: x.contactType === "obreystore" ? "Bookland" : x.contactType,
      })),
    [dynamicGroups.array()]
  );

  const companiesData = useMemo(
    () =>
      companies.array().map((x) => ({
        id: x.id,
        name: x.name,
        contactType: "...",
      })),
    [companies.array()]
  );

  const columns = useMemo(
    () => [
      { Header: "GROUP NAME", accessor: "name" },
      { Header: "CONTACT TYPE", accessor: "contactType", pr: 135, alignRight: true },
    ],
    []
  );

  let submit = null; // delete after finish work
  const onSubmit = useCallback(async (values: any) => {
    navigate("/marketing/campaigns/newCampaign/send")
  }, [submit]);


  const onGroupRowClick = useCallback(
    (data: any) => {
      const entityId = recepients && recepients.entities && recepients.entities.id || "";
      if (data.id !== entityId) {
        recepients?.setEntity({id: data.id, type: RecepientsType.Group});
      } else {
        recepients?.setEntity(null);
      }
    },
    [recepients]
  );

  const onCompaniesRowClick = useCallback(
    (data: any) => {
      if (data.id !== recepients?.entities?.id) {
        recepients?.setEntity({id: data.id, type: RecepientsType.Company});
      } else {
        recepients?.setEntity(null);
      }
    },
    [recepients]
  );

  const selectedColumns = useMemo( 
    () => [
      (data: any) =>
        recepients?.entities?.id === data.id ? (
          <Circle confirm={true}>
            <Icon viewBox="0 0 24 24">
              <polyline points="6,13 10,17 18,8" />
            </Icon>
          </Circle>
        ) : (
          <></>
        ),
    ],
    [recepients?.entities?.id]
  );

  const onSearchChange = useCallback((value: string | number) => {
    setSearchValue(value as string);
  }, []);

  return (
    <>
      <HeaderNewCampaign />
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Container>
              <Row>
                <Col>
                  <Text type={textTypes.h3Bold!}>Choose recipients</Text>
                </Col>
              </Row>
              <Row ml={0} mt={Space.lg}>
                <TextField
                  value={searchValue}
                  onChange={onSearchChange}
                  size={TextFieldSize.md}
                  placeholder="Search group or company"
                  fitWidth
                  customBackground="#EFEFEF"
                  prefixIcon={
                    <>
                      <SearchIcon />
                    </>
                  }
                />
              </Row>
              <Row mt={Space.lg}>
                <Tabs>
                  <Tab name="Groups" icon={<GroupsIcon />}>
                    <Row mt={Space.lg}>
                      <Table
                        data-id="table_groups"
                        columns={columns}
                        data={groupsData}
                        height={"50vh"}
                        truncateContent={false}
                        onClick={onGroupRowClick}
                        prefixColumns={selectedColumns}
                        filter={searchValue}
                      />
                    </Row>
                  </Tab>
                  <Tab name="Companies" icon={<CompaniesIcon />}>
                    <Row mt={Space.lg}>
                      <Table
                        columns={columns}
                        data={companiesData}
                        height={"50vh"}
                        truncateContent={false}
                        onClick={onCompaniesRowClick}
                        prefixColumns={selectedColumns}
                        filter={searchValue}
                      />
                    </Row>
                  </Tab>
                </Tabs>
              </Row>
              <Row mt={Space.lg}>
                <Col itemRight>
                  <Button
                    type="submit"
                    variant={buttonTypes.default}
                    size={ButtonSize.lg}
                    round
                  >
                    Continue
                  </Button>
                </Col>
              </Row>
            </Container>
          </form>
        )}
      />
    </>
  );
};

export default observer(ChooseRecepients);
