import styled from "../../../styles/theme";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`;

export const SendAction = styled.div`
    opacity: 0;
    transition: 300ms;

    & path{
        fill: rgba(0,0,0, 0.68);
    }

    div[role="row"]:hover &{
        opacity: 1;
    }

    &:hover path{
        fill: rgba(0,0,0, 0.9);
    }
`;

export const TestButton = styled.button`
    width: 200px;
    height: 40px;
    outline: none;
    background-color: ${({theme: {colors}}) => colors.primary._700};
    color: #fff;
`;