import styled from "../../../../styles/theme";
import { css } from "styled-components";
import { baseProps } from "../../../../styles/helpers";

export const Container = styled.div`
	${baseProps}
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: calc(100% - 248px);
	overflow-x: none;
	background-color: inherit;
	box-sizing: border-box;
`;

interface ITriggerContainer {
	left?: number;
	start?: boolean;
	show?: boolean;
}

export const TriggerContainer = styled.div<ITriggerContainer>`
	${baseProps}
	position: relative;
	left: ${({left}) => left}px;
	display: ${({ show }) => (show || show === undefined ? "flex" : "none")};
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 225px;
	border: 1px solid;
	padding: 24px;
	border-radius: 8px;
	box-sizing: border-box;
	text-align: center;
	text-align-last: center;
	font-size: ${({ theme: { typography }}) => typography.bodyMd.size};
	font-weight: ${({ theme: { typography }}) => typography.bodyMd.weight};
	line-height: ${({ theme: { typography }}) => typography.bodyMd.lineHeight};
	${({ start }) =>
		!start
			? css`
					color: rgba(24, 24, 25, 0.9);
					background-color: #fff;
					border-color: #e7e7e8;
					cursor: default; 
			  `
			: css`
					font-weight: ${({ theme: { typography }}) => typography.bodyMdBold.weight};
					color: ${({ theme: { colors } }) => colors.primary._900};
					background-color: ${({ theme: { colors } }) => colors.primary._80};
					border-color: ${({ theme: { colors } }) => colors.primary._700};
					border-style: dashed;
					cursor: pointer;
					:hover {
						background-color: ${({ theme: { colors } }) => colors.primary._120};
					}
			  `}
	transition: all 300ms ease;
`;

export const DelayContainer = styled(TriggerContainer)`
	flex-direction: row;
	border-radius: 24px;
	padding: 10px 40px;
`;

export const YesContainer = styled.div`
	display: flex;
	position: relative;
	align-items: center;
	justify-content: center;
	width: 225px;
	border: 1px solid;
	padding: 24px;
	border-radius: 8px;
	box-sizing: border-box;
	text-align: center;
	text-align-last: center;
	left: 221px;
	color: rgba(24, 24, 25, 0.9);
	background-color: #fff;
	border-color: #e7e7e8;
	cursor: default;
`;

export const NoContainer = styled.div`
	display: flex;
	position: relative;
	align-items: center;
	justify-content: center;
	width: 225px;
	border: 1px solid;
	padding: 24px;
	border-radius: 8px;
	box-sizing: border-box;
	text-align: center;
	text-align-last: center;
	left: -221px;
	color: rgba(24, 24, 25, 0.9);
	background-color: #fff;
	border-color: #e7e7e8;
	cursor: default;
`;

interface IAddBlockContainer {
	left?: boolean;
	right?: boolean;
}

export const AddBlockContainer = styled.div<IAddBlockContainer>`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 32px;
	height: 32px;
	border-radius: 16px;
	background-color: #e7e7e8;
	${({ right, left }) =>
		(left &&
			css`
				transform: translateX(-50%);
			`) ||
		(right &&
			css`
				right: -100%;
				transform: translateX(-50%);
			`)};
	cursor: pointer;
	& > svg {
		& > path {
			fill: rgba(24, 24, 25, 0.42);
		}
	}
	:hover {
		background-color: ${({ theme: { colors } }) => colors.primary._120};
		& > svg {
			& > path {
				fill: ${({ theme: { colors } }) => colors.primary._900};
			}
		}
	}
	transition: all 300ms ease;
	//pointer-events: none;
`;

interface INextStepContainer {
	left?: number;
}

export const NextStepContainer = styled.div<INextStepContainer>`
	display: flex;
	position: relative;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 568px;
	height: 100%;
	padding: 24px 32px 32px 32px;
	box-sizing: border-box;
	border-radius: 8px;
	background-color: #fff;
	left: ${({ left }) => left && left}px;
	margin-top: 14px;
	box-shadow: 0px 4px 12px rgba(60, 73, 87, 0.04),
		0px 6px 17px rgba(60, 73, 87, 0.16);
	:after {
		position: absolute;
		content: "";
		top: -11px;
		left: 50%;
		transform: translateX(-50%);
		border-left: 20px solid transparent;
		border-right: 20px solid transparent;
		border-bottom: 20px solid #fff;
	}
`;

export const NextStepItem = styled.div`
	${baseProps};
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	width: 112px;
	height: 112px;
	border: 1px solid #e7e7e8;
	border-radius: 8px;
	color: rgba(24, 24, 25, 0.9);
	font-size: ${({ theme: { typography } }) => typography.bodyMdBold.size};
	font-weight: ${({ theme: { typography } }) => typography.bodyMdBold.weight};
	line-height: ${({ theme: { typography } }) =>
		typography.bodyMdBold.lineHeight};
	& > svg {
		margin-bottom: 4px;
		& > path {
			stroke: ${({ theme: { colors } }) => colors.primary._700};
		}
	}
	:hover {
		background-color: ${({ theme: { colors } }) => colors.primary._120};
		color: rgba(10, 97, 92, 0.7);
	}
	transition: all 300ms ease;
	cursor: pointer;
`;

export const ConditionContainer = styled(TriggerContainer)`
	position: relative;
	top: 20px;
`;

interface IConditionYesNo {
	no?: boolean;
	yes?: boolean;
};

export const VerticalDevider = styled.div<IConditionYesNo>`
	height: 32px;
	width: 1px;
	background-color: ${({ yes, no }) =>
		yes ? "rgb(0, 192, 138)" : no ? "rgb(234, 104, 82)" : "#e7e7e8"};
`;

export const ConditionDevider = styled.div<IConditionYesNo>`
	position: relative;
	display: flex;
	width: 108.5px;
	height: 48px;
	border-top: 1px solid;
	font-size: ${({ theme: { typography } }) => typography.bodySmBold.size};
	font-weight: ${({ theme: { typography } }) => typography.bodySmBold.weight};
	line-height: ${({ theme: { typography } }) =>
		typography.bodySmBold.lineHeight};
	margin-top: 50%;
	:after {
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 41px;
		height: 24px;
		color: #fff;
		z-index: 99;
		border-radius: 100px;
	}
	${({ yes, no }) =>
		(yes &&
			css`
				border-right: 1px solid;
				border-color: rgba(0, 192, 138, 0.5);
				border-top-right-radius: 8px;
				:after {
					content: "Yes";
					top: -12px;
					right: 30%;
					background-color: rgb(0, 192, 138);
				}
			`) ||
		(no &&
			css`
				border-left: 1px solid;
				border-color: rgba(234, 104, 82, 0.5);
				border-top-left-radius: 8px;
				:after {
					position: absolute;
					content: "No";
					top: -12px;
					left: 30%;
					background-color: rgb(234, 104, 82);
				}
			`)};
`;

export const ConditionIconContainer = styled.div`
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 30px;
	height: 30px;
	top: -16px;
	border-radius: 4px;
	border: 1px solid;
	border-color: ${({ theme: { colors } }) => colors.primary._700};
	background-color: #fff;
	transform: rotate(45deg);
	& > svg {
		width: 12.4px;
		height: 12.4px;
		transform: rotate(-45deg);
		& path {
			stroke: ${({ theme: { colors } }) => colors.primary._700};
		}
	}
`;

export const CloseContainer = styled.div`
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 24px;
	height: 24px;
	top: 8px;
	right: 8px;
	border-radius: 24px;
	background-color: rgba(74, 74, 74, 0.04);
	transition: all 300ms ease;
	cursor: pointer;
	:hover {
		background-color: rgba(74, 74, 74, 0.1);
	}
`;
