import { FC, useCallback, useEffect, useState } from "react";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import { ReactComponent as ErrorIcon } from "../../assets/icons/error.svg";
import { ReactComponent as InfoIcon } from "../../assets/icons/info.svg";
import { ReactComponent as SuccessIcon } from "../../assets/icons/success.svg";
import { ReactComponent as WarningIcon } from "../../assets/icons/warning.svg";
import { alertTypes, VariantTypes } from "../../styles/alerts";
import {
  BaseAlert,
  Close,
  Content,
  ControlButton,
  ControlContainer,
  Icon,
  Title
} from "./styled";
import { Transition } from "react-transition-group";

interface IAlert {
  isOpen: boolean;
  onClose: () => void; //close Alert
  size: string; // detailed, comfort, compact
  variant: string; // info, error, warning, success
  titleText?: string;
  onLearn?: () => void; //onClick button Learn more
  onContactSupport?: () => void; // onClick button Contact Support
  children: JSX.Element | string;
};

const Alert: FC<IAlert> = ({
  isOpen,
  onClose,
  size = alertTypes!.detailed_primary,
  variant = VariantTypes.info,
  titleText,
  onLearn,
  onContactSupport,
  children
}) => {
  const icons: Array<JSX.Element> = [
    <InfoIcon />,
    <ErrorIcon />,
    <WarningIcon />,
    <SuccessIcon />,
  ];
  const [timeId, setTimeId] = useState<ReturnType<typeof setTimeout>>();
  useEffect(() => {
      if(isOpen){
        setTimeId(global.setTimeout(onClose, 5000));
      } else {
        clearTimeout(timeId!);
      }
  }, [isOpen]);

  return (
    <Transition in={isOpen} timeout={200} unmountOnExit>
      {state => (
        <BaseAlert size={size!} variant={variant} state={state}>
        <Icon size={size!}>
          {Object.values(VariantTypes).map(
            (item, index) => item === variant && icons[index]
          )}
        </Icon>
        <Content>
          {size === "detailed_primary" || size === "detailed_default" ? (
            <>
              {Boolean(titleText) && <Title>{titleText}</Title>}
              {children}
              <ControlContainer>
                <ControlButton
                  size={size!}
                  variant={variant}
                  onClick={onLearn}
                >
                  Learn More
                </ControlButton>
                <ControlButton
                  size={size!}
                  variant={variant}
                  onClick={onContactSupport}
                >
                  Contact Support
                </ControlButton>
              </ControlContainer>
            </>
          ) : (
            children
          )}
        </Content>
        <Close size={size!} onClick={onClose}>
          <CloseIcon />
        </Close>
      </BaseAlert>
      )}
    </Transition>
  );
};

export default Alert;

