import styled from 'styled-components';

export const Container = styled.div`
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
`;

export const SendAction = styled.div`
    opacity: 0;
    transition: 300ms;

    & path{
        fill: rgba(0,0,0, 0.68);
    }

    div[role="row"]:hover &{
        opacity: 1;
    }

    &:hover path{
        fill: rgba(0,0,0, 0.9);
    }
`;