import useSWR from "swr";
import { ENDPOINTS } from "../types/Endpoints";
import authFetch from "../utils/auth-fetch";

const groupsFetcher = (url: string) => {
	return authFetch(url).then((r) => r.json());
};

export const useDynamicGroups = (hasPermission: boolean) => {
	const { data } = useSWR(
		hasPermission ? ENDPOINTS.GROUPS.GET_GROUP : null,
		groupsFetcher
	);

	return data;
};

export const uploadDynamicGroup = async (data: any) => {
	const { visitDate, createdDate } = data;
	const json = await (
		await authFetch(ENDPOINTS.GROUPS.ADD_GROUP, {
			method: data.id ? "PUT" : "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				...data,
				visitDate: visitDate && [
					{
						isNot: false,
						value: { from: visitDate.from, to: visitDate.to },
					},
				],
				createdDate: createdDate && [
					{
						isNot: false,
						value: { from: createdDate.from, to: createdDate.to },
					},
				],
			}),
		})
	).json();

	if (data.id) {
		return json;
	}

	const newId = json && json.split(" ");

	return newId[1] ? newId[1].slice(0, -1) : newId[0]; // id from response
};

interface ISendGroupEmail {
	groupId: string;
	title: string;
	email: string;
	templateId: string;
}

export const sendEmailsByGroup = async (data: ISendGroupEmail) => {
	const json = await (
		await authFetch(ENDPOINTS.GROUPS.SEND_EMAIL, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(data),
		})
	).json();

	return json;
};

export const getGroupContactsCount = async (id: string) => {
	const json = await (
		await authFetch(`${ENDPOINTS.GROUPS.COUNT}/${id}`)
	).json();

	return json;
};

export const deleteDynamicGroup = async (id: string) => {
	const json = await (
		await authFetch(`${ENDPOINTS.GROUPS.ADD_GROUP}/${id}`, {
			method: "DELETE",
			headers: {
				"Content-Type": "application/json",
			},
		})
	).json();

	return json;
};
