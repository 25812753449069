import { FC } from "react";
import { Container, Devider } from "./styled";
import { Row, Col } from "../../Grid";
import Text from "../../Text";
import Badge from "../../Badge";
import { textTypes } from "../../../styles/typography";
import { BadgeSize, BadgeStatus, badgesTypes } from "../../../styles/badges";
import { Space } from "../../../types/constants";
import Button from "../../Button";
import { ButtonSize, buttonTypes } from "../../../styles/buttons";
import { Transition } from "react-transition-group";

interface ICalendarEventModal {
	top?: number;
	isOpen?: boolean;
}

const CalendarEventModal: FC<ICalendarEventModal> = ({ top, isOpen }) => {
	// TODO get real info and set it below
	return (
		<>
			<Transition in={isOpen} timeout={200} unmountOnExit>
				{(state) => (
					<Container id="CalendarEventModal" top={top} state={state}>
						<Row pt={Space.sm} pl={Space.sm} pr={Space.sm}>
							<Row>
								<Col>
									<Text type={textTypes.bodyLgBold!}>
										Company news update about new products
									</Text>
								</Col>
								<Col itemRight auto>
									<Badge
										size={BadgeSize.xSmall!}
										type={badgesTypes.soft!}
										status={BadgeStatus.sent}
										round
									/>
								</Col>
							</Row>
							<Row fitWidth mt={Space.xs}>
								<Col>
									<Text
										type={textTypes.captionSm!}
										customColor="rgba(24, 24, 25, 0.42)"
									>
										Type
									</Text>
								</Col>
								<Col>
									<Text
										type={textTypes.captionSm!}
										customColor="rgba(24, 24, 25, 0.42)"
									>
										Group
									</Text>
								</Col>
							</Row>
							<Row fitWidth mt={Space.basic}>
								{/* import info */}
								<Col>Email</Col>
								<Col>Main Group</Col>
							</Row>
							<Row fitWidth mt={Space.xs}>
								<Col>
									<Text
										type={textTypes.captionSm!}
										customColor="rgba(24, 24, 25, 0.42)"
									>
										Sent
									</Text>
								</Col>
							</Row>
							<Row fitWidth mt={Space.basic}>
								{/* import info */}
								<Col>Tue, September 5th at 4:22 PM Kyiv</Col>
							</Row>
						</Row>
						<Devider />
						<Row fitWidth>
							<Col ml={Space.sm}>
								<Row>
									1920 {/* import info */}{" "}
									<Text
										type={textTypes.bodySmBold!}
										customColor="rgba(24, 24, 25, 0.42)"
									>
										Sends
									</Text>
								</Row>
							</Col>
							<Col>
								<Row>
									73.9% {/* import info */}
									<Text
										type={textTypes.bodySmBold!}
										customColor="rgba(24, 24, 25, 0.42)"
									>
										Opens
									</Text>
								</Row>
							</Col>
							<Col mr={Space.sm}>
								<Row>
									64.3% {/* import info */}
									<Text
										type={textTypes.bodySmBold!}
										customColor="rgba(24, 24, 25, 0.42)"
									>
										Clicks
									</Text>
								</Row>
							</Col>
						</Row>
						<Devider />
						<Row fitWidth>
							<Col mr={Space.xs} mb={Space.xs} ml={Space.xs} itemRight>
								<Button
									variant={buttonTypes.outlineSecondary}
									type="button"
									round
									size={ButtonSize.sm}
									mr={Space.xs}
									onClick={() => console.log("close")}
								>
									CLOSE
								</Button>
								<Button
									variant={buttonTypes.default}
									type="button"
									round
									size={ButtonSize.sm}
								>
									VIEW REPORT
								</Button>
							</Col>
						</Row>
					</Container>
				)}
			</Transition>
		</>
	);
};

export default CalendarEventModal;
