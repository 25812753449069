import { FC, Fragment, useEffect, useState } from "react";
import { Container, Circle, Text, Devider } from "./styled";
import { Icon } from "../Checkbox/styled";
import { useLocation } from "react-router-dom";

interface ISteps {
	steps: Array<Record<string, string>>; // [{label: "last word of pathname", value: "name of page"}]
}

const Steps: FC<ISteps> = ({ steps }) => {
	const location = useLocation();
	const [id, setId] = useState<number>(0);
	let path = location!.pathname.match(/\w+$/i)?.shift();

	useEffect(() => {
		steps.map((item, index) => {
			if (item.label === path) {
				setId(index);
			}
		});
	}, [steps]);

	return (
		<Container>
			{steps.map((item, index) => {
				return (
					<Fragment key={`${index}`}>
						{(index < id && (
							<>
								<Circle confirm={true}>
									<Icon viewBox="0 0 24 24">
										<polyline points="6,13 10,17 18,8" />
									</Icon>
								</Circle>
								<Text confirm={true}>{item.value}</Text>
							</>
						)) ||
							(index === id && (
								<>
									<Circle confirm={true}>{index + 1}</Circle>
									<Text confirm={true}>{item.value}</Text>
								</>
							)) ||
							(index > id && (
								<>
									<Circle confirm={false}>{index + 1}</Circle>
									<Text confirm={false}>{item.value}</Text>
								</>
							))}
						{index === steps.length - 1 ? null : <Devider />}
					</Fragment>
				);
			})}
		</Container>
	);
};

export default Steps;
