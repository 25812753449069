import { FC } from "react";
import Modal from "..";
import { textTypes } from "../../../styles/typography";
import { Row, Col } from "../../Grid";
import Text from "../../Text";
import { BottomContainer, Container, DeleteIcon } from "./styled";
import { Space } from "../../../types/constants";
import { Transition } from "react-transition-group";
import { useStore } from "../../../store";
import { Form } from "react-final-form";
import { useCallback } from "react";
import { useMemo } from "react";
import { FormTextField as TextField, TextFieldType } from "../../TextField";
import { FormSelect as Select } from "../../Select";
import { TextFieldSize } from "../../../styles/text-field";
import Button from "../../Button";
import { ButtonSize, buttonTypes } from "../../../styles/buttons";
import { ReactComponent as CloseSVG } from "../../../assets/icons/close.svg";
import { ReactComponent as DeleteSVG } from "../../../assets/icons/delete.svg";
import { SelectSize } from "../../../styles/select";
import { required, requiredMin } from "../../../validations";
import Table from "../../Table";
import PermissionType from "../../../types/permissions";

interface ICompanySidePage {
	id?: string;
	isOpen?: boolean;
	onSuccess: () => void;
	onClose: () => void;
}

interface ICompanyForm {
	id?: string;
	name: string;
	webAddress?: string;
	responsible?: string;
	phoneNumber?: string;
	category?: string;
	industry?: string;
	address?: {
		main?: string;
		another?: string;
		country?: string;
		region?: string;
		city?: string;
		index?: string;
	};
	comuntication?: {
		number?: string;
		fax?: string;
		email?: string;
		facebook?: string;
		twitter?: string;
	};
}

const Company: FC<ICompanySidePage> = ({ id, isOpen, onClose, onSuccess }) => {
	const { user, companies } = useStore();
	const currentCompany = useMemo(
		() => (id ? companies.entries.get(id) : undefined),
		[id]
	);

	const hasDeletePermission = useMemo(
		() => user.hasPolicy(PermissionType.DELETE_COMPANY),
		[user.permissions.policies]
	);

	const hasEditPermission = useMemo(
		() => user.hasPolicy(PermissionType.EDIT_COMPANY),
		[user.permissions.policies]
	);

	const hasCreatePermission = useMemo(
		() => user.hasPolicy(PermissionType.ADD_COMPANY),
		[user.permissions.policies]
	);

	const onSubmit = useCallback(
		async (values: ICompanyForm) => {
			companies.update(values);
			onSuccess();
		},
		[currentCompany]
	);

	const validate = useCallback(({ name }: ICompanyForm) => {
		const errors = {
			name: required(name) || requiredMin(name, 2),
		};

		return errors;
	}, []);

	const deleteCompany = useCallback(async () => {
		if (currentCompany) {
			companies.delete(currentCompany.id);
		}
		onClose();
	}, [currentCompany]);

	const initialValues = useMemo(() => {
		return currentCompany || {};
	}, [currentCompany]);

	const columns = useMemo(
		() => [
			{
				Header: "email",
				accessor: "email",
			},
			{
				Header: "Full name",
				accessor: "fullName",
			},
		],
		[]
	);

	const employees = useMemo(
		() =>
			(currentCompany &&
				currentCompany.employees.map((x) => ({
					email: x?.email ?? "",
					fullName: x?.fullName ?? "",
				}))) ||
			[],
		[currentCompany]
	);

	return (
		<Modal onClose={onClose} isOpen={isOpen} backdrop={true}>
			<Transition in={isOpen} timeout={250} unmountOnExit>
				{(state) => (
					<Container state={state}>
						<Row mt={Space.lg} ml={Space.lg} mr={Space.lg}>
							<Row fitWidth>
								<Col>
									<Text type={textTypes.h3Bold!}>
										{id ? "Company info" : "Create company"}
									</Text>
								</Col>
								<Col itemRight>
									<Button
										size={ButtonSize.sm}
										variant={buttonTypes.softSecondary}
										onClick={onClose}
										icon
									>
										<CloseSVG />
									</Button>
								</Col>
							</Row>
							<Col pt={Space.lg}>
								<Form
									initialValues={initialValues}
									validate={validate}
									onSubmit={onSubmit}
									render={({ handleSubmit }) => (
										<form onSubmit={handleSubmit}>
											<Row>
												<TextField
													name="name"
													label="Name"
													size={TextFieldSize.sm}
													fitWidth
												/>
											</Row>
											<Row mt={Space.md} spaceBetween>
												<Col pr={Space.tiny}>
													<TextField
														name="webAddress"
														label="Web address"
														size={TextFieldSize.sm}
														fitWidth
													/>
												</Col>
												<Col pl={Space.tiny}>
													<TextField
														name="comuntication.email"
														label="Email"
														size={TextFieldSize.sm}
														fitWidth
													/>
												</Col>
											</Row>

											<Row mt={Space.md}>
												<Col pr={Space.tiny}>
													<TextField
														name="responsible"
														label="Responsible"
														size={TextFieldSize.sm}
														fitWidth
													/>
												</Col>
												<Col pl={Space.tiny}>
													<TextField
														name="phoneNumber"
														type={TextFieldType.phone}
														label="Phone number"
														size={TextFieldSize.sm}
														fitWidth
													/>
												</Col>
											</Row>
											<Row mt={Space.md}>
												<Col pr={Space.tiny}>
													<TextField
														name="category"
														label="Category"
														size={TextFieldSize.sm}
														fitWidth
													/>
												</Col>
												<Col pl={Space.tiny}>
													<TextField
														name="industry"
														label="Industry"
														size={TextFieldSize.sm}
														fitWidth
													/>
												</Col>
											</Row>
											<Row mt={Space.md}>
												<Col pr={Space.tiny}>
													<TextField
														name="address.main"
														label="Main address"
														size={TextFieldSize.sm}
														fitWidth
													/>
												</Col>
												<Col pl={Space.tiny}>
													<TextField
														name="address.another"
														label="Additional address"
														size={TextFieldSize.sm}
														fitWidth
													/>
												</Col>
											</Row>
											<Row mt={Space.md}>
												<Col pr={Space.tiny}>
													<TextField
														name="address.country"
														label="Country"
														size={TextFieldSize.sm}
														fitWidth
													/>
												</Col>
												<Col pl={Space.tiny}>
													<TextField
														name="address.region"
														label="Region"
														size={TextFieldSize.sm}
														fitWidth
													/>
												</Col>
											</Row>
											<Row mt={Space.md}>
												<Col pr={Space.tiny}>
													<TextField
														name="address.city"
														label="City"
														size={TextFieldSize.sm}
														fitWidth
													/>
												</Col>
												<Col pl={Space.tiny}>
													<TextField
														name="address.index"
														label="Postal code"
														size={TextFieldSize.sm}
														fitWidth
													/>
												</Col>
											</Row>
											<BottomContainer>
												<Row pt={Space.sm} pl={Space.lg} pr={Space.lg}>
													<Col itemRight>
														{id ? (
															<>
																{hasDeletePermission && (
																	<Button
																		type="button"
																		size={ButtonSize.md}
																		variant={buttonTypes.softSecondary}
																		onClick={deleteCompany}
																		mr={16}
																		icon
																	>
																		<DeleteIcon>
																			<DeleteSVG />
																		</DeleteIcon>
																	</Button>
																)}
																{hasEditPermission && (
																	<Button
																		type="submit"
																		size={ButtonSize.md}
																		variant={buttonTypes.outlinePrimary}
																		onClick={() => {}}
																	>
																		UPDATE
																	</Button>
																)}
															</>
														) : (
															<>
																{hasCreatePermission && (
																	<Button
																		type="submit"
																		size={ButtonSize.md}
																		variant={buttonTypes.outlinePrimary}
																		onClick={() => {}}
																	>
																		CREATE
																	</Button>
																)}
															</>
														)}
													</Col>
												</Row>
											</BottomContainer>
										</form>
									)}
								/>
								{employees && employees.length > 0 ? (
									<Row pt={Space.lg}>
										<Table
											columns={columns}
											data={employees}
											height={"calc(100vh - 716px)"}
										/>
									</Row>
								) : (
									<></>
								)}
							</Col>
						</Row>
					</Container>
				)}
			</Transition>
		</Modal>
	);
};

export default Company;
