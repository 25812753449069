import { colors } from "./colors";
import { buttons } from "./buttons";
import { typography } from "./typography";
import { ThemedStyledInterface } from "styled-components";
import styled from "styled-components";
import { fonts } from "./fonts";
import { alerts } from "./alerts"; // add alerts
import { banners } from "./banners"; // add banners
import { toggle } from "./toggle"; // add toggle
import { chips } from './chips'; // add chips 
import { badges } from './badges'; // add badges
export const theme = {
	colors: colors,
	buttons: buttons,
	typography: typography,
	fonts: fonts,
	alerts: alerts, // add alerts
	banners: banners, // add banners
	toggle: toggle, // add toggle
	chips: chips, // add chips
	badges: badges, // add badges
};

export type ThemeType = typeof theme;

export default styled as ThemedStyledInterface<ThemeType>;
