import styled from '../../styles/theme';
import { css, keyframes} from "styled-components";
import { ReactComponent as Logo } from "../../assets/icons/logo.svg";
import { ReactComponent as Up } from "../../assets/icons/arrow_up.svg";
import { ReactComponent as Down } from "../../assets/icons/arrow_down.svg";
import { ReactComponent as HideNav } from "../../assets/icons/hide_nav_icon.svg";
import { fadeIn, fadeOut } from "../../styles/animations";
import { Row, Col } from '../Grid'; // add 

export const HideNavIcon = styled(HideNav)<IHide>`
	position: relative;
	cursor: pointer;
	${({ hide }) =>
		hide === true
			? css`
					position: inherit;
					margin: 32px auto 104px 32px;
			  `
			: css`
					position: absolute;
					right: 8px;
					top: 32px;
			  `};
`;

interface IHide {
	hide?: boolean;
};

export const Container = styled.div<IHide>`
	position: relative;
	display: flex;
	flex-direction: row;
	${({ hide }) =>
		hide === true
			? css`
					width: 87px;
			  `
			: css`
					width: fit-content;
			  `}
	height: 100vh;
	background-color: #f6f6f6;
`;

export const Menu = styled.div`
	display: flex;
	flex-direction: column;
	margin: 0;
	background-color: inherit;
`;

export const LogoIcon = styled(Logo)<IHide>`
	display: ${({ hide }) => (hide === true ? "none" : "block")};
	margin-top: 34px;
	margin-left: 28px;
	cursor: pointer;
`;

export const HideNavContainer = styled.div<IHide>`
	position: relative;
	display: flex;
	align-items: center;
	width: 24px;
	height: 24px;
	cursor: pointer;
	${({ hide }) =>
		hide === true
			? css`
					position: static;
					margin: 32px auto 104px 32px;
			  `
			: css`
					position: absolute;
					right: 8px;
					top: 32px;
			  `};
`;

export const CategoryContainer = styled.div<IHide>`
	display: ${({ hide }) => (hide === true ? "none" : "flex")};
	flex-direction: row;
	align-items: center;
	width: 224px;
	height: 56px;
	margin: 27px 12px 16px 12px;
	background-color: #f0f1f2;
	border-radius: 4px;
	cursor: pointer;
`;

export const CategoryIcon = styled.div`
	display: flex; // change block on flex
	justify-content: center;
	align-items: center;
	width: 32px;
	height: 32px;
	border-radius: 16px;
	margin: 12px;
	background-color: rgba(234, 104, 82, 1);
`;

export const Category = styled.div`
	display: block;
	width: fit-content;
	height: 16px;
	color: rgba(2, 14, 25, 0.7);
	font-size: 12px;
	line-height: 16px;
	letter-spacing: 0.3px;
	font-weight: 600;
	text-transform: uppercase;
`;

interface ICategoryDropDown extends IHide {
	mr?: number | undefined;
}

export const CategoryDropDown = styled.div<ICategoryDropDown>`
	display: ${({ hide }) => (hide === true ? "none" : "flex")};
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 24px;
	height: 24px;
	margin-left: auto;
	margin-right: ${({ mr }) => (mr === undefined ? 0 : 8)}px;
`;

interface IDropdown {
	show?: boolean;
}

export const UpIcon = styled(Up)<IDropdown>`
	display: block;
	margin-bottom: 3px;
	transform: ${({ show }) => (show === true ? "rotate(180deg)" : null)};
	transition: all 400ms ease;
`;

export const DownIcon = styled(Down)<IDropdown>`
	display: block;
	margin-top: 3px;
	transform: ${({ show }) => (show === true ? "rotate(180deg)" : null)};
	transition: all 400ms ease;
`;

interface IAllCategories {
	state?: string;
}

export const AllCategories = styled.div<IAllCategories>`
	display: flex;
	flex-direction: column;
	position: absolute;
	width: 224px;
	height: auto;
	border-radius: 4px;
	padding-top: 4px;
	padding-bottom: 4px;
	color: rgba(24, 24, 25, 0.7);
	background-color: #fff;
	left: 12px;
	top: 148px;
	box-shadow: 2px 4px 20px 4px rgba(34, 60, 80, 0.11);

	${({ state }) =>
		(state === "entering" &&
			css`
				animation: ${fadeIn} 300ms;
			`) ||
		(state === "exiting" &&
			css`
				animation: ${fadeOut} 300ms;
			`)}
`;

export const AllCategoriesItem = styled.div`
	display: flex;
	align-items: center;
	width: 200px;
	height: 32px;
	padding: 12px;
	cursor: pointer;
	:hover {
		background-color: rgba(65, 65, 65, 0.04);
	}
	transition: all 300ms ease;
	text-transform: uppercase;
	letter-spacing: 0.3px;
`;

export const MenuItemsContainer = styled.div<IHide>`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-left: 14px;
	transition: all 300ms ease;
	cursor: pointer;
	${({ hide }) =>
		hide === true
			? css`
          width: fit-content;
          margin-top: 4px;
          height: 40px;
          }
        `
			: css`
					width: 224px;
					height: 40px;
					margin-top: 4px;
					:hover {
						background-color: rgba(65, 65, 65, 0.04);
						border-radius: 4px;
					}
			  `}
`;

interface ICategoryItem extends IHide {
	isActive?: boolean;
}
export const MenuItem = styled.div<ICategoryItem>`
	display: ${({ hide }) => (hide === true ? "none" : "block")};
	width: fit-content;
	height: 20px;
	font-size: 14px;
	line-height: 20px;
	color: ${({ isActive }) =>
		isActive === true ? "rgba(10, 97, 92, 0.9)" : "rgba(24, 24, 25, 0.7)"};
	font-weight: ${({ isActive }) => (isActive === true ? 600 : 400)};
`;

export const MenuItemIcon = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 24px;
	height: 24px;
	margin-right: 12px;
	margin-left: 16px;
`;

export const VerticalDivider = styled.div`
	position: absolute;
	right: 0;
	background-color: #e6e7e9;
	height: 100vh;
	width: 1px;
`;

interface ISelector {
	position: number;
}

export const Selector = styled.div<ISelector>`
	position: absolute;
	background-color: #0a615c;
	width: 3px;
	height: 32px;
	right: 0;
	top: ${({ position }) => position}px;
	transition: all 500ms ease;
`;

export const UserAccount = styled.div<IHide>`
	display: flex;
	flex-direction: row;
	align-items: center;
	width: ${({ hide }) => (hide === true ? 40 : 200)}px;
	height: 88px;
	border-top: 1px solid #e6e7e9;
	margin: auto 24px 0px 24px;
	cursor: ${({ hide }) => hide ? "pointer" : "default"};
`;

export const Avatar = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 40px;
	height: 40px;
	background-color: ${({theme: {colors}}) => colors.primary._700};
	border-radius: 20px;
	color: #fff;
`;

export const UsersInfo = styled.div<IHide>`
	display: ${({ hide }) => (hide === true ? "none" : "flex")};
	flex-direction: column;
	width: auto;
	height: auto;
	margin-left: 12px;
`;

export const UsersInfoName = styled.div`
	width: fit-content;
	height: auto;
	color: rgba(44, 64, 74, 1);
	font-size: 14px;
	line-height: 20px;
	font-weight: 400;
`;

export const UsersInfoPost = styled.div`
	width: fit-content;
	height: auto;
	color: rgba(24, 24, 25, 0.42);
	font-size: 12px;
	line-height: 16px;
	font-weight: 400;
`;

export const UsersSettingsContainer = styled.div<IAllCategories>`
	position: absolute;
	display: flex;
	flex-direction: column;
	width: 224px;
	height: auto;
	left: 12px;
	bottom: 87px;
	border-radius: 4px;
	padding-top: 8px;
	padding-bottom: 8px;
	background-color: #fff;
	box-shadow: 2px 4px 20px 4px rgba(34, 60, 80, 0.11);
	z-index: 999999;
	${({ state }) =>
		(state === "entering" &&
			css`
				animation: 300ms ${fadeIn};
			`) ||
		(state === "exiting" &&
			css`
				animation: 300ms ${fadeOut};
			`)};
`;

export const UsersSettingsHeader = styled.div`
	width: auto;
	height: auto;
	padding: 16px;
	border-bottom: 1px solid #e6e7e9;
`;

export const UsersSettingsItem = styled(MenuItemsContainer)`
	margin: 0;
	color: rgba(24, 24, 25, 0.9);
`;

export const SettingsHeaderEmail = styled.div`
	color: rgba(10, 97, 92, 0.9);
	font-size: 14px;
	line-height: 20px;
	font-weight: 600;
	overflow-x: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`;

export const LogOutContainer = styled.div<IHide>`
	display: ${({ hide }) => (hide === true ? "none" : "flex")};
	margin-right: 0;
	margin-left: auto;
	cursor: pointer;
`;
