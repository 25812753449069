import { FC, useCallback, useRef } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import {
	AutoSizer,
	CellMeasurerCache,
	Index,
	List,
	ListRowRenderer,
} from "react-virtualized";

interface IVirtualList {
	rowCount: number;
	rowHeight:
		| number
		| (number & ((info: Index) => number))
		| (((params: Index) => number) & number)
		| (((params: Index) => number) & ((info: Index) => number));
	rowRenderer: ListRowRenderer;
	shadowOnScroll?: boolean;
	deferredMeasurementCache?: CellMeasurerCache;
}

const VirtualList: FC<IVirtualList> = ({
	rowCount,
	rowHeight,
	rowRenderer,
	shadowOnScroll,
	...rest
}) => {
	const listRef = useRef<List>(null);

	const handleScroll = useCallback(({ target }: any) => {
		const { scrollTop, scrollLeft } = target;

		const { Grid: grid } = listRef.current!;

		grid!.handleScrollEvent({ scrollTop, scrollLeft });
	}, []);

	return (
		<Scrollbars universal onScroll={handleScroll}>
			<AutoSizer>
				{({ height, width }) => (
					<List
						ref={listRef}
						height={height - 40}
						width={width - 20}
						style={{
							outline: "none",
							overflowX: "visible",
							overflowY: "visible",
						}}
						rowCount={rowCount}
						rowHeight={rowHeight}
						rowRenderer={rowRenderer}
						{...rest}
					/>
				)}
			</AutoSizer>
		</Scrollbars>
	);
};

export default VirtualList;
