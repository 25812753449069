import styled from "../../styles/theme";
import { css } from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    width: fit-content;
    align-items: center;
`;

interface IConfirm { // check name of the interface 
    confirm: boolean;
};

export const Circle = styled.div<IConfirm>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    ${({confirm}) => confirm && css`
        background-color: ${({ theme: { colors } }) => colors.primary._700};
        color: ${({ theme: {colors}}) => colors.white};
    ` || css`
        background-color: ${({ theme: { colors } }) => colors.gray._200};
        color: rgba(24, 24, 25, 0.32);
    `};
    font-weight: ${({ theme: {typography}}) => typography.bodySmBold.weight};
    font-size: ${({ theme: {typography}}) => typography.bodySmBold.size};
    line-height: ${({ theme: {typography}}) => typography.bodySmBold.lineHeight};
    margin-right: 12px;
    border-radius: 12px;
    @media (max-width: 1300px){
        margin-right: 8px;
    }
`;

export const Text = styled.div<IConfirm>`
    display: flex;
    color: ${({confirm, theme: {colors}}) => confirm && colors.black || "rgba(24, 24, 25, 0.32)"};
    font-size: ${({theme: {typography} }) => typography.bodyMdBold.size};
    font-weight: ${({ theme: {typography}}) => typography.bodyMdBold.weight};
    line-height: ${({ theme: {typography}}) => typography.bodyMdBold.lineHeight};
    margin-right: 12px;
    @media (max-width: 1360px){
        margin-right: 0;
    }
    @media (max-width: 1300px){
        font-size: ${({theme: {typography} }) => typography.bodySmBold.size};
        line-height: ${({ theme: {typography}}) => typography.bodySmBold.lineHeight};
        margin-right: 0;
    }
`;

export const Devider = styled.div`
    display: flex;
    width: 72px;
    height: 1px;
    background-color: ${({theme: {colors}}) => colors.gray._200};
    margin-right: 12px;
    @media (max-width: 1360px){
        width: 52px;
        margin-right: 8px;
        margin-left: 8px;
    }
`;
