import styled from "../../styles/theme";

export const Editor = styled.div`
	height: calc(100vh - 110px) !important;
	width: calc(100% - 32px) !important;
	border-radius: 8px;
	margin-top: 16px;
	margin-bottom: 16px;
	margin-left: 16px;
	margin-right: 16px;

	& .block-btn {
		position: relative;
		width: 100%;

		& b {
			position: relative;
			padding-top: 1.3vw;
		}
	}

	& .block-btn-img {
		display: block;
		position: relative;
		left: 50%;
		transform: translateX(-50%);
		width: 35%;
	}

	& .gjs-pn-views {
		background: #fff !important;
	}

	& .gjs-mdl-dialog {
		background: #fff !important;
	}

	& .gjs-editor {
		font-family: "Noto-Sans";
	}

	& .gjs-editor-cont::-webkit-scrollbar-thumb {
		background-color: rgba(255, 255, 255, 1) !important;
	}

	& .gjs-clm-tag {
		background-color: #0a615c !important;
		color: #fff;
	}

	& .gjs-rte-toolbar {
		background-color: #0a615c !important;
		color: #fff;
	}

	& .gjs-cv-canvas {
		background-color: #f6f6f6;
		width: 80%;
	}

	& .gjs-one-bg {
		background-color: unset;
	}

	& .gjs-pn-commands {
		background-color: #fff;
		width: 80%;
		border-radius: 4px;
	}

	& .gjs-two-color {
		color: rgba(0, 0, 0, 0.6);
	}

	& .gjs-pn-btn.gjs-pn-active {
		box-shadow: unset;
		background-color: #0a615c;
	}

	& .gjs-four-color {
		color: #fff;
	}

	& gjs-pn-panel.gjs-pn-commands.gjs-one-bg.gjs-two-color {
		width: 80%;
	}

	& .gjs-pn-options {
		right: 20%;
	}

	& .gjs-pn-views-container {
		width: calc(20% - 32px);
		background-color: #fff;
		border-radius: 4px;
	}

	& .gjs-pn-views {
		width: calc(20% - 32px);
	}

	& .gjs-pn-btn:not(.gjs-pn-active):hover {
		color: #0a615c;
	}

	& .gjs-four-color-h:hover {
		color: #0a615c;
	}

	& .gjs-selected {
		outline: 3px solid #0a615c !important;
		outline-color: #0a615c !important;
	}

	& .gjs-hovered {
		outline-color: #0a615c !important;
		border-color: #0a615c !important;
	}

	& .gjs-highlighter {
		outline: 1px solid #0a615c;
	}

	& .gjs-badge {
		background-color: #0a615c;
	}

	& iframe .gjs-selected {
		outline: 3px solid #0a615c !important;
		outline-color: #0a615c !important;
	}

	& .gjs-toolbar {
		background-color: #0a615c;
	}

	& .cke_inner{
		width: 600px;
	}
`;

export const CompiledMarkup = styled.div`
	position: absolute;
	top: -9999px;
	z-index: -9999;
	width: 600px;
`;

export const ModalContainer = styled.div`
	position: absolute;
	width: 480px;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background: #f6f6f6;
	border-radius: 8px;
	padding: 24px 24px;
`;
