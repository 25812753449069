import { css } from "styled-components";
import { IGridItem, IMargin, IPadding } from "../types/styles";

export const marginProps = css<IMargin>`
	${({ mt }) =>
		mt &&
		css`
			margin-top: ${mt}px;
		`}
	${({ mb }) =>
		mb &&
		css`
			margin-bottom: ${mb}px;
		`}
  ${({ ml }) =>
		ml &&
		css`
			margin-left: ${ml}px;
		`}
  ${({ mr }) =>
		mr &&
		css`
			margin-right: ${mr}px;
		`}
`;

export const paddingProps = css<IPadding>`
	${({ pt }) =>
		pt &&
		css`
			padding-top: ${pt}px;
		`}
	${({ pb }) =>
		pb &&
		css`
			padding-bottom: ${pb}px;
		`}
  ${({ pl }) =>
		pl &&
		css`
			padding-left: ${pl}px;
		`}
  ${({ pr }) =>
		pr &&
		css`
			padding-right: ${pr}px;
		`}
`;

export const baseProps = css<IMargin & IPadding>`
	${marginProps}
	${paddingProps}
`;

export const gridItemXsProps = css<IGridItem>`
	${({ xsHidden }) => xsHidden && "display: none;"}
	${({ xsTop }) =>
		xsTop &&
		css`
			top: ${xsTop}px;
		`}
  ${({ xsBottom }) =>
		xsBottom &&
		css`
			bottom: ${xsBottom}px;
		`}
  ${({ xsLeft }) =>
		xsLeft &&
		css`
			left: ${xsLeft}px;
		`}
  ${({ xsRight }) =>
		xsRight &&
		css`
			right: ${xsRight}px;
		`}
  ${({ xsMt }) =>
		xsMt &&
		css`
			margin-top: ${xsMt}px;
		`}
  ${({ xsMb }) =>
		xsMb &&
		css`
			margin-bottom: ${xsMb}px;
		`}
  ${({ xsMl }) =>
		xsMl &&
		css`
			margin-left: ${xsMl}px;
		`}
  ${({ xsMr }) =>
		xsMr &&
		css`
			margin-right: ${xsMr}px;
		`}
  ${({ xsPt }) =>
		xsPt &&
		css`
			padding-top: ${xsPt}px;
		`}
  ${({ xsPb }) =>
		xsPb &&
		css`
			padding-bottom: ${xsPb}px;
		`}
  ${({ xsPl }) =>
		xsPl &&
		css`
			padding-left: ${xsPl}px;
		`}
  ${({ xsPr }) =>
		xsPr &&
		css`
			padding-right: ${xsPr}px;
		`}
`;

export const gridItemSmProps = css<IGridItem>`
	${({ smPt }) =>
		smPt &&
		css`
			padding-top: ${smPt}px;
		`}
	${({ smPb }) =>
		smPb &&
		css`
			padding-bottom: ${smPb}px;
		`}
  ${({ smPl }) =>
		smPl &&
		css`
			padding-left: ${smPl}px;
		`}
  ${({ smPr }) =>
		smPr &&
		css`
			padding-right: ${smPr}px;
		`}
`;

export const gridItemMdProps = css<IGridItem>`
	${({ mdPt }) =>
		mdPt &&
		css`
			padding-top: ${mdPt}px;
		`}
	${({ mdPb }) =>
		mdPb &&
		css`
			padding-bottom: ${mdPb}px;
		`}
  ${({ mdPl }) =>
		mdPl &&
		css`
			padding-left: ${mdPl}px;
		`}
  ${({ mdPr }) =>
		mdPr &&
		css`
			padding-right: ${mdPr}px;
		`}
`;

export const gridItemLgProps = css<IGridItem>`
	${({ lgPt }) =>
		lgPt &&
		css`
			padding-top: ${lgPt}px;
		`}
	${({ lgPb }) =>
		lgPb &&
		css`
			padding-bottom: ${lgPb}px;
		`}
  ${({ lgPl }) =>
		lgPl &&
		css`
			padding-left: ${lgPl}px;
		`}
  ${({ lgPr }) =>
		lgPr &&
		css`
			padding-right: ${lgPr}px;
		`}
`;

export const gridItemXlProps = css<IGridItem>`
	${({ xlPt }) =>
		xlPt &&
		css`
			padding-top: ${xlPt}px;
		`}
	${({ xlPb }) =>
		xlPb &&
		css`
			padding-bottom: ${xlPb}px;
		`}
  ${({ xlPl }) =>
		xlPl &&
		css`
			padding-left: ${xlPl}px;
		`}
  ${({ xlPr }) =>
		xlPr &&
		css`
			padding-right: ${xlPr}px;
		`}
`;
