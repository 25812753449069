import tglImagesCommand from "./toggleImagesCommand";
import importCommand from "./command-import-mjml";
import exportCommand from "./command-export-mjml";

export default (editor: any, opt = {}) => {
	const cmd = editor.Commands;
	const exportName = (opt as any).overwriteExport
		? "export-template"
		: "mjml-export";

	cmd.add("mjml-import", importCommand(editor, opt));
	cmd.add("mjml-import:change", {
		run() {
			const code = editor.getHtml();
			return code.trim();
		},
	});
	cmd.add(exportName, exportCommand(editor, opt));

	// @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
	cmd.add((opt as any).cmdTglImages, tglImagesCommand(opt));

	cmd.add("undo", {
		run(editor: any, sender: any) {
			sender.set("active", 0);
			editor.UndoManager.undo(1);
		},
	});

	cmd.add("redo", {
		run(editor: any, sender: any) {
			sender.set("active", 0);
			editor.UndoManager.redo(1);
		},
	});
	cmd.add("set-device-desktop", {
		run(editor: any) {
			editor.setDevice("Desktop");
		},
	});
	cmd.add("set-device-tablet", {
		run(editor: any) {
			editor.setDevice("Tablet");
		},
	});
	cmd.add("set-device-mobile", {
		run(editor: any) {
			editor.setDevice("Mobile portrait");
		},
	});
};
