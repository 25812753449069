const languages = [
  { label: "Afar", value: "aar" },
  { label: "Abkhazian", value: "abk" },
  { label: "Achinese", value: "ace" },
  { label: "Acoli", value: "ach" },
  { label: "Adangme", value: "ada" },
  { label: "Adyghe", value: "ady" },
  { label: "Afro-Asiatic languages", value: "afa" },
  { label: "Afrihili", value: "afh" },
  { label: "Afrikaans", value: "afr" },
  { label: "Ainu", value: "ain" },
  { label: "Akan", value: "aka" },
  { label: "Akkadian", value: "akk" },
  { label: "Albanian", value: "alb" },
  { label: "Aleut", value: "ale" },
  { label: "Algonquian languages", value: "alg" },
  { label: "Southern Altai", value: "alt" },
  { label: "Amharic", value: "amh" },
  { label: "English, Old (ca.450-1100)", value: "ang" },
  { label: "Angika", value: "anp" },
  { label: "Apache languages", value: "apa" },
  { label: "Arabic", value: "ara" },
  { label: "Official Aramaic (700-300 BCE)", value: "arc" },
  { label: "Aragonese", value: "arg" },
  { label: "Armenian", value: "arm" },
  { label: "Mapudungun", value: "arn" },
  { label: "Arapaho", value: "arp" },
  { label: "Artificial languages", value: "art" },
  { label: "Arawak", value: "arw" },
  { label: "Assamese", value: "asm" },
  { label: "Asturian", value: "ast" },
  { label: "Athapascan languages", value: "ath" },
  { label: "Australian languages", value: "aus" },
  { label: "Avaric", value: "ava" },
  { label: "Avestan", value: "ave" },
  { label: "Awadhi", value: "awa" },
  { label: "Aymara", value: "aym" },
  { label: "Azerbaijani", value: "aze" },
  { label: "Banda languages", value: "bad" },
  { label: "Bamileke languages", value: "bai" },
  { label: "Bashkir", value: "bak" },
  { label: "Baluchi", value: "bal" },
  { label: "Bambara", value: "bam" },
  { label: "Balinese", value: "ban" },
  { label: "Basque", value: "baq" },
  { label: "Basa", value: "bas" },
  { label: "Baltic languages", value: "bat" },
  { label: "Beja", value: "bej" },
  { label: "Belarusian", value: "bel" },
  { label: "Bemba", value: "bem" },
  { label: "Bengali", value: "ben" },
  { label: "Berber languages", value: "ber" },
  { label: "Bhojpuri", value: "bho" },
  { label: "Bihari languages", value: "bih" },
  { label: "Bikol", value: "bik" },
  { label: "Bini", value: "bin" },
  { label: "Bislama", value: "bis" },
  { label: "Siksika", value: "bla" },
  { label: "Bantu languages", value: "bnt" },
  { label: "Tibetan", value: "bod" },
  { label: "Bosnian", value: "bos" },
  { label: "Braj", value: "bra" },
  { label: "Breton", value: "bre" },
  { label: "Batak languages", value: "btk" },
  { label: "Buriat", value: "bua" },
  { label: "Buginese", value: "bug" },
  { label: "Bulgarian", value: "bul" },
  { label: "Burmese", value: "bur" },
  { label: "Blin", value: "byn" },
  { label: "Caddo", value: "cad" },
  { label: "Central American Indian languages", value: "cai" },
  { label: "Galibi Carib", value: "car" },
  { label: "Catalan", value: "cat" },
  { label: "Caucasian languages", value: "cau" },
  { label: "Cebuano", value: "ceb" },
  { label: "Celtic languages", value: "cel" },
  { label: "Czech", value: "ces" },
  { label: "Chamorro", value: "cha" },
  { label: "Chibcha", value: "chb" },
  { label: "Chechen", value: "che" },
  { label: "Chagatai", value: "chg" },
  { label: "Chinese", value: "chi" },
  { label: "Chuukese", value: "chk" },
  { label: "Mari", value: "chm" },
  { label: "Chinook jargon", value: "chn" },
  { label: "Choctaw", value: "cho" },
  { label: "Chipewyan", value: "chp" },
  { label: "Cherokee", value: "chr" },
  { label: "Church Slavic", value: "chu" },
  { label: "Chuvash", value: "chv" },
  { label: "Cheyenne", value: "chy" },
  { label: "Chamic languages", value: "cmc" },
  { label: "Coptic", value: "cop" },
  { label: "Cornish", value: "cor" },
  { label: "Corsican", value: "cos" },
  { label: "Creoles and pidgins, English based", value: "cpe" },
  { label: "Creoles and pidgins, French-based", value: "cpf" },
  { label: "Creoles and pidgins, Portuguese-based", value: "cpp" },
  { label: "Cree", value: "cre" },
  { label: "Crimean Tatar", value: "crh" },
  { label: "Creoles and pidgins", value: "crp" },
  { label: "Kashubian", value: "csb" },
  { label: "Cushitic languages", value: "cus" },
  { label: "Welsh", value: "cym" },
  { label: "Czech", value: "cze" },
  { label: "Dakota", value: "dak" },
  { label: "Danish", value: "dan" },
  { label: "Dargwa", value: "dar" },
  { label: "Land Dayak languages", value: "day" },
  { label: "Delaware", value: "del" },
  { label: "Slave (Athapascan)", value: "den" },
  { label: "German", value: "deu" },
  { label: "Dogrib", value: "dgr" },
  { label: "Dinka", value: "din" },
  { label: "Divehi", value: "div" },
  { label: "Dogri", value: "doi" },
  { label: "Dravidian languages", value: "dra" },
  { label: "Lower Sorbian", value: "dsb" },
  { label: "Duala", value: "dua" },
  { label: "Dutch, Middle (ca.1050-1350)", value: "dum" },
  { label: "Dutch", value: "dut" },
  { label: "Dyula", value: "dyu" },
  { label: "Dzongkha", value: "dzo" },
  { label: "Efik", value: "efi" },
  { label: "Egyptian (Ancient)", value: "egy" },
  { label: "Ekajuk", value: "eka" },
  { label: "Greek, Modern (1453-)", value: "ell" },
  { label: "Elamite", value: "elx" },
  { label: "English", value: "eng" },
  { label: "English, Middle (1100-1500)", value: "enm" },
  { label: "Esperanto", value: "epo" },
  { label: "Estonian", value: "est" },
  { label: "Basque", value: "eus" },
  { label: "Ewe", value: "ewe" },
  { label: "Ewondo", value: "ewo" },
  { label: "Fang", value: "fan" },
  { label: "Faroese", value: "fao" },
  { label: "Persian", value: "fas" },
  { label: "Fanti", value: "fat" },
  { label: "Fijian", value: "fij" },
  { label: "Filipino", value: "fil" },
  { label: "Finnish", value: "fin" },
  { label: "Finno-Ugrian languages", value: "fiu" },
  { label: "Fon", value: "fon" },
  { label: "French", value: "fra" },
  { label: "French", value: "fre" },
  { label: "French, Middle (ca.1400-1600)", value: "frm" },
  { label: "French, Old (842-ca.1400)", value: "fro" },
  { label: "Northern Frisian", value: "frr" },
  { label: "Eastern Frisian", value: "frs" },
  { label: "Western Frisian", value: "fry" },
  { label: "Fulah", value: "ful" },
  { label: "Friulian", value: "fur" },
  { label: "Ga", value: "gaa" },
  { label: "Gayo", value: "gay" },
  { label: "Gbaya", value: "gba" },
  { label: "Germanic languages", value: "gem" },
  { label: "Georgian", value: "geo" },
  { label: "German", value: "ger" },
  { label: "Geez", value: "gez" },
  { label: "Gilbertese", value: "gil" },
  { label: "Gaelic", value: "gla" },
  { label: "Irish", value: "gle" },
  { label: "Galician", value: "glg" },
  { label: "Manx", value: "glv" },
  { label: "German, Middle High (ca.1050-1500)", value: "gmh" },
  { label: "German, Old High (ca.750-1050)", value: "goh" },
  { label: "Gondi", value: "gon" },
  { label: "Gorontalo", value: "gor" },
  { label: "Gothic", value: "got" },
  { label: "Grebo", value: "grb" },
  { label: "Greek, Ancient (to 1453)", value: "grc" },
  { label: "Greek, Modern (1453-)", value: "gre" },
  { label: "Guarani", value: "grn" },
  { label: "Swiss German", value: "gsw" },
  { label: "Gujarati", value: "guj" },
  { label: "Gwich'in", value: "gwi" },
  { label: "Haida", value: "hai" },
  { label: "Haitian", value: "hat" },
  { label: "Hausa", value: "hau" },
  { label: "Hawaiian", value: "haw" },
  { label: "Hebrew", value: "heb" },
  { label: "Herero", value: "her" },
  { label: "Hiligaynon", value: "hil" },
  { label: "Himachali languages", value: "him" },
  { label: "Hindi", value: "hin" },
  { label: "Hittite", value: "hit" },
  { label: "Hmong", value: "hmn" },
  { label: "Hiri Motu", value: "hmo" },
  { label: "Croatian", value: "hrv" },
  { label: "Upper Sorbian", value: "hsb" },
  { label: "Hungarian", value: "hun" },
  { label: "Hupa", value: "hup" },
  { label: "Armenian", value: "hye" },
  { label: "Iban", value: "iba" },
  { label: "Igbo", value: "ibo" },
  { label: "Icelandic", value: "ice" },
  { label: "Ido", value: "ido" },
  { label: "Sichuan Yi", value: "iii" },
  { label: "Ijo languages", value: "ijo" },
  { label: "Inuktitut", value: "iku" },
  { label: "Interlingue", value: "ile" },
  { label: "Iloko", value: "ilo" },
  {
    label: "Interlingua (International Auxiliary Language Association)",
    value: "ina",
  },
  { label: "Indic languages", value: "inc" },
  { label: "Indonesian", value: "ind" },
  { label: "Indo-European languages", value: "ine" },
  { label: "Ingush", value: "inh" },
  { label: "Inupiaq", value: "ipk" },
  { label: "Iranian languages", value: "ira" },
  { label: "Iroquoian languages", value: "iro" },
  { label: "Icelandic", value: "isl" },
  { label: "Italian", value: "ita" },
  { label: "Javanese", value: "jav" },
  { label: "Lojban", value: "jbo" },
  { label: "Japanese", value: "jpn" },
  { label: "Judeo-Persian", value: "jpr" },
  { label: "Judeo-Arabic", value: "jrb" },
  { label: "Kara-Kalpak", value: "kaa" },
  { label: "Kabyle", value: "kab" },
  { label: "Kachin", value: "kac" },
  { label: "Kalaallisut", value: "kal" },
  { label: "Kamba", value: "kam" },
  { label: "Kannada", value: "kan" },
  { label: "Karen languages", value: "kar" },
  { label: "Kashmiri", value: "kas" },
  { label: "Georgian", value: "kat" },
  { label: "Kanuri", value: "kau" },
  { label: "Kawi", value: "kaw" },
  { label: "Kazakh", value: "kaz" },
  { label: "Kabardian", value: "kbd" },
  { label: "Khasi", value: "kha" },
  { label: "Khoisan languages", value: "khi" },
  { label: "Central Khmer", value: "khm" },
  { label: "Khotanese", value: "kho" },
  { label: "Kikuyu", value: "kik" },
  { label: "Kinyarwanda", value: "kin" },
  { label: "Kirghiz", value: "kir" },
  { label: "Kimbundu", value: "kmb" },
  { label: "Konkani", value: "kok" },
  { label: "Komi", value: "kom" },
  { label: "Kongo", value: "kon" },
  { label: "Korean", value: "kor" },
  { label: "Kosraean", value: "kos" },
  { label: "Kpelle", value: "kpe" },
  { label: "Karachay-Balkar", value: "krc" },
  { label: "Karelian", value: "krl" },
  { label: "Kru languages", value: "kro" },
  { label: "Kurukh", value: "kru" },
  { label: "Kuanyama", value: "kua" },
  { label: "Kumyk", value: "kum" },
  { label: "Kurdish", value: "kur" },
  { label: "Kutenai", value: "kut" },
  { label: "Ladino", value: "lad" },
  { label: "Lahnda", value: "lah" },
  { label: "Lamba", value: "lam" },
  { label: "Lao", value: "lao" },
  { label: "Latin", value: "lat" },
  { label: "Latvian", value: "lav" },
  { label: "Lezghian", value: "lez" },
  { label: "Limburgan", value: "lim" },
  { label: "Lingala", value: "lin" },
  { label: "Lithuanian", value: "lit" },
  { label: "Mongo", value: "lol" },
  { label: "Lozi", value: "loz" },
  { label: "Luxembourgish", value: "ltz" },
  { label: "Luba-Lulua", value: "lua" },
  { label: "Luba-Katanga", value: "lub" },
  { label: "Ganda", value: "lug" },
  { label: "Luiseno", value: "lui" },
  { label: "Lunda", value: "lun" },
  { label: "Luo (Kenya and Tanzania)", value: "luo" },
  { label: "Lushai", value: "lus" },
  { label: "Macedonian", value: "mac" },
  { label: "Madurese", value: "mad" },
  { label: "Magahi", value: "mag" },
  { label: "Marshallese", value: "mah" },
  { label: "Maithili", value: "mai" },
  { label: "Makasar", value: "mak" },
  { label: "Malayalam", value: "mal" },
  { label: "Mandingo", value: "man" },
  { label: "Maori", value: "mao" },
  { label: "Austronesian languages", value: "map" },
  { label: "Marathi", value: "mar" },
  { label: "Masai", value: "mas" },
  { label: "Malay", value: "may" },
  { label: "Moksha", value: "mdf" },
  { label: "Mandar", value: "mdr" },
  { label: "Mende", value: "men" },
  { label: "Irish, Middle (900-1200)", value: "mga" },
  { label: "Mi'kmaq", value: "mic" },
  { label: "Minangkabau", value: "min" },
  { label: "Uncoded languages", value: "mis" },
  { label: "Macedonian", value: "mkd" },
  { label: "Mon-Khmer languages", value: "mkh" },
  { label: "Malagasy", value: "mlg" },
  { label: "Maltese", value: "mlt" },
  { label: "Manchu", value: "mnc" },
  { label: "Manipuri", value: "mni" },
  { label: "Manobo languages", value: "mno" },
  { label: "Mohawk", value: "moh" },
  { label: "Mongolian", value: "mon" },
  { label: "Mossi", value: "mos" },
  { label: "Maori", value: "mri" },
  { label: "Malay", value: "msa" },
  { label: "Multiple languages", value: "mul" },
  { label: "Munda languages", value: "mun" },
  { label: "Creek", value: "mus" },
  { label: "Mirandese", value: "mwl" },
  { label: "Marwari", value: "mwr" },
  { label: "Burmese", value: "mya" },
  { label: "Mayan languages", value: "myn" },
  { label: "Erzya", value: "myv" },
  { label: "Nahuatl languages", value: "nah" },
  { label: "North American Indian languages", value: "nai" },
  { label: "Neapolitan", value: "nap" },
  { label: "Nauru", value: "nau" },
  { label: "Navajo", value: "nav" },
  { label: "Ndebele, South", value: "nbl" },
  { label: "Ndebele, North", value: "nde" },
  { label: "Ndonga", value: "ndo" },
  { label: "Low German", value: "nds" },
  { label: "Nepali", value: "nep" },
  { label: "Nepal Bhasa", value: "new" },
  { label: "Nias", value: "nia" },
  { label: "Niger-Kordofanian languages", value: "nic" },
  { label: "Niuean", value: "niu" },
  { label: "Dutch", value: "nld" },
  { label: "Norwegian Nynorsk", value: "nno" },
  { label: "Bokmål, Norwegian", value: "nob" },
  { label: "Nogai", value: "nog" },
  { label: "Norse, Old", value: "non" },
  { label: "Norwegian", value: "nor" },
  { label: "N'Ko", value: "nqo" },
  { label: "Pedi", value: "nso" },
  { label: "Nubian languages", value: "nub" },
  { label: "Classical Newari", value: "nwc" },
  { label: "Chichewa", value: "nya" },
  { label: "Nyamwezi", value: "nym" },
  { label: "Nyankole", value: "nyn" },
  { label: "Nyoro", value: "nyo" },
  { label: "Nzima", value: "nzi" },
  { label: "Occitan (post 1500)", value: "oci" },
  { label: "Ojibwa", value: "oji" },
  { label: "Oriya", value: "ori" },
  { label: "Oromo", value: "orm" },
  { label: "Osage", value: "osa" },
  { label: "Ossetian", value: "oss" },
  { label: "Turkish, Ottoman (1500-1928)", value: "ota" },
  { label: "Otomian languages", value: "oto" },
  { label: "Papuan languages", value: "paa" },
  { label: "Pangasinan", value: "pag" },
  { label: "Pahlavi", value: "pal" },
  { label: "Pampanga", value: "pam" },
  { label: "Panjabi", value: "pan" },
  { label: "Papiamento", value: "pap" },
  { label: "Palauan", value: "pau" },
  { label: "Persian, Old (ca.600-400 B.C.)", value: "peo" },
  { label: "Persian", value: "per" },
  { label: "Philippine languages", value: "phi" },
  { label: "Phoenician", value: "phn" },
  { label: "Pali", value: "pli" },
  { label: "Polish", value: "pol" },
  { label: "Pohnpeian", value: "pon" },
  { label: "Portuguese", value: "por" },
  { label: "Prakrit languages", value: "pra" },
  { label: "Provençal, Old (to 1500)", value: "pro" },
  { label: "Pushto", value: "pus" },
  { label: "Quechua", value: "que" },
  { label: "Rajasthani", value: "raj" },
  { label: "Rapanui", value: "rap" },
  { label: "Rarotongan", value: "rar" },
  { label: "Romance languages", value: "roa" },
  { label: "Romansh", value: "roh" },
  { label: "Romany", value: "rom" },
  { label: "Romanian", value: "ron" },
  { label: "Romanian", value: "rum" },
  { label: "Rundi", value: "run" },
  { label: "Aromanian", value: "rup" },
  { label: "Russian", value: "rus" },
  { label: "Sandawe", value: "sad" },
  { label: "Sango", value: "sag" },
  { label: "Yakut", value: "sah" },
  { label: "South American Indian languages", value: "sai" },
  { label: "Salishan languages", value: "sal" },
  { label: "Samaritan Aramaic", value: "sam" },
  { label: "Sanskrit", value: "san" },
  { label: "Sasak", value: "sas" },
  { label: "Santali", value: "sat" },
  { label: "Sicilian", value: "scn" },
  { label: "Scots", value: "sco" },
  { label: "Selkup", value: "sel" },
  { label: "Semitic languages", value: "sem" },
  { label: "Irish, Old (to 900)", value: "sga" },
  { label: "Sign Languages", value: "sgn" },
  { label: "Shan", value: "shn" },
  { label: "Sidamo", value: "sid" },
  { label: "Sinhala", value: "sin" },
  { label: "Siouan languages", value: "sio" },
  { label: "Sino-Tibetan languages", value: "sit" },
  { label: "Slavic languages", value: "sla" },
  { label: "Slovak", value: "slk" },
  { label: "Slovak", value: "slo" },
  { label: "Slovenian", value: "slv" },
  { label: "Southern Sami", value: "sma" },
  { label: "Northern Sami", value: "sme" },
  { label: "Sami languages", value: "smi" },
  { label: "Lule Sami", value: "smj" },
  { label: "Inari Sami", value: "smn" },
  { label: "Samoan", value: "smo" },
  { label: "Skolt Sami", value: "sms" },
  { label: "Shona", value: "sna" },
  { label: "Sindhi", value: "snd" },
  { label: "Soninke", value: "snk" },
  { label: "Sogdian", value: "sog" },
  { label: "Somali", value: "som" },
  { label: "Songhai languages", value: "son" },
  { label: "Sotho, Southern", value: "sot" },
  { label: "Spanish", value: "spa" },
  { label: "Albanian", value: "sqi" },
  { label: "Sardinian", value: "srd" },
  { label: "Sranan Tongo", value: "srn" },
  { label: "Serbian", value: "srp" },
  { label: "Serer", value: "srr" },
  { label: "Nilo-Saharan languages", value: "ssa" },
  { label: "Swati", value: "ssw" },
  { label: "Sukuma", value: "suk" },
  { label: "Sundanese", value: "sun" },
  { label: "Susu", value: "sus" },
  { label: "Sumerian", value: "sux" },
  { label: "Swahili", value: "swa" },
  { label: "Swedish", value: "swe" },
  { label: "Classical Syriac", value: "syc" },
  { label: "Syriac", value: "syr" },
  { label: "Tahitian", value: "tah" },
  { label: "Tai languages", value: "tai" },
  { label: "Tamil", value: "tam" },
  { label: "Tatar", value: "tat" },
  { label: "Telugu", value: "tel" },
  { label: "Timne", value: "tem" },
  { label: "Tereno", value: "ter" },
  { label: "Tetum", value: "tet" },
  { label: "Tajik", value: "tgk" },
  { label: "Tagalog", value: "tgl" },
  { label: "Thai", value: "tha" },
  { label: "Tibetan", value: "tib" },
  { label: "Tigre", value: "tig" },
  { label: "Tigrinya", value: "tir" },
  { label: "Tiv", value: "tiv" },
  { label: "Tokelau", value: "tkl" },
  { label: "Klingon", value: "tlh" },
  { label: "Tlingit", value: "tli" },
  { label: "Tamashek", value: "tmh" },
  { label: "Tonga (Nyasa)", value: "tog" },
  { label: "Tonga (Tonga Islands)", value: "ton" },
  { label: "Tok Pisin", value: "tpi" },
  { label: "Tsimshian", value: "tsi" },
  { label: "Tswana", value: "tsn" },
  { label: "Tsonga", value: "tso" },
  { label: "Turkmen", value: "tuk" },
  { label: "Tumbuka", value: "tum" },
  { label: "Tupi languages", value: "tup" },
  { label: "Turkish", value: "tur" },
  { label: "Altaic languages", value: "tut" },
  { label: "Tuvalu", value: "tvl" },
  { label: "Twi", value: "twi" },
  { label: "Tuvinian", value: "tyv" },
  { label: "Udmurt", value: "udm" },
  { label: "Ugaritic", value: "uga" },
  { label: "Uighur", value: "uig" },
  { label: "Ukrainian", value: "ukr" },
  { label: "Umbundu", value: "umb" },
  { label: "Undetermined", value: "und" },
  { label: "Urdu", value: "urd" },
  { label: "Uzbek", value: "uzb" },
  { label: "Vai", value: "vai" },
  { label: "Venda", value: "ven" },
  { label: "Vietnamese", value: "vie" },
  { label: "Volapük", value: "vol" },
  { label: "Votic", value: "vot" },
  { label: "Wakashan languages", value: "wak" },
  { label: "Wolaitta", value: "wal" },
  { label: "Waray", value: "war" },
  { label: "Washo", value: "was" },
  { label: "Welsh", value: "wel" },
  { label: "Sorbian languages", value: "wen" },
  { label: "Walloon", value: "wln" },
  { label: "Wolof", value: "wol" },
  { label: "Kalmyk", value: "xal" },
  { label: "Xhosa", value: "xho" },
  { label: "Yao", value: "yao" },
  { label: "Yapese", value: "yap" },
  { label: "Yiddish", value: "yid" },
  { label: "Yoruba", value: "yor" },
  { label: "Yupik languages", value: "ypk" },
  { label: "Zapotec", value: "zap" },
  { label: "Blissymbols", value: "zbl" },
  { label: "Zenaga", value: "zen" },
  { label: "Standard Moroccan Tamazight", value: "zgh" },
  { label: "Zhuang", value: "zha" },
  { label: "Chinese", value: "zho" },
  { label: "Zande languages", value: "znd" },
  { label: "Zulu", value: "zul" },
  { label: "Zuni", value: "zun" },
  { label: "No linguistic content", value: "zxx" },
  { label: "Zaza", value: "zza" },
];

export default languages;
