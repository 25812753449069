import React, { FC, useCallback, useState } from "react";
import { textTypes } from "../../styles/typography";
import Text from "../Text";
import { Container, ImageContainer, InfoContainer, Hover } from "./styled";
import { ReactComponent as AddIcon } from "../../assets/icons/add.svg";
import Button from "../Button";
import { buttonTypes, ButtonSize } from "../../styles/buttons";
import { useNavigate } from "react-router";
import { useStore } from "../../store";
import { Space } from "../../types/constants";
import { getImageSrc } from "../../utils/get-host";

interface ICampaignCard {
	image?: any;
	id: string;
	newCard?: boolean; // add new card
	children?: string; // text for tooltip or for create new
	onSelect?: () => void; // custom click on Select
	onPreview?: () => void; // custom click on Preview
}

const CampaignCard: FC<ICampaignCard> = ({
	children,
	image,
	id,
	newCard,
	onSelect,
	onPreview,
}) => {
	const navigate = useNavigate();
	const { campaigns } = useStore();
	const [leave, setLeave] = useState<boolean>(false);
	const mouseOver = () => {
		setLeave(!leave);
	};

	const preView = (e: any) => {
		if (onPreview !== undefined) {
			campaigns.setTemplate(
				id.match(/[^template|automation\_]\w+/gi)?.shift()! // add regExp
			);
			onPreview();
		} else {
			campaigns.setTemplate(
				id.match(/[^template|automation\_]\w+/gi)?.shift()! // add regExp
			);
			navigate("/marketing/campaigns/newCampaign/preview");
			e.stopPropagation();
		}
	};

	let from = id?.match(/\w+(?=\_)/i)?.shift();
	const onClick = () => {
		if (!newCard && onSelect === undefined && from === "template") {
			campaigns.setTemplate(
				id.match(/[^template|automation\_]\w+/gi)?.shift()!
			);
			navigate("/marketing/campaigns/newCampaign/addSubject");
		} else if (onSelect !== undefined && from === "template") {
			campaigns.setTemplate(
				id.match(/[^template|automation\_]\w+/gi)?.shift()!
			);
			onSelect();
		} else if (id === "newAutomation") {
			// new automation
			navigate("/marketing/campaigns/newAutomationCampaign");
		} else if (id === "newTemplate") {
			// new template
			campaigns.setTemplate(null);
			navigate("/marketing/campaigns/newCampaign/addSubject");
		}
	};

	return (
		<>
			{(newCard && (
				<Container newCard={newCard} onClick={onClick}>
					<AddIcon />
					<Text type={textTypes.bodyLg!} color="primary__900">
						{children || `Create new`}
					</Text>
				</Container>
			)) || (
				<Container onMouseEnter={mouseOver} onMouseLeave={mouseOver}>
					<ImageContainer>
						<Hover leave={leave} pl={Space.xxl} pr={Space.xxl}>
							<Button
								variant={buttonTypes.default}
								size={ButtonSize.sm}
								round
								mb={Space.tiny}
								onClick={onClick}
								fitWidth
							>
								SELECT
							</Button>
							<Button
								variant={buttonTypes.transparentPrimary}
								size={ButtonSize.sm}
								round
								onClick={preView}
								fitWidth
							>
								PREVIEW
							</Button>
						</Hover>
						<img src={getImageSrc(image)} width={246} />
					</ImageContainer>
					<InfoContainer>
						<Text type={textTypes.h6Bold!} mt={Space.sm} ml={Space.sm}>
							{children}
						</Text>
					</InfoContainer>
				</Container>
			)}
		</>
	);
};

export default CampaignCard;
