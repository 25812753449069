import styled from '../../../styles/theme';
import { ReactComponent as BaseBackward } from "../../../assets/icons/backward.svg";
import { ReactComponent as BaseForward } from "../../../assets/icons/forward.svg";

export const Container = styled.div`
    position: sticky;
    display: flex;
    top: 0;
    background-color: #fff;
    padding: 17px 192px;
    z-index: 2;
    @media (max-width: 1700px){
        padding-left: 150px;
        padding-right: 150px
    };
    @media (max-width: 1500px){
        padding-left: 100px;
        padding-right: 100px
    }
    @media (max-width: 1300px){
        padding-left: 70px;
        padding-right: 70px;
    }
`;

export const Devider = styled.div`
    width: 1px;
    height: 32px;
    background-color: #E7E7E8;
    margin-right: 16px;
    margin-left: 16px;
`;

export const Backward = styled(BaseBackward)`
    margin-right: 6px;
    cursor: pointer;
`;

export const Forward = styled(BaseForward)`
    margin-left: 6px;
    cursor: pointer;
`;

export const SaveContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    cursor: pointer;
`;

export const CloseContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    border: 1px solid rgba(74, 74, 74, 0.16);
    background-color: transparent;
    cursor: pointer;
    transition: all 300ms ease;
    :hover{
        background-color: rgba(74, 74, 74, 0.06);
    }
`;

export const EditContainer = styled.div`
    width: 18px;
    height: 18px;
    padding: 2px;
    box-sizing: border-box;
    & > svg {
        width: 14.5px;
        height: 14px;
        & > path {
            stroke: rgba(24, 24, 25, 0.42);
        }
    };
    cursor: pointer;
`;

export const CampaignNameField = styled.input`
    width: 120px;
    border: none;
    background-color: transparent;
    font-size: ${({theme: { typography }}) => typography.h6Bold.size};
    line-height: ${({theme: { typography }}) => typography.h6Bold.lineHeight};
    font-weight: ${({theme: { typography }}) => typography.h6Bold.weight};
    outline: none;
    transition: all 300ms ease;
    box-sizing: border-box;
    color: rgba(24, 24, 25, 0.9);
    pointer-events: none;
    :focus {
        background-color: transparent;
        cursor: pointer;
    };
    ::placeholder{
        color: rgba(24, 24, 25, 0.9);
    }
`;

