import styled from "../../../styles/theme";

export const Container = styled.div`
	position: relative;
	width: 100%;
	height: 100vh;
	overflow: hidden;
`;

export const CardsView = styled.div`
	height: calc(100vh - 200px);
	width: 100%;
`;

export const CardImgCover = styled.div`
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
	position: absolute;
	background: rgba(0,0,0, 0.68);
	transition: 300ms;

	& > button {
		position: relative;
		top: 50%;
		transform: translateY(-50%);
	}
`;

export const Card = styled.div`
	position: relative;
	width: calc(100% - 32px);
	height: 380px;
	margin-right: 16px;
	margin-left: 16px;
	padding-top: 14px;
	padding-bottom: 14px;
	border-radius: 6px;
	background-color: #fff;
	transition: 300ms;

	&:hover {
		box-shadow: 0px 1px 9px 0px rgba(0, 0, 0, 0.56);

		${CardImgCover} {
			opacity: 1;
		}
	}
`;

export const CardImage = styled.div`
	position: relative;
	top: 16px;
	width: 100%;
	height: 285px;
	text-align: center;
	overflow: hidden;

	& img {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		max-width: 100%;
	}
`;

export const CardControls = styled.div`
	position: absolute;
	bottom: 16px;
	width: 100%;

	& svg {
		transition: 300ms;
		cursor: pointer;

		&:hover {
			transform: scale(1.1);
		}
	}
`;

export const ModalContainer = styled.div`
	position: absolute;
	width: 480px;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background: #f6f6f6;
	border-radius: 8px;
	padding: 24px 24px;
`;
