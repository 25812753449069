import styled from "../../../../styles/theme";

export const Container = styled.div`
    width: 100%;
`;

export const FieldsContainer = styled.div`
    background: #fbfbfb;
    width: 100%;
    border-radius: 4px;
    padding: 0px 16px 16px 16px;
`;

export const Label = styled.div`
	display: block;
	margin-left: 2px;
	color: rgba(24, 24, 25, 0.9);
	font-size: 12px;
	line-height: 16px;
`;

export const AddButtonWrap = styled.div`
    background: #fff;
    border-radius: 100%;
`;

export const IconContainer = styled.div`
    position: relative;
    width: 18px;
`;

export const DeleteIcon = styled.div`
    position:absolute;
    top: 0px;
    cursor: pointer;

	& svg path {
		fill: #ea5c52;
	}

	&:hover {
		svg path {
			fill: #ea6852;
		}
	}
`;
