import { FC, useMemo, useEffect, useState, useCallback } from "react";
import Text from "../../../components/Text";
import Button from "../../../components/Button";
import { textTypes } from "../../../styles/typography";
import { Space } from "../../../types/constants";
import { Row, Col } from "../../../components/Grid";
import Table from "../../../components/Table";
import { ButtonSize, buttonTypes } from "../../../styles/buttons";
import { useStore } from "../../../store";
import { DateTime } from "luxon";
import { observer } from "mobx-react-lite";
import GroupEdit from "../../../components/Modal/GroupEdit";
import GroupSend from "../../../components/Modal/GroupSend";
import { ReactComponent as SendSVG } from "../../../assets/icons/send-outline.svg";
import { SendAction } from "./styled";
import TextField from "../../../components/TextField";
import { TextFieldSize } from "../../../styles/text-field";
import { ReactComponent as SearchSVG } from "../../../assets/icons/search.svg";
import WithPermisions from "../../../components/WithPermisions";
import PermissionType from "../../../types/permissions";

const Groups: FC = () => {
	const { dynamicGroups } = useStore();

	const [group, setGroup] = useState<{ isOpen: boolean; id?: string }>({
		isOpen: false,
		id: "",
	});
	const [groupSend, setGroupSend] = useState<{ isOpen: boolean; id?: string }>({
		isOpen: false,
		id: "",
	});
	const [searchValue, setSearchValue] = useState<string>("");

	const groupsData = useMemo(
		() =>
			dynamicGroups.array().map((x) => ({
				id: x.id,
				name: x.name,
				contactType: x.contactType,
				modifiedOn: DateTime.fromISO(x.modifiedOn).toISODate(),
			})),
		[dynamicGroups.array()]
	);

	const groupOnSuccess = useCallback(() => {
		setGroup({
			id: "",
			isOpen: false,
		});
	}, []);

	const openGroup = useCallback((id?: string) => {
		setGroup({
			id: id,
			isOpen: true,
		});
	}, []);

	const groupSendOnClose = useCallback(() => {
		setGroupSend({
			id: "",
			isOpen: false,
		});
	}, []);

	const groupSendOnSuccess = useCallback(() => {
		setGroupSend({
			id: "",
			isOpen: false,
		});
	}, []);

	const openSendGroup = useCallback((id?: string) => {
		setGroupSend({
			id: id,
			isOpen: true,
		});
	}, []);

	const columns = useMemo(
		() => [
			{
				Header: "Id",
				accessor: "id",
			},
			{
				Header: "Name",
				accessor: "name",
			},
			{
				Header: "Type",
				accessor: "contactType",
			},
			{
				Header: "Modified on",
				accessor: "modifiedOn",
			},
		],
		[]
	);

	const postfixColumns = useMemo(
		() => [
			(data: any) => (
				<SendAction
					onClick={() => {
						openSendGroup(data.id);
					}}
				>
					<SendSVG />
				</SendAction>
			),
		],
		[openSendGroup]
	);

	const onSearchChange = useCallback((value: string | number) => {
		setSearchValue(value as string);
	}, []);

	return (
		<>
			<GroupEdit
				id={group.id}
				isOpen={group.isOpen}
				onClose={groupOnSuccess}
				onSuccess={groupOnSuccess}
			/>
			<GroupSend
				id={groupSend.id}
				isOpen={groupSend.isOpen}
				onClose={groupSendOnClose}
				onSuccess={groupSendOnSuccess}
			/>
			<Row pl={Space.xxxl} pr={Space.xxxl} pt={Space.xl} spaceBetween>
				<Col auto>
					<Text type={textTypes.h3Bold!}>All groups</Text>
				</Col>
				<WithPermisions permissions={[PermissionType.ADD_GROUP]}>
					<Col auto>
						<Button
							variant={buttonTypes.default!}
							size={ButtonSize.md}
							onClick={() => openGroup(undefined)}
							round
						>
							ADD GROUP
						</Button>
					</Col>
				</WithPermisions>
			</Row>
			<Row pl={Space.xxxl} pr={Space.xxxl} pt={Space.xl}>
				<Col md={5} lg={4} xl={3}>
					<TextField
						value={searchValue}
						onChange={onSearchChange}
						size={TextFieldSize.md!}
						prefixIcon={
							<>
								<SearchSVG />
							</>
						}
						placeholder="Search group"
						fitWidth
					/>
				</Col>
			</Row>
			<Row pl={Space.xxxl} pr={Space.xxxl} pt={Space.xl}>
				<Table
					columns={columns}
					data={groupsData}
					height={"calc(100vh - 200px)"}
					onClick={(row) => openGroup(row.id)}
					postfixColumns={postfixColumns}
					filter={searchValue}
					placeholder={<Row mt={24} centerItems><Text type={textTypes.captionMd!} alignCenter>No groups available</Text></Row>}
				/>
			</Row>
		</>
	);
};

export default observer(Groups);
