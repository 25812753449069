import styled, { css } from "styled-components";
import { CampaignSettings } from "../AddSubject/styled";

interface IEditButton {
  block?: boolean;
}

export const Container = styled(CampaignSettings)`
  transition: all 300ms ease;
  @media (max-width: 1800px) {
    margin-left: 100px;
    margin-right: 100px;
  }
  @media (max-width: 1700px) {
    margin-left: 50px;
    margin-right: 50px;
  }
  @media (max-width: 1600px) {
    margin-left: 40px;
    margin-right: 40px;
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  //position: relative;
  width: 100%;
  padding: 20px 20px 24px 20px;
  border-radius: 8px;
  background-color: #fff;
  color: rgba(24, 24, 25, 0.9);
`;
/////////////////////////////////////////////
export const TemplateWrapper = styled.div`
  width: 100%;
  height: calc(
    100vh - 500px
  ); // if you want change height of template container
  @media (max-height: 1200px) {
    height: calc(100vh - 400px);
  };
  @media (max-height: 1000px) {
    height: calc(100vh - 330px);
  };
  @media (max-height: 900px) {
    height: calc(100vh - 300px);
  };
`;
export const Card = styled.div`
  position: relative;
  width: calc(100% - 32px);
  height: 285px;
  margin-right: 16px;
  margin-left: 16px;
  padding-top: 14px;
  padding-bottom: 14px;
  margin-bottom: 16px;
  border-radius: 6px;
  background-color: #fff;
  transition: 300ms;
  cursor: pointer;
`;

export const CardImage = styled.div`
  position: relative;
  top: 16px;
  width: 100%;
  height: 255px;
  text-align: center;
  //overflow: hidden;

  & img {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    max-width: 100%;
  }
`;
/////////////////////////////////////////////////////////////
export const EditButton = styled.div<IEditButton>`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666666;
  cursor: pointer;
  ${({ block }) =>
    (block &&
      css`
        width: 32px;
        height: 32px;
        background-color: rgba(65, 65, 65, 0.04);
        border-radius: 32px;
      `) ||
    css`
      width: fit-content;
      height: 20px;
      font-weight: 600;
      font-size: 11px;
      line-height: 20px;
      /* top: 20px;
      right: 20px; */
      & > svg {
        & > path {
          stroke: rgba(24, 24, 25, 0.42);
        }
      }
    `}
`;

export const TemplateContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 24px 0 24px;
  border-radius: 8px;
  background-color: #fff;
  color: rgba(24, 24, 25, 0.9);
  height: calc(100ch - 100px);
  width: 100%;
  overflow-y:hidden;

  @media (max-height: 1100px) {
    height: calc(100ch - 200px);
  };
  @media (max-height: 970px) {
    height: calc(100ch - 270px);
  }
  @media (max-height: 900px) {
    height: calc(100ch - 300px);
  };
  @media (max-height: 870px) {
    height: calc(100ch - 350px);
  };
  @media (max-height: 800px) {
    height: calc(100ch - 400px);
  };
`;

export const EditContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

interface IWrapper {
  width?: number;
}

export const Wrapper = styled.div<IWrapper>`
  width: ${({ width }) => width}px;
  margin: 0;
  padding: 0;
  @media (max-width: 1500px) {
    width: 400px;
  }
  @media (max-width: 1300px) {
    width: 350px;
  } ;
`;
