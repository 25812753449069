import { reflectObjectProps } from "../utils/reflection";

export enum ButtonSize {
	xs = "xs",
	sm = "sm",
	md = "md",
	lg = "lg",
}

export const buttons = {
	default: {
		radius: 4,
		padding: {
			// For default button
			default: {
				xs: "4px 8px",
				sm: "6px 12px",
				md: "10px 16px",
				lg: "12px 20px",
			},
			round: {
				xs: "4px 12px",
				sm: "8px 16px",
				md: "12px 24px",
				lg: "12px 24px",
			},
			// For icon button
			icon: {
				xs: "3px 3px",
				sm: "7px 7px",
				md: "8px 8px",
				lg: "12px 12px",
			},
		},
		font: {
			round: {
				xs: {
					size: 10,
					weight: 600,
					lineHeight: 16,
					letterSpacing: 0.5,
				},
				sm: {
					size: 12,
					weight: 600,
					lineHeight: 16,
					letterSpacing: 0.3,
				},
				md: {
					size: 12,
					weight: 600,
					lineHeight: 16,
					letterSpacing: 0.3,
				},
				lg: {
					size: 14,
					weight: 600,
					lineHeight: 20,
					letterSpacing: 0.3,
				},
			},
			default: {
				xs: {
					size: 12,
					weight: 600,
					lineHeight: 16,
					letterSpacing: 0,
				},
				sm: {
					size: 14,
					weight: 600,
					lineHeight: 20,
					letterSpacing: 0,
				},
				md: {
					size: 14,
					weight: 600,
					lineHeight: 20,
					letterSpacing: 0,
				},
				lg: {
					size: 16,
					weight: 600,
					lineHeight: 24,
					letterSpacing: 0,
				},
			},
		},
		background: {
			base: "#0A615C",
			hover:
				"linear-gradient(0deg, rgba(24, 24, 25, 0.08), rgba(24, 24, 25, 0.08)), #0A615C",
			focus:
				"linear-gradient(0deg, rgba(24, 24, 25, 0.16), rgba(24, 24, 25, 0.16)), #0A615C",
			active:
				"linear-gradient(0deg, rgba(24, 24, 25, 0.24), rgba(24, 24, 25, 0.24)), #0A615C",
			disabled: "#0A615C",
		},
		textColor: {
			base: "#FFFFFF",
			hover: "#FFFFFF",
			focus: "#FFFFFF",
			active: "#FFFFFF",
			disabled: "rgba(255, 255, 255, 0.32)",
		},
	},
	softPrimary: {
		radius: 4,
		padding: {
			default: {
				xs: "4px 8px",
				sm: "6px 12px",
				md: "10px 16px",
				lg: "12px 20px",
			},
			round: {
				xs: "4px 12px",
				sm: "8px 16px",
				md: "12px 24px",
				lg: "12px 24px",
			},
			icon: {
				xs: "3px 3px",
				sm: "7px 7px",
				md: "8px 8px",
				lg: "12px 12px",
			},
		},
		font: {
			round: {
				xs: {
					size: 10,
					weight: 600,
					lineHeight: 16,
					letterSpacing: 0.5,
				},
				sm: {
					size: 12,
					weight: 600,
					lineHeight: 16,
					letterSpacing: 0.3,
				},
				md: {
					size: 12,
					weight: 600,
					lineHeight: 16,
					letterSpacing: 0.3,
				},
				lg: {
					size: 14,
					weight: 600,
					lineHeight: 20,
					letterSpacing: 0.3,
				},
			},
			default: {
				xs: {
					size: 12,
					weight: 600,
					lineHeight: 16,
				},
				sm: {
					size: 14,
					weight: 600,
					lineHeight: 20,
				},
				md: {
					size: 14,
					weight: 600,
					lineHeight: 20,
				},
				lg: {
					size: 16,
					weight: 600,
					lineHeight: 24,
				},
			},
		},
		background: {
			base: "rgba(10, 97, 92, 0.04)",
			hover: "rgba(10, 97, 92, 0.08)",
			focus: "rgba(10, 97, 92, 0.12)",
			active: "rgba(10, 97, 92, 0.16)",
			disabled: "rgba(10, 97, 92, 0.04)",
		},
		textColor: {
			base: "rgba(10, 97, 92, 0.9)",
			hover: "rgba(10, 97, 92, 0.9)",
			focus: "rgba(10, 97, 92, 0.9)",
			active: "rgba(10, 97, 92, 0.9)",
			disabled: "rgba(10, 97, 92, 0.32)",
		},
	},
	softSecondary: {
		radius: 4,
		padding: {
			default: {
				xs: "4px 8px",
				sm: "6px 12px",
				md: "10px 16px",
				lg: "12px 20px",
			},
			round: {
				xs: "4px 12px",
				sm: "8px 16px",
				md: "12px 24px",
				lg: "12px 24px",
			},
			icon: {
				xs: "3px 3px",
				sm: "7px 7px",
				md: "8px 8px",
				lg: "12px 12px",
			},
		},
		font: {
			round: {
				xs: {
					size: 10,
					weight: 600,
					lineHeight: 16,
					letterSpacing: 0.5,
				},
				sm: {
					size: 12,
					weight: 600,
					lineHeight: 16,
					letterSpacing: 0.3,
				},
				md: {
					size: 12,
					weight: 600,
					lineHeight: 16,
					letterSpacing: 0.3,
				},
				lg: {
					size: 14,
					weight: 600,
					lineHeight: 20,
					letterSpacing: 0.3,
				},
			},
			default: {
				xs: {
					size: 12,
					weight: 600,
					lineHeight: 16,
				},
				sm: {
					size: 14,
					weight: 600,
					lineHeight: 20,
				},
				md: {
					size: 14,
					weight: 600,
					lineHeight: 20,
				},
				lg: {
					size: 16,
					weight: 600,
					lineHeight: 24,
				},
			},
		},
		background: {
			base: "rgba(65, 65, 65, 0.04)",
			hover: "rgba(74, 74, 74, 0.08)",
			focus: "rgba(74, 74, 74, 0.12)",
			active: "rgba(74, 74, 74, 0.16)",
			disabled: "rgba(65, 65, 65, 0.04)",
		},
		textColor: {
			base: "rgba(24, 24, 25, 0.9)",
			hover: "rgba(24, 24, 25, 0.9)",
			focus: "rgba(24, 24, 25, 0.9)",
			active: "rgba(24, 24, 25, 0.9)",
			disabled: "rgba(24, 24, 25, 0.32)",
		},
	},
	outlinePrimary: {
		radius: 4,
		padding: {
			default: {
				xs: "4px 8px",
				sm: "6px 12px",
				md: "10px 16px",
				lg: "12px 20px",
			},
			round: {
				xs: "4px 12px",
				sm: "8px 16px",
				md: "12px 24px",
				lg: "12px 24px",
			},
			icon: {
				xs: "3px 3px",
				sm: "7px 7px",
				md: "8px 8px",
				lg: "12px 12px",
			},
		},
		font: {
			round: {
				xs: {
					size: 10,
					weight: 600,
					lineHeight: 16,
					letterSpacing: 0.5,
				},
				sm: {
					size: 12,
					weight: 600,
					lineHeight: 16,
					letterSpacing: 0.3,
				},
				md: {
					size: 12,
					weight: 600,
					lineHeight: 16,
					letterSpacing: 0.3,
				},
				lg: {
					size: 14,
					weight: 600,
					lineHeight: 20,
					letterSpacing: 0.3,
				},
			},
			default: {
				xs: {
					size: 12,
					weight: 600,
					lineHeight: 16,
				},
				sm: {
					size: 14,
					weight: 600,
					lineHeight: 20,
				},
				md: {
					size: 14,
					weight: 600,
					lineHeight: 20,
				},
				lg: {
					size: 16,
					weight: 600,
					lineHeight: 24,
				},
			},
		},
		border: {
			base: "1px solid rgba(10, 97, 92, 0.16)",
			hover: "1px solid rgba(10, 97, 92, 0.16)",
			focus: "1px solid rgba(10, 97, 92, 0.16)",
			active: "1px solid rgba(10, 97, 92, 0.16)",
			disabled: "1px solid rgba(10, 97, 92, 0.16)",
		},
		background: {
			base: "none",
			hover: "rgba(10, 97, 92, 0.04)",
			focus: "rgba(10, 97, 92, 0.08)",
			active: "rgba(10, 97, 92, 0.12)",
			disabled: "none",
		},
		textColor: {
			base: "rgba(10, 97, 92, 0.9)",
			hover: "rgba(10, 97, 92, 0.9)",
			focus: "rgba(10, 97, 92, 0.9)",
			active: "rgba(10, 97, 92, 0.9)",
			disabled: "rgba(10, 97, 92, 0.32)",
		},
	},
	outlineSecondary: {
		radius: 4,
		padding: {
			default: {
				xs: "4px 8px",
				sm: "6px 12px",
				md: "10px 16px",
				lg: "12px 20px",
			},
			round: {
				xs: "4px 12px",
				sm: "8px 16px",
				md: "12px 24px",
				lg: "12px 24px",
			},
			icon: {
				xs: "3px 3px",
				sm: "7px 7px",
				md: "8px 8px",
				lg: "12px 12px",
			},
		},
		font: {
			round: {
				xs: {
					size: 10,
					weight: 600,
					lineHeight: 16,
					letterSpacing: 0.5,
				},
				sm: {
					size: 12,
					weight: 600,
					lineHeight: 16,
					letterSpacing: 0.3,
				},
				md: {
					size: 12,
					weight: 600,
					lineHeight: 16,
					letterSpacing: 0.3,
				},
				lg: {
					size: 14,
					weight: 600,
					lineHeight: 20,
					letterSpacing: 0.3,
				},
			},
			default: {
				xs: {
					size: 12,
					weight: 600,
					lineHeight: 16,
				},
				sm: {
					size: 14,
					weight: 600,
					lineHeight: 20,
				},
				md: {
					size: 14,
					weight: 600,
					lineHeight: 20,
				},
				lg: {
					size: 16,
					weight: 600,
					lineHeight: 24,
				},
			},
		},
		border: {
			base: "1px solid rgba(74, 74, 74, 0.16)",
			hover: "1px solid rgba(74, 74, 74, 0.16)",
			focus: "1px solid rgba(74, 74, 74, 0.16)",
			active: "1px solid rgba(74, 74, 74, 0.16)",
			disabled: "1px solid rgba(74, 74, 74, 0.16)",
		},
		background: {
			base: "none",
			hover: "rgba(65, 65, 65, 0.04)",
			focus: "rgba(74, 74, 74, 0.08)",
			active: "rgba(74, 74, 74, 0.12)",
			disabled: "none",
		},
		textColor: {
			base: "rgba(24, 24, 25, 0.9)",
			hover: "rgba(24, 24, 25, 0.9)",
			focus: "rgba(24, 24, 25, 0.9)",
			active: "rgba(24, 24, 25, 0.9)",
			disabled: "rgba(24, 24, 25, 0.32)",
		},
	},
	transparentPrimary: {
		radius: 4,
		padding: {
			default: {
				xs: "4px 8px",
				sm: "6px 12px",
				md: "10px 16px",
				lg: "12px 20px",
			},
			round: {
				xs: "4px 12px",
				sm: "8px 16px",
				md: "12px 24px",
				lg: "12px 24px",
			},
			icon: {
				xs: "3px 3px",
				sm: "7px 7px",
				md: "8px 8px",
				lg: "12px 12px",
			},
		},
		font: {
			round: {
				xs: {
					size: 10,
					weight: 600,
					lineHeight: 16,
					letterSpacing: 0.5,
				},
				sm: {
					size: 12,
					weight: 600,
					lineHeight: 16,
					letterSpacing: 0.3,
				},
				md: {
					size: 12,
					weight: 600,
					lineHeight: 16,
					letterSpacing: 0.3,
				},
				lg: {
					size: 14,
					weight: 600,
					lineHeight: 20,
					letterSpacing: 0.3,
				},
			},
			default: {
				xs: {
					size: 12,
					weight: 600,
					lineHeight: 16,
				},
				sm: {
					size: 14,
					weight: 600,
					lineHeight: 20,
				},
				md: {
					size: 14,
					weight: 600,
					lineHeight: 20,
				},
				lg: {
					size: 16,
					weight: 600,
					lineHeight: 24,
				},
			},
		},
		border: {
			base: "none",
			hover: "none",
			focus: "none",
			active: "none",
			disabled: "none",
		},
		background: {
			base: "rgba(255, 255, 255, 1);",
			hover: "rgba(235, 235, 235, 1);",
			focus: "rgba(201, 201, 201, 1);",
			active: "rgba(163, 163, 163, 1);",
			disabled: "#FFFFFF",
		},
		textColor: {
			base: "rgba(10, 97, 92, 0.9)",
			hover: "rgba(10, 97, 92, 0.9)",
			focus: "rgba(10, 97, 92, 0.9)",
			active: "rgba(10, 97, 92, 0.9)",
			disabled: "rgba(10, 97, 92, 0.32)",
		},
	},
	transparentOutline: {
		radius: 4,
		padding: {
			default: {
				xs: "4px 8px",
				sm: "6px 12px",
				md: "10px 16px",
				lg: "12px 20px",
			},
			round: {
				xs: "4px 12px",
				sm: "8px 16px",
				md: "12px 24px",
				lg: "12px 24px",
			},
			icon: {
				xs: "3px 3px",
				sm: "7px 7px",
				md: "8px 8px",
				lg: "12px 12px",
			},
		},
		font: {
			round: {
				xs: {
					size: 10,
					weight: 600,
					lineHeight: 16,
					letterSpacing: 0.5,
				},
				sm: {
					size: 12,
					weight: 600,
					lineHeight: 16,
					letterSpacing: 0.3,
				},
				md: {
					size: 12,
					weight: 600,
					lineHeight: 16,
					letterSpacing: 0.3,
				},
				lg: {
					size: 14,
					weight: 600,
					lineHeight: 20,
					letterSpacing: 0.3,
				},
			},
			default: {
				xs: {
					size: 12,
					weight: 600,
					lineHeight: 16,
				},
				sm: {
					size: 14,
					weight: 600,
					lineHeight: 20,
				},
				md: {
					size: 14,
					weight: 600,
					lineHeight: 20,
				},
				lg: {
					size: 16,
					weight: 600,
					lineHeight: 24,
				},
			},
		},
		border: {
			base: "none",
			hover: "none",
			focus: "none",
			active: "none",
			disabled: "none",
		},
		background: {
			base: "rgba(255, 255, 255, 1);",
			hover: "rgba(235, 235, 235, 1);",
			focus: "rgba(201, 201, 201, 1);",
			active: "rgba(163, 163, 163, 1);",
			disabled: "#FFFFFF",
		},
		textColor: {
			base: "rgba(24, 24, 25, 0.9)",
			hover: "rgba(24, 24, 25, 0.9)",
			focus: "rgba(24, 24, 25, 0.9)",
			active: "rgba(24, 24, 25, 0.9)",
			disabled: "rgba(24, 24, 25, 0.32)",
		},
	},
	warningRed: {
		radius: 4,
		padding: {
			// For default button
			default: {
				xs: "4px 8px",
				sm: "6px 12px",
				md: "10px 16px",
				lg: "12px 20px",
			},
			round: {
				xs: "4px 12px",
				sm: "8px 16px",
				md: "12px 24px",
				lg: "12px 24px",
			},
			// For icon button
			icon: {
				xs: "3px 3px",
				sm: "7px 7px",
				md: "8px 8px",
				lg: "12px 12px",
			},
		},
		font: {
			round: {
				xs: {
					size: 10,
					weight: 600,
					lineHeight: 16,
					letterSpacing: 0.5,
				},
				sm: {
					size: 12,
					weight: 600,
					lineHeight: 16,
					letterSpacing: 0.3,
				},
				md: {
					size: 12,
					weight: 600,
					lineHeight: 16,
					letterSpacing: 0.3,
				},
				lg: {
					size: 14,
					weight: 600,
					lineHeight: 20,
					letterSpacing: 0.3,
				},
			},
			default: {
				xs: {
					size: 12,
					weight: 600,
					lineHeight: 16,
					letterSpacing: 0,
				},
				sm: {
					size: 14,
					weight: 600,
					lineHeight: 20,
					letterSpacing: 0,
				},
				md: {
					size: 14,
					weight: 600,
					lineHeight: 20,
					letterSpacing: 0,
				},
				lg: {
					size: 16,
					weight: 600,
					lineHeight: 24,
					letterSpacing: 0,
				},
			},
		},
		background: {
			base: "#EA5C52",
			hover: "#d54e44",
			focus: "#d54e44",
			active: "#d54e44",
			disabled: "#b3b3b3",
		},
		textColor: {
			base: "#FFFFFF",
			hover: "#FFFFFF",
			focus: "#FFFFFF",
			active: "#FFFFFF",
			disabled: "rgba(255, 255, 255, 0.32)",
		},
	},
};

export type ColorsDictionary = Record<string, string | Record<string, string>>;

export const buttonTypes = reflectObjectProps(buttons);
