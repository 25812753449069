export enum Space {
	basic = 4,
	tiny = 8,
	xs = 12,
	sm = 16,
	md = 20,
	lg = 24,
	xl = 32,
	xxl = 40,
	xxxl = 48,
	jumbo = 64,
	superJumbo = 96,
}

export const MODAL_ROOT_ID = "modal-root";
export const TOOLTIP_ROOT_ID = "tooltip-root";
