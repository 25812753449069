import { IContactCard } from "../types/contact-center";
import { ENDPOINTS } from "../types/Endpoints";
import authFetch from "../utils/auth-fetch";

export const searchContactCard = async (data: {
	contactType: string;
	email: string;
}) => {
	try {
		const result = await authFetch(
			`${ENDPOINTS.CONTACT_CARD.GET}?email=${data.email}&contactType=${data.contactType}`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
				},
			}
		);

		if (result.ok) {
			return (await result.json()) as IContactCard;
		}

		return null;
	} catch {
		return null;
	}
};

export const getContactCardInfo = async (email: string) => {
	try {
		const result = await authFetch(
			`${ENDPOINTS.CONTACT_CARD.GET}?email=${email}&contactType=all`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
				},
			}
		);

		if (result.ok) {
			return (await result.json()) as IContactCard;
		}

		return null;
	} catch {
		return null;
	}
};

export const deleteContactCard = async (data: {
	contact: IContactCard;
	contactType: string;
}) => {
	const result = await authFetch(ENDPOINTS.CONTACT_CARD.DELETE, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify(data),
	});

	if (result.ok) {
		return true;
	}

	return false;
};
