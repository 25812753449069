import { ENDPOINTS } from "../types/Endpoints";

interface IAuthorizeUser {
	username: string;
	password: string;
}

export const authorizeUser = async (credentials: IAuthorizeUser) => {
	const response = await fetch(ENDPOINTS.AUTH.TOKEN.GENERATE, {
		method: "POST",
		mode: "cors",
		body: JSON.stringify(credentials),
		headers: {
			"Content-Type": "application/json",
		},
	});

	const data: { accessToken: string; username: string } = await response.json();

	if (response.ok) {
		localStorage.setItem("token", data.accessToken);

		return { username: data.username, authorized: true };
	}

	return { username: "", authorized: false };
};

export const validateToken = async () => {
	const token = localStorage.getItem("token");

	if (token) {
		const response = await fetch(ENDPOINTS.AUTH.TOKEN.VALIDATE, {
			method: "POST",
			mode: "cors",
			body: JSON.stringify(token),
			headers: {
				"Content-Type": "application/json",
			},
		});

		const isValid: string = await response.json();

		return response.ok && isValid ? true : false;
	}

	return false;
};
