import parsePhoneNumberFromString from "libphonenumber-js/min";
import { DateTime } from "luxon";

export const required = <T>(value?: T, message = "Field required") =>
	!value ? message : undefined;

export const requiredArray = <T>(
	value?: Array<T>,
	message = "Field required"
) => (!value || value.length < 1 ? message : undefined);

export const requiredMin = (
	value: string,
	min: number,
	message = `Min ${min} characters allowed`
) => (!value || value.length < min ? message : undefined);

export const requiredMax = (
	value: string,
	max: number,
	message = `Min ${max} characters allowed`
) => (value && value.length > max ? message : undefined);

export const requiredMinDate = (
	date: DateTime,
	dtMin: DateTime,
	message = `Inncorrect date`
) => (!date || date < dtMin ? message : undefined);

export const requiredMaxDate = (
	date: DateTime,
	dtMax: DateTime,
	message = `Inncorrect date`
) => (!date || date > dtMax ? message : undefined);

export const requiredValidPhone = (phone?: string) => {
	if (!phone) return "Please, enter a phone number";

	const phoneNumber = parsePhoneNumberFromString(phone);
	const invalidMsg = "Please, enter a valid phone number";

	return phoneNumber
		? phoneNumber.isValid()
			? undefined
			: invalidMsg
		: invalidMsg;
};

export const requiredValidPhones = (values: string[]) => {
	const isPhonesExists = values && values.length > 0;

	return isPhonesExists
		? values?.map((phone: string) => {
				if (!phone) return "Please, enter a phone number";

				const phoneNumber = parsePhoneNumberFromString(phone);
				const invalidMsg = "Please, enter a valid phone number";

				return phoneNumber
					? phoneNumber.isValid()
						? undefined
						: invalidMsg
					: invalidMsg;
		  })
		: undefined;
};

export const requiredValidEmail = (
	value: string,
	msg: string = "Email is not valid"
) =>
	value && /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)
		? undefined
		: msg;

export const requiredValidEmails = (
	values: string[],
	msg: string = "Email is not valid"
) =>
	values.length > 0 && values[0]
		? values?.map((item: string) =>
				// eslint-disable-next-line
				/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(item)
					? undefined
					: msg
		  )
		: undefined;
