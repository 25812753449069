import React, { FC, useCallback, useMemo } from "react";
import Modal from "../";
import { Container, CloseContainer, Wrapper } from "./styled";
import { Row, Col } from "../../Grid";
import Text from "../../Text";
import { textTypes } from "../../../styles/typography";
import { ReactComponent as CloseIcon } from "../../../assets/icons/close.svg";
import { FormTextField as TextField, TextFieldType } from "../../TextField";
import { FormSelect as Select } from "../../Select";
import Button from "../../Button";
import { ButtonSize, buttonTypes } from "../../../styles/buttons";
import { TextFieldSize } from "../../../styles/text-field";
import { Form } from "react-final-form";
import { SelectSize } from "../../../styles/select";
import { sendTestTemplate } from "../../../api/templates";
import { searchReadRateContacts, searchEcommerceContacts, searchBooklandContacts } from "../../../api/autocomplete";
import { required, requiredMin } from "../../../validations";
import { useStore } from "../../../store";
import { FormCheckbox as Checkbox } from "../../Checkbox";
import { CheckboxSize, CheckboxType } from "../../../styles/checkbox";

interface ISendTestTemplateModal {
	isOpen?: boolean;
  id?: string;
	onSuccess: () => void;
	onClose: () => void;
}

interface ISendTestForm{
  email?: { label: string, value: string };
  contactType?: { label: string, value: string };
  recipients?: Array<{ label: string, value: string }> | { label: string, value: string };
  customTitle: boolean;
  title?: string;
}

const emailOptions = [
	{
		label: "no-reply@pocketbook-int.com",
		value: "no-reply@pocketbook-int.com",
	},
	{
		label: "support@readrate.com",
		value: "support@readrate.com",
	},
	{
		label: "mails@bookland.com",
		value: "mails@bookland.com",
	},
];

const contactTypeOptions = [
	{
		label: "Any",
		value: "any",
	},
	{
		label: "E-Commerce",
		value: "ecommerce",
	},
	{
		label: "Bookland",
		value: "obreystore",
	},
	{
		label: "ReadRate",
		value: "readrate",
	},
	{
		label: "Company",
		value: "company",
	},
];

const getAutocomplete = (contactType?:string) => {
  switch(contactType){
    case "ecommerce":
      return searchEcommerceContacts;
    case "obreystore":
      return searchBooklandContacts;
    case "readrate":
      return searchReadRateContacts;
  }

  return undefined;
}

const payload = ({recipients, email, contactType, customTitle, title}:ISendTestForm, templateId:string) => {
  return {
    templateId: templateId,
    recipients: (recipients && (contactType && contactType.value === "company")) 
	? [(recipients as { label: string, value: string }).value] 
	: (recipients as Array<{ label: string, value: string }>).map(x => x.value) || [],
    email: email && email.value || "",
    contactType: contactType && contactType.value || "",
	title: customTitle ? title! : undefined
  }
}

const SendTestTemplateModal: FC<ISendTestTemplateModal> = ({
  id,
	isOpen,
	onClose,
	onSuccess,
}) => {
	const { companies } = useStore();
  const onSubmit = useCallback((values:ISendTestForm) => {
	sendTestTemplate(payload(values, id!));
    onSuccess();
  }, [id]);

  const validate = useCallback(({ email, contactType, recipients, title, customTitle }: ISendTestForm) => {
		const errors = {
			email: required(email && email.value),
			contactType: required(contactType && contactType.value),
      		recipients:  contactType && contactType.value === "company" 
			  ? required(recipients) 
			  : !recipients || (recipients as Array<{ label: string, value: string }>).length < 1 
			  ? "Field required" : undefined,
			title: customTitle ? required(title) || requiredMin(title!, 3) : undefined
		};

		return errors;
	}, []);

  const initialValues = useMemo(() => ({
    recipients: [],
	customTitle: false,
  } as ISendTestForm), []);

	return (
		<Modal onClose={onClose} isOpen={isOpen} backdrop={true}>
			<Container>
				<Wrapper>
					<Row mb={10}>
						<Col>
							<Text type={textTypes.h5Bold!}>Send a test email</Text>
						</Col>
						<Col auto itemRight>
							<CloseContainer onClick={onClose}>
								<CloseIcon />
							</CloseContainer>
						</Col>
					</Row>
					<Form
						onSubmit={onSubmit}
						initialValues={initialValues}
            validate={validate}
						render={({ handleSubmit, values }) => (
							<form onSubmit={handleSubmit}>
								<Row>
									<Select
										name="email"
										label="From"
										size={SelectSize.md}
										placeholder="Select email"
										options={emailOptions}
									/>
								</Row>
								{ values.customTitle &&
								<Row mt={16}>
								<TextField
									name="title"
									label="Title"
									size={TextFieldSize.md}
									fitWidth
									placeholder="Title"
								/>
							</Row>
								}
								<Row mt={16}>
									<Checkbox name="customTitle" label="Custom title" size={CheckboxSize.sm} type={CheckboxType.default}/>
								</Row>
								<Row mt={16}>
									<Select
										name="contactType"
										label="Contact type"
										size={SelectSize.md}
										placeholder="Select contact type"
										options={contactTypeOptions}
									/>
								</Row>
								<Row mt={16}>
                  <Col>
                  {values.contactType && values.contactType.value === "any" ? (
										<Select
											name="recipients"
											label="Recepients"
											placeholder="Type recipients"
											isMulti
											isCreatable
                      isDisabled={!values.contactType || !values.contactType.value}
										/>
									) : (
										<Select
											name="recipients"
											label="Recepients"
											placeholder="Type recipients"
											isMulti={values.contactType && values.contactType.value !== "company"}
                      isDisabled={!values.contactType || !values.contactType.value}
					  options={values.contactType && values.contactType.value === "company" ? companies.array().map(x => ({label: x.name, value: x.id})) : undefined}
                      loadOptions={getAutocomplete(values.contactType && values.contactType.value) as any}
										/>
									)}
                  </Col>
								</Row>
								<Row mt={16}>
									<Col mr={12} itemRight>
										<Button
											type="button"
											size={ButtonSize.lg}
											variant={buttonTypes.outlineSecondary}
											round
											onClick={onClose}
										>
											Cancel
										</Button>
									</Col>
									<Col auto>
										<Button
											type="submit"
											size={ButtonSize.lg}
											variant={buttonTypes.default}
											round
										>
											Send
										</Button>
									</Col>
								</Row>
							</form>
						)}
					/>
				</Wrapper>
			</Container>
		</Modal>
	);
};

export default SendTestTemplateModal;
