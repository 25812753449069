import styled from "../../styles/theme";
import { keyframes, css } from "styled-components";
import { SpinnerSize } from "../../styles/spinners";

const roller = keyframes`
    from{
        transform: rotate(0deg);
    }
    to{
        transform: rotate(360deg);
    }
`;

export const Roller = styled.div`
  display: inline-block; 
  position: relative;
  width: 80px;
  height: 80px;
  & > div {
    animation: ${roller} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite; 
    transform-origin: 40px 40px; 
  }
  & > div:after {
    content: "";
    display: block;
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: ${({ theme: { colors } }) => colors.primary._700};
    margin: -4px 0 0 -4px;
  }
  & > div:nth-child(1) {
    animation-delay: -0.036s;
  }
  & > div:nth-child(1):after {
    top: 63px;
    left: 63px;
  }
  & > div:nth-child(2) {
    animation-delay: -0.072s;
  }
  & > div:nth-child(2):after {
    top: 68px;
    left: 56px;
  }
  & > div:nth-child(3) {
    animation-delay: -0.108s;
  }
  & > div:nth-child(3):after {
    top: 71px;
    left: 48px;
  }
  & > div:nth-child(4) {
    animation-delay: -0.144s;
  }
  & > div:nth-child(4):after {
    top: 72px;
    left: 40px;
  }
  & > div:nth-child(5) {
    animation-delay: -0.18s;
  }
  & > div:nth-child(5):after {
    top: 71px;
    left: 32px;
  }
  & > div:nth-child(6) {
    animation-delay: -0.216s;
  }
  & > div:nth-child(6):after {
    top: 68px;
    left: 24px;
  }
  & > div:nth-child(7) {
    animation-delay: -0.252s;
  }
  & > div:nth-child(7):after {
    top: 63px;
    left: 17px;
  }
  & > div:nth-child(8) {
    animation-delay: -0.288s;
  }
  & > div:nth-child(8):after {
    top: 56px;
    left: 12px;
  }
`;

const bounce = keyframes`
    0%, 80%, 100%{
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    40%{
        -webkit-transform: scale(1.0);
        transform: scale(1.0);
    }
`;

interface IDots {
  size?: string;
}

export const Dots = styled.div<IDots>`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  & > div {
    ${({ size }) =>
      size === SpinnerSize.sm
        ? css`
            width: 4px;
            height: 4px;
        `
        : size === SpinnerSize.md
        ? css`
            width: 6px;
            height: 6px;
        `
        : size === SpinnerSize.lg
        ? css`
            width: 8px;
            height: 8px;
        `
        : css`
            width: 8px;
            height: 8px;
        `};
    background-color: ${({ theme: { colors } }) => colors.primary._700};
    margin: 4px;
    border-radius: 100%;
    display: inline-block;
    animation: ${bounce} 1.4s infinite ease-in-out both;
  }
  & > div:nth-child(1) {
    animation-delay: -0.32s;
  }
  & > div:nth-child(2) {
    animation-delay: -0.16s;
  }
  & > div:nth-child(3) {
    animation-delay: -0.036s;
  }
`;
