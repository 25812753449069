import { FC } from "react";
import { Grid, Row, Col } from "../../components/Grid";
import { Outlet, useLocation } from "react-router-dom";
import Navigation from "../../components/Navigation";
//import { menuItems } from "../../helpers/menu-items";
import { eCommerceRoutes } from "../../types/menu-routes/e-commerce";
import { Transition, TransitionGroup } from "react-transition-group";
import { Wrapper } from "../marketing/index";

const ECommerce: FC = () => {
  const { pathname } = useLocation();
  return (
    <Grid>
      <Row>
        <Col auto>
          <Navigation routes={eCommerceRoutes} />
        </Col>
        <Col>
          <TransitionGroup exit={false}>
            <Transition key={pathname} timeout={300}>
              {(state) => (
                <Wrapper state={state}>
                  <Outlet />
                </Wrapper>
              )}
            </Transition>
          </TransitionGroup>
        </Col>
      </Row>
    </Grid>
  );
};

export default ECommerce;
