import { css } from "styled-components";
import styled from "../../styles/theme";
import { ITypographyText } from "../../styles/typography";
import { IMargin } from "../../types/styles";

export interface ITextStyled extends IMargin {
	type: string;
	color?: string;
	customColor?: string;
	isBlock?: boolean;
	isInline?: boolean;
	isItalic?: boolean;
	alignCenter?: boolean;
	textFloat?: "left" | "right";
	allowNewLines?: boolean;
	underline?: boolean;
	clickable?: boolean;
}

export const textProps = css<ITextStyled>`
	margin-left: ${({ ml }) => ml || 0}px;
	margin-right: ${({ mr }) => mr || 0}px;
	margin-top: ${({ mt }) => mt || 0}px;
	margin-bottom: ${({ mb }) => mb || 0}px;
	overflow-wrap: break-word;
	color: ${({ color, customColor, theme: { colors } }) => {
		if (color) {
			const [prefix, suffix] = color.split("__");

			return suffix
				? (colors as Record<string, string | Record<string, string> | any>)[
						prefix
				  ][`_${suffix}`]
				: (colors as Record<string, string | Record<string, string>>)[prefix];
		} else if (customColor) {
			return customColor;
		} else {
			return colors.black;
		}
	}};
	${({ clickable }) =>
		clickable &&
		css`
			cursor: pointer;
		`}
	${({ allowNewLines }) =>
		allowNewLines &&
		css`
			white-space: pre-wrap;
		`}
	${({ alignCenter }) =>
		alignCenter &&
		css`
			text-align: center;
		`}
		${({ isItalic }) =>
		isItalic &&
		css`
			font-style: italic;
		`}
		${({ underline }) =>
		underline &&
		css`
			text-decoration: underline;
		`}
	${({ textFloat }) =>
		textFloat &&
		css`
			float: ${textFloat};
		`}
	${({ isBlock, isInline }) =>
		isBlock &&
		!isInline &&
		css`
			display: block;
		`}
      ${({ isInline, isBlock }) =>
		isInline &&
		!isBlock &&
		css`
			display: inline-block;
		`}
    font-family: ${({ type, theme: { typography, fonts } }) =>
		(typography as Record<string, ITypographyText>)[type].font ||
		fonts.default};
	font-weight: ${(props) =>
		(props.theme.typography as Record<string, ITypographyText>)[props.type]
			.weight};
	font-size: ${(props) =>
		(props.theme.typography as Record<string, ITypographyText>)[props.type]
			.size};
	line-height: ${(props) =>
		(props.theme.typography as Record<string, ITypographyText>)[props.type]
			.lineHeight};
	letter-spacing: ${(props) =>
		(props.theme.typography as Record<string, ITypographyText>)[props.type]
			.tracking};
	padding: ${({ type, theme: { typography } }) =>
			(typography as Record<string, ITypographyText>)[type].gap || "0.05px"}
		0;

	@media (min-width: 336px) and (max-width: 576px) {
		font-size: ${(props) =>
			(props.theme.typography as Record<string, ITypographyText>)[props.type]
				.sizeXs};
		line-height: unset;
	}

	@media (min-width: 576px) and (max-width: 768px) {
		font-size: ${(props) =>
			(props.theme.typography as Record<string, ITypographyText>)[props.type]
				.sizeSm};
	}

	@media (min-width: 992px) and (max-width: 1280px) {
		font-size: ${(props) =>
			(props.theme.typography as Record<string, ITypographyText>)[props.type]
				.sizeMd};
	}

	&::before {
		content: "";
		margin-top: -${({ type, theme: { typography } }) => (typography as Record<string, ITypographyText>)[type].gap};
		display: block;
		height: 0;
	}

	&::after {
		content: "";
		margin-bottom: -${({ type, theme: { typography } }) => (typography as Record<string, ITypographyText>)[type].gap};
		display: block;
		height: 0;
	}
`;

export const H1 = styled.h1<ITextStyled>`
	${textProps}
`;

export const H2 = styled.h2<ITextStyled>`
	${textProps}
`;

export const H3 = styled.h3<ITextStyled>`
	${textProps}
`;

export const H4 = styled.h4<ITextStyled>`
	${textProps}
`;

export const H5 = styled.h5<ITextStyled>`
	${textProps}
	overflow-wrap: break-word;
`;

export const H5Bold = styled.h5<ITextStyled>`
	${textProps}
`;

export const H5Article = styled.h5<ITextStyled>`
	${textProps}
`;

export const H6 = styled.h6<ITextStyled>`
	${textProps}
`;

export const Body = styled.p<ITextStyled>`
	${textProps}
`;

export const Caption = styled.span<ITextStyled>`
	display: block;
	${textProps}
`;

export const TextWrapper = styled.div<ITextStyled>`
	${textProps}
`;
