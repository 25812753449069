import { FC } from "react";
import { ButtonSize, buttonTypes } from "../../styles/buttons";
import { IMargin } from "../../types/styles";
import { BaseButton, IconContainer } from "./styled";

interface IButton extends IMargin {
  id?: string;
  type?: "button" | "submit" | "reset" | undefined;
  disabled?: boolean;
  round?: boolean;
  icon?: boolean;
  variant?: string;
  size?: string;
  children: JSX.Element | string;
  onClick?: (e?: any) => void;
  fitWidth?: boolean;
}

const Button: FC<IButton> = ({
  variant = buttonTypes.default,
  size = ButtonSize.md,
  round = false,
  icon = false,
  children,
  fitWidth = false,
  ...rest
}) => {
  return (
    <BaseButton
      variant={variant!}
      size={size}
      round={round}
      icon={icon}
      fitWidth={fitWidth}
      {...rest}
    >
      {icon ? <IconContainer size={size}>{children}</IconContainer> : children}
    </BaseButton>
  );
};

export default Button;
