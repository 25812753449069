import { createRef, RefObject, useCallback, useState, useRef } from "react";
import { Fragment } from "react";
import { useEffect } from "react";
import { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
	Container,
	Menu,
	HideNavIcon,
	CategoryContainer,
	CategoryIcon,
	Category,
	CategoryDropDown,
	UpIcon,
	DownIcon,
	MenuItem,
	VerticalDivider,
	Selector,
	LogoIcon,
	MenuItemIcon,
	MenuItemsContainer,
	AllCategories,
	AllCategoriesItem,
	UserAccount,
	Avatar,
	UsersInfo,
	UsersInfoName,
	UsersInfoPost,
	UsersSettingsContainer,
	UsersSettingsHeader,
	UsersSettingsItem,
	SettingsHeaderEmail,
	LogOutContainer,
} from "./styled";
import { ReactComponent as SettingsIcon } from "../../assets/icons/settings.svg";
import { ReactComponent as EditIcon } from "../../assets/icons/edit.svg";
import { ReactComponent as LogOutIcon } from "../../assets/icons/log_out.svg";
import menuRoutes from "../../types/menu-routes"; // add file with array of all cateories
import Tooltip, { useTooltipData } from "../Tooltip";
import { FC } from "react";
import { Transition } from "react-transition-group";
import useOutsideClick from "../../hooks/detectClickOutside";
import { TooltipSide } from "../../styles/tooltips"; // add
import ChangePasswordModal from "../Modal/ChangePassword";
import { useStore } from "../../store";
import PermissionType from "../../types/permissions";

interface INavItem {
	label?: string;
	path: string;
	icon?: any;
	permission?: string;
}

interface INavItems {
	label: string;
	categoryIcon: JSX.Element;
	items: Array<INavItem>;
}

interface INavigation {
	routes: INavItems; // change
}

const DropdownArrows: FC<{ show?: boolean; hide: boolean }> = ({
	show,
	hide,
}) => {
	return (
		<CategoryDropDown hide={hide}>
			<UpIcon show={show} />
			<DownIcon show={show} />
		</CategoryDropDown>
	);
};

const Navigation: FC<INavigation> = ({ routes }) => {
	const { user, userLogOut } = useStore();
	const navigate = useNavigate();
	const location = useLocation();
	const [changePasswordIsOpen, setChangePasswordIsOpen] = useState(false);
	const categoryContainerRef = useRef<any>(null);
	const UserAccountRef = useRef<any>(null);
	const [tooltipIn, tooltipOut, tooltipData] = useTooltipData();
	const [show, setShow] = useState(false); // sidebar
	const [showCategories, setShowCategories] = useOutsideClick(
		categoryContainerRef,
		false
	);
	const [showSettings, setShowSettings] = useOutsideClick(
		UserAccountRef,
		false
	); //show list of settings
	const toggleChangePasswordModal = useCallback(() => {
		setChangePasswordIsOpen(!changePasswordIsOpen);
	}, [changePasswordIsOpen]);

	const settingsItems = useMemo(
		() => [
			{
				icon: <EditIcon />,
				name: "Change password",
				onClick: () => {
					toggleChangePasswordModal();
				},
			},
			{
				icon: <LogOutIcon />,
				name: "Sign out",
				onClick: () => {
					userLogOut();
					navigate("/sign-in");
				},
			},
		],
		[location.pathname, navigate, toggleChangePasswordModal]
	);

	const availableRoutes = useMemo(() => {
		const newRoutes = { ...routes };
		newRoutes.items = newRoutes.items.filter((x) => user.hasPolicy(x.permission as PermissionType) && x.label)

		return newRoutes;
	}, [routes, user.permissions.policies]);

	const itemsRefs = useMemo<
		Record<string, { refs: Array<RefObject<HTMLDivElement>> }>
	>(() => {
		const refs: Record<string, { refs: Array<RefObject<HTMLDivElement>> }> = {};
		// Used loop insted js array func, for performance
		refs[availableRoutes.label] = {
			refs: [],
		};
		for (let i = 0; i < availableRoutes.items.length; i++) {
			refs[availableRoutes.label].refs.push(createRef());
		}
		return refs;
	}, [availableRoutes]);
	const [top, setTop] = useState<number>(0);
	useEffect(() => {
		const path = location.pathname.match(/\/\w+\/\w+/gi)?.shift() || location.pathname;
		for (let j = 0; j < availableRoutes.items.length; j++) {
			if (availableRoutes.items[j].path.indexOf(path!) >= 0) {
				// change (location.pathname) -> path!
				const refItem = itemsRefs[availableRoutes.label].refs[j];
				setTop(refItem?.current!.offsetTop + 5 || 0); // Change 2 on 5
				break;
			}
		}
	}, [location.pathname, availableRoutes, show]);
	const showSideBar = useCallback(() => {
		setShow(!show);
	}, [show]);

	const toggleCategories = useCallback(() => {
		setShowCategories(!showCategories);
	}, [showCategories]);

	const changeCategory = useCallback(
		(path: string) => {
			if (path !== location.pathname) {
				navigate(path, { replace: true });
				setShowCategories(false);
			}
		},
		[showCategories]
	);
	const toggleShowSettings = useCallback(() => {
		setShowSettings(!showSettings);
	}, [showSettings, show]);

	const logOut = useCallback(() => {
		userLogOut();
		navigate("/sign-in");
	}, [location.pathname, navigate]);

	return (
		<Container hide={show}>
			<ChangePasswordModal isOpen={changePasswordIsOpen} onClose={toggleChangePasswordModal} onSuccess={toggleChangePasswordModal} />
			<VerticalDivider />
			<Menu>
				<HideNavIcon
					data-tooltip={(show && "Show sidebar") || "Hide sidebar"}
					onClick={showSideBar}
					onMouseEnter={tooltipIn}
					onMouseLeave={tooltipOut}
					hide={show}
				/>
				{show && <Tooltip {...tooltipData} side={TooltipSide.right} />}
				<LogoIcon hide={show} />
				<Fragment key={availableRoutes.label}>
					<CategoryContainer
						hide={show}
						onClick={toggleCategories}
						ref={categoryContainerRef}
					>
						<CategoryIcon>{availableRoutes.categoryIcon}</CategoryIcon>
						<Category>{availableRoutes.label}</Category>
						<DropdownArrows show={showCategories} hide={show} />
					</CategoryContainer>
					{availableRoutes.items.map((item, index) => (
						<MenuItemsContainer
						key={`${item.label}_${index}`}
						hide={show}
						ref={itemsRefs[availableRoutes.label].refs[index]}
						onClick={() => {
							navigate(item.path);
						}}
					>
						<MenuItemIcon
							data-tooltip={item.label}
							onMouseEnter={tooltipIn}
							onMouseLeave={tooltipOut}
						>
							{item.icon}
						</MenuItemIcon>
						<MenuItem
							isActive={item.path.indexOf(location.pathname) >= 0}
							hide={show}
						>
							{item.label}
						</MenuItem>
					</MenuItemsContainer>
					))}
				</Fragment>
				<UserAccount
					onClick={toggleShowSettings}
					hide={show}
					ref={UserAccountRef}
				>
					<Avatar
						data-id="footer"
						onMouseEnter={tooltipIn}
						onMouseLeave={tooltipOut}
						data-tooltip="Sign out"
					>
						{user.username.substr(0, 2)}
					</Avatar>
					<UsersInfo hide={show}>
						<UsersInfoName>{user.username}</UsersInfoName>
						<UsersInfoPost>{user.permissions.roleName}</UsersInfoPost>
					</UsersInfo>
					<LogOutContainer hide={show}>
						<LogOutIcon onClick={logOut} />
					</LogOutContainer>
				</UserAccount>
			</Menu>
			<Selector position={top} />
			{/* Render List of all categories below. Add allCategoriesRoutes*/}
			<Transition in={showCategories} timeout={200} unmountOnExit>
				{(state) => (
					<AllCategories state={state}>
						{menuRoutes.routesArray.map((item, index) => {
							const isPermitted = Boolean(user.permissions) && user.permissions.departments.indexOf(item.departmentType) > -1;
							return isPermitted ? (
								<AllCategoriesItem
									key={`${item.label}_${index}`}
									onClick={() =>
										changeCategory(menuRoutes.getDefaultPath(item))
									}
								>
									<CategoryIcon>{item.icon}</CategoryIcon>
									{item.label}
								</AllCategoriesItem>
							) : <></>
						})}
					</AllCategories>
				)}
			</Transition>
			{/* Render List of settings categories below */}
			<Transition in={showSettings} timeout={200} unmountOnExit>
				{(state) => (
					<UsersSettingsContainer state={state}>
						{settingsItems.map(({ icon, name, onClick }, index) => (
							<UsersSettingsItem
								key={`${name}_${index}`}
								onClick={() => {
									toggleShowSettings();

									if (onClick) {
										onClick();
									}
								}}
							>
								<MenuItemIcon>{icon}</MenuItemIcon>
								{name}
							</UsersSettingsItem>
						))}
					</UsersSettingsContainer>
				)}
			</Transition>
		</Container>
	);
};

export default Navigation;
