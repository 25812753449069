import { FC, useState, useCallback } from "react";
import Modal from "../..";
import { Container } from "./styled";
import { Row, Col } from "../../../Grid";
import Text from "../../../Text";
import { textTypes } from "../../../../styles/typography";
import { Space } from "../../../../types/constants";
import { ReactComponent as CloseIcon } from "../../../../assets/icons/close.svg";
import CampaignTemplatesModal from "../../CreateCampaign/CalendarCampaignModal/TemplatesModal";
import Preview from "../../CreateCampaign/CalendarCampaignModal/PreviewModal";
import Button from "../../../Button";
import { ButtonSize, buttonTypes } from "../../../../styles/buttons";

interface IEmailsModal {
	isOpen: boolean;
	onClose: () => void;
	onSelect: () => void;
}

const EmailsModal: FC<IEmailsModal> = ({ isOpen, onClose, onSelect }) => {
	const [openPreview, setOpenPreview] = useState<boolean>(false);
	const showPreview = useCallback(() => {
		setOpenPreview(!openPreview);
	}, [openPreview]);
	const closeModal = useCallback(() => {
		setOpenPreview(false);
		onClose();
	}, [openPreview, onClose]);
	const select = useCallback(() => {
		onSelect();
		onClose();
	}, [onSelect]);

	return (
		<>
			<Modal onClose={closeModal} isOpen={isOpen} backdrop={true}>
				<Container>
					{!openPreview && (
						<Row ml={Space.lg} mt={Space.lg} mr={Space.lg}>
							<Col mr={Space.basic}>
								<Text type={textTypes.h4Bold!}>Choose Email</Text>
							</Col>
							<Col mr={0}>
								<Button
									type="button"
									size={ButtonSize.sm}
									variant={buttonTypes.softSecondary!}
									round
									icon
									onClick={onClose}
								>
									<CloseIcon />
								</Button>
							</Col>
						</Row>
					)}
					{(!openPreview && (
						<CampaignTemplatesModal
							onSelect={select}
							onPreview={showPreview}
						/>
					)) ||
						(openPreview && (
							<Preview onSelect={onSelect} onClose={showPreview} />
						))}
				</Container>
			</Modal>
		</>
	);
};

export default EmailsModal;
