import useSWR from "swr";
import { ENDPOINTS } from "../types/Endpoints";
import authFetch from "../utils/auth-fetch";

const contactsFetcher = (url: string) => {
	return authFetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
		},
	}).then((r) => r.json());
};

export const useInternalContacts = (hasPermission: boolean) => {
	const { data } = useSWR(
		hasPermission ? ENDPOINTS.CONTACTS.GET_ALL : null,
		contactsFetcher
	);

	return data;
};

export const uploadInternalContact = async (data: {
	id?: string;
	fullName: string;
	gender?: string;
	//   "company": types.maybeNull,
	email?: string;
	companyPosition?: string;
	prefferedLanguage?: string;
}) => {
	const json = await (
		await authFetch(ENDPOINTS.CONTACTS.ADD_CONTACT, {
			method: data.id ? "PUT" : "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(data),
		})
	).json();

	if (data.id) {
		return json;
	}

	const newId = json && json.split(" ");

	return newId[1] ? newId[1].slice(0, -1) : newId[0]; // id from response
};

export const deleteInternalContact = async (id: string) => {
	const json = await (
		await authFetch(`${ENDPOINTS.CONTACTS.ADD_CONTACT}/${id}`, {
			method: "DELETE",
			headers: {
				"Content-Type": "application/json",
			},
		})
	).json();

	return json;
};
