import { FC, useCallback } from "react";
import { Container, ConditionList, ConditionListItem } from "./styled";
import Modal from "../..";
import { Row, Col } from "../../../Grid";
import Text from "../../../Text";
import { textTypes } from "../../../../styles/typography";
import { Space } from "../../../../types/constants";
import Button from "../../../Button";
import { ButtonSize, buttonTypes } from "../../../../styles/buttons";
import { ReactComponent as CloseIcon } from "../../../../assets/icons/close.svg";
import { Devider } from "../Trigger/styled";
import Scrollbars from "react-custom-scrollbars";
import { ReactComponent as ConditionIcon } from "../../../../assets/icons/condition.svg";
import { ReactComponent as NextIcon } from "../../../../assets/icons/keyboard_arrow_right.svg";
import { NextContainer } from "../Trigger/styled";

interface IConditionModal {
	isOpen: boolean;
	onClose: () => void;
	onSelect: (value: string) => void;
}

const conditionItems: Array<string> = [
	"Campaign activity",
	"Field value",
	"Presense in the groups",
];

const ConditionModal: FC<IConditionModal> = ({ isOpen, onClose, onSelect }) => {
	const chooseAction = useCallback((event: any) => {
		if (event.target && event.target.id) {
			onSelect(event.target.innerText);
		}
		onClose();
	}, [onClose, onSelect]);
	return (
		<>
			<Modal isOpen={isOpen} onClose={onClose} backdrop={true}>
				<Container>
					<Row centerItems mb={Space.md}>
						<ConditionIcon />
						<Col ml={Space.basic}>
							<Text type={textTypes.h5Bold!}>Choose condition</Text>
						</Col>
						<Col itemRight>
							<Button
								type="button"
								size={ButtonSize.sm}
								variant={buttonTypes.softSecondary!}
								round
								icon
								onClick={onClose}
							>
								<CloseIcon />
							</Button>
						</Col>
					</Row>
					<ConditionList elements={conditionItems.length}>
						<Scrollbars hideTracksWhenNotNeeded>
							{conditionItems.map((item, index) => {
								return (
									<>
										<ConditionListItem
											id={`${item}`}
											onClick={chooseAction}
										>
											{item}
											<NextContainer>
												<NextIcon />
											</NextContainer>
										</ConditionListItem>
										{index === conditionItems.length - 1 ? null : <Devider />}
									</>
								);
							})}
						</Scrollbars>
					</ConditionList>
				</Container>
			</Modal>
		</>
	);
};

export default ConditionModal;
