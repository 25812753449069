import { FC, useCallback, useState } from "react";
import { Container } from "./styled";
import Modal from "../..";
import { Row, Col } from "../../../Grid";
import Text from "../../../Text";
import Button from "../../../Button";
import { ButtonSize, buttonTypes } from "../../../../styles/buttons";
import { textTypes } from "../../../../styles/typography";
import { ReactComponent as CloseIcon } from "../../../../assets/icons/close.svg";
import { ReactComponent as SandTimeIcon } from "../../../../assets/icons/sand_glass.svg";
import { Space } from "../../../../types/constants";
import { TextField, TextFieldType } from "../../../TextField";
import { TextFieldSize } from "../../../../styles/text-field";
import { Select } from "../../../../components/Select";
import { SelectSize } from "../../../../styles/select";

interface IDelayModal {
	isOpen: boolean;
	onClose: () => void;
	onSave: (delay: string) => void;
}

const time: Array<any> = [
	{ value: "days", label: "days" },
	{ value: "hours", label: "hours" },
];

const DelayModal: FC<IDelayModal> = ({ isOpen, onClose, onSave }) => {
	const [counter, setCounter] = useState<number | string>(0);
	const [delay, setDelay] = useState<string>("hours");
	const save = useCallback(() => {
		onSave(`${counter} ${delay}`)
	}, [counter, delay]);
	return (
		<>
			<Modal isOpen={isOpen} onClose={onClose} backdrop={true}>
				<Container>
					<Row centerItems mb={Space.md}>
						<SandTimeIcon />
						<Col ml={Space.xs}>
							<Text type={textTypes.h5Bold!}>Set delay</Text>
						</Col>
						<Col itemRight>
							<Button
								type="button"
								size={ButtonSize.sm}
								variant={buttonTypes.softSecondary!}
								round
								icon
								onClick={onClose}
							>
								<CloseIcon />
							</Button>
						</Col>
					</Row>
					<Row centerItems mb={Space.sm}>
						<Text type={textTypes.bodyMd!} mr={Space.md}>
							Wait for
						</Text>
						<Col mr={Space.xs}>
							<TextField
								name="numb"
								size={TextFieldSize.md}
								placeholder="0"
								type={TextFieldType.number}
								onChange={(value) => {
									setCounter(value);
									console.log(value);
								}}
							/>
						</Col>
						<Col>
							<Select
								name="time"
								size={SelectSize.md}
								options={time}
								placeholder={time[0].label}
								onChange={(e: any) => {
									setDelay(e.value);
								}}
							/>
						</Col>
					</Row>
					<Row>
						<Col mr={Space.xs} itemRight>
							<Button
								type="button"
								size={ButtonSize.md}
								variant={buttonTypes.outlineSecondary!}
								round
								onClick={onClose}
							>
								CANCEL
							</Button>
						</Col>
						<Col auto>
							<Button
								type="button"
								variant={buttonTypes.default}
								size={ButtonSize.lg}
								round
								onClick={save}
							>
								Save
							</Button>
						</Col>
					</Row>
				</Container>
			</Modal>
		</>
	);
};

export default DelayModal;
