import { reflectObjectProps } from "../utils/reflection";
import { fonts } from "./fonts";

export interface ITypographyText {
	size: string;
	sizeMd: string;
	sizeSm: string;
	sizeXs: string;
	weight: string;
	lineHeight: string;
	tracking: string;
	gap: string;
	font: string;
}

export const typography = {
	h1: {
		font: fonts.default,
		size: "32px",
		sizeMd: "32px",
		sizeSm: "28px",
		sizeXs: "28px",
		weight: "400",
		lineHeight: "91px;",
		tracking: "-0.022em",
		gap: "0.2395em",
	},
	h1Bold: {
		font: fonts.default,
		size: "32px",
		sizeMd: "32px",
		sizeSm: "28px",
		sizeXs: "28px",
		weight: "600",
		lineHeight: "40px",
		tracking: "-0.017em",
		gap: "0.2206em",
	},
	h2: {
		font: fonts.default,
		size: "28px",
		sizeMd: "28px",
		sizeSm: "26px",
		sizeXs: "26px",
		weight: "400",
		lineHeight: "36px",
		tracking: "-0.022em",
		gap: "0.2704em",
	},
	h2Bold: {
		font: fonts.default,
		size: "28px",
		sizeMd: "28px",
		sizeSm: "26px",
		sizeXs: "26px",
		weight: "600",
		lineHeight: "36px",
		tracking: "-0.022em",
		gap: "0.2704em",
	},
	h3: {
		font: fonts.default,
		size: "24px",
		sizeMd: "24px",
		sizeSm: "24px",
		sizeXs: "24px",
		weight: "400",
		lineHeight: "32px",
		tracking: "-0.021em",
		gap: "0.22em",
	},
	h3Bold: {
		font: fonts.default,
		size: "24px",
		sizeMd: "24px",
		sizeSm: "24px",
		sizeXs: "24px",
		weight: "600",
		lineHeight: "32px",
		tracking: "-0.021em",
		gap: "0.22em",
	},
	h4: {
		font: fonts.default,
		size: "20px",
		sizeMd: "20px",
		sizeSm: "20px",
		sizeXs: "20px",
		weight: "400",
		lineHeight: "24px",
		tracking: "-0.019em",
		gap: "0.2374em",
	},
	h4Bold: {
		font: fonts.default,
		size: "20px",
		sizeMd: "20px",
		sizeSm: "20px",
		sizeXs: "20px",
		weight: "600",
		lineHeight: "24px",
		tracking: "-0.019em",
		gap: "0.2374em",
	},
	h5: {
		font: fonts.default,
		size: "18px",
		sizeMd: "18px",
		sizeSm: "18px",
		sizeXs: "18px",
		weight: "400",
		lineHeight: "24px",
		tracking: "-0.017em",
		gap: "0.2206em",
	},
	h5Bold: {
		font: fonts.default,
		size: "18px",
		sizeMd: "18px",
		sizeSm: "18px",
		sizeXs: "18px",
		weight: "600",
		lineHeight: "24px",
		tracking: "-0.017em",
		gap: "0.2206em",
	},
	h6: {
		font: fonts.default,
		size: "16px",
		sizeMd: "16px",
		sizeSm: "16px",
		sizeXs: "16px",
		weight: "400",
		lineHeight: "20px",
		tracking: "-0.011em",
		gap: "0.2455em",
	},
	h6Bold: {
		font: fonts.default,
		size: "16px",
		sizeMd: "16px",
		sizeSm: "16px",
		sizeXs: "16px",
		weight: "600",
		lineHeight: "20px",
		tracking: "-0.011em",
		gap: "0.2455em",
	},
	bodySm: {
		font: fonts.default,
		size: "12px",
		sizeMd: "12px",
		sizeSm: "12px",
		sizeXs: "12px",
		weight: "400",
		lineHeight: "16px",
		tracking: "-0.006em",
		gap: "0.3673em",
	},
	bodySmBold: {
		font: fonts.default,
		size: "12px",
		sizeMd: "12px",
		sizeSm: "12px",
		sizeXs: "12px",
		weight: "600",
		lineHeight: "16px",
		tracking: "-0.006em",
		gap: "0.3673em",
	},
	bodyMd: {
		font: fonts.default,
		size: "14px",
		sizeMd: "14px",
		sizeSm: "14px",
		sizeXs: "14px",
		weight: "400",
		lineHeight: "20px",
		tracking: "-0.006em",
		gap: "0.3673em",
	},
	bodyMdBold: {
		font: fonts.default,
		size: "14px",
		sizeMd: "14px",
		sizeSm: "14px",
		sizeXs: "14px",
		weight: "600",
		lineHeight: "20px",
		tracking: "-0.006em",
		gap: "0.3673em",
	},
	bodyLg: {
		font: fonts.default,
		size: "16px",
		sizeMd: "16px",
		sizeSm: "16px",
		sizeXs: "16px",
		weight: "400",
		lineHeight: "24px",
		tracking: "-0.006em",
		gap: "0.3673em",
	},
	bodyLgBold: {
		font: fonts.default,
		size: "16px",
		sizeMd: "16px",
		sizeSm: "16px",
		sizeXs: "16px",
		weight: "600",
		lineHeight: "24px",
		tracking: "-0.006em",
		gap: "0.3673em",
	},
	captionSm: {
		font: fonts.default,
		size: "10px",
		sizeMd: "10px",
		sizeSm: "10px",
		sizeXs: "10px",
		weight: "600",
		lineHeight: "16px",
		tracking: "0",
		gap: "0.2869em",
	},
	captionMd: {
		font: fonts.default,
		size: "12px",
		sizeMd: "12px",
		sizeSm: "12px",
		sizeXs: "12px",
		weight: "600",
		lineHeight: "16px",
		tracking: "0",
		gap: "0.2869em",
	},
	captionLg: {
		font: fonts.default,
		size: "14px",
		sizeMd: "14px",
		sizeSm: "14px",
		sizeXs: "14px",
		weight: "600",
		lineHeight: "20px",
		tracking: "0",
		gap: "0.2869em",
	},
};

export const textTypes = reflectObjectProps(typography);
