// Specs: https://mjml.io/documentation/#mjml-social
import { isComponentType } from "./utils";

export default (editor: any, { dc, coreMjmlModel, coreMjmlView }: any) => {
	const type = "mj-social-element";

	dc.addType(type, {
		isComponent: isComponentType(type),

		model: {
			...coreMjmlModel,
			defaults: {
				name: editor.I18n.t("grapesjs-mjml.components.names.socialElement"),
				draggable: "[data-gjs-type=mj-social]",
				stylable: [
					"icon-size",
					"text-decoration",
					"align",
					"font-family",
					"font-size",
					"line-height",
					"padding",
					"padding-top",
					"padding-left",
					"padding-right",
					"padding-bottom",
					"border-radius",
					"border-top-left-radius",
					"border-top-right-radius",
					"border-bottom-left-radius",
					"border-bottom-right-radius",
					"background-color",
					"color",
					"vertical-align",
				],
				"style-default": {
					align: "center",
					"font-size": "13px",
					"line-height": "22px",
					"vertical-align": "middle",
				},
				traits: [
					{
						type: "select",
						label: "Icon",
						name: "name",
						options: [
							{ value: "custom", name: "Custom" },
							{ value: "facebook-noshare", name: "Facebook" },
							{ value: "facebook", name: "Facebook share" },
							{ value: "twitter-noshare", name: "Twitter" },
							{ value: "twitter", name: "Twitter share" },
							{ value: "google-noshare", name: "Google" },
							{ value: "google", name: "Google share" },
							{ value: "instagram", name: "Instagram" },
							{ value: "web", name: "Web" },
							{ value: "youtube", name: "Youtube" },
							{ value: "pinterest-noshare", name: "Pinterest" },
							{ value: "pinterest", name: "Pinterest share" },
							{ value: "linkedin-noshare", name: "Linkedin" },
							{ value: "linkedin", name: "Linkedin share" },
							{ value: "snapchat", name: "Snapchat" },
							{ value: "vimeo", name: "Vimeo" },
							{ value: "tumblr-noshare", name: "Tumblr" },
							{ value: "github", name: "Github" },
							{ value: "soundcloud", name: "SoundCloud" },
							{ value: "medium", name: "Medium" },
							{ value: "dribbble", name: "Dribbble" },
							{ value: "xing-noshare", name: "Xing" },
							{ value: "xing", name: "Xing share" },
						],
					},
					{ name: "src" },
					{ name: "href" },
				],
			},
		},

		view: {
			...coreMjmlView,
			tagName: "table",
			attributes: {
				style: "pointer-events: all; float: none; display: inline-table;",
			},

			getMjmlTemplate() {
				let parentView = this.model.parent().view;
				if (parentView.getInnerMjmlTemplate) {
					let mjmlSocial = coreMjmlView.getInnerMjmlTemplate.call(parentView);
					return {
						start: `<mjml><mj-body><mj-column>${mjmlSocial.start}`,
						end: `${mjmlSocial.end}</mj-column></mj-body></mjml>`,
					};
				} else {
					return {
						start: `<mjml><mj-body><mj-column><mj-social>`,
						end: `</mj-social></mj-column></mj-body></mjml>`,
					};
				}
			},

			getTemplateFromEl(sandboxEl: any) {
				return sandboxEl.querySelector("tr > td > table").innerHTML;
			},

			getChildrenSelector() {
				return "img";
			},
		},
	});
};
