import {FC} from 'react';
import { CardsView } from '../TemplateCard/styled';
import { Row, Col } from '../../Grid';
import CampaignCard from '../index';
import Scrollbars from "react-custom-scrollbars";

const AutomationCards: FC = () => {
    return (
      <>
        <CardsView>
          <Scrollbars>
          <Row>
            <Col auto mr={24} mt={24}>
              <CampaignCard id={`newAutomation`} newCard />
            </Col>
          </Row>
          </Scrollbars>
        </CardsView>
      </>
    );
  };

export default AutomationCards;


