import { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useDynamicGroups } from "../../api/dynamic-groups";
import { useTemplates } from "../../api/templates";
import { useStore } from "../../store";
import { useInternalContacts } from "../../api/internal-contacts";
import { useCompanies } from "../../api/companies";
import { useEmailStats } from "../../api/email-stats";
import { useAdminUsers, useAdminRoles } from "../../api/admin";
import PermissionType from "../../types/permissions";

const DataLoader = () => {
	const {
		user,
		dynamicGroups,
		templates,
		internalContacts,
		companies,
		emailStats,
        admin
	} = useStore();
	const groupsData = useDynamicGroups(user.hasPolicy(PermissionType.VIEW_GROUP));
	const templatesData = useTemplates(user.hasPolicy(PermissionType.VIEW_EMAIL_TEMPLATE));
	const internalContactsData = useInternalContacts(user.hasPolicy(PermissionType.VIEW_CONTACT));
	const companiesData = useCompanies(user.hasPolicy(PermissionType.VIEW_COMPANY));
	const emailStatsData = useEmailStats(user.hasPolicy(PermissionType.VIEW_EMAIL_STATS));
    const adminUsersData = useAdminUsers(user.hasPolicy(PermissionType.VIEW_SYSTEM_USER));
    const adminRolesData = useAdminRoles(user.hasPolicy(PermissionType.VIEW_SYSTEM_ROLE));

	useEffect(() => {
		if (groupsData) {
			dynamicGroups.init(groupsData);
		}
	}, [groupsData]);

	useEffect(() => {
		if (templatesData) {
			templates.init(templatesData);
		}
	}, [templatesData]);

	useEffect(() => {
		if (internalContactsData) {
			internalContacts.init(internalContactsData);
		}
	}, [internalContactsData]);

	useEffect(() => {
		if (companiesData) {
			companies.init(companiesData);
		}
	}, [companiesData]);

	useEffect(() => {
		if (emailStatsData) {
			emailStats.init(emailStatsData);
		}
	}, [emailStatsData]);

    useEffect(() => {
		if (adminUsersData) {
			admin.initUsers(adminUsersData);
		}
	}, [adminUsersData]);

	useEffect(() => {
		if (adminRolesData) {
			admin.initRoles(adminRolesData);
		}
	}, [adminRolesData]);

	return <></>;
};

export default observer(DataLoader);
