import { css } from "styled-components";
import styled from "../../styles/theme";
import { SnackBarPositions } from "../../styles/snackbar";
import { fadeIn, fadeOut } from "../../styles/animations";

interface IBaseSnackBar {
	position: string;
	state: string;
}

export const BaseSnackBar = styled.div<IBaseSnackBar>`
	position: fixed;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 386px;
	height: fit-content;
	padding: 16px 20px;
	background-color: rgba(24, 24, 25, 0.9);
	border-radius: 8px;
	color: #fff;
	font-size: ${({ theme: { typography } }) => typography.bodyMdBold.size};
	line-height: ${({ theme: { typography } }) =>
		typography.bodyMdBold.lineHeight};
	font-weight: ${({ theme: { typography } }) => typography.bodyMdBold.weight};
	margin: 20px;
	box-shadow: 2px 4px 20px 4px rgba(34, 60, 80, 0.11);
	${({ position }) =>
		position === SnackBarPositions.topLeft
			? css`
					left: 0;
			  `
			: position === SnackBarPositions.topCenter
			? css`
					left: 50%;
					transform: translateX(-50%);
			  `
			: position === SnackBarPositions.topRight
			? css`
					right: 0;
			  `
			: position === SnackBarPositions.midLeft
			? css`
					left: 0;
					top: 50%;
					transform: translateY(-100%);
			  `
			: position === SnackBarPositions.midCenter
			? css`
					left: 50%;
					top: 50%;
					transform: translate(-50%, -100%);
			  `
			: position === SnackBarPositions.midRight
			? css`
					right: 0;
					top: 50%;
					transform: translateY(-100%);
			  `
			: position === SnackBarPositions.botLeft
			? css`
					left: 0;
					bottom: 0;
			  `
			: position === SnackBarPositions.botCenter
			? css`
					left: 50%;
					bottom: 0;
					transform: translateX(-50%);
			  `
			: position === SnackBarPositions.botRight
			? css`
					right: 0;
					bottom: 0;
			  `
			: null};
	${({ state }) =>
		(state === "entering" &&
			css`
				animation: ${fadeIn} 300ms;
			`) ||
		(state === "exiting" &&
			css`
				animation: ${fadeOut} 300ms;
			`)};
`;

export const Button = styled.button`
	display: flex;
	width: fit-content;
	height: 32px;
	background-color: rgba(10, 97, 92, 1);
	padding: 8px 24px;
	border-radius: 40px;
	color: #fff;
	font-size: 12px;
	line-height: 16px;
	font-weight: 600;
	letter-spacing: 0.3px;
	margin-left: 20px;
	border: none;
	:hover {
		background-color: #084d49;
	}
	transition: all 300ms ease;
	cursor: pointer;
`;
