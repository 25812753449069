import useSWR from "swr";
import { ENDPOINTS } from "../../types/Endpoints";
import authFetch from "../../utils/auth-fetch";

const usersFetcher = (url: string) => {
	return authFetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
		},
	}).then((r) => r.json());
};

export const useAdminUsers = (hasPermission: boolean) => {
	const { data } = useSWR(
		hasPermission ? ENDPOINTS.ADMIN.USERS.GET_ALL : null,
		usersFetcher
	);

	return data;
};

export const useUsersHistory = (hasPermission: boolean, size?: number) => {
	const { data } = useSWR(
		hasPermission
			? size
				? `${ENDPOINTS.ADMIN.USERS.USER_LOGS}?page=1&size=${size}`
				: `${ENDPOINTS.ADMIN.USERS.USER_LOGS}/all`
			: null,
		usersFetcher
	);

	console.log("data", data);

	return data ? data.items : [];
};

export const getUsersHistory = async (size?: number) => {
	const json = await (
		await authFetch(
			size
				? `${ENDPOINTS.ADMIN.USERS.USER_LOGS}?page=1&size=${size}`
				: `${ENDPOINTS.ADMIN.USERS.USER_LOGS}/all`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
				},
			}
		)
	).json();

	return json && json.items ? json.items : [];
};

export const getUserHistory = async (id: string) => {
	const json = await (
		await authFetch(`${ENDPOINTS.ADMIN.USERS.USER_LOGS}/${id}?page=1&size=40`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
			},
		})
	).json();

	return json && json.items ? json.items : [];
};

export const updateSystemUser = async (data: {
	id?: string;
	username?: string;
	password?: string;
	role: string;
}) => {
	const json = await (
		await authFetch(
			data.id
				? `${ENDPOINTS.ADMIN.USERS.ADD}/${data.id}`
				: ENDPOINTS.ADMIN.USERS.ADD,
			{
				method: data.id ? "PUT" : "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(data),
			}
		)
	).json();

	if (data.id) {
		return json;
	}

	const newId = json && json.split(" ");

	return newId[1] ? newId[1].slice(0, -1) : newId[0]; // id from response
};

export const deleteSystemUser = async (id: string) => {
	const json = await (
		await authFetch(`${ENDPOINTS.ADMIN.USERS.GET}/${id}`, {
			method: "DELETE",
			headers: {
				"Content-Type": "application/json",
			},
		})
	).json();

	return json;
};
