import { keyframes } from "styled-components";

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const fadeOut = keyframes`
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
`;

export const slideInFromRight = keyframes`
  from {
    right: -500px;
  }
  to {
    right: 0px;
  }
`;

export const slideOutToRight = keyframes`
  from {
    right: 0px;
  }
  to {
    right: -500px;
  }
`;
