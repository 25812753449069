import { reflectObjectProps } from "../utils/reflection";

export const colors = {
	white: "#FFF",
	black: "#000",
	primary: {
		_40: "rgba(10, 97, 92, 0.04)",
		_80: "rgba(10, 97, 92, 0.08)",
		_120: "rgba(10, 97, 92, 0.12)",
		_160: "rgba(10, 97, 92, 0.16)",
		_300: "#01A781",
		_700: "#0A615C",
		_900: "rgba(10, 97, 92, 0.9)",
	},
	cerulean: {
		_50: "#E8EAF6",
		_100: "#C4CBE8",
		_200: "#9DAAD9",
		_300: "#7688C9",
		_400: "#586EBE",
		_500: "#3855B3",
		_600: "#324DA9",
		_700: "#28439D",
		_800: "#203991",
		_900: "#10277C",
	},
	gray: {
		_50: "#F8F8FA",
		_100: "#F0F1F2",
		_200: "#E6E7E9",
		_300: "#D6D6D8",
		_400: "#B1B2B4",
		_500: "#878D94",
		_600: "#5B606B",
		_700: "#454B58",
		_800: "#29303A",
		_900: "#020E19",
	},
	yellow: {
		_50: "#FEFAE7",
		_100: "#FBF1C4",
		_200: "#F8E99F",
		_300: "#F4E07B",
		_400: "#F0D961",
		_500: "#ECD348",
		_600: "#DCC243",
		_700: "#C8AC3D",
		_800: "#B39638",
		_900: "#90722F",
	},
	green: {
		_50: "#E8F5E9",
		_100: "#C8E6C9",
		_200: "#A5D6A7",
		_300: "#81C784",
		_400: "#66BB6A",
		_500: "#4CAF50",
		_600: "#43A047",
		_700: "#388E3C",
		_800: "#2E7D32",
		_900: "#1B5E20",
	},
	red: {
		_50: "#FBE5EA",
		_100: "#F6BECB",
		_200: "#F095A9",
		_300: "#EA6D88",
		_400: "#E35470",
		_500: "#DD425A",
		_600: "#CD3D57",
		_700: "#B73853",
		_800: "#A3324F",
		_900: "#7D2A47",
	},
	teal: {
		_50: "#E0F6F2",
		_100: "#B2E9DE",
		_200: "#7DDCC9",
		_300: "#3DCDB3",
		_400: "#00C0A1",
		_500: "#00B391",
		_600: "#00A483",
		_700: "#009372",
		_800: "#008364",
		_900: "#006547",
	},
	cyan: {
		_50: "#E0F7F8",
		_100: "#B1ECED",
		_200: "#7FDFE2",
		_300: "#4AD1D7",
		_400: "#1CC7D0",
		_500: "#00BDCB",
		_600: "#00ADB8",
		_700: "#00989F",
		_800: "#008488",
		_900: "#00605E",
	},
	blue: {
		_50: "#E1F5FD",
		_100: "#B3E5FB",
		_200: "#81D3F8",
		_300: "#4FC2F4",
		_400: "#29B5F2",
		_500: "#00A8F0",
		_600: "#009AE1",
		_700: "#0087CD",
		_800: "#0076B9",
		_900: "#005698",
	},
	indigo: {
		_50: "#EAEAFD",
		_100: "#CACCF7",
		_200: "#A7ACF2",
		_300: "#828AED",
		_400: "#666EE8",
		_500: "#4A4DE6",
		_600: "#4544DA",
		_700: "#3C38CE",
		_800: "#342BC2",
		_900: "#250DB0",
	},
	purple: {
		_50: "#F2E7FC",
		_100: "#DDC4F7",
		_200: "#C79BF2",
		_300: "#B16FED",
		_400: "#9E4AE8",
		_500: "#8C18E2",
		_600: "#8110DC",
		_700: "#7300D3",
		_800: "#6500CE",
		_900: "#4E00C6",
	},
	pink: {
		_50: "#FBE5EE",
		_100: "#F6BED6",
		_200: "#F194BA",
		_300: "#ED6A9F",
		_400: "#EA4C89",
		_500: "#E83274",
		_600: "#D72F70",
		_700: "#C02C69",
		_800: "#AA2964",
		_900: "#852259",
	},
	orange: {
		_50: "#FBEDE7",
		_100: "#FFD0BC",
		_200: "#FFB291",
		_300: "#FF9365",
		_400: "#FF7B43",
		_500: "#FF6422",
		_600: "#F45E1E",
		_700: "#E65719",
		_800: "#D85015",
		_900: "#BF420C",
	},
	amber: {
		_50: "#FFF8E1",
		_100: "#FFECB3",
		_200: "#FFE082",
		_300: "#FFD54F",
		_400: "#FFCA28",
		_500: "#FFC107",
		_600: "#FFB300",
		_700: "#FFA000",
		_800: "#FF8F00",
		_900: "#ED6700",
	},
	brown: {
		_50: "#FAF0E7",
		_100: "#EAD9CE",
		_200: "#D8C1B2",
		_300: "#C4A794",
		_400: "#B3917B",
		_500: "#A27C62",
		_600: "#95715A",
		_700: "#84634F",
		_800: "#755646",
		_900: "#64473A",
	},
};

export type ColorsDictionary = Record<string, string | Record<string, string>>;
const baseColors = reflectObjectProps(colors);

export const colorTypes = {
	...baseColors,
	...{
		primary: reflectObjectProps(colors.primary, "primary"),
		cerulean: reflectObjectProps(colors.cerulean, "cerulean"),
		gray: reflectObjectProps(colors.gray, "gray"),
		yellow: reflectObjectProps(colors.yellow, "yellow"),
		green: reflectObjectProps(colors.green, "green"),
		red: reflectObjectProps(colors.red, "red"),
		teal: reflectObjectProps(colors.teal, "teal"),
		cyan: reflectObjectProps(colors.cyan, "cyan"),
		blue: reflectObjectProps(colors.blue, "blue"),
		indigo: reflectObjectProps(colors.indigo, "indigo"),
		purple: reflectObjectProps(colors.purple, "purple"),
		pink: reflectObjectProps(colors.pink, "pink"),
		orange: reflectObjectProps(colors.orange, "orange"),
		amber: reflectObjectProps(colors.amber, "amber"),
		brown: reflectObjectProps(colors.brown, "brown"),
	},
};
