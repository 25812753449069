import { css } from "styled-components";
import { baseProps } from "../../styles/helpers";
import { IMargin, IPadding } from "../../types/styles";
import styled from "../../styles/theme";

interface ICol extends IMargin, IPadding {
	xs?: number;
	sm?: number;
	md?: number;
	lg?: number;
	xl?: number;
	auto?: boolean;
	center?: boolean;
	itemRight?: boolean;
	xsHidden?: boolean;
	smHidden?: boolean;
	mdHidden?: boolean;
	lgHidden?: boolean;
	xlHidden?: boolean;
	xsOrder?: number;
	smOrder?: number;
	mdOrder?: number;
	xsPt?: number;
	xsPb?: number;
	xsPl?: number;
	xsPr?: number;
	smPt?: number;
	smPb?: number;
	smPl?: number;
	smPr?: number;
	mdPt?: number;
	mdPb?: number;
	mdPl?: number;
	mdPr?: number;
	lgPt?: number;
	lgPb?: number;
	lgPl?: number;
	lgPr?: number;
	xlPt?: number;
	xlPb?: number;
	xlPl?: number;
	xlPr?: number;
}

export const Grid = styled.div`
	${baseProps}
	width: 100%;
	height: 100vh; // add height
`;

interface IRow extends IMargin, IPadding {
	centerItems?: boolean;
	xsMt?: number;
	xsCenterItems?: boolean;
	spaceBetween?: boolean;
	fitWidth?: boolean;
}

export const Row = styled.div<IRow>`
	${baseProps}
	flex-direction: row;
	display: flex;
	flex-wrap: wrap;

	${({ fitWidth }) =>
		fitWidth &&
		css`
			width: 100%;
		`}
	${({ spaceBetween }) =>
		spaceBetween &&
		css`
			justify-content: space-between;
		`}
	${({ centerItems }) =>
		centerItems &&
		css`
			align-items: center;
			justify-content: center;
		`}

	${({ xsCenterItems }) =>
		xsCenterItems !== undefined &&
		css`
			@media (min-width: 336px) and (max-width: 576px) {
				align-items: ${xsCenterItems ? "center" : "unset"};
				justify-content: ${xsCenterItems ? "center" : "unset"};
			}
		`}

		${({ xsMt }) =>
		xsMt !== undefined &&
		css`
			@media (min-width: 336px) and (max-width: 576px) {
				margin-top: ${xsMt}px;
			}
		`}
`;

const colSizes: Array<string> = [
	"8.33333333%",
	"16.6666666667%",
	"25%",
	"33.3333333333%",
	"41.6666666667%",
	"50%",
	"58.3333333333%",
	"66.6666666666%",
	"75%",
	"83.3333333333%",
	"91.6666666666%",
	"100%",
];

export const Col = styled.div<ICol>`
	${baseProps}
	flex: ${({ auto }) => (auto ? "0 0 auto" : "1")};
	${({ center }) =>
		center &&
		css`
			align-self: center;
		`}

	${({ itemRight }) =>
		itemRight &&
		css`
			& {
				text-align: end;
			}
			& > div > div {
				margin-left: auto;
			}
		`}

	${({ xsOrder }) =>
		xsOrder &&
		css`
			@media (min-width: 336px) and (max-width: 576px) {
				order: ${xsOrder};
			}
		`}

		${({ smOrder }) =>
		smOrder &&
		css`
			@media (max-width: 768px) {
				order: ${smOrder};
			}
		`}

		${({ mdOrder }) =>
		mdOrder &&
		css`
			@media (min-width: 768px) and (max-width: 1164px) {
				order: ${mdOrder};
			}
		`}

	${({ xs }) =>
		xs &&
		css`
			@media (max-width: 576px) {
				flex: 0 0 ${colSizes[xs - 1]};
			}

			@media (min-width: 576px) {
				flex: 0 0 ${colSizes[xs - 1]};
			}
		`}

	${({ sm }) =>
		sm &&
		css`
			@media (min-width: 768px) {
				flex: 0 0 ${colSizes[sm - 1]};
			}
		`}

    ${({ md }) =>
		md &&
		css`
			@media (min-width: 992px) {
				flex: 0 0 ${colSizes[md - 1]};
			}
		`}

  ${({ lg }) =>
		lg &&
		css`
			@media (min-width: 1200px) {
				flex: 0 0 ${colSizes[lg - 1]};
			}
		`}

  ${({ xl }) =>
		xl &&
		css`
			@media (min-width: 1900px) {
				flex: 0 0 ${colSizes[xl - 1]};
			}
		`}
`;
