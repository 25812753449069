import { FC, useCallback, useMemo, useState } from "react";
import Text from "../../../components/Text";
import Button from "../../../components/Button";
import { textTypes } from "../../../styles/typography";
import { Space } from "../../../types/constants";
import { Row, Col } from "../../../components/Grid";
import Table from "../../../components/Table";
import { persons } from "../../../types/random-persons";
import { ButtonSize, buttonTypes } from "../../../styles/buttons";
import CompanySidePage from "../../../components/Modal/Company";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../store";
import { DateTime } from "luxon";
import TextField from "../../../components/TextField";
import { TextFieldSize } from "../../../styles/text-field";
import { ReactComponent as SearchSVG } from "../../../assets/icons/search.svg";
import WithPermisions from "../../../components/WithPermisions";
import PermissionType from "../../../types/permissions";

const Companies: FC = () => {
	const { companies } = useStore();

	const [company, setCompany] = useState<{
		id?: string;
		isOpen: boolean;
	}>({
		id: undefined,
		isOpen: false,
	});

	const [searchValue, setSearchValue] = useState<string>("");

	const data = useMemo(
		() =>
			companies.array().map((x) => ({
				id: x.id,
				name: x.name,
				web: x.webAddress,
				responsible: x.responsible,
				employees: x.employeesCount(),
				modifiedOn: DateTime.fromISO(x.modifiedOn).toISODate(),
			})),
		[companies.array()]
	);

	const columns = useMemo(
		() => [
			{
				Header: "Name",
				accessor: "name",
			},
			{
				Header: "Web adress",
				accessor: "web",
			},
			{
				Header: "Responsible",
				accessor: "responsible",
			},
			{
				Header: "Employees",
				accessor: "employees",
			},
			{
				Header: "Modified on",
				accessor: "modifiedOn",
			},
		],
		[]
	);

	const createCompanyInfo = useCallback(
		(id: string | undefined = undefined) => {
			setCompany({
				id: undefined,
				isOpen: true,
			});
		},
		[company]
	);

	const openCompanyInfo = useCallback(
		(id: string | undefined = undefined) => {
			setCompany({
				id: id,
				isOpen: true,
			});
		},
		[company]
	);

	const closeCompanyInfo = useCallback(() => {
		setCompany({
			id: undefined,
			isOpen: false,
		});
	}, [company]);

	const onSearchChange = useCallback((value: string | number) => {
		setSearchValue(value as string);
	}, []);

	return (
		<>
			<CompanySidePage
				id={company.id}
				isOpen={company.isOpen}
				onClose={closeCompanyInfo}
				onSuccess={closeCompanyInfo}
			/>
			<Row pl={Space.xxxl} pr={Space.xxxl} pt={Space.xl} spaceBetween>
				<Col auto>
					<Text type={textTypes.h3Bold!}>All Companies</Text>
				</Col>
				<WithPermisions permissions={[PermissionType.ADD_COMPANY]}>
					<Col auto>
						<Button
							variant={buttonTypes.default!}
							size={ButtonSize.md}
							onClick={createCompanyInfo}
							round
						>
							ADD COMPANY
						</Button>
					</Col>
				</WithPermisions>
			</Row>
			<Row pl={Space.xxxl} pr={Space.xxxl} pt={Space.xl}>
				<Col md={5} lg={4} xl={3}>
					<TextField
						value={searchValue}
						onChange={onSearchChange}
						size={TextFieldSize.md!}
						prefixIcon={
							<>
								<SearchSVG />
							</>
						}
						placeholder="Search company"
						fitWidth
					/>
				</Col>
			</Row>
			<Row pl={Space.xxxl} pr={Space.xxxl} pt={Space.xl}>
				<Table
					columns={columns}
					data={data}
					height={"calc(100vh - 200px)"}
					onClick={(row) => openCompanyInfo(row.id)}
					filter={searchValue}
					placeholder={<Row mt={24} centerItems><Text type={textTypes.captionMd!} alignCenter>No companies available</Text></Row>}
				/>
			</Row>
		</>
	);
};

export default observer(Companies);
