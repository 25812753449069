import { DateTime } from "luxon";
import { types } from "mobx-state-tree";

const StatsModel = types
	.model("StatsModel", {
		id: types.identifier,
		title: types.string,
		contactType: types.string,
		sent: types.number,
		opened: types.number,
		bounced: types.number,
		delivered: types.number,
		createdOn: types.string,
	})
	.views((self) => ({
		get contactTypeTitle() {
			switch (self.contactType) {
				case "obreystore":
					return "bookland";
				default:
					return self.contactType;
			}
		},
	}));

const EmailStatsModel = types
	.model("EmailStatsModel", {
		entries: types.map(StatsModel),
	})
	.actions((self) => ({
		init(contacts?: any) {
			const entriesData = contacts.reduce(
				(acc: any, { id, title, contactsType, ...rest }: any) => {
					return {
						...acc,
						[id]: {
							id: id,
							title: title ? title : "...",
							contactType: contactsType ?? "...",
							...rest,
						},
					};
				},
				{}
			);

			self.entries?.replace(entriesData);
		},
	}))
	.views((self) => ({
		array() {
			return self.entries
				? Array.from(self.entries?.values()).sort((first, second) => {
						const dt1 = first.createdOn && DateTime.fromISO(first.createdOn);
						const dt2 = second.createdOn && DateTime.fromISO(second.createdOn);
						if (dt1! > dt2!) {
							return -1;
						}
						if (dt1! < dt2!) {
							return 1;
						}

						return 0;
				  })
				: [];
		},
	}));

export default EmailStatsModel;
