import React, { FC, useCallback, useState } from "react";
import { CheckboxSize, CheckboxType } from "../../styles/checkbox";
import { Container, HiddenBox, WrapperBox, Icon } from "./styled";
import Text from "../Text";
import { textTypes } from "../../styles/typography";
import { Field, FieldRenderProps } from "react-final-form";

interface ICheckbox {
  name: string; // name of input
  label: string; // text nearby checkbox
  onChange?: (checked: boolean) => void; // func to change checked
  size?: string; // CheckboxSize.sm || .lg
  disabled?: boolean;
  type?: string; // CheckboxType.default || .minus
  checked?: boolean;
}

const Checkbox: FC<ICheckbox> = ({
  name,
  label,
  onChange,
  size = CheckboxSize.sm,
  disabled = false,
  type = CheckboxType.default,
  checked = false,
}) => {
  const change = useCallback(() => {
    onChange && onChange(!checked);
  }, [onChange, checked]);
  return (
    <>
      <Container onClick={change}>
        <HiddenBox name={name} disabled={disabled} value={label}/>
        <WrapperBox
          checked={checked}
          sizeBox={size}
          disabled={disabled}
        >
          {!disabled && checked && (
            <Icon viewBox="0 0 14 14">
              {type === CheckboxType.default ? (
                <polyline points="3,7 11,7" />
              ) : (
                <polyline points="2,7 6,10 12,4" />
              )}
            </Icon>
          )}
        </WrapperBox>
        <Text type={textTypes.bodyLg!}>{label}</Text>
      </Container>
    </>
  );
};

interface ICheckboxWrapper extends FieldRenderProps<string, HTMLElement> {
  label: string;
  size?: string;
  disabled?: boolean;
  checkboxType?: string;
}
const CheckboxWrapper: FC<ICheckboxWrapper> = ({
  label,
  size,
  disabled,
  checkboxType,
  input
}) => {
  return (
    <Checkbox
      label={label}
      size={size}
      disabled={disabled}
      type={checkboxType}
      {...input}
    />
  );
};

interface IFormCheckbox {
  name: string;
  label: string;
  size?: string;
  disabled?: boolean;
  type?: string;
}
export const FormCheckbox: FC<IFormCheckbox> = ({
  type,
 ...props 
}) => {
  return (
    <Field
      {...props}
      type="checkbox"
      checkboxType={type}
      component={CheckboxWrapper}
    />
  );
};

export default Checkbox;
