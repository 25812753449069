import { reflectObjectProps } from "../utils/reflection";

export enum ToggleSize {
  sm = "sm",
  lg = "lg",
}

export enum ToggleVariant {
    active = "active",
    disabled = "disabled",
}

export const toggle = {
    active:{
        size: {
            lg: {
                width: 44,
                height: 24,
            },
            sm: {
                width: 32,
                height: 18,
            },
        },
        borderRadius: 100,
        background: {
            off: "rgba(145, 146, 148, 1)",
            on: "rgba(10, 97, 92, 1)"
        },
    },
    disabled:{
        size: {
            lg: {
                width: 44,
                height: 24,
            },
            sm: {
                width: 32,
                height: 18,
            },
        },
        borderRadius: 100,
        background: {
            off: "rgb(222, 222, 223)",
            on: "rgb(237, 240, 248)"
        },
    },
};

export const toggleTypes = reflectObjectProps(toggle);
