import { FC } from "react";
import { SpinnerType } from "../../styles/spinners";
import { Roller, Dots } from "./styled";

interface ISpinner {
  variant: string;
  size?:string; 
};

const Spinner: FC<ISpinner> = ({ variant, size }) => {
  return (
    <>
      {(variant === SpinnerType.ring && (
        <Roller>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </Roller>
      )) ||
        (variant === SpinnerType.dots && (
          <Dots size={size}>
            <div></div>
            <div></div>
            <div></div>
          </Dots>
        ))}
    </>
  );
};

export default Spinner;
