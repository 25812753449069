import { types } from "mobx-state-tree";
import { routes } from "../types/menu-routes";
import PermissionType from "../types/permissions";
import { changePassword } from "../api/user";

const PermissionsModel = types.model("PermissionsModel", {
	roleName: types.string,
	regions: types.array(types.string),
	contactTypes: types.array(types.string),
	departments: types.array(types.string),
	policies: types.array(types.string),
});

const UserModel = types
	.model("UserModel", {
		username: types.optional(types.string, ""),
		permissions: PermissionsModel,
	})
	.actions((self) => ({
		setName(name: string) {
			self.username = name;
		},
		setPermissions(permissions: any) {
			self.permissions = permissions;
		},
		changePassword(data: { currentPassword: string; newPassword: string }) {
			changePassword(data);
		},
	}))
	.views((self) => ({
		hasPolicy(policy: PermissionType) {
			return self.permissions.policies.indexOf(policy) > -1;
		},
		get availableRoutes() {
			const result: Array<string> = [];

			if (
				self.permissions.departments.length <= 0 ||
				self.permissions.policies.length <= 0
			) {
				return result;
			}

			for (let routeKey in routes) {
				const route = routes[routeKey];
				const hasDepartment =
					self.permissions.departments.indexOf(route.departmentType) > -1;

				if (hasDepartment) {
					for (let i = 0; i < route.children.items.length; i++) {
						if (this.hasPolicy(route.children.items[i].permission)) {
							result.push(route.children.items[i].path);
						}
					}
				}
			}

			return result;
		},
	}));

export default UserModel;
