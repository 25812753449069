import React, { FC } from "react";
import Scrollbars from "react-custom-scrollbars";
import Modal from "..";
import { textTypes } from "../../../styles/typography";
import { Row, Col } from "../../Grid";
import Text from "../../Text";
import {
  Container,
  CustomTemplateContainer,
  AutomationTemplateContainer,
  ArrowIconWrap,
  CenterItems,
  AutomationsContainer,
  EventDescription,
} from "./styled";
import { Tabs, Tab } from "../../Tabs";
import { Space } from "../../../types/constants";
//import { ReactComponent as ArrowRight } from "../../../assets/icons/keyboard_arrow_right.svg";
//import { automationsMock } from "../../../types/automations-mock";
import CampaignCard from "../../CampaignCard";
import TemplateCards from '../../CampaignCard/TemplateCard';
import AutomationCards from '../../CampaignCard/AutomationCard';

interface ICreateCampaignModal {
  isOpen?: boolean;
  onSuccess: () => void;
  onClose: () => void;
}

interface IAutomationCard {
  title: string;
  description: string;
  startingEvent: string;
}

const AutomationCard: FC<IAutomationCard> = ({
  title,
  description,
  startingEvent,
}) => {
  return (
    <AutomationTemplateContainer>
      <Row mt={Space.sm} spaceBetween>
        <Col auto>
          <Text
            type={textTypes.bodySm!}
            customColor={"rgba(18, 20, 23, 0.5)"}
            isInline
          >
            {title}
          </Text>
        </Col>
        <Col auto>
          <Text
            type={textTypes.bodySmBold!}
            customColor={"rgba(24, 24, 25, 0.7)"}
            isInline
          >
            Choose
          </Text>
          <Text
            type={textTypes.bodySmBold!}
            customColor={"rgba(24, 24, 25, 0.7)"}
            isInline
            ml={Space.sm}
          >
            View
          </Text>
        </Col>
      </Row>
      <Row mt={Space.tiny}>
        <Col auto>
          <Text type={textTypes.bodyLg!} customColor={"#121417"} isInline>
            {description}
          </Text>
        </Col>
      </Row>
      <EventDescription>
        <Text
          type={textTypes.bodyMd!}
          customColor={"rgba(24, 24, 25, 0.7)"}
          isInline
        >
          Starting event:
        </Text>
        <Text
          ml={4}
          type={textTypes.bodyMdBold!}
          customColor={"rgba(24, 24, 25, 0.7)"}
          isInline
        >
          {startingEvent}
        </Text>
      </EventDescription>
    </AutomationTemplateContainer>
  );
};

const CreateCampaignModal: FC<ICreateCampaignModal> = ({ isOpen, onClose }) => {
  return (
    <Modal onClose={onClose} isOpen={isOpen} backdrop={true}>
      <Container>
        <Row mt={Space.sm}>
          <Tabs centerTabs>
            <Tab name="Regular campaign">
              <Row pl={Space.lg} pt={Space.lg}>
                <Text type={textTypes.h3Bold!}>Create an Email</Text>
              </Row>
              {/* <Row ml={Space.xl} mr={Space.xl} mt={Space.md}> */}
              <Row ml={Space.xl} mt={Space.md}>
                <Tabs>
                  <Tab name="All templates">
                    <Row mt={Space.lg}>
                      <Col>
                        <TemplateCards />
                      </Col>
                    </Row>
                  </Tab>
                  <Tab name="Celebration">
                    <Row mt={Space.lg}>
                      <Col>
                        <TemplateCards />
                      </Col>
                    </Row>
                  </Tab>
                  <Tab name="Promotion">
                    <Row mt={Space.lg}>
                      <Col>
                        <TemplateCards />
                      </Col>
                    </Row>
                  </Tab>
                  <Tab name="Quiz">
                    <Row mt={Space.lg}>
                      <Col>
                        <TemplateCards />
                      </Col>
                    </Row>
                  </Tab>
                </Tabs>
              </Row>
            </Tab>
            <Tab name="Automation">
              <Row pl={Space.lg} pt={Space.lg}>
                <Text type={textTypes.h2Bold!}>Create an Automation</Text>
              </Row>
              {/* <Row pl={Space.lg} pt={Space.lg}>
                <AutomationsContainer>
                  <Scrollbars>
                    <Row>
                      <Col auto pr={Space.lg} pb={Space.xl}>
                        <CustomAutomationCard />
                      </Col>
                      {automationsMock.map((x) => (
                        <Col auto pr={Space.lg} pb={Space.xl}>
                          <AutomationCard
                            title={x.title}
                            description={x.description}
                            startingEvent={x.startingEvent}
                          />
                        </Col>
                      ))}
                    </Row>
                  </Scrollbars>
                </AutomationsContainer>
              </Row> */}
              {/* vlad change below*/}
              <Row pl={Space.lg}>
                <AutomationCards />
              </Row>
            </Tab>
          </Tabs>
        </Row>
      </Container>
    </Modal>
  );
};

export default CreateCampaignModal;
