import { FC, useMemo } from "react";
import { observer } from "mobx-react-lite";
import { useStore } from "../../store";

interface IWithPermissions {
	permissions: Array<string>;
	allRequired?: boolean;
	notPermitted?: JSX.Element;
	children: JSX.Element;
}

const WithPermissions: FC<IWithPermissions> = ({
	permissions,
	allRequired = false,
	notPermitted = <></>,
	children,
}) => {
	const { user } = useStore();
    const isPermitted = useMemo(() => {
        if(permissions.length === 0){
            return true;
        }

        let hasPermission = false;
        if(allRequired){
            for(let i=0; i < permissions.length; i++){
                hasPermission = Boolean(user.permissions.policies.some((x) => x === permissions[i]));

                if(!hasPermission){
                    break;
                }
            }

            return hasPermission;
        }

        for(let i=0; i < permissions.length; i++){
            hasPermission = Boolean(user.permissions.policies.some((x) => x === permissions[i]));

            if(hasPermission){
                break;
            }
        }

        return hasPermission;
    }, [permissions, allRequired, user.permissions]);

	return isPermitted ? children : notPermitted;
};

export default observer(WithPermissions);
