import { FC, useEffect, useMemo, useState } from "react";
import { FieldArray, FieldArrayRenderProps } from "react-final-form-arrays";
import { Space } from "../../../../types/constants";
import { Row, Col } from "../../../Grid";
import { FormSelect as Select } from "../../../Select";
import {
	Container,
	Label,
	AddButtonWrap,
	FieldsContainer,
	IconContainer,
	DeleteIcon,
} from "./styled";
import {
	FieldType,
	Options,
	RuleOptions,
	RuleData,
	contactTypeFields,
	fieldsLabels,
	fieldsOptions,
	OptionsFetch,
} from "./fields";
import Button from "../../../Button";
import { ButtonSize, buttonTypes } from "../../../../styles/buttons";
import { keys } from "lodash";
import { FormTextField as TextField } from "../../../TextField";
import { FormCalendarField as CalendarField } from "../../../CalendarField";
import { TextFieldSize } from "../../../../styles/text-field";
import { ReactComponent as DeleteSVG } from "../../../../assets/icons/delete.svg";

interface IRule {
	name: string;
	field: string;
	contactType?: string;
	availableRules: Array<string>;
	onRemove?: () => void;
}

interface IFormRule {
	name: string;
}

const fetchedOptionsCache: Record<
	string,
	Array<{ value: string; label: string }>
> = {};

type RuleStateData =
	| { fieldType: FieldType; label: string; options: RuleOptions }
	| undefined;

const Rule: FC<IRule> = ({
	name,
	contactType,
	field,
	availableRules,
	onRemove,
}) => {
	const [ruleData, setRuleData] = useState<RuleStateData>(undefined);

	useEffect(() => {
		const rule =
			contactType && name
				? contactTypeFields[contactType][name] !== undefined && {
						fieldType: contactTypeFields[contactType][name],
						label: fieldsLabels[name],
						options:
							contactTypeFields[contactType][name] === FieldType.Select ||
							contactTypeFields[contactType][name] == FieldType.MultiSelect ||
							contactTypeFields[contactType][name] == FieldType.CurrencyRange
								? fieldsOptions[contactType][name]
								: undefined,
				  }
				: undefined;
		if (rule && rule.options) {
			if (typeof rule.options === "function") {
				const key = `${name}_${contactType}`;

				if (fetchedOptionsCache[key]) {
					setRuleData({
						fieldType: rule.fieldType,
						label: rule.label,
						options: fetchedOptionsCache[key],
					});
				} else {
					(async () => {
						const data = await (rule.options as OptionsFetch)();

						setRuleData({
							fieldType: rule.fieldType,
							label: rule.label,
							options: data as Options[],
						});
					})();
				}
			} else {
				setRuleData({
					fieldType: rule.fieldType,
					label: rule.label,
					options: rule.options as Options[],
				});
			}
		} else if (!rule) {
			setRuleData(undefined);
		} else if (rule && !rule.options) {
			setRuleData({
				fieldType: rule.fieldType,
				label: rule.label,
				options: undefined,
			});
		}
	}, [name, contactType]);

	return ruleData ? (
		<Row mt={Space.md} fitWidth>
			{(ruleData.fieldType === FieldType.Select ||
				ruleData.fieldType === FieldType.MultiSelect) && (
				<>
					<Col>
						<Select
							label={ruleData.label}
							placeholder="Select option"
							name={`${field}.option`}
							options={
								ruleData.options as Array<{ value: string; label: string }>
							}
							isMulti={ruleData.fieldType === FieldType.MultiSelect}
						/>
					</Col>
				</>
			)}
			{ruleData.fieldType === FieldType.Date && (
				<Col>
				<CalendarField label={ruleData.label} name={`${field}.option`} />
				</Col>
			)}
			{ruleData.fieldType === FieldType.DateRange && (
				<>
					<Col pr={4} center>
						<CalendarField
							label={ruleData.label}
							name={`${field}.option.from`}
							placeholder={"From"}
						/>
					</Col>
					<Col pl={4} center>
						<CalendarField
							label={"\u00A0"}
							name={`${field}.option.to`}
							placeholder={"To"}
							calendarRight
						/>
					</Col>
				</>
			)}
			{ruleData.fieldType === FieldType.CurrencyRange && (
				<>
					<Col pr={4} center>
						<Select
							label={ruleData.label}
							placeholder="Currency"
							name={`${field}.option.currency`}
							options={
								ruleData.options as Array<{ value: string; label: string }>
							}
						/>
					</Col>
					<Col pl={4} pr={4} center>
						<TextField
							type="number"
							size={TextFieldSize.md}
							label={"\u00A0"}
							placeholder="Min"
							name={`${field}.option.min`}
							fitWidth
						/>
					</Col>
					<Col pl={4} center>
						<TextField
							type="number"
							size={TextFieldSize.md}
							label={"\u00A0"}
							name={`${field}.option.max`}
							fitWidth
						/>
					</Col>
				</>
			)}
			<Col pl={8} auto center>
				<IconContainer>
				<DeleteIcon onClick={onRemove}>
					<DeleteSVG />
				</DeleteIcon>
				</IconContainer>
			</Col>
		</Row>
	) : name === "" ? (
		<Row mt={Space.md} fitWidth>
			<Select
				placeholder="Select option"
				name={`${field}.name`}
				parse={(data) => data?.value ?? ""}
				options={availableRules.map((x) => ({
					label: fieldsLabels[x],
					value: x,
				}))}
			/>
		</Row>
	) : (
		<></>
	);
};

export const Rules = ({ name, contactType }: any) => {
	const maxAvailableRules = useMemo(
		() =>
			contactType && contactTypeFields[contactType]
				? keys(contactTypeFields[contactType]).length
				: 0,
		[contactType]
	);

	return contactType ? (
		<FieldArray name={name}>
			{({ fields }: FieldArrayRenderProps<IFormRule, HTMLElement>) => {
				let availableRules: Array<string> = [];

				if (contactType && fields) {
					let rulesNames = keys(contactTypeFields[contactType]);
					for (let i = 0; i < rulesNames.length; i++) {
						let contains = false;

						for (let j = 0; j < fields!.length!; j++) {
							if (fields.value[j].name === rulesNames[i]) {
								contains = true;
								break;
							}
						}

						if (!contains) {
							availableRules.push(rulesNames[i]);
						}
					}
				}

				const showOptions = contactType
					? fields.value.some(
							(x) =>
								contactTypeFields[contactType][x.name] !== undefined ||
								x.name === ""
					  )
					: false;

				return (
					<Container>
						<Row mt={Space.md}>
							<Label>Rules</Label>
						</Row>
						<Row mt={Space.sm}>
							{showOptions ? (
								<FieldsContainer>
									<>
										{fields.map((field, index: number) =>
											fields.value[index] !== null ? (
												<Rule
													name={fields.value[index].name}
													field={field}
													contactType={contactType}
													key={index}
													availableRules={availableRules}
													onRemove={() => {
														fields.remove(index);
													}}
												/>
											) : (
												<></>
											)
										)}
									</>
								</FieldsContainer>
							) : (
								<Row fitWidth mt={Space.lg} centerItems>
									<Label>Please click + to show options</Label>
								</Row>
							)}
						</Row>
						{fields !== undefined && fields!.length! < maxAvailableRules ? (
							<Row mt={Space.md} centerItems>
								<AddButtonWrap>
									<Button
										type="button"
										size={ButtonSize.sm}
										variant={buttonTypes.outlineSecondary}
										round
										icon
										onClick={() => {
											fields.push({ name: "" });
										}}
									>
										+
									</Button>
								</AddButtonWrap>
							</Row>
						) : (
							<></>
						)}
					</Container>
				);
			}}
		</FieldArray>
	) : (
		<Container>
			<Row mt={Space.md}>
				<Label>Rules</Label>
			</Row>
			<Row mt={Space.lg} centerItems>
				<Label>Please select contact type to show available options</Label>
			</Row>
		</Container>
	);
};
