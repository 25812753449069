import { css } from "styled-components";
import styled  from "../../../styles/theme";
import { fadeIn, fadeOut } from "../../../styles/animations";

interface IContainer {
    top?: number;
    state?: string;
}

export const Container = styled.div<IContainer>`
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 280px;
    border-radius: 8px;
    top: ${({top}) => top ? top : 40}px;
    background-color: #fff;
    z-index: 9999;
    box-shadow: 2px 4px 20px 4px rgba(34, 60, 80, 0.11);
    color: rgba(24, 24, 25, 0.9);
    font-size: ${({theme: {typography}}) => typography.bodySmBold.size};
    line-height: ${({theme: {typography}}) => typography.bodySmBold.lineHeight};
    font-weight: ${({theme: {typography}}) => typography.bodySmBold.weight};
    ${({ state }) =>
    (state === "entering" &&
      css`
        animation: ${fadeIn} 300ms;
      `) ||
    (state === "exiting" &&
      css`
        animation: ${fadeOut} 300ms;
      `)};
`;

export const Devider = styled.div`
    height: 1px;
    width: 100%;
    background-color: rgba(231, 231, 232, 1);
    margin-top: 15px;
    margin-bottom: 15px;
`;
