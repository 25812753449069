enum PermissionType {
	// Contacts
	VIEW_CONTACT = "View_Contact",
	ADD_CONTACT = "Add_Contact",
	EDIT_CONTACT = "Edit_Contact",
	DELETE_CONTACT = "Delete_Contact",
	// ECommerce contacts
	VIEW_ECOMMERCE_CONTACT = "View_Ecommerce_Contact",
	ADD_ECOMMERCE_CONTACT = "Add_Ecommerce_Contact",
	EDIT_ECOMMERCE_CONTACT = "Edit_Ecommerce_Contact",
	DELETE_ECOMMERCE_CONTACT = "Delete_Ecommerce_Contact",
	// Readrate contacts
	VIEW_READRATE_CONTACT = "View_Readrate_Contact",
	ADD_READRATE_CONTACT = "Add_Readrate_Contact",
	EDIT_READRATE_CONTACT = "Edit_Readrate_Contact",
	DELETE_READRATE_CONTACT = "Delete_Readrate_Contact",
	// Bookland contacts
	VIEW_BOOKLAND_CONTACT = "View_Bookland_Contact",
	ADD_BOOKLAND_CONTACT = "Add_Bookland_Contact",
	EDIT_BOOKLAND_CONTACT = "Edit_Bookland_Contact",
	DELETE_BOOKLAND_CONTACT = "Delete_Bookland_Contact",
	// Contacts cards
	VIEW_CONTACT_CARD = "View_Contact_card",
	DELETE_CONTACT_CARD = "Delete_Contact_card",
	// Companies
	VIEW_COMPANY = "View_Company",
	ADD_COMPANY = "Add_Company",
	EDIT_COMPANY = "Edit_Company",
	DELETE_COMPANY = "Delete_Company",
	//Campaign
	VIEW_CAMPAIGN = "View_Campaign",
	ADD_CAMPAIGN = "Add_Campaign",
	EDIT_CAMPAIGN = "Edit_Campaign",
	DELETE_CAMPAIGN = "Delete_Campaign",
	// Group
	VIEW_GROUP = "View_Group",
	ADD_GROUP = "Add_Group",
	EDIT_GROUP = "Edit_Group",
	DELETE_GROUP = "Delete_Group",
	// System user
	VIEW_SYSTEM_USER = "View_System_User",
	ADD_SYSTEM_USER = "Add_System_User",
	EDIT_SYSTEM_USER = "Edit_System_User",
	DELETE_SYSTEM_USER = "Delete_System_User",
	// System user logs
	VIEW_SYSTEM_USER_LOGS = "View_System_User_Logs",
	// Roles
	VIEW_SYSTEM_ROLE = "View_System_Role",
	ADD_SYSTEM_ROLE = "Add_System_Role",
	EDIT_SYSTEM_ROLE = "Edit_System_Role",
	DELETE_SYSTEM_ROLE = "Delete_System_Role",
	// Email template
	VIEW_EMAIL_TEMPLATE = "View_Email_Template",
	ADD_EMAIL_TEMPLATE = "Add_Email_Template",
	EDIT_EMAIL_TEMPLATE = "Edit_Email_Template",
	DELETE_EMAIL_TEMPLATE = "Delete_Email_Template",
	// Files
	ADD_FILE = "Add_File",
	// Email stats
	VIEW_EMAIL_STATS = "View_Email_Stats",
	// Send email
	SEND_EMAIL_USER = "Send_Email_User",
	SEND_EMAIL_READRATE_USER = "Send_Email_Readrate_User",
	SEND_EMAIL_ECOMMERCE_USER = "Send_Email_Ecommerce_User",
	SEND_EMAIL_BOOKLAND_USER = "Send_Email_Ecommerce_User",
	SEND_EMAIL_COMPANY = "Send_Email_Company",
	SEND_EMAIL_GROUP = "Send_Email_Group",
}

export enum DepartmentType {
	MARKETING = "Marketing",
	CONTACT_CENTER = "ContactCenter",
	ADMIN = "Admin",
}

export default PermissionType;
