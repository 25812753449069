import { ENDPOINTS } from "../types/Endpoints";
import authFetch from "../utils/auth-fetch";

export const getPermissions = async () => {
	const json = await (
		await authFetch(`${ENDPOINTS.USER.GET_PERMISSIONS}`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
			},
		})
	).json();

	return json;
};
