import { FC } from "react";
import { Container } from "./styled";
import { badgesTypes } from "../../styles/badges";

interface IBadge {
	variant?: string; // primary, secondary, warning, success, danger, info
	status?: string; // sent, active, draft, default
	size: string; // xxLarge, xLarge, Large, Medium, Small, xSmall
	type: string; // filled, soft, outlined
	round?: boolean;
	children?: string;
}
const Badge: FC<IBadge> = ({
	variant,
	status,
	size,
	type = badgesTypes.filled!,
	round,
	children,
}) => {
	return (
		<Container
			variant={variant}
			status={status}
			size={size}
			type={type}
			round={round}
		>
			{status !== undefined ? status : children}
		</Container>
	);
};

export default Badge;
