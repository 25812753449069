import useSWR from "swr";
import { ENDPOINTS } from "../types/Endpoints";
import authFetch from "../utils/auth-fetch";
import { getImageSrc } from "../utils/get-host";

const templatesFetcher = (url: string) => {
	//return authFetch(url).then((r) => r.json());
	const token = `${localStorage.getItem("token")}`;

	return fetch(url, {
		method: "POST",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
		body: "{}",
	}).then((r) => r.json());
};

export const useTemplates = (hasPermission: boolean) => {
	const { data } = useSWR(
		hasPermission ? ENDPOINTS.EMAIL_TEMPLATE.QUERY_TEMPLATE : null,
		templatesFetcher
	);

	return data;
};

export const renderTemplate = async (template: string) => {
	const token = `${localStorage.getItem("token")}`;

	const response = await fetch(ENDPOINTS.EMAIL_TEMPLATE.RENDER_TEMPLATE, {
		method: "POST",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
			Accept: "application/json",
		},
		body: JSON.stringify({
			template: template,
		}),
	});

	const json = await response.json();

	return json;
};

export const uploadTemplate = async (data: {
	id?: string;
	image: string;
	name: string;
	template: string;
}) => {
	const json = await (
		await authFetch(ENDPOINTS.EMAIL_TEMPLATE.ADD_TEMPLATE, {
			method: data.id ? "PUT" : "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(data),
		})
	).json();

	return json && json.split(" ")[0]; // id from response
};

export const uploadTemplateImage = async (data: FormData) => {
	const token = `${localStorage.getItem("token")}`;

	const json = await (
		await fetch(ENDPOINTS.FILES.UPLOAD, {
			method: "POST",
			headers: {
				Authorization: `Bearer ${token}`,
				Accept: "application/json",
			},
			body: data,
		})
	).json();

	return json ? getImageSrc(json) : "";
};

export const getTemplate = async (id: string) => {
	return await (
		await authFetch(`${ENDPOINTS.EMAIL_TEMPLATE.GET_TEMPLATE}?id=${id}`)
	).json();
};

export const deleteTemplate = async (id: string) => {
	const token = `${localStorage.getItem("token")}`;

	const json = await (
		await fetch(`${ENDPOINTS.EMAIL_TEMPLATE.ADD_TEMPLATE}/${id}`, {
			method: "DELETE",
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})
	).json();

	return json;
};

export const sendTestTemplate = async (data: {
	recipients: Array<string>;
	contactType: string;
	email: string;
	title?: string;
	templateId: string;
}) => {
	const json = await (
		await authFetch(ENDPOINTS.EMAIL.SEND_TEST_TEMPLATE, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(data),
		})
	).json();

	return json && json.split(" ")[1].slice(0, -1); // id from response
};
