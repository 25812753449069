import { FC, useCallback, useMemo, useState } from "react";
import Text from "../../../components/Text";
import { textTypes } from "../../../styles/typography";
import { Space } from "../../../types/constants";
import { Row, Col } from "../../../components/Grid";
import Table from "../../../components/Table";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../store";
import TextField from "../../../components/TextField";
import { TextFieldSize } from "../../../styles/text-field";
import { ReactComponent as SearchSVG } from "../../../assets/icons/search.svg";
import { ReactComponent as SendSVG } from "../../../assets/icons/send-outline.svg";
import EmailStatsInfo from "../../../components/Modal/EmailStatsInfo";
import { SendAction } from "./styled";

const Stats: FC = () => {
	const { emailStats } = useStore();

	const [statsInfo, setStatsInfo] = useState<{
		id?: string;
		isOpen: boolean;
	}>({
		id: undefined,
		isOpen: false,
	});

	const [searchValue, setSearchValue] = useState<string>("");

	const data = useMemo(
		() =>
      emailStats.array().map(({ contactTypeTitle, createdOn: _co,...rest}) => ({
        contactsTitle: contactTypeTitle,
        ...rest
			})),
		[emailStats.array()]
	);

	const statsInfoOnClose = useCallback(() => { 
		setStatsInfo({
			id: "",
			isOpen: false
		});
	}, []);

	const statsInfoOnSuccess = useCallback(() => { 
		setStatsInfo({
			id: "",
			isOpen: false
		});
	}, []);

	const openStatsInfo = useCallback((id?:string) => { 
		setStatsInfo({
			id: id,
			isOpen: true
		});
	}, []);


	const columns = useMemo(
		() => [
			{
				Header: "Id",
				accessor: "id",
			},
			{
				Header: "Title",
				accessor: "title",
			},
      {
				Header: "Contact type",
				accessor: "contactsTitle",
			},
			{
				Header: "Sent",
				accessor: "sent",
			},
			{
				Header: "Bounced",
				accessor: "bounced",
			},
			{
				Header: "Opened",
				accessor: "opened",
			},
		],
		[]
	);

	const onSearchChange = useCallback((value:string | number) => {
		setSearchValue(value as string);
	}, []);

	const postfixColumns = useMemo(() => [
		(data:any) => <SendAction onClick={() => {
			openStatsInfo(data.id);
		}}><SendSVG/></SendAction>
	], [openStatsInfo]);

	return (
		<>
			<EmailStatsInfo id={statsInfo.id} isOpen={statsInfo.isOpen} onClose={statsInfoOnClose} onSuccess={statsInfoOnSuccess} />
			<Row pl={Space.xxxl} pr={Space.xxxl} pt={Space.xl} spaceBetween>
				<Col auto>
					<Text type={textTypes.h3Bold!}>Stats</Text>
				</Col>
			</Row>
			<Row pl={Space.xxxl} pr={Space.xxxl} pt={Space.xl}>
				<Col md={5} lg={4} xl={3}>
					<TextField value={searchValue} onChange={onSearchChange} size={TextFieldSize.md!} prefixIcon={<><SearchSVG /></>} placeholder="Search" fitWidth />
				</Col>
			</Row>
			<Row pl={Space.xxxl} pr={Space.xxxl} pt={Space.xl}>
				<Table
					columns={columns}
					postfixColumns={postfixColumns}
					data={data}
					height={"calc(100vh - 200px)"}
					onClick={(row) => openStatsInfo(row.id)}
					filter={searchValue}
					placeholder={<Row mt={24} centerItems><Text type={textTypes.captionMd!} alignCenter>No stats available</Text></Row>}
				/>
			</Row>
		</>
	);
};

export default observer(Stats);
