import styled, { css } from "styled-components";
import {
	fadeIn,
	fadeOut,
	slideInFromRight,
	slideOutToRight,
} from "../../../styles/animations";

interface IContainer {
	state?: string;
}

export const Container = styled.div<IContainer>`
	position: fixed;
	top: 0px;
	right: 0px;
	right: 0px;
	width: 500px;
	box-sizing: border-box;
	height: 100%;
	background: #f6f6f6;
	overflow-x: hidden;
	overflow-y: auto;

	${({ state }) =>
		(state === "entered" &&
			css`
				animation: ${slideInFromRight} 250ms;
			`) ||
		(state === "exiting" &&
			css`
				animation: ${slideOutToRight} 250ms;
			`)}
`;

export const BottomContainer = styled.div`
	position: fixed;
	height: 72px;
	width: 500px;
	background: #fff;
	bottom: 0;
	display: flex;
	align-self: center;
`;

export const DeleteIcon = styled.div`
	height: 18px;
	width: 18px;
	& svg path {
		fill: #ea5c52;
	}

	&:hover {
		svg path {
			fill: #ea6852;
		}
	}
`;

export const Avatar = styled.div`
	width: 72px;
	height: 72px;
	border-radius: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background: rgba(1, 167, 129, 0.04);
`;

export const Divider = styled.div`
	height: 1px;
	width: 100%;
	background: #e0e4e9;
`;

export const VerticalDivider = styled.div`
	height: 48px;
	width: 1px;
	background: #e0e4e9;
`;

export const InfoFieldContainer = styled.div`
	display: flex;
	height: 40px;
	width: 370px;
	align-items: center;
	justify-content: center;

	& > div:first-child {
		width: 70px;
	}
	& > div:last-child {
		display: flex;
		width: 300px;
		height: 40px;
		background: #f1f1f1;
		border-radius: 4px;
		align-items: center;
		padding-left: 12px;
	}
`;

export const DeleteWrapper = styled.div`
	position: relative;
	height: 40px;
`;

export const DeleteButton = styled.div`
	max-width: 140px;
	height: 40px;
	display: flex;
	align-items: center;
	cursor: pointer;
	padding-left: 12px;
	padding-right: 12px;
	margin-right: 12px;
	border-radius: 8px;
	user-select: none;

	& svg path {
		fill: #ea5c52;
	}

	&:hover {
		background: rgba(65, 65, 65, 0.04);
		color: #ea6852;
		svg path {
			fill: #ea6852;
		}
	}
`;

interface IDeleteFor {
	state?: string;
}

export const DeleteFor = styled.div<IDeleteFor>`
	position: absolute;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	width: 239px;
	background-color: #fff;
	border-radius: 4px;
	width: 200px;
	bottom: 52px;
	right: 10px;
	padding: 14px 20px;
	padding-bottom: 2px;
	box-shadow: 2px 4px 20px 4px rgba(34, 60, 80, 0.11);
	z-index: 9999;
	cursor: pointer;

	${({ state }) =>
		(state === "entering" &&
			css`
				animation: ${fadeIn} 300ms;
			`) ||
		(state === "exiting" &&
			css`
				animation: ${fadeOut} 300ms;
			`)};
`;

export const ConfirmModalWrapper = styled.div<{ state?: string }>`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.4);
	z-index: 99999;

	${({ state }) =>
		(state === "entering" &&
			css`
				animation: ${fadeIn} 300ms;
			`) ||
		(state === "exiting" &&
			css`
				animation: ${fadeOut} 300ms;
			`)};
`;

export const ConfirmModal = styled.div`
	position: relative;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 480px;
	padding: 24px;
	background: #f6f6f6;
	border-radius: 8px;
`;

export const SpinnerContainer = styled.div`
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
`;
