import styled from "../../../../styles/theme";

export const Container = styled.div`
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
	right: 0px;
	width: 480px;
	height: fit-content;
	padding: 20px 24px 24px 24px;
	background: #f6f6f6;
	box-sizing: border-box;
	//overflow: hidden;
`;

export const CloseContainer = styled.div`
	display: flex;
	width: 32px;
	height: 32px;
	background-color: rgba(74, 74, 74, 0.04);
`;

interface ITriggerList {
	elements?: number;
}

export const TriggerList = styled.div<ITriggerList>`
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #fff;
	height: ${({ elements }) =>
		elements && elements * 57 < 570 ? elements * 57 : 570}px;
	width: 100%;
	user-select: none;
	border-radius: 8px;
    div:first-child{
                border-top-right-radius: 8px;
				border-top-left-radius: 8px;
    }
    div:last-child{
                border-bottom-right-radius: 8px;
				border-bottom-left-radius: 8px;
    }
`;

export const TriggerListItem = styled.div`
	display: flex;
	height: 56px;
	padding-top: 18px;
	padding-left: 16px;
	padding-bottom: 16px;
	padding-right: 16px;
	box-sizing: border-box;
	background-color: #fff;
	cursor: pointer;
	:hover {
		background-color: rgba(74, 74, 74, 0.03);
	};
    overflow: hidden;
	transition: all 300ms ease;
`;

export const Devider = styled.div`
	width: 100%;
	height: 1px;
	background-color: #e7e7e8;
`;

export const NextContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 24px;
	height: 24px;
	margin-right: 0;
	margin-left: auto;
	& > svg {
		width: 24px;
		height: 24px;
		& > path {
			fill: ${({ theme: { colors } }) => colors.primary._700};
		}
	}
`;
