const authFetch = (input: RequestInfo, init?: RequestInit) => {
	const token = `${localStorage.getItem("token")}`;
	const headers = init?.headers;

	return fetch(input, {
		...init,
		headers: {
			Authorization: `Bearer ${token}`,
			Accept: "application/json",
			...headers,
		},
	});
};

export default authFetch;
