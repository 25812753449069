import styled, { css } from "styled-components";
import { slideInFromRight, slideOutToRight } from "../../../styles/animations";
// import { default as BaseSpinner } from "../../Spinners";

interface IContainer {
	state?: string;
}

export const Container = styled.div<IContainer>`
	position: fixed;
	top: 0px;
	right: 0px;
	right: 0px;
	width: 460px;
	box-sizing: border-box;
	height: 100%;
	background: #f6f6f6;
	overflow: hidden;

	${({ state }) =>
		(state === "entered" &&
			css`
				animation: ${slideInFromRight} 250ms;
			`) ||
		(state === "exiting" &&
			css`
				animation: ${slideOutToRight} 250ms;
			`)}
`;

export const BottomContainer = styled.div`
	position: absolute;
	height: 72px;
	width: 100%;
	margin-left: -24px;
	background: #fff;
	bottom: 0;
`;

export const TemplatesContainer = styled.div`
	width: 100%;
	height: calc(100vh - 476px);
`;

interface ICard{
	selected?: boolean;
}

export const Card = styled.div<ICard>`
	position: relative;
	width: calc(100% - 32px);
	height: 285px;
	margin-right: 16px;
	margin-left: 16px;
	padding-top: 14px;
	padding-bottom: 14px;
	margin-bottom: 16px;
	border-radius: 6px;
	background-color: #fff;
	transition: 300ms;
	cursor: pointer;

	${({ selected }) => selected && css`
		box-shadow: 0px 1px 9px 0px rgba(10,97,92,0.9);
	`}

	&:first-child{
		margin-top: 8px;
	}

	&:hover {
		box-shadow: 0px 1px 9px 0px rgba(0, 0, 0, 0.56);
	}
`;

export const CardImage = styled.div`
	position: relative;
	top: 16px;
	width: 100%;
	height: 255px;
	text-align: center;
	overflow: hidden;

	& img {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		max-width: 100%;
	}
`;