import { DateTime } from "luxon";
import React, {
  useEffect,
  useState,
  useRef,
  FC,
  useCallback,
} from "react";
import { useMemo } from "react";
import {
  Container,
  Header,
  Slider,
  HeaderText,
  MainContent,
  Day,
  CurrentDay,
  PrevNextDay,
  HeaderMonthContainer,
  HeaderMonthItem,
  MonthList,
  YearList, // add
  DayCellContent,
  CreateCampaign, // add
} from "./styled";
import { ReactComponent as AddIcon } from "../../assets/icons/add.svg";
import { CreateCampaignFor } from "../../pages/marketing/campaigns/index";
import useOutsideClick from "../../hooks/detectClickOutside";
import Button from '../Button';
import {ButtonSize, buttonTypes} from "../../styles/buttons";
import CalendarEvent from "../CalendarEvent";

/*
	TODO:

	NEED TO REWRITE WHOLE COMPONENT TO MORE READABLE FORM

	Should use luxon instead Native js Date

	Simplify structures
*/

interface IDate {
  year: number;
  month: number;
  days: number; // How many days in month
  day: number; // day of the week(1/currentMonth/currentYear)
}

interface ICurrentDate {
  year: number;
  month: number;
  day: number;
}

const month: Array<string> = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const headers: Array<string> = [
  "SUN",
  "MON",
  "TUE",
  "WED",
  "THU",
  "FRI",
  "SAT",
];

interface ITestItems { // delete after confirm CalendarEvent component
  name: string;
  id: string;
  time: string;
}

const testItems: Array<ITestItems> = [ // delete after confirm CalendarEvent component
  {
    name: "Some email sssssssssssssssssssssssssssssssssssssssssssssssssssssssssssss",
    id: "sdaweasd21312312",
    time: "2021-12-15T16:10:04.547+02:00",
  }, 
  {
    name: "Some email 2",
    id: "sdaweasd21312312",
    time: "2021-12-15T16:10:04.547+02:00",
  }, 
  {
    name: "Some email 3",
    id: "sdaweasd21312312",
    time: "2021-12-15T16:10:04.547+02:00",
  }, 
  {
    name: "Some email 4",
    id: "sdaweasd21312312",
    time: "2021-12-15T16:10:04.547+02:00",
  }, 
  {
    name: "Some email 5",
    id: "sdaweasd21312312",
    time: "2021-12-15T16:10:04.547+02:00",
  }, 
] 

const now = new Date();

interface ICalendar {
  date?: DateTime;
  onChange?: (date: DateTime) => void;
  addNewCampaign?: boolean; // add
  openCampaignModal?: (name?: string) => void; // add 
}

const Calendar: FC<ICalendar> = ({
  date = DateTime.local(),
  onChange = (_date: DateTime) => {},
  addNewCampaign = false, // for AddNewCampaignIcon
  openCampaignModal, // for CreateCampaignFor component
}) => {
  const wrapper = useRef<HTMLDivElement>(null);
  ///////////////////////////////////////////////////////// my code below
  const listRef = useRef<any>(null);
  const [showStores, setShowStores] = useOutsideClick(listRef, false);
  const [showAddCampaignIcon, setShowCampaignIcon] = useState<{
    id: string;
    show: boolean;
  }>({ id: "", show: false }); // mouseEnter, mouseLeave show add Icon
  const toggleShowAddCampaign = useCallback(
    (e: any) => {
      // for Day component
      if (e.target.dataset.id && showStores === false) {
        setShowCampaignIcon({ id: e.target.dataset.id, show: true });
      } else {
        setShowCampaignIcon({ id: showAddCampaignIcon.id, show: true });
      }
    },
    [showAddCampaignIcon, showStores]
  );
  const checkInsideContent = useCallback(() => {
    // for MainContent component(enter, leave)
    setShowCampaignIcon({ id: "", show: !showAddCampaignIcon.show });
    setShowStores(false);
  }, [showAddCampaignIcon.show]);
  const openStoreList = useCallback(() => {// for AddNewCampaignIcon component
    setShowStores(!showStores);
  }, [showStores]);
  const chooseStore = useCallback((e: any) => {
    if(openCampaignModal){
      openCampaignModal(e.target.id);
    }
  }, []);
  const renderAddNewCampaignIcon = useCallback(() => {
    return (
      <>
      {addNewCampaign && (
        <>
          <CreateCampaign onClick={openStoreList} ref={listRef}>
              <AddIcon />
          </CreateCampaign>
          <CreateCampaignFor
            open={showStores}
            onClick={chooseStore}
            top={38}
          />
        </>
      )}
    </>
    )
  }, [showStores]);

  const [openYearMonthContainer, setOpenYearMonthContainer] = useState<{
    year: boolean;
    month: boolean;
  }>({ year: false, month: false });

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        wrapper.current &&
        !wrapper.current.contains(event.target as HTMLElement)
      ) {
        setOpenYearMonthContainer({ year: false, month: false });
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [wrapper]);

  const todayDate: ICurrentDate = useMemo(
    () => ({
      year: now.getFullYear(),
      month: now.getMonth(),
      day: now.getDate(),
    }),
    []
  );
  let currentMonth: IDate = useMemo(
    () => ({
      year: now.getFullYear(),
      month: now.getMonth(),
      days: new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate(),
      day: new Date(now.getFullYear(), now.getMonth(), 1).getDay(),
    }),
    []
  );

  const [internalDate, setInternalDate] = useState<IDate>(currentMonth);
  const changeMonth = () => {
    setOpenYearMonthContainer({
      year: false,
      month: !openYearMonthContainer.month,
    });
  };
  const changeYear = () => {
    setOpenYearMonthContainer({
      year: !openYearMonthContainer.year,
      month: false,
    });
  };

  const nextMonth = useCallback(() => {
    if (internalDate.month === 11) {
      setInternalDate({
        year: internalDate.year + 1,
        month: 0,
        days: new Date(internalDate.year + 1, 1, 0).getDate(),
        day: new Date(internalDate.year + 1, 0, 1).getDay(),
      });
    } else {
      setInternalDate({
        year: internalDate.year,
        month: internalDate.month + 1,
        days: new Date(internalDate.year, internalDate.month + 2, 0).getDate(),
        day: new Date(internalDate.year, internalDate.month + 1, 1).getDay(),
      });
    }
  }, [internalDate]);

  const prevMonth = useCallback(() => {
    if (internalDate.month === 0) {
      setInternalDate({
        year: internalDate.year - 1,
        month: 11,
        days: new Date(internalDate.year - 1, 0, 0).getDate(),
        day: new Date(internalDate.year - 1, 11, 1).getDay(),
      });
    } else {
      setInternalDate({
        year: internalDate.year,
        month: internalDate.month - 1,
        days: new Date(internalDate.year, internalDate.month, 0).getDate(),
        day: new Date(internalDate.year, internalDate.month - 1, 1).getDay(),
      });
    }
  }, [internalDate]);

  const targetDay = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      let id: number = parseInt(
        (event.target as HTMLDivElement).dataset?.id! ||
          (event.target as HTMLDivElement).parentElement!.dataset?.id! ||
          (event.target as HTMLDivElement).parentElement!.parentElement!.dataset
            ?.id!,
        10
      );
      let day: number = parseInt(
        (event.target as HTMLDivElement).dataset?.day! ||
          (event.target as HTMLDivElement).parentElement!.dataset?.day! ||
          (event.target as HTMLDivElement).parentElement!.parentElement!.dataset
            ?.day!
      );

      const month = internalDate.month + 1;

      if (!isNaN(day)) {
        if (id < internalDate.day && day > 13) {
          // Previous Month
          if (internalDate.month === 0) {
            onChange(
              DateTime.fromObject({
                year: internalDate.year - 1,
                month: 12,
                day: day,
              })
            );
          } else {
            onChange(
              DateTime.fromObject({
                year: internalDate.year,
                month: internalDate.month,
                day: day,
              })
            );
          }
        } else if (id > internalDate.day + internalDate.days - 1 && day < 14) {
          // Next month
          if (internalDate.month === 11) {
            onChange(
              DateTime.fromObject({
                year: internalDate.year + 1,
                month: 1,
                day: day,
              })
            );
          } else {
            onChange(
              DateTime.fromObject({
                year: internalDate.year,
                month: month + 1,
                day: day,
              })
            );
          }
        } else {
          //Present month
          onChange(
            DateTime.fromObject({
              year: internalDate.year,
              month: month,
              day: day,
            })
          );
        }
      }
    },
    [internalDate]
  );

  const renderDays = useCallback(() => {
    let day: number = 1;
    let arr: any[] = [];
    let dayOfWeek: number = internalDate.day - 1;
    let prevMonth: number = new Date(
      internalDate.year,
      internalDate.month,
      0
    ).getDate();
    let nextMonth: number = 1;
    for (let counter = 0; counter < 42; counter++) {
      if (counter >= internalDate.day && day <= internalDate.days) {
        //Days of present month
        if (
          day === now.getDate() &&
          internalDate.year === now.getFullYear() &&
          internalDate.month === now.getMonth()
        ) {
          arr[counter] = (
            <CurrentDay
              id={`${counter}`} 
              data-id={`${counter}`}
              data-day={day}
              key={`${internalDate.month}-${counter}`}
              onMouseEnter={toggleShowAddCampaign}
              onMouseLeave={toggleShowAddCampaign}
            >
              <DayCellContent>
                <p id="currentDay">{counter < 7 ? headers[counter] : null}</p>
                {day}
              </DayCellContent>
              {/* my code below */}
              {counter.toString() === showAddCampaignIcon.id &&
                showAddCampaignIcon.show && renderAddNewCampaignIcon()}
                {addNewCampaign && <CalendarEvent info={testItems} id={`${counter}`}/>}
              {/* end of my code */}
            </CurrentDay>
          ); //выделить текущий день
          day++;
        } else if (
          date.year === internalDate.year &&
          date.month === internalDate.month + 1 &&
          date.day === day
        ) {
          arr[counter] = (
            <Day
              id={`${counter}`} 
              data-id={`${counter}`}
              data-day={day}
              key={`${internalDate.month}-${counter}`}
              targetDay={true}
              onMouseEnter={toggleShowAddCampaign}
              onMouseLeave={toggleShowAddCampaign}
            >
              <DayCellContent>
                <p>{counter < 7 ? headers[counter] : null}</p>
                {day}
              </DayCellContent>
              {/* my code below */}
              {counter.toString() === showAddCampaignIcon.id &&
                showAddCampaignIcon.show && renderAddNewCampaignIcon()}
                 {addNewCampaign && <CalendarEvent info={testItems} id={`${counter}`}/>}
              {/* end of my code */}
            </Day>
          );
          day++;
        } else {
          arr[counter] = (
            <Day
              id={`${counter}`} 
              data-id={`${counter}`}
              data-day={day}
              key={`${internalDate.month}-${counter}`}
              targetDay={false}
              onMouseEnter={toggleShowAddCampaign}
              onMouseLeave={toggleShowAddCampaign}
            >
              <DayCellContent>
                <p>{counter < 7 ? headers[counter] : null}</p>
                {day}
              </DayCellContent>
              {/* my code below */}
              {counter.toString() === showAddCampaignIcon.id &&
                showAddCampaignIcon.show && renderAddNewCampaignIcon()}
              {/* end of my code */}
            </Day>
          );
          day++;
        }
      } else if (counter <= internalDate.day && dayOfWeek !== -1) {
        //Last days of prevMonth
        if (
          (date.year === internalDate.year &&
            date.month === internalDate.month &&
            date.day === prevMonth - dayOfWeek) ||
          (date.year === internalDate.year - 1 &&
            date.month === 12 &&
            date.day === prevMonth - dayOfWeek)
        ) {
          arr[counter] = (
            <PrevNextDay
              id={`${counter}`} 
              data-id={`${counter}`}
              data-day={prevMonth - dayOfWeek}
              key={`${internalDate.month}-${counter}`}
              targetDay={true}
              onMouseEnter={toggleShowAddCampaign}
              onMouseLeave={toggleShowAddCampaign}
            >
              <DayCellContent>
                <p>{counter < 7 ? headers[counter] : null}</p>
                {prevMonth - dayOfWeek}
              </DayCellContent>
              {/* my code below */}
              {counter.toString() === showAddCampaignIcon.id &&
                showAddCampaignIcon.show && renderAddNewCampaignIcon()}
              {/* end of my code */}
            </PrevNextDay>
          );
          dayOfWeek--;
        } else {
          arr[counter] = (
            <PrevNextDay
              id={`${counter}`} 
              data-id={`${counter}`}
              data-day={prevMonth - dayOfWeek}
              key={`${internalDate.month}-${counter}`}
              targetDay={false}
              onMouseEnter={toggleShowAddCampaign}
              onMouseLeave={toggleShowAddCampaign}
            >
              <DayCellContent>
                <p>{counter < 7 ? headers[counter] : null}</p>
                {prevMonth - dayOfWeek}
              </DayCellContent>
              {/* my code below */}
              {counter.toString() === showAddCampaignIcon.id &&
                showAddCampaignIcon.show && renderAddNewCampaignIcon()}
              {/* end of my code */}
            </PrevNextDay>
          );
          dayOfWeek--;
        }
      } else if (
        (date.year === internalDate.year &&
          date.month === internalDate.month + 1 + 1 &&
          date.day === nextMonth) ||
        (date.year === internalDate.year + 1 &&
          date.month === 1 &&
          date.day === nextMonth)
      ) {
        //First days of                   nextMonth
        arr[counter] = (
          <PrevNextDay
            id={`${counter}`} 
            data-id={`${counter}`}
            data-day={nextMonth}
            key={`${internalDate.month}-${counter}`}
            targetDay={true}
            onMouseEnter={toggleShowAddCampaign}
            onMouseLeave={toggleShowAddCampaign}
          >
            <DayCellContent>{nextMonth}</DayCellContent>
            {/* my code below */}
            {counter.toString() === showAddCampaignIcon.id &&
              showAddCampaignIcon.show && renderAddNewCampaignIcon()}
            {/* end of my code */}
          </PrevNextDay>
        );
        nextMonth++;
      } else {
        arr[counter] = (
          <PrevNextDay
            id={`${counter}`} 
            data-id={`${counter}`}
            data-day={nextMonth}
            key={`${internalDate.month}-${counter}`}
            targetDay={false}
            onMouseEnter={toggleShowAddCampaign}
            onMouseLeave={toggleShowAddCampaign}
          >
            <DayCellContent>{nextMonth}</DayCellContent>
            {/* my code below */}
            {counter.toString() === showAddCampaignIcon.id &&
              showAddCampaignIcon.show && renderAddNewCampaignIcon()}
            {/* end of my code */}
          </PrevNextDay>
        );
        nextMonth++;
      }
    }
    return arr;
  }, [date, internalDate, showAddCampaignIcon, showStores]);
  ////////////////////////////////////////////////////////////////////
  const showMonth = useCallback(() => {
    return (
      <>
        {month.map((item, index) => {
          return (
            <HeaderMonthItem
              choose={index === internalDate.month ? true : false}
              key={`headerMonth-${index}`}
            >
              {item}
            </HeaderMonthItem>
          );
        })}
      </>
    );
  }, [internalDate.month]);

  const setNewMonth = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      let newMonth = month.indexOf((event.target as any).textContent);
      setInternalDate({
        year: internalDate.year,
        month: newMonth,
        days: new Date(internalDate.year, newMonth + 1, 0).getDate(),
        day: new Date(internalDate.year, newMonth, 1).getDay(),
      });
      setOpenYearMonthContainer({ year: false, month: false });
    },
    [internalDate]
  );
  //////////////////////////////////////
  const setNewYear = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      let newYear: number = parseInt((event.target as any).textContent, 10);
      setInternalDate({
        year: newYear,
        month: internalDate.month,
        days: new Date(newYear, internalDate.month + 1, 0).getDate(),
        day: new Date(newYear, internalDate.month, 1).getDay(),
      });
      setOpenYearMonthContainer({ year: false, month: false });
    },
    [internalDate]
  );

  const showYear = useCallback(() => {
    let years: JSX.Element[] = [];
    for (let i = currentMonth.year - 13; i < currentMonth.year + 11; i++) {
      years.push(
        <>
          <HeaderMonthItem
            choose={i === internalDate.year ? true : false}
            key={`headerYear-${internalDate.year}-${internalDate.days}`}
          >
            {i}
          </HeaderMonthItem>
        </>
      );
    }
    return years;
  }, [internalDate.year]);
  /////////////////////////////////////////////////
  const backToday = useCallback(() => {
    setInternalDate(currentMonth);
    onChange(DateTime.local());
  }, [currentMonth]);
  return (
    <Container>
      <Header>
        {/* <HeaderText ref={wrapper} onClick={changeMonth}> */}
        <HeaderText ref={wrapper}>
          {/* Add to divs below */}
          <div onClick={changeMonth}>
            {`${month[internalDate.month]}`}&nbsp;
          </div>
          <div onClick={changeYear}>{`${internalDate.year}`}</div>
          {/* End of changes */}
          {openYearMonthContainer.month && (
            <HeaderMonthContainer onClick={setNewMonth}>
              <MonthList
                height={150}
                width={140}
                rowCount={12}
                rowHeight={30}
                rowRenderer={showMonth}
                scrollToIndex={internalDate.month}
                scrollToAlignment="center"
              />
            </HeaderMonthContainer>
          )}
          {/* my changes below */}
          {openYearMonthContainer.year && (
            <HeaderMonthContainer onClick={setNewYear}>
              <YearList
                height={150}
                width={140}
                rowCount={24}
                rowHeight={30}
                rowRenderer={showYear}
                scrollToIndex={internalDate.year - (currentMonth.year - 13)}
                scrollToAlignment="center"
              />
            </HeaderMonthContainer>
          )}
          {/* End of my changes */}
        </HeaderText>
        <Slider slide="left" onClick={prevMonth} />
        <Slider slide="right" onClick={nextMonth} />
        <Button 
          variant={buttonTypes.outlineSecondary}
          size={ButtonSize.md}
          type="button"
          onClick={backToday}
        >
          Today
        </Button>
      </Header>
      <MainContent
        id="CalendarMainContent"
        onClick={targetDay}
        onMouseEnter={checkInsideContent}
        onMouseLeave={checkInsideContent}
        addNewCampaign={addNewCampaign}
      >
        {renderDays()}
      </MainContent>
    </Container>
  );
};

export default Calendar;
