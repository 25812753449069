import styled from "../../../../../styles/theme";

export const CampaignSettings = styled.div`
  background-color: "transparent";
  color: rgba(24, 24, 25, 0.9);
  margin: 40px 192px auto 192px;
  @media (max-width: 2050px) {
    & > #campaign {
      margin-right: 500px;
    }
  }
  @media (max-width: 1600px) {
    & > #campaign {
      margin-right: auto;
    }
  }
  @media (max-width: 1500px) {
    margin-left: 40px;
    margin-right: 40px;
  } ;
`;

export const Wrapper = styled.div`
  display: flex;
`;
