import styled from "../../../styles/theme";
import {css} from 'styled-components';
import { fadeIn, fadeOut } from "../../../styles/animations";

export const Container = styled.div`
	width: 100%;
	height: 100%;
	 & .ReactVirtualized__Grid__innerScrollContainer{
		overflow: visible;
	};
	& .ReactVirtualized__Grid.ReactVirtualized__List{
		overflow: visible;
	}
`;

export const Item = styled.div`
	position: relative; // add my code
	height: 103px;
	box-sizing: border-box;
	border-radius: 0px 0px 0px 0px;
	background: #fff;
	border-bottom: 1px solid rgba(196, 196, 196, 0.4);
	padding-left: 25px;
	padding-right: 35px;
	padding-top: 22px;
	padding-bottom: 22px;

	&:first-child {
		border-radius: 8px 8px 0px 0px;
	}

	&:last-child {
		border-radius: 0px 0px 8px 8px;
		border-bottom: unset;
	}
`;

const badgeColor = (status?: string) => {
	switch (status) {
		case "sent":
			return "rgba(0, 192, 138, 0.15)";
		case "active":
			return "rgba(0, 192, 138, 0.15)";
		case "draft":
			return "rgba(255, 167, 43, 0.15)";
		case "default":
			return "rgba(255, 167, 43, 0.15)";
	}
};

const badgeTextColor = (status?: string) => {
	switch (status) {
		case "sent":
			return "#00C08A";
		case "active":
			return "#00C08A";
		case "draft":
			return "#FFA72B";
		case "default":
			return "#FFA72B";
	}
};

interface IStatusBadge {
	status?: string;
}

export const StatusBadge = styled.div<IStatusBadge>`
	border-radius: 100px;
	padding: 6px 12px;
	background: ${({ status }) => badgeColor(status)};
	color: ${({ status }) => badgeTextColor(status)};
	font-weight: 600;
	font-size: 12px;
	line-height: 16px;
	width: fit-content;
	position: relative;
	margin-left: 52px;

	@media (max-width: 1425px) {
		margin-left: 20px;
	}
`;

export const ButtonsContainer = styled.div`
	min-width: 152px;
`;

export const Button = styled.button`
	padding: 6px 12px;
	background: rgba(65, 65, 65, 0.04);
	float: right;
	border: none;
	border-radius: 4px;
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	outline: none;
	cursor: pointer;
`;

export const IconButton = styled.button`
	background: rgba(65, 65, 65, 0.04);
	float: right;
	margin-left: 4px;
	border: none;
	border-radius: 4px;
	padding: 7px 7px;
	outline: none;
	width: 32px;
	height: 32px;
	cursor: pointer;
`;

export const StatsItem = styled.div`
	max-width: 50px;
	position: relative;
	left: 50%;
	transform: translateX(-50%);
`;


interface ISettingsContainer {
	state?: string;
};

export const SettingsContainer = styled.div<ISettingsContainer>`
	position: absolute;
	display: flex;
	flex-direction: column;
	width: 192px;
	border-radius: 4px;
	background-color: #fff;
	top: 64px;
	right: 29px;
	z-index: 99999;
	padding: 8px 7px;
	box-shadow: 2px 4px 20px 4px rgba(34, 60, 80, 0.11);
	${({ state }) =>
		(state === "entering" &&
			css`
				animation: ${fadeIn} 300ms;
			`) ||
		(state === "exiting" &&
			css`
				animation: ${fadeOut} 300ms;
			`)}
`;

export const SettingsItem = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	color: rgba(24, 24, 25, 0.9);
	height: 32px;
	font-size: ${({theme: {typography}}) => typography.bodyMd.size};
	font-weight: ${({theme: {typography}}) => typography.bodyMd.weight};
	line-height: ${({theme: {typography}}) => typography.bodyMd.lineHeight};
	cursor:pointer;
	border-radius: 4px;
	transition: all 300ms ease;
	& > svg{
		width: 18px;
		height: 18px;
		margin-right: 12px;
		& > path {
			stroke: rgba(24, 24, 25, 0.42);
			fill: transparent;
		}
	};
	:hover{
		background-color: #f6f6f6;
	};
`;



